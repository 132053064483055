import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';

@Component({
  selector: 'xormon-reporter-history',
  templateUrl: './reporter-history.component.html',
  styleUrls: ['./reporter-history.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReporterHistoryComponent implements OnDestroy {

  private navigationSubscription = Subscription.EMPTY;
  loading = true;

  constructor(private router: Router, private dataService: DataService, private http: HttpClient,
    private log: LoggerService, private confirmationService: ConfirmationService) {
    this.navigationSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.dataService.nodeSelected.promise.then(() => {
          if (!this.navigationSubscription.closed) {
            if (Globals.isSameNavigation(e))
              return;
            this.init();
          }
        });
      });
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }

  private init() {
    const self = this;
    const rh = $('#reporterHistory');
    rh.empty();
    this.loading = true;
    this.http.get(Globals.RUNTIME_PATH + this.dataService.cgiPath + '/reporter.sh?cmd=history', { responseType: 'text' }).subscribe(data => {
      this.loading = false;
      let dataRep = data.replace(/'/g, '"');
      dataRep = dataRep.replace(/href="/g, 'href="' + Globals.htmlUrlPrefix + Globals.RUNTIME_PATH);
      rh.html(dataRep);
      //this.dataService.mainLib.myreadyFunc();
      setTimeout(() => {
        $('#rephistory').tablesorter({
          theme: 'ice',
          ignoreCase: false,
          sortList: [[0, 0]],
          widgets: ['group', 'stickyHeaders'],
          widgetOptions: {
            // css class name applied to the sticky header
            stickyHeaders: "tablesorter-stickyHeader",
            //stickyHeaders_appendTo: '.stickyheaders-holder',
            stickyHeaders_attachTo: '.content-main',
            //stickyHeaders_xScroll: $(this).parents('.scroll-wrap').get(0),
            stickyHeaders_yScroll: '.content-main',

            group_collapsed: false,
            group_saveGroups: false,
            group_callback($cell, $rows, column, table) {
              const colspan = $cell.find('.group-name').parent().prop('colspan');
              $cell.find('.group-name').parent().prop('colspan', colspan - 1);
              $cell.find('.group-name').parent().after('<td class=\'remrepgr\'><div class=\'delete group\'></div></td>');
            }
          },
          textExtraction(node, table, cellIndex) {
            const n = $(node);
            return n.attr('data-sortValue') || n.text();
          },
          headers: {
            2: {
              sorter: 'metric'
            }
          }
        });
        $('#rephistory div.delete').on('click', function (event) {
          const isGroup = $(this).hasClass('group');
          const cstr = isGroup ? 'Are you sure you want to delete all history of this report?' :
            'Are you sure you want to delete this report?';

          self.confirmationService.confirm({
            message: cstr,
            header: 'Report delete confirmation',
            accept: () => {
              var repname, repfile, cmd;
              if (isGroup) {
                repname = $(event.target).parent().siblings().find('.group-name').text();
                cmd = 'remdir';
              } else {
                repname = $(event.target).parent().parent().find('.repname').text();
                repfile = $(event.target).parent().parent().find('.repfile').text();
                cmd = 'remrep';
              }
              var postdata = { cmd: cmd, repfile: repfile, repname: repname };

              $.getJSON(self.dataService.cgiPath + '/reporter.sh', postdata, function (data) {
                //SaveRepCfg();
                if (data.success) {
                  self.log.info('Generated report history deleted successfully.');
                  self.init();
                } else {
                  self.log.error('Problem deleting report history!', data);
                  alert(data.log);
                }
              });
            }
          });
        });
      });

    }, error => this.log.error('Failed to get history items!', error));
  }
}
