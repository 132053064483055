/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./health-status.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i4 from "@angular/material/tabs";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../../../loading-indicator/loading-indicator.component.ngfactory";
import * as i7 from "../../../loading-indicator/loading-indicator.component";
import * as i8 from "./health-status.component";
import * as i9 from "../../../data.service";
import * as i10 from "@angular/common/http";
import * as i11 from "../../../logger.service";
import * as i12 from "@angular/router";
var styles_HealthStatusComponent = [i0.styles];
var RenderType_HealthStatusComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_HealthStatusComponent, data: {} });
export { RenderType_HealthStatusComponent as RenderType_HealthStatusComponent };
function View_HealthStatusComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "text-center font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "ok"], ["title", "OK"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" | "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "nok"], ["title", "Not OK"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵted(-1, null, [" | "])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "na"], ["title", "Not available"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.hsOK; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.hsNOK; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.hsNA; _ck(_v, 8, 0, currVal_2); }); }
function View_HealthStatusComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 0, 0, currVal_0); }); }
function View_HealthStatusComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HealthStatusComponent_6)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.htmls; _ck(_v, 2, 0, currVal_0); }, null); }
function View_HealthStatusComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 8, "mat-tab", [], null, null, null, i3.View_MatTab_0, i3.RenderType_MatTab)), i1.ɵdid(1, 770048, [[1, 4]], 2, i4.MatTab, [i1.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i1.ɵqud(603979776, 2, { templateLabel: 0 }), i1.ɵqud(335544320, 3, { _explicitContent: 0 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_HealthStatusComponent_4)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, 0, 2, "div", [["class", "stickyheaders-holder center mt-1"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HealthStatusComponent_5)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.name, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = (!_v.context.index && ((_co.hsOK || _co.hsNOK) || _co.hsNA)); _ck(_v, 5, 0, currVal_1); var currVal_3 = _v.context.$implicit.htmls; _ck(_v, 8, 0, currVal_3); }, function (_ck, _v) { var currVal_2 = i1.ɵinlineInterpolate(1, "", ("tab" + _v.context.index), ""); _ck(_v, 6, 0, currVal_2); }); }
function View_HealthStatusComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "mat-tab-group", [["class", "mat-tab-group"]], [[24, "@.disabled", 0], [2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], [[null, "selectedTabChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedTabChange" === en)) {
        var pd_0 = (_co.onTabSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatTabGroup_0, i3.RenderType_MatTabGroup)), i1.ɵdid(1, 3325952, null, 1, i4.MatTabGroup, [i1.ElementRef, i1.ChangeDetectorRef, [2, i4.MAT_TABS_CONFIG], [2, i5.ANIMATION_MODULE_TYPE]], null, { selectedTabChange: "selectedTabChange" }), i1.ɵqud(603979776, 1, { _tabs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HealthStatusComponent_3)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.tabPage.tps; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 1).dynamicHeight; var currVal_2 = (i1.ɵnov(_v, 1).headerPosition === "below"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_HealthStatusComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 0, 0, currVal_0); }); }
function View_HealthStatusComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "stickyheaders-holder"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HealthStatusComponent_8)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabPage.tpCommon.htmls; _ck(_v, 2, 0, currVal_0); }, null); }
function View_HealthStatusComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["id", "health_status"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HealthStatusComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HealthStatusComponent_7)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.tabPage.tps && _co.tabPage.tps.length); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.tabPage.tpCommon; _ck(_v, 4, 0, currVal_1); }, null); }
function View_HealthStatusComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xormon-loading-indicator", [], null, null, null, i6.View_LoadingIndicatorComponent_0, i6.RenderType_LoadingIndicatorComponent)), i1.ɵdid(1, 114688, null, 0, i7.LoadingIndicatorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_HealthStatusComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HealthStatusComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HealthStatusComponent_9)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabPage; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.tabPage; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_HealthStatusComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xormon-health-status", [], null, null, null, View_HealthStatusComponent_0, RenderType_HealthStatusComponent)), i1.ɵdid(1, 245760, null, 0, i8.HealthStatusComponent, [i9.DataService, i10.HttpClient, i11.LoggerService, i12.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HealthStatusComponentNgFactory = i1.ɵccf("xormon-health-status", i8.HealthStatusComponent, View_HealthStatusComponent_Host_0, {}, {}, []);
export { HealthStatusComponentNgFactory as HealthStatusComponentNgFactory };
