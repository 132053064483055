<div [hidden]="result" class="text-center scroll-flex">
  <h3>{{pageTitle}}</h3>
  <select id="histrepsrc" class="d-none" *ngIf="source" [(ngModel)]="source">
    <option value="SAN"></option>
    <option value="LAN"></option>
  </select>
  <div id="histRepHolder" class="extraHtml"></div>
</div>

<xormon-tabs *ngIf="result" [data]="result" class="d-flex flex-grow-1 flex-column"></xormon-tabs>
