import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';
import { Product } from 'src/app/model/product';

@Component({
  selector: 'xormon-rmc-check',
  templateUrl: './rmc-check.component.html',
  styleUrls: ['./rmc-check.component.scss']
})
export class RmcCheckComponent implements OnInit {

  html;

  constructor(private http: HttpClient, private sanitizer: DomSanitizer, private log: LoggerService) { }

  ngOnInit() {
    this.http.get(Globals.RUNTIME_PATH + Product.LPAR.path + 'gui-rmc.html', { responseType: 'text' }).subscribe(data => {
      this.html = this.sanitizer.bypassSecurityTrustHtml(data);
    }, error => this.log.error('Failed to get RMC page!', error));
  }

}
