<h1 mat-dialog-title>Import shared dashboards</h1>
<div mat-dialog-content stylex="width: 500px; height: 100px;">
    <p-table #tt [value]="publicDashboards" selectionMode="single" [(selection)]="selectedDashboard" [loading]="loading"
        dataKey="id" (onRowSelect)="onRowSelected($event)">
        <ng-template pTemplate="header">
            <tr>
                <th [pSortableColumn]="'name'">Name <p-sortIcon [field]="'name'"></p-sortIcon>
                </th>
                <th style="width: 14em;" pSortableColumn="technology">Technology <p-sortIcon field="technology">
                    </p-sortIcon>
                </th>
                <th pSortableColumn="user">User <p-sortIcon field="user"></p-sortIcon>
                </th>
            </tr>
            <tr>
                <th>
                    <input pInputText type="text" (input)="tt.filter($event.target.value, 'name', 'contains')"
                        style="width:100%">
                </th>
                <th>
                    <p-dropdown [options]="technologies" [style]="{'width':'100%'}" [(ngModel)]="selectedTechno"
                        baseZIndex="9" (onChange)="tt.filter($event.value, 'technology', 'equals')" appendTo="body">
                    </p-dropdown>
                </th>
                <th>
                    <p-multiSelect [options]="users" defaultLabel="All users" [style]="{'width':'100%'}" baseZIndex="9"
                        (onChange)="tt.filter($event.value, 'user', 'in')" appendTo="body"></p-multiSelect>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-dash>
            <tr [pSelectableRow]="rowData">
                <td>{{dash.name}}</td>
                <td>{{dash.technology}}</td>
                <td>{{dash.user}}</td>
            </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
            <tr>
                <td [attr.colspan]="3">
                    No shared dashboards found for specified criteria
                </td>
            </tr>
        </ng-template>
    </p-table>
</div>
<div mat-dialog-actions class="text-right" align="end">
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" (click)="importAsReadonly()"
        title="Import selected dashboards as live readonly reference"
        [disabled]="!selectedDashboard || selectedDashboard.userId===userId || alreadyImported">Import as
        view</button>
    <button mat-raised-button color="primary" (click)="importAsCopy()"
        title="Import selected dashboards as copy of their current state" [disabled]="!selectedDashboard">Import
        as copy</button>
</div>
