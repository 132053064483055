<div class="row w-100 dashboard-manage">
  <div class="col-xl-6 mb-2">
    <p-table #tWritable [value]="writableTabs" dataKey="id" tableStyleClass="table" rowHover="true">
      <ng-template pTemplate="caption">
        Your dashboards
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th [pSortableColumn]="'name'">Name <p-sortIcon [field]="'name'"></p-sortIcon>
          </th>
          <th style="width: 14em;" pSortableColumn="technology">Technology <p-sortIcon field="technology">
            </p-sortIcon>
          </th>
          <th style="width: 9em;" pSortableColumn="shared">Shared <p-sortIcon field="shared"></p-sortIcon>
          </th>
          <th style="width: 5em;">Delete</th>
        </tr>
        <tr>
          <th>
            <input pInputText type="text" (input)="tWritable.filter($event.target.value, 'name', 'contains')"
              style="width:100%">
          </th>
          <th>
            <p-dropdown [options]="technologiesW" [style]="{'width':'100%'}" [(ngModel)]="selectedTechnoW"
              (onChange)="tWritable.filter($event.value, 'technology', 'equals')"></p-dropdown>
          </th>
          <th>
            <p-dropdown [options]="sharedOpts" [style]="{'width':'100%', 'min-width': '7em'}"
              (onChange)="tWritable.filter($event.value, 'shared', 'equals')"></p-dropdown>
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-dash>
        <tr [pSelectableRow]="rowData">
          <td><a
              [routerLink]="['/', { outlets: { primary: [routeType, dash.technology, (dash.name===defaultName || dash.name==='Global' ? dash.name : dash.id)], content: 'portal'}}]">
              {{dash.name}}</a>
          </td>
          <td>{{dash.technology}}</td>
          <td class="text-center"
            [title]="rowData.shared ? 'Unshare this dashboard' : 'Share this dashboard with other users'"
            [ngClass]="{'disabled': !rowData.shared, 'shared': rowData.shared}">
            <a class="pointer" (click)="switchShare(rowData)"><i class="fas fa-share"></i></a>
          </td>
          <td class="text-center"
            title="{{dash.name===defaultName || dash.name==='Global' ? 'Cannot delete default dashboard' : 'Permanently delete this dashboard'}}">
            <button class="pointer hover-danger btn btn-link" (click)="deleteTab(rowData)"
              [disabled]="dash.name===defaultName || dash.name==='Global'"><i class="fas fa-trash-alt"></i></button>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="4">
            No dashboards found for specified criteria
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="col-xl-6 mb-2">
    <p-table #tReferenced [value]="referencedTabs" [loading]="loadingReferenced" dataKey="id" tableStyleClass="table"
      rowHover="true">
      <ng-template pTemplate="caption">
        Referenced dashboards
        <a [href]="helpUrl" target="_blank" class="float-right text-info" title="Dashboard documentation"><i
            class="fas fa-question-circle fa-lg"></i></a>
      </ng-template>
      <ng-template pTemplate="header">
        <tr>
          <th [pSortableColumn]="'name'">Name <p-sortIcon [field]="'name'"></p-sortIcon>
          </th>
          <th style="width: 14em;" pSortableColumn="technology">Technology <p-sortIcon field="technology">
            </p-sortIcon>
          </th>
          <th pSortableColumn="user">User <p-sortIcon field="user"></p-sortIcon>
          </th>
          <th style="width: 6em;">Remove</th>
        </tr>
        <tr>
          <th>
            <input pInputText type="text" (input)="tReferenced.filter($event.target.value, 'name', 'contains')"
              style="width:100%">
          </th>
          <th>
            <p-dropdown [options]="technologiesR" [style]="{'width':'100%'}" [(ngModel)]="selectedTechnoR"
              (onChange)="tReferenced.filter($event.value, 'technology', 'equals')">
            </p-dropdown>
          </th>
          <th>
            <p-multiSelect [options]="usersR" defaultLabel="All users" [style]="{'width':'100%'}"
              (onChange)="tReferenced.filter($event.value, 'user', 'in')"></p-multiSelect>
          </th>
          <th></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-dash>
        <tr [pSelectableRow]="rowData" [ngClass]="{'disabled': !rowData.shared}"
          [title]="rowData.shared ? '' : 'This dashboard is currently unavailable'">
          <td>
            <a *ngIf="rowData.shared"
              [routerLink]="['/', { outlets: { primary: [routeType, dash.technology, dash.id], content: 'portal'}}]">{{dash.name}}</a>
            <div *ngIf="!rowData.shared">{{dash.name}}</div>
          </td>
          <td>{{dash.technology}}</td>
          <td>{{dash.user}}</td>
          <td class="text-center"><a title="Remove this dashboard from your collection" class="pointer hover-danger"
              (click)="removeTab(rowData)"><i class="fas fa-times"></i></a>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <tr>
          <td [attr.colspan]="4">
            No shared dashboards found for specified criteria
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
<div class="row justify-content-end w-100">
  <button class="btn btn-primary m-1" (click)="importDashboards()" title="View other users' shared dashboards"><i
      class="fas fa-chart-line"></i>
    View public
    dashboards</button>
</div>
