<mat-tab-group (selectedTabChange)="tabSelected($event)" [@.disabled]="true" *ngIf="rca">
    <mat-tab *ngFor="let rtp of rca.rtps" label="{{rtp.name}}">
        <div style="overflow: auto;" class="rca center">
            <table>
                <tr *ngFor="let row of rtp.rows">
                    <td *ngFor="let cell of row.cells">
                        <div *ngIf="cell.html" [innerHTML]="cell.html"></div>
                    </td>
                </tr>
                <tr *ngFor="let row of rca.rtpCommon.rows">
                    <td *ngFor="let cell of row.cells">
                        <div *ngIf="cell.html" [innerHTML]="cell.html"></div>
                    </td>
                </tr>
            </table>
        </div>
    </mat-tab>
</mat-tab-group>
