import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';
import { Deferred } from 'src/app/model/deferred';

@Component({
  selector: 'xormon-multipath',
  templateUrl: './multipath.component.html',
  styleUrls: ['./multipath.component.scss']
})
export class MultipathComponent implements OnInit, AfterViewInit {

  private readonly viewInited = new Deferred();

  constructor(private dataService: DataService, private http: HttpClient, private log: LoggerService) { }

  ngOnInit() {
    this.dataService.nodeSelected.promise.then(() => {
      this.http.get(Globals.API_HOST + Globals.RUNTIME_PATH + this.dataService.selectedNode.data.url, { responseType: 'text' }).subscribe(response => {
        this.viewInited.promise.then(() => {
          $('#multipath').html(response);
          Globals.tableSorter('table', true, null, true);
        }, reason => console.warn(reason));
      }, error => this.log.error('Failed to get multipath!', error));
    }, reason => console.warn(reason));
  }

  ngAfterViewInit(): void {
    this.viewInited.resolve();
  }

}
