import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalSearchService } from '../content/settings/global-search/global-search.service';
import { DataService } from '../data.service';
import { BooleanWrap, Globals } from '../globals';
import { MenuItem, MenuItems } from '../menu-items';
import { ROUTES } from '../model/routes';
import { AuthService } from '../_security/auth.service';


@Component({
  selector: 'xormon-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss']
})
export class TopMenuComponent implements OnInit {

  configItems: MenuItem;
  dashboardItems: MenuItem;
  reportItems: MenuItem;
  serverPlatforms: {};
  storageTypes: {};
  networkTypes: {};
  globalSearchText: string;
  rootHolder: {};
  displaySearchbox: boolean;
  searchInputActive = false;
  customGroupItems: MenuItem;

  private searchboxTimeout: number;

  constructor(private dataService: DataService, private authService: AuthService,
    private router: Router, private searchService: GlobalSearchService) {
  }

  ngOnInit() {
    this.configItems = MenuItems.configItems;
    this.dashboardItems = MenuItems.dashboardItems;
    this.reportItems = MenuItems.reportItems;
    this.serverPlatforms = this.dataService.serverPlatforms;
    this.storageTypes = this.dataService.storageTypes;
    this.networkTypes = this.dataService.networkTypes;
    this.rootHolder = this.dataService.sgHolder;
    this.customGroupItems = MenuItems.customGroupItems;
  }

  get userFullName() {
    if (Globals.currentUser)
      return Globals.currentUser.name;
  }

  get userLoginName() {
    if (Globals.currentUser)
      return Globals.currentUser.username;
  }

  get isAdmin() {
    return Globals.IS_USER_ADMIN;
  }

  get activeMenu() {
    return this.dataService.selectedType;
  }

  get activeMenu2() {
    return this.dataService.selectedParent;
  }

  get isStorage() {
    return Globals.BACKEND_INFO.storEnabled.value && Globals.STORAGE_PRESENT.value;
  }

  get isNetwork() {
    return Globals.BACKEND_INFO.storEnabled.value && (Globals.LAN_PRESENT.value || Globals.SAN_PRESENT.value);
  }

  get isServer() {
    return Globals.BACKEND_INFO.lparEnabled.value && Globals.SERVER_PRESENT.value;
  }

  get isCustomGroup() {
    return this.customGroupItems.children.some(mi => MenuItems.isVisibleWraps(mi.visible));
  }

  isVisible(wraps: BooleanWrap[]) {
    return MenuItems.isVisibleWraps(wraps);
  }

  searchGlobal(event) {

    this.searchService.globalSearchQuery = this.globalSearchText;
    this.searchService.globalSearchResults = null;

    this.router.navigate([{
      outlets: {
        content: [ROUTES.GLOB_SEARCH],
        primary: [Globals.TYPE_CONFIG, Globals.TYPE_OTHER, Globals.TYPE_GLOB_SEARCH]
      }
    }], {
      replaceUrl: this.dataService.isGlobSearch(),
      queryParams: { q: this.globalSearchText }
    });
  }

  onSearchInputEnter() {
    window.clearTimeout(this.searchboxTimeout);
    this.searchInputActive = false;
    this.displaySearchbox = true;
    this.focus('#globalSearchbox');
  }

  onSearchInputLeave() {
    if (!this.searchInputActive) {
      window.clearTimeout(this.searchboxTimeout);
      this.searchboxTimeout = window.setTimeout(() => {
        this.displaySearchbox = false;
      }, 1000);
    }
  }

  focus(id) {
    setTimeout(() => $(id).focus());
  }

  logout(event) {
    event.preventDefault();
    this.authService.logout(true);
  }

  keepOrder = (a, b) => {
    return 0;
  }

  get typeConfig() {
    return Globals.TYPE_CONFIG;
  }

  get typeSettings() {
    return Globals.TYPE_OTHER;
  }
}
