import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/globals';
import { Product } from 'src/app/model/product';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class StorageTotalsService {
    constructor(http) {
        this.http = http;
    }
    getAllDataCap(storage) {
        if (storage !== null) {
            return this.http.get(Globals.RUNTIME_PATH + Product.STOR.pathCgi + '/detail-graph.sh?host=' + storage + '&type=POOL&name=cap_pool&item=sum&time=d', { responseType: 'text' });
        }
        else {
            return this.http.get(Globals.RUNTIME_PATH + Product.STOR.pathCgi + '/genjson.sh?jsontype=caps');
        }
    }
    getOneValCap(value) {
        return this.http.get(Globals.RUNTIME_PATH + value.url, { responseType: 'text' });
    }
    getTabsHost() {
        return this.http.post('/api/tabs', { url: 'glob-hosts-top.html', rrdType: 'stor', type: 'totals' });
    }
    getUrlHost(url) {
        return this.http.get(Globals.RUNTIME_PATH + url, { responseType: 'text' });
    }
    getPageConfigurationLpar(url) {
        return this.http.get(Globals.RUNTIME_PATH + url, { responseType: 'text' });
    }
    getLocalHeatMapUrl(url) {
        return this.http.get(Globals.RUNTIME_PATH + Product.STOR.path + url, { responseType: 'text' });
    }
    getLocalHeatMap(storage) {
        return this.http.get(Globals.RUNTIME_PATH + Product.STOR.path + storage + '/heatmap.html', { responseType: 'text' });
    }
    getHeatmapTab(url) {
        return this.http.get(Globals.RUNTIME_PATH + Product.STOR.path + url, { responseType: 'text' });
    }
    getUrlNameVol(urlName) {
        return this.http.get(Globals.RUNTIME_PATH + urlName, { responseType: 'text' });
    }
    loadDataVol(postData) {
        return this.http.get(Globals.RUNTIME_PATH + Product.STOR.pathCgi + '/detail-graph.sh', { params: postData, responseType: 'text' });
    }
    mappingPage(url) {
        if (url) {
            if (!url.startsWith(Product.STOR.pathCgi))
                url = Product.STOR.path + url;
            return this.http.get(Globals.RUNTIME_PATH + url, { responseType: 'text' });
        }
        else {
            return this.http.post('/api/page', { url: Product.STOR.pathCgi + '/mapping.sh?host=ALL&storage=ALL', rrdType: 'stor', type: 'totals' }, { responseType: 'text' });
        }
    }
    getPage(url) {
        if (!url.startsWith(Product.STOR.pathCgi))
            url = Product.STOR.path + url;
        return this.http.get(Globals.RUNTIME_PATH + url, { responseType: 'text' });
    }
}
StorageTotalsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StorageTotalsService_Factory() { return new StorageTotalsService(i0.ɵɵinject(i1.HttpClient)); }, token: StorageTotalsService, providedIn: "root" });
