
export enum ROUTES {
  TYPE = 'type',
  PARENT = 'parent',
  CHILD = 'child',

  GLOB_SEARCH = 'global_search',
  EMPTY = 'empty',
  DUMMY = 'dummy',
  TABS = 'tabs',
  HISTORICAL_REPORTS = 'historical_reports',
  TOP10 = 'top10',
  HW_CONFIG = 'hw_config',
  REPORT_LPAR = 'repLparDefs',
  REPORT_STOR = 'repStorDefs',

  MULTIPATH = 'multipath',

  OVERVIEW_TABS = 'overview-tabs',
  SAN_TOPOLOGY = 'san_topology',
  MAPPING = 'mapping',
  HOSTS_TOP = 'hosts_top',
  VOLUMES_TOP = 'volumes_top',

  DASHBOARDS = 'dashboards',
  PORTAL = 'portal',
  HEATGLOB = 'heatGlob',
  LOG = 'log',
  ABOUT = 'about'
}
