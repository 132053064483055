import { AfterViewInit, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Globals } from 'src/app/globals';
import { Product } from 'src/app/model/product';

@Component({
  selector: 'xormon-overview-content',
  templateUrl: './overview-content.component.html',
  styleUrls: ['./overview-content.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OverviewContentComponent implements OnInit, AfterViewInit {

  @Input()
  content;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    Product.STOR.module.myreadyFunc();
    $('#globoverviewdiv').on('click', 'a:not(.backlink)', event => {
      let url = event.currentTarget.getAttribute('href') as string;
      if (url.startsWith(Product.STOR.pathCgi))
        event.currentTarget.setAttribute('href', Globals.API_HOST + Globals.RUNTIME_PATH + url);
    });
  }

}
