<mat-tab-group [@.disabled]="true" (selectedTabChange)="tabChanged($event)" *ngIf="tops">
  <mat-tab *ngFor="let rtp of tops.rtps" label="{{rtp.name}}">
    <div class="scroll-wrap">
      <table style="height: calc(100vh - 200px);">
        <tr *ngFor="let row of rtp.rows">
          <td *ngFor="let cell of row.cells" style="vertical-align: top;">
            <div *ngIf="cell.html" [innerHTML]="cell.html"></div>
          </td>
        </tr>
        <tr *ngFor="let row of tops.rtpCommon.rows">
          <td *ngFor="let cell of row.cells">
            <div *ngIf="cell.html" [innerHTML]="cell.html"></div>
          </td>
        </tr>
      </table>
    </div>
  </mat-tab>
</mat-tab-group>
<xormon-loading-indicator *ngIf="!tops"></xormon-loading-indicator>
