import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';
import { HwType } from 'src/app/model/hwType';
import { Product } from 'src/app/model/product';
import { TabPage } from 'src/app/model/tabs';

@Component({
  selector: 'xormon-historical-report',
  templateUrl: './historical-report.component.html',
  styleUrls: ['./historical-report.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HistoricalReportComponent
  implements OnInit, OnDestroy {

  source: string;
  result: TabPage;
  pageTitle = 'Historical report';
  private navigationSubscription = Subscription.EMPTY;
  private observer = new MutationObserver(this.formLoaded.bind(this));

  constructor(
    private dataService: DataService,
    private http: HttpClient,
    private log: LoggerService,
    private router: Router
  ) {
    this.navigationSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.dataService.nodeSelected.promise.then(() => {
          if (!this.navigationSubscription.closed) {
            if (Globals.isSameNavigation(e))
              return;
            this.init();
          }
        });
      });
  }

  ngOnInit() {
    window.onpopstate = event => {
      if (event.state) {
        this.result = event.state.result;
      } else {
        this.result = null;
      }
    }
  }

  ngOnDestroy(): void {
    window.onpopstate = null;
    this.observer.disconnect();
    this.navigationSubscription.unsubscribe();
  }

  private init() {
    if (!this.navigationSubscription.closed) {
      this.result = null;
      this.observer.disconnect();
      this.pageTitle = this.dataService.getPageTitle();
      const url: string = this.dataService.selectedNode.data.href || this.dataService.selectedNode.data.url;
      if (this.dataService.selectedParent === HwType.VMWARE.name && !this.dataService.selectedNode.parent.isRootNode()) {
        Product.LPAR.module.xormonVars.vc = this.dataService.selectedNode.parent.data.next_level.path_ids;
      }
      if (url.includes('SAN')) {
        this.source = 'SAN';
      }
      else if (url.includes('LAN')) {
        this.source = 'LAN';
      }
      else
        this.source = null;
      this.http.get(Globals.RUNTIME_PATH + url, { responseType: 'text' }).subscribe(page => {
        $('#histRepHolder').html(page);
        const src = $('#histrepsrc:visible, #vmhistrepsrc:visible');
        if (src.length === 1) {
          let repDiv = document.getElementById('histrepdiv');
          if (!repDiv) {
            repDiv = document.getElementById('vmhistrepdiv');
            if (repDiv) {
              src.on('change', event => {
                Product.LPAR.module.xormonVars.vc = src.val() as string;
              })
            }
          }
          this.observer.observe(repDiv, { childList: true });
          this.dataService.mainModule.myreadyFunc();
        } else {
          this.dataService.mainModule.myreadyFunc();
          this.formLoaded();
        }
      }, error => this.log.error('Failed to get historical report form!', error));
    }
  }

  private formLoaded() {
    $('#pooltree, #porttree, #ranktree, #voltree, #hosttree').addClass('tree-menu seltree').find('.fancytree-container').addClass('fancytree-connectors').addClass('fancytree-content');
    $('#histRepHolder input[type=text]').each((index, element) => {
      (element as HTMLInputElement).size++;
    });
    let form = $('#histRepHolder form');
    form.on('submit', event => {
      if (!event.result)
        return;
      let params: string;
      if (Array.isArray(event.result)) {
        params = $.param(event.result);
      } else if (event.result instanceof Object) {
        params = new HttpParams({ fromObject: event.result }).toString();
      } else {
        params = event.result;
      }
      this.http.post<TabPage>('/api/historicalReport', {
        action: form.attr('action'),
        querry: params
      }).subscribe(data => {
        this.result = data;
        window.history.pushState({ result: data }, 'Historical report');
      },
        error => {
          this.log.error('Failed to plot report!', error);
        });
    });
    this.afterTreesLoaded();
  }

  private afterTreesLoaded(limit = 10) {
    setTimeout(() => {
      if ($('.fancytree-statusnode-loading').length === 0)
        Globals.initFloatingScroll();
      else if (limit > 0)
        this.afterTreesLoaded(limit - 1);
    }, 500);
  }



}
