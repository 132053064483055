import { Component, ElementRef, OnInit } from '@angular/core';
import { CustomMessagesService } from 'src/app/custom-messages.service';
import { LoggerService } from 'src/app/logger.service';
import { AlertingService } from '../alerting.service';

@Component({
  selector: 'xormon-alerting-stor-performance',
  templateUrl: './alerting-stor-performance.component.html',
  styleUrls: ['./alerting-stor-performance.component.scss']
})
export class AlertingStorPerformanceComponent implements OnInit {

  cfg: any = {
    NAGIOS: 0,
    EXTERN_ALERT: 'bin/external_alert_example.sh',
    EMAIL_GRAPH: 25,
    REPEAT_DEFAULT: 60,
    PEAK_TIME_DEFAULT: 30
  };

  urlNagios: string;

  constructor(private alertingService: AlertingService, private log: LoggerService,
    private cmService: CustomMessagesService, private elef: ElementRef) {
  }

  ngOnInit() {
    this.urlNagios = this.cmService.getMsg('nagios', this.elef);
    this.alertingService.getStorCfg().subscribe((data: any) => {
      this.cfg = data;
    }, error => {
      this.log.error('Failed to get configuration!', error);
    });
  }

  saveCfg() {
    this.alertingService.saveStorCfg(this.cfg).subscribe(data => {
      this.log.info('Configuration saved successfully');
    }, error => {
      this.log.error('Failed to save configuration!', error);
    });
  }

}
