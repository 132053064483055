import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem, TreeNode } from 'primeng/api';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CustomMessagesService } from 'src/app/custom-messages.service';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';
import { HwType } from 'src/app/model/hwType';
import { Product } from 'src/app/model/product';

interface CgOption {
  value: string;
  title: string;
  types: string[];
  subsystem?: string[];
  topsystem?: string[];
}

interface CgResponse {
  items: TreeNode[];
  names: string[];
}

@Component({
  selector: 'xormon-custom-groups',
  templateUrl: './custom-groups.component.html',
  styleUrls: ['./custom-groups.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CustomGroupsComponent implements OnInit, OnDestroy {

  static readonly TYPES: Record<string, CgOption[]> = {
    server: [
      { value: 'LPAR', title: 'IBM Power LPAR', types: [HwType.POWER.name], subsystem: ['VM'], topsystem: ['SERVER'] },
      { value: 'POOL', title: 'IBM Power Pool', types: [HwType.POWER.name], subsystem: ['POOL'], topsystem: ['SERVER'] },
      { value: 'OVIRTVM', title: 'oVirt VM', types: [HwType.OVIRT.name], subsystem: ['VM'], topsystem: ['CLUSTER'] },
      { value: 'VM', title: 'VMware VM', types: [HwType.VMWARE.name], subsystem: ['VM'], topsystem: ['VCENTER'] },
      { value: 'ESXI', title: 'VMware ESXi', types: [HwType.VMWARE.name], subsystem: ['ESXI'], topsystem: ['VCENTER'] },
      { value: 'XENVM', title: 'XenServer VM', types: [HwType.XENSERVER.name], subsystem: ['VM'], topsystem: ['POOL'] },
      { value: 'SOLARISZONE', title: 'Solaris Zone', types: [HwType.SOLARIS.name], subsystem: ['ZONE_C'], topsystem: ['CDOM'] },
      { value: 'SOLARISLDOM', title: 'Solaris LDOM', types: [HwType.SOLARIS.name], subsystem: ['ZONE_L'], topsystem: ['LDOM'] },
      { value: 'HYPERVM', title: 'Hyper-V VM', types: [HwType.WINDOWS.name], subsystem: ['CLUSTER_VM', 'VM'], topsystem: ['WINDOWS_CLUSTER', 'SERVER'] },
      { value: 'NUTANIXVM', title: 'Nutanix VM', types: [HwType.NUTANIX.name], subsystem: ['VM'], topsystem: ['POOL'] },
      { value: 'ODB', title: 'OracleDB', types: [HwType.ORACLEDB.name], subsystem: ['ITEMS'], topsystem: ['HOSTS'] },
    ],
    storage: [
      { value: 'VOLUME', title: 'Volume', types: ['STORAGE'] },
      { value: 'POOL', title: 'Pool', types: ['STORAGE'] },
      { value: 'HOST', title: 'Host', types: ['STORAGE'] },
      { value: 'SANPORT', title: 'SAN Port', types: ['SAN', 'BNA'] }
    ],
  };
  readonly menuOptions: MenuItem[] = [
    {
      label: 'Delete',
      icon: 'fas fa-trash-alt fa-fw text-danger',
      command: event => {
        const level = this.getSelectedLevel();
        if (level === 0) {
          this.customGroups = this.customGroups.filter(value => value !== this.selectedNode);
          this.invalid = this.customGroups.some(tn => tn.data.invalid);
        } else if (level === 1 || level === 2) {
          this.selectedNode.parent.children = this.selectedNode.parent.children.filter(value => value !== this.selectedNode);
          this.refreshCustomGroupsTreeTable();
        }
      }
    },
    { separator: true },
    {
      label: 'Add ...',
      icon: 'fas fa-plus fa-fw',
      command: event => {
        this.selectedNode.expanded = true;
        this.selectedNode.children.unshift({ expanded: true, data: { name: '.*' }, children: [{ data: { name: '.*' } }] });
        this.refreshCustomGroupsTreeTable();
      },
    },
    {
      label: 'Add child',
      icon: 'fas fa-plus fa-fw text-success',
      command: event => {
        this.selectedNode.expanded = true;
        this.selectedNode.children.unshift({ data: { name: '.*' } });
        this.refreshCustomGroupsTreeTable();
      }
    }
  ];

  private cgNames: string[] = [];

  selectedTypes: CgOption[];
  filteredTypes: CgOption[];
  lpRows: Record<string, string>[];
  customGroups: TreeNode[];
  selectedNode: TreeNode;
  prevSelectedNod: TreeNode;
  selectedParent: TreeNode;
  helpUrl: string;
  technologyName: string;
  notImplemented = false;
  lpLoading = true;
  loading = true;
  invalid = false;
  inactiveTypeSelected = false;
  successDialog = false;
  autoTitles: string[] = [];
  product: Product;
  isLpar: boolean;
  private livePreviewSubscription = Subscription.EMPTY;
  private titleSubscription = Subscription.EMPTY;
  private navigationSubscription = Subscription.EMPTY;

  constructor(private http: HttpClient, private log: LoggerService, private dataService: DataService,
    private cmService: CustomMessagesService, private elef: ElementRef, private router: Router) {
    this.navigationSubscription = this.router.events
      .pipe(filter(event =>
        event instanceof NavigationEnd
      ))
      .subscribe((e: NavigationEnd) => {
        if (Globals.isSameNavigation(e))
          return;
        this.init();
      });
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }

  ngOnInit() {
  }


  private init() {
    this.dataService.nodeSelected.promise.then(() => {
      this.isLpar = this.dataService.isLpar();
      this.product = this.isLpar ? Product.LPAR : Product.STOR;
      this.technologyName = this.dataService.selectedParent;
      this.helpUrl = this.cmService.getMsg(this.dataService.selectedParent, this.elef);
      const procname = this.dataService.cgiPath === Product.STOR.pathCgi ? 'getActiveClasses' : 'getActiveHwTypes';
      this.http.get<any[]>(Globals.RUNTIME_PATH + this.dataService.cgiPath + Globals.DBWRAPPER_SH + '?procname=' + procname)
        .subscribe(hwclasses => {
          this.selectedTypes = CustomGroupsComponent.TYPES[this.dataService.selectedParent];
          if (this.dataService.cgiPath === Product.LPAR.pathCgi) {
            hwclasses = hwclasses.map(hc => hc.hw_type);
          }
          this.filteredTypes = this.selectedTypes.filter(value => hwclasses.some(hc => value.types.includes(hc)));
        }, error => this.log.error('Failed ' + procname, error));
      this.selectedNode = null;
      this.notImplemented = this.dataService.selectedParent === Globals.TYPE_LAN;

      this.customGroups = [
        {
          'data': {
            'name': 'Angular',
            'type': 'POOL',
            setOfGroups: ''
          },
          'children': [
            {
              'data': {
                'name': '.*'
              },
              children: [
                {
                  data: {
                    name: '.*'
                  }
                }
              ]
            }
          ]
        }
      ];

      this.loading = true;
      this.http.get<CgResponse>('/admin/customGroup/list/' + this.dataService.selectedParent).subscribe(data => {
        this.customGroups = data.items;
        this.cgNames = data.names;
        this.loading = false;
      }, error => {
        this.log.error('Failed to get custom group configuration!', error);
        this.loading = false;
      });
    });
  }

  customizeMenu() {
    this.menuOptions[2].disabled = this.getSelectedLevel() !== 0;
    this.menuOptions[2].label = this.getMenuAddParentLabel();
    this.menuOptions[2].icon = this.getMenuParentIcon();
    this.menuOptions[3].disabled = this.getSelectedLevel() !== 1;
    this.menuOptions[3].label = this.getMenuAddChildLabel();
  }

  private getMenuParentIcon() {
    switch (this.dataService.selectedParent) {
      case 'server':
        return 'fas fa-server fa-fw text-success';
      case 'storage':
        return 'fas fa-hdd fa-fw text-success';
      case 'lan':
        return 'fas fa-ethernet fa-fw text-success';
      case 'san':
        return 'fas fa-network-wired fa-fw text-success';
      default:
        return 'fas fa-plus fa-fw text-success';
    }
  }

  private getMenuAddParentLabel() {
    if (this.getSelectedLevel() === 0) {
      return 'Add ' + this.technologyName;
    } else {
      return 'Add parent';
    }
  }

  private getMenuAddChildLabel() {
    if (this.getSelectedLevel() === 1) {
      return 'Add ' + this.getSelectedType();
    } else {
      return 'Add child';
    }
  }

  /**
   * Zero based level, 0 = group, 1 = parent, 2 = child
   */
  private getSelectedLevel() {
    let level = -1;
    let node = this.selectedNode;
    while (node) {
      node = node.parent;
      level++;
    }
    return level;
  }

  addNewCG() {
    if (this.filteredTypes && this.filteredTypes.length > 0) {
      this.customGroups.push({
        expanded: true,
        data:
          { type: this.getSelectedType(), setOfGroups: this.getSelectedGroup(), unsaved: true },
        children: [{ expanded: true, data: { name: '.*' }, children: [{ data: { name: '.*' } }] }]
      });
      this.refreshCustomGroupsTreeTable();
      this.invalid = true;
      setTimeout(() => {
        let level0 = $('#cgTreeTable tbody td[colspan=1] input');
        level0.get(level0.length - 1).focus();
        $('#buttons')[0].scrollIntoView();
        let rows = $('#cgTreeTable tbody tr').slice(-3);
        rows.addClass('new-cg');
        rows.removeClass('new-cg', 1000);
      });
    } else {
      this.log.warn('No custom group types available, check you have configured some devices/platforms');
    }
  }

  private refreshCustomGroupsTreeTable() {
    this.customGroups = [...this.customGroups];
  }

  private getSelectedType() {
    if (!this.selectedNode) {
      return this.filteredTypes[0].value;
    }
    return this.getSelectedParent().data.type;
  }

  private getSelectedGroup() {
    if (!this.selectedNode) {
      return null;
    }
    return this.getSelectedParent().data.setOfGroups;
  }

  private getSelectedParent() {
    if (!this.selectedNode) {
      return null;
    }
    let node = this.selectedNode;
    while (node.parent != null) {
      node = node.parent;
    }
    return node;
  }

  saveCG() {
    this.http.put<any>('/admin/customGroup/list/' + this.dataService.selectedParent, this.cloneWithoutParents(this.customGroups))
      .subscribe(data => {
        this.log.info('Custom groups saved successfully.');
        this.successDialog = true;
      }, error => {
        this.log.error('Failed saving custom groups!', error);
      });
  }

  checkUniqueName(itemName: string) {
    if (!itemName) {
      this.invalid = true;
      return;
    }
    this.invalid = false;
    for (const tn of this.customGroups) {
      tn.data.invalid = false;
      if (tn.data.duplicit = this.cgNames.includes(itemName))
        this.invalid = true;
    }
    for (let i = 0; i < this.customGroups.length; i++) {
      if (!this.customGroups[i].data.name || !this.customGroups[i].data.name.trim()) {
        this.invalid = true;
        this.customGroups[i].data.invalid = true;
        continue;
      }
      for (let j = i + 1; j < this.customGroups.length; j++) {
        if (this.customGroups[i].data.name === this.customGroups[j].data.name) {
          this.customGroups[i].data.duplicit = true;
          this.customGroups[j].data.duplicit = true;
          this.invalid = true;
        }
      }
    }
  }

  private cloneWithoutParents(treeNodes: TreeNode[]) {
    if (!treeNodes) {
      return null;
    }
    const tnsClone = [];
    for (const tn of treeNodes) {
      const tnClone = { ...tn };
      tnClone.parent = null;
      tnClone.children = this.cloneWithoutParents(tnClone.children);
      tnsClone.push(tnClone);
    }
    return tnsClone;
  }

  nodeSelected(event) {
    if (this.prevSelectedNod === this.selectedNode && event !== 'refresh')
      return;
    this.prevSelectedNod = this.selectedNode;
    this.selectedParent = this.getSelectedParent();
    this.deselect(this.selectedParent);
    this.selectedNode.data.selected = true;
    this.selectedParent.data.level = this.getSelectedLevel();
    const selected = { ...this.selectedParent };
    selected.children = this.cloneWithoutParents(selected.children);
    this.getLivePreview(selected);
  }

  private getLivePreview(selected: TreeNode) {
    this.livePreviewSubscription.unsubscribe();
    const selectedOption = this.filteredTypes.find(co => co.value === selected.data.type);
    if (!selectedOption) {
      this.inactiveTypeSelected = true;
      this.lpLoading = false;
      return;
    } else {
      this.inactiveTypeSelected = false;
      this.lpLoading = true;
    }
    const selectedClone = {
      ...selected, procname: 'getCGPreview',
      hw_types: selectedOption.types,
      subsystem: selectedOption.subsystem,
      topsystem: selectedOption.topsystem
    };
    this.livePreviewSubscription = this.http.post<Record<string, string>[]>(Globals.RUNTIME_PATH + this.dataService.cgiPath + Globals.DBWRAPPER_SH, selectedClone)
      .subscribe(data => {
        this.lpRows = data;
        this.lpLoading = false;
      },
        error => {
          this.lpLoading = false;
          this.lpRows = null;
          this.log.error('Failed to get live preview for ' + selected.data.name, error);
        });
  }

  nodeUnselected(event) {
    event.node.data.selected = false;
    this.livePreviewSubscription.unsubscribe();
  }

  private deselect(node: TreeNode) {
    if (!node) {
      return;
    }
    node.data.selected = false;
    if (!node.children) {
      return;
    }
    for (const child of node.children) {
      this.deselect(child);
    }
  }

  selectRow(rowNode) {
    if (this.selectedNode === rowNode.node) {
      return;
    }
    setTimeout(() => {
      this.selectedNode = rowNode.node;
      this.nodeSelected(false);
    });
  }

  searchForTitles(event) {
    this.titleSubscription.unsubscribe();
    if (!event.query || !this.selectedParent) {
      this.autoTitles = [];
      return;
    }
    const selectedOption = this.filteredTypes.find(co => co.value === this.selectedParent.data.type);
    const selected = {
      ...this.selectedParent, procname: 'getCGTitles',
      hw_types: selectedOption.types,
      subsystem: selectedOption.subsystem,
      topsystem: selectedOption.topsystem
    };
    selected.children = this.cloneWithoutParents(selected.children);
    this.getLivePreview(selected);
    this.titleSubscription = this.http.post<string[]>(Globals.RUNTIME_PATH + this.dataService.cgiPath + Globals.DBWRAPPER_SH, selected).subscribe(data => {
      this.autoTitles = data;
    }, error => this.log.error('Failed to get autocomplete titles!', error));
  }

}
