import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { RxStompService } from '@stomp/ng2-stompjs';
import { DeferLoadModule } from '@trademe/ng-defer-load';
import { AngularSplitModule } from 'angular-split';
import 'jquery.fancytree/dist/skin-lion/ui.fancytree.less';
import { BlockUIModule } from 'ng-block-ui';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DeferModule } from 'primeng/defer';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { FieldsetModule } from 'primeng/fieldset';
import { ListboxModule } from 'primeng/listbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { PickListModule } from 'primeng/picklist';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { TreeTableModule } from 'primeng/treetable';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { ApiInterceptor } from './api.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AlertingEmailComponent } from './content/alerting/alerting-email/alerting-email.component';
import { AlertingLparAlertsComponent } from './content/alerting/alerting-lpar-alerts/alerting-lpar-alerts.component';
import { AlertingLparEmailComponent } from './content/alerting/alerting-lpar-email/alerting-lpar-email.component';
import { AlertingLparHeaderComponent } from './content/alerting/alerting-lpar-header/alerting-lpar-header.component';
import { AlertingLparLogsComponent } from './content/alerting/alerting-lpar-logs/alerting-lpar-logs.component';
import { AlertingLparOptionsComponent } from './content/alerting/alerting-lpar-options/alerting-lpar-options.component';
import { AlertingStorAlertsComponent } from './content/alerting/alerting-stor-alerts/alerting-stor-alerts.component';
import { AlertingStorEmailComponent } from './content/alerting/alerting-stor-email/alerting-stor-email.component';
import { AlertingStorHeaderComponent } from './content/alerting/alerting-stor-header/alerting-stor-header.component';
import { AlertingStorHWComponent } from './content/alerting/alerting-stor-hw/alerting-stor-hw.component';
import { AlertingStorLogsComponent } from './content/alerting/alerting-stor-logs/alerting-stor-logs.component';
import { AlertingStorPerformanceComponent } from './content/alerting/alerting-stor-performance/alerting-stor-performance.component';
import { ResultDialogComponent } from './content/alerting/test-events/result-dialog/result-dialog.component';
import { TestEventsComponent } from './content/alerting/test-events/test-events.component';
import { DashboardsComponent } from './content/dashboard/dashboards/dashboards.component';
import { PortalImportDialogComponent } from './content/dashboard/dashboards/portal-import-dialog/portal-import-dialog.component';
import { HeatmapGlobalComponent } from './content/dashboard/heatmap-global/heatmap-global.component';
import { PortalDialogComponent } from './content/dashboard/portal/portal-dialog/portal-dialog.component';
import { PortalComponent } from './content/dashboard/portal/portal.component';
import { EmptyComponent } from './content/empty/empty.component';
import { HistoricalReportComponent } from './content/historical-report/historical-report.component';
import { CpuWorkloadEstimatorComponent } from './content/lpar/cpu-workload-estimator/cpu-workload-estimator.component';
import { HeatmapLocalLparComponent } from './content/lpar/heatmap-local-lpar/heatmap-local-lpar.component';
import { MultipathComponent } from './content/lpar/multipath/multipath.component';
import { ResourceConfigAdvisorComponent } from './content/lpar/resource-config-advisor/resource-config-advisor.component';
import { RmcCheckComponent } from './content/lpar/rmc-check/rmc-check.component';
import { ReporterGroupsComponent } from './content/reporter/reporter-groups/reporter-groups.component';
import { ReporterHistoryComponent } from './content/reporter/reporter-history/reporter-history.component';
import { ReporterLparDefinitionsComponent } from './content/reporter/reporter-lpar-definitions/reporter-lpar-definitions.component';
import { ReporterOptionsComponent } from './content/reporter/reporter-options/reporter-options.component';
import { ReporterStorDefinitionsComponent } from './content/reporter/reporter-stor-definitions/reporter-stor-definitions.component';
import { AboutComponent } from './content/settings/about/about.component';
import { CertificateDialogComponent } from './content/settings/certificate-dialog/certificate-dialog.component';
import { CustomGroupsComponent } from './content/settings/custom-groups/custom-groups.component';
import { DevicesComponent } from './content/settings/devices/devices.component';
import { GlobalSearchComponent } from './content/settings/global-search/global-search.component';
import { HeatmapConfigurationComponent } from './content/settings/heatmap-configuration/heatmap-configuration.component';
import { InfoComponent } from './content/settings/info/info.component';
import { LdapSetupComponent } from './content/settings/ldap-setup/ldap-setup.component';
import { LdapUserComponent } from './content/settings/ldap-setup/ldap-user/ldap-user.component';
import { LogsComponent } from './content/settings/logs/logs.component';
import { PlatformComponent } from './content/settings/platform/platform.component';
import { RuntimeSetupComponent } from './content/settings/runtime-setup/runtime-setup.component';
import { StorageGroupingComponent } from './content/settings/storage-grouping/storage-grouping.component';
import { UserGroupEditComponent } from './content/settings/user-groups-list/user-group-edit/user-group-edit.component';
import { UserGroupsListComponent } from './content/settings/user-groups-list/user-groups-list.component';
import { UserProfilePasswordComponent } from './content/settings/user-profile/user-profile-password/user-profile-password.component';
import { UserProfileComponent } from './content/settings/user-profile/user-profile.component';
import { UserEditComponent } from './content/settings/users/user-edit/user-edit.component';
import { UsersComponent } from './content/settings/users/users.component';
import { CapacityLocalComponent } from './content/stor/capacity-local/capacity-local.component';
import { CapacityComponent } from './content/stor/capacity/capacity.component';
import { HealthStatusComponent } from './content/stor/health-status/health-status.component';
import { HeatmapLocalComponent } from './content/stor/heatmap-local/heatmap-local.component';
import { HostsTopComponent } from './content/stor/hosts-top/hosts-top.component';
import { HwConfigurationComponent } from './content/stor/hw-configuration/hw-configuration.component';
import { MappingComponent } from './content/stor/mapping/mapping.component';
import { OverviewComponent } from './content/stor/overview/overview-all/overview.component';
import { OverviewContentComponent } from './content/stor/overview/overview-content/overview-content.component';
import { OverviewTabsComponent } from './content/stor/overview/overview-tabs/overview-tabs.component';
import { SanTopologyComponent } from './content/stor/san-topology/san-topology.component';
import { VolumesTopComponent } from './content/stor/volumes-top/volumes-top.component';
import { TabsComponent } from './content/tabs/tabs.component';
import { Top10Component } from './content/top10/top10.component';
import { MatchValueDirective } from './directives/match-value.directive';
import { MaxDirective } from './directives/max.directive';
import { MinDirective } from './directives/min.directive';
import { PoptipDirective } from './directives/poptip.directive';
import { Globals } from './globals';
import { B64Component } from './graph/b64/b64.component';
import { GraphSimpleComponent } from './graph/graph-simple/graph-simple.component';
import { GraphComponent } from './graph/graph.component';
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component';
import { LoginComponent } from './login/login.component';
import { CallbackPipe } from './pipes/callback.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { PromptComponent } from './prompt/prompt.component';
import { TopMenuComponent } from './top-menu/top-menu.component';
import { TreeComponent } from './tree/tree.component';



@NgModule({
  declarations: [
    AppComponent,
    TreeComponent,
    TopMenuComponent,
    TabsComponent,
    B64Component,
    GraphComponent,
    StorageGroupingComponent,
    RuntimeSetupComponent,
    LdapSetupComponent,
    InfoComponent,
    PlatformComponent,
    TestEventsComponent,
    ResultDialogComponent,
    HistoricalReportComponent,
    CpuWorkloadEstimatorComponent,
    MinDirective,
    MaxDirective,
    AlertingLparLogsComponent,
    AlertingLparAlertsComponent,
    AlertingLparHeaderComponent,
    AlertingLparEmailComponent,
    AlertingLparOptionsComponent,
    AlertingStorAlertsComponent,
    AlertingStorHeaderComponent,
    AlertingStorEmailComponent,
    AlertingStorPerformanceComponent,
    AlertingStorHWComponent,
    AlertingStorLogsComponent,
    CustomGroupsComponent,
    AlertingStorHeaderComponent,
    GraphSimpleComponent,
    FilterPipe,
    CallbackPipe,
    HeatmapGlobalComponent,
    ReporterHistoryComponent,
    ReporterOptionsComponent,
    ReporterGroupsComponent,
    ReporterLparDefinitionsComponent,
    CapacityComponent,
    ReporterStorDefinitionsComponent,
    UsersComponent,
    UserEditComponent,
    MatchValueDirective,
    UserGroupEditComponent,
    VolumesTopComponent,
    HostsTopComponent,
    LoginComponent,
    HwConfigurationComponent,
    HealthStatusComponent,
    MappingComponent,
    UserProfileComponent,
    UserProfilePasswordComponent,
    HeatmapConfigurationComponent,
    CapacityLocalComponent,
    Top10Component,
    RmcCheckComponent,
    ResourceConfigAdvisorComponent,
    HeatmapLocalComponent,
    HeatmapLocalLparComponent,
    GlobalSearchComponent,
    DevicesComponent,
    PortalComponent,
    PortalDialogComponent,
    AlertingEmailComponent,
    LdapUserComponent,
    CertificateDialogComponent,
    LoadingIndicatorComponent,
    PromptComponent,
    PortalImportDialogComponent,
    UserGroupsListComponent,
    PoptipDirective,
    DashboardsComponent,
    LogsComponent,
    AboutComponent,
    SanTopologyComponent,
    EmptyComponent,
    OverviewComponent,
    OverviewContentComponent,
    OverviewTabsComponent,
    MultipathComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTabsModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatDialogModule,
    MatButtonModule,
    BlockUIModule.forRoot(),
    AngularSplitModule,
    TreeTableModule,
    ContextMenuModule,
    DeferModule,
    OverlayPanelModule,
    ToastModule,
    ConfirmDialogModule,
    DialogModule,
    DynamicDialogModule,
    PickListModule,
    AutoCompleteModule,
    DeferLoadModule,
    FieldsetModule,
    PanelModule,
    TabViewModule,
    DropdownModule,
    ScrollPanelModule,
    ToggleButtonModule,
    SelectButtonModule,
    NoopAnimationsModule,
    TableModule,
    MultiSelectModule,
    ListboxModule,
    MatCheckboxModule,
    TriStateCheckboxModule,
    ProgressSpinnerModule
  ],
  entryComponents: [TestEventsComponent, ResultDialogComponent,
    UserEditComponent, UserGroupEditComponent, UserProfilePasswordComponent, PortalDialogComponent,
    LdapUserComponent, CertificateDialogComponent, PromptComponent, PortalImportDialogComponent],
  providers: [
    { provide: APP_BASE_HREF, useValue: (Globals.isProd ? Globals.API_HOST : window['_servlet_name'] || '') + '/app' },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    RxStompService, MessageService, ConfirmationService],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
  }
}
