<div class="text-center scroll-wrap" id="about">
  <a href="http://xormon.com" target="_blank"><img alt="xormon-logo" src="./assets/xormon-color.svg" width="140"></a>
  <table class="table boot center fit mt-3 table-align-together">
    <tbody>
      <tr>
        <td>Xormon version</td>
        <td [title]="about.xormonBuildTime">{{about.xormonVersion}}</td>
      </tr>
      <tr>
        <td>OS info</td>
        <td>{{about.osInfo}}</td>
      </tr>
      <tr>
        <td>Java version</td>
        <td>{{about.javaVersion}}</td>
      </tr>
      <tr>
        <td>Application server</td>
        <td>{{about.appServer}}</td>
      </tr>
    </tbody>
  </table>
  <div class="mt-4 d-flex">
    <div *ngIf="lpar.value" class="center">
      <a href="http://lpar2rrd.com" target="_blank"><img
          [src]="hostRuntime + lparPath + 'css/images/logo-lpar2rrd.png'"></a>
      <table class="table boot fit table-align-together mt-3 mr-2">
        <tbody>
          <tr>
            <td>LPAR2RRD version</td>
            <td>{{lparInfo.about.tool_version}}</td>
          </tr>
          <tr>
            <td>LPAR2RRD edition</td>
            <td>{{lparInfo.about.edition}}</td>
          </tr>
          <tr>
            <td>OS info</td>
            <td>{{lparInfo.about.os_info}}</td>
          </tr>
          <tr>
            <td>Perl version</td>
            <td>{{lparInfo.about.perl_version}}</td>
          </tr>
          <tr>
            <td>Web server info</td>
            <td>{{lparInfo.server}}</td>
          </tr>
          <tr>
            <td>RRDTOOL version</td>
            <td>{{lparInfo.about.rrdtool_version}}</td>
          </tr>
          <tr>
            <td>RRDp version</td>
            <td>{{lparInfo.about.RRDp_version}}</td>
          </tr>
          <tr>
            <td>SQLite version</td>
            <td>{{lparInfo.sqlite}}</td>
          </tr>
        </tbody>
      </table>

      <div *ngIf="lparInfo.about.vmcount">
        <div class="text-left">
          <span style="font-weight: 500; margin-right: 5em;">Total VMs</span>
          <span>Get a quote via <a [href]="lparQuote" target="_blank">email</a></span>
        </div>
        <table class="table boot fit table-align-together">
          <tbody>
            <tr *ngFor="let tech of lparInfo.about.vmcount | keyvalue | callback: greaterZero">
              <td>{{tech.key}}</td>
              <td>{{tech.value}}</td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>

    <div *ngIf="stor.value" class="center">
      <a href="http://stor2rrd.com" target="_blank"><img
          [src]="hostRuntime + storPath + 'css/images/logo-stor2rrd.png'"></a>
      <table class="table boot fit table-align-together mt-3">
        <tbody>
          <tr>
            <td>STOR2RRD version</td>
            <td>{{storInfo.about.tool_version}}</td>
          </tr>
          <tr>
            <td>STOR2RRD edition</td>
            <td>{{storInfo.about.edition}}</td>
          </tr>
          <tr>
            <td>OS info</td>
            <td>{{storInfo.about.os_info}}</td>
          </tr>
          <tr>
            <td>Perl version</td>
            <td>{{storInfo.about.perl_version}}</td>
          </tr>
          <tr>
            <td>Web server info</td>
            <td>{{storInfo.server}}</td>
          </tr>
          <tr>
            <td>RRDTOOL version</td>
            <td>{{storInfo.about.rrdtool_version}}</td>
          </tr>
          <tr>
            <td>RRDp version</td>
            <td>{{storInfo.about.RRDp_version}}</td>
          </tr>
          <tr>
            <td>SQLite version</td>
            <td>{{storInfo.sqlite}}</td>
          </tr>
        </tbody>
      </table>

      <div *ngIf="storInfo.about.devices">
        <div class="text-left">
          <span style="font-weight: 500; margin-right: 4em;">Device count</span>
          <span>Get a quote via <a [href]="storQuote" target="_blank">email</a></span>
        </div>
        <table class="table boot fit table-align-together">
          <tbody>
            <tr *ngFor="let class of storInfo.about.devices | keyvalue">
              <td colspan="2">
                <table class="table boot">
                  <caption>{{class.key}}</caption>
                  <tr *ngFor="let device of class.value | keyvalue | callback: greaterZero">
                    <td class="pl-3 w-100">{{device.key}}</td>
                    <td>{{device.value}}</td>
                  </tr>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
