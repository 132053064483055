import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';
import { TabPage } from 'src/app/model/tabs';

@Component({
  selector: 'xormon-overview-tabs',
  templateUrl: './overview-tabs.component.html',
  styleUrls: ['./overview-tabs.component.scss']
})
export class OverviewTabsComponent implements OnInit, OnDestroy {

  tabPage: TabPage;
  private navigationSubscription = Subscription.EMPTY;

  constructor(private dataService: DataService, private router: Router, private http: HttpClient, private log: LoggerService) {
    this.navigationSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.dataService.nodeSelected.promise.then(() => {
          if (!this.navigationSubscription.closed) {
            if (Globals.isSameNavigation(e))
              return;
            this.init();
          }
        });
      });
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }

  ngOnInit() {
  }

  private init() {
    this.tabPage = null;
    let nodeData = this.dataService.selectedNode.data;
    let href = nodeData.href as string;
    this.http.post<TabPage>('/api/tabs', { url: href, type: 'storage' }).subscribe(tp => {
      this.tabPage = tp;
      this.onTabSelected({ index: 0, tab: null });
    }, error => this.log.error('Failed to get overview page!', error));
  }

  onTabSelected(event: MatTabChangeEvent) {
    let tab = this.tabPage.tps[event.index];
    if (tab.urlContent)
      return;
    this.http.get(Globals.RUNTIME_PATH + (tab.url.startsWith(this.dataService.cgiPath) ? '' : this.dataService.path) +
      tab.url, { responseType: 'text' }).subscribe(page => {
        tab.urlContent = Globals.domSanitizer.bypassSecurityTrustHtml(page);
      }, error => this.log.error('Failed to get tab page ' + tab.url, error));
  }

}
