/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alerting-lpar-logs.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../alerting-lpar-header/alerting-lpar-header.component.ngfactory";
import * as i3 from "../alerting-lpar-header/alerting-lpar-header.component";
import * as i4 from "../../../custom-messages.service";
import * as i5 from "../../../loading-indicator/loading-indicator.component.ngfactory";
import * as i6 from "../../../loading-indicator/loading-indicator.component";
import * as i7 from "./alerting-lpar-logs.component";
import * as i8 from "../alerting.service";
import * as i9 from "../../../logger.service";
var styles_AlertingLparLogsComponent = [i0.styles];
var RenderType_AlertingLparLogsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertingLparLogsComponent, data: {} });
export { RenderType_AlertingLparLogsComponent as RenderType_AlertingLparLogsComponent };
export function View_AlertingLparLogsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "xormon-alerting-lpar-header", [["label", "Logs"]], null, null, null, i2.View_AlertingLparHeaderComponent_0, i2.RenderType_AlertingLparHeaderComponent)), i1.ɵdid(3, 114688, null, 0, i3.AlertingLparHeaderComponent, [i1.ElementRef, i4.CustomMessagesService], { label: [0, "label"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "tablesorter-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "div", [["class", "table-height-md"], ["id", "lparLogs"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "spinner-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "xormon-loading-indicator", [], null, null, null, i5.View_LoadingIndicatorComponent_0, i5.RenderType_LoadingIndicatorComponent)), i1.ɵdid(8, 114688, null, 0, i6.LoadingIndicatorComponent, [], null, null)], function (_ck, _v) { var currVal_0 = "Logs"; _ck(_v, 3, 0, currVal_0); _ck(_v, 8, 0); }, null); }
export function View_AlertingLparLogsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xormon-alerting-lpar-logs", [], null, null, null, View_AlertingLparLogsComponent_0, RenderType_AlertingLparLogsComponent)), i1.ɵdid(1, 114688, null, 0, i7.AlertingLparLogsComponent, [i8.AlertingService, i9.LoggerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertingLparLogsComponentNgFactory = i1.ɵccf("xormon-alerting-lpar-logs", i7.AlertingLparLogsComponent, View_AlertingLparLogsComponent_Host_0, {}, {}, []);
export { AlertingLparLogsComponentNgFactory as AlertingLparLogsComponentNgFactory };
