/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./resource-config-advisor.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i4 from "@angular/material/tabs";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "./resource-config-advisor.component";
import * as i7 from "@angular/common/http";
import * as i8 from "../../../data.service";
import * as i9 from "../../../logger.service";
var styles_ResourceConfigAdvisorComponent = [i0.styles];
var RenderType_ResourceConfigAdvisorComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ResourceConfigAdvisorComponent, data: {} });
export { RenderType_ResourceConfigAdvisorComponent as RenderType_ResourceConfigAdvisorComponent };
function View_ResourceConfigAdvisorComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.html; _ck(_v, 0, 0, currVal_0); }); }
function View_ResourceConfigAdvisorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResourceConfigAdvisorComponent_5)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.html; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ResourceConfigAdvisorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResourceConfigAdvisorComponent_4)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.cells; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ResourceConfigAdvisorComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.html; _ck(_v, 0, 0, currVal_0); }); }
function View_ResourceConfigAdvisorComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResourceConfigAdvisorComponent_8)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.html; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ResourceConfigAdvisorComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResourceConfigAdvisorComponent_7)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.cells; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ResourceConfigAdvisorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 9, "mat-tab", [], null, null, null, i3.View_MatTab_0, i3.RenderType_MatTab)), i1.ɵdid(1, 770048, [[1, 4]], 2, i4.MatTab, [i1.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i1.ɵqud(603979776, 2, { templateLabel: 0 }), i1.ɵqud(335544320, 3, { _explicitContent: 0 }), (_l()(), i1.ɵeld(4, 0, null, 0, 5, "div", [["class", "rca center"], ["style", "overflow: auto;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "table", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResourceConfigAdvisorComponent_3)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResourceConfigAdvisorComponent_6)), i1.ɵdid(9, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.name, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.rows; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.rca.rtpCommon.rows; _ck(_v, 9, 0, currVal_2); }, null); }
function View_ResourceConfigAdvisorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "mat-tab-group", [["class", "mat-tab-group"]], [[24, "@.disabled", 0], [2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], [[null, "selectedTabChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedTabChange" === en)) {
        var pd_0 = (_co.tabSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatTabGroup_0, i3.RenderType_MatTabGroup)), i1.ɵdid(1, 3325952, null, 1, i4.MatTabGroup, [i1.ElementRef, i1.ChangeDetectorRef, [2, i4.MAT_TABS_CONFIG], [2, i5.ANIMATION_MODULE_TYPE]], null, { selectedTabChange: "selectedTabChange" }), i1.ɵqud(603979776, 1, { _tabs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResourceConfigAdvisorComponent_2)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.rca.rtps; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 1).dynamicHeight; var currVal_2 = (i1.ɵnov(_v, 1).headerPosition === "below"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_ResourceConfigAdvisorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ResourceConfigAdvisorComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rca; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ResourceConfigAdvisorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xormon-resource-config-advisor", [], null, null, null, View_ResourceConfigAdvisorComponent_0, RenderType_ResourceConfigAdvisorComponent)), i1.ɵdid(1, 114688, null, 0, i6.ResourceConfigAdvisorComponent, [i7.HttpClient, i8.DataService, i9.LoggerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ResourceConfigAdvisorComponentNgFactory = i1.ɵccf("xormon-resource-config-advisor", i6.ResourceConfigAdvisorComponent, View_ResourceConfigAdvisorComponent_Host_0, {}, {}, []);
export { ResourceConfigAdvisorComponentNgFactory as ResourceConfigAdvisorComponentNgFactory };
