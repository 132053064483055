import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';
import { AlertingService } from '../alerting.service';

@Component({
  selector: 'xormon-alerting-lpar-logs',
  templateUrl: './alerting-lpar-logs.component.html',
  styleUrls: ['./alerting-lpar-logs.component.scss']
})
export class AlertingLparLogsComponent implements OnInit {

  constructor(private alertingService: AlertingService, private log: LoggerService) { }

  ngOnInit() {
    this.alertingService.getLparLogs().subscribe(data => {
      $('#lparLogs').html(data);
      Globals.tableSorter($('#lparLogs table'));
      $('#lparLogs table').floatThead({
        scrollContainer($table: JQuery) {
          return $table.closest('#lparLogs');
        },
        headerCellSelector: 'tr>th'
      });
      $('div.floatThead-container').css('overflow-y', 'hidden');
    }, error => this.log.error('Failed to get logs!', error));
  }

}
