/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hw-configuration.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i3 from "@angular/material/tabs";
import * as i4 from "@angular/common";
import * as i5 from "../../../loading-indicator/loading-indicator.component.ngfactory";
import * as i6 from "../../../loading-indicator/loading-indicator.component";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./hw-configuration.component";
import * as i9 from "../../../data.service";
import * as i10 from "../storage-totals.service";
import * as i11 from "../../../logger.service";
import * as i12 from "@angular/router";
import * as i13 from "@angular/common/http";
var styles_HwConfigurationComponent = [i0.styles];
var RenderType_HwConfigurationComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_HwConfigurationComponent, data: {} });
export { RenderType_HwConfigurationComponent as RenderType_HwConfigurationComponent };
function View_HwConfigurationComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "mb-2 bg-white"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "a", [], [[8, "href", 4], [8, "title", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "i", [["class", "fas fa-file-csv fa-2x"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.href; var currVal_1 = _v.context.$implicit.title; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_HwConfigurationComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 9, "mat-tab", [], null, null, null, i2.View_MatTab_0, i2.RenderType_MatTab)), i1.ɵdid(1, 770048, [[1, 4]], 2, i3.MatTab, [i1.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i1.ɵqud(603979776, 2, { templateLabel: 0 }), i1.ɵqud(335544320, 3, { _explicitContent: 0 }), (_l()(), i1.ɵeld(4, 0, null, 0, 2, "div", [["class", "csvfloat"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HwConfigurationComponent_3)), i1.ɵdid(6, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(7, 0, null, 0, 2, "div", [["class", "stickyheaders-holder text-center"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "xormon-loading-indicator", [], null, null, null, i5.View_LoadingIndicatorComponent_0, i5.RenderType_LoadingIndicatorComponent)), i1.ɵdid(9, 114688, null, 0, i6.LoadingIndicatorComponent, [], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.name, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.csvList; _ck(_v, 6, 0, currVal_1); _ck(_v, 9, 0); }, function (_ck, _v) { var currVal_2 = i1.ɵinlineInterpolate(1, "", ("tab" + _v.context.index), ""); _ck(_v, 7, 0, currVal_2); }); }
function View_HwConfigurationComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "mat-tab-group", [["class", "mat-tab-group"]], [[24, "@.disabled", 0], [2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], [[null, "selectedTabChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedTabChange" === en)) {
        var pd_0 = (_co.onTabSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatTabGroup_0, i2.RenderType_MatTabGroup)), i1.ɵdid(1, 3325952, null, 1, i3.MatTabGroup, [i1.ElementRef, i1.ChangeDetectorRef, [2, i3.MAT_TABS_CONFIG], [2, i7.ANIMATION_MODULE_TYPE]], null, { selectedTabChange: "selectedTabChange" }), i1.ɵqud(603979776, 1, { _tabs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HwConfigurationComponent_2)), i1.ɵdid(4, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.tabsTps; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 1).dynamicHeight; var currVal_2 = (i1.ɵnov(_v, 1).headerPosition === "below"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_HwConfigurationComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "inner-html stickyheaders-holder position-relative"], ["id", "hwconfig"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "xormon-loading-indicator", [], null, null, null, i5.View_LoadingIndicatorComponent_0, i5.RenderType_LoadingIndicatorComponent)), i1.ɵdid(2, 114688, null, 0, i6.LoadingIndicatorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_HwConfigurationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "hw-configuration d-flex flex-grow-1 flex-column"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HwConfigurationComponent_1)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HwConfigurationComponent_4)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabsTps; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.tabsTps; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_HwConfigurationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xormon-hw-configuration", [], null, null, null, View_HwConfigurationComponent_0, RenderType_HwConfigurationComponent)), i1.ɵdid(1, 4440064, null, 0, i8.HwConfigurationComponent, [i9.DataService, i10.StorageTotalsService, i11.LoggerService, i12.Router, i13.HttpClient], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HwConfigurationComponentNgFactory = i1.ɵccf("xormon-hw-configuration", i8.HwConfigurationComponent, View_HwConfigurationComponent_Host_0, {}, {}, []);
export { HwConfigurationComponentNgFactory as HwConfigurationComponentNgFactory };
