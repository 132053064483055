import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';
import { Product } from 'src/app/model/product';

@Component({
  selector: 'xormon-capacity-local',
  templateUrl: './capacity-local.component.html',
  styleUrls: ['./capacity-local.component.scss']
})
export class CapacityLocalComponent implements OnInit, OnDestroy {

  pageTitle;
  private navigationSubscription = Subscription.EMPTY;

  constructor(private dataService: DataService, private router: Router,
    private log: LoggerService) {
    this.navigationSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.dataService.nodeSelected.promise.then(() => {
          if (!this.navigationSubscription.closed) {
            if (Globals.isSameNavigation(e))
              return;
            this.init();
          }
        });
      });
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }

  private init() {
    this.pageTitle = this.dataService.getPageTitle();
    let topNode = Globals.getTopNode(this.dataService.selectedNode);
    Product.STOR.module.genPoolCapacity(topNode.title, this.dataService.selectedNode.data.href, topNode.data.hw_type).then(() => {
      this.dataService.registerBacklinks();
    });
  }

}
