<div id="backend" class="card">
  <div class="card-body">
    <div class="row col-md-12">
      <h4>Back-end Setup</h4>
    </div>

    <form (ngSubmit)="checkSaveConfig()" #rtForm="ngForm" class="max-width">
      <p-fieldset>
        <p-header>
          <div class="form-check form-check-lg">
            <input class="form-check-input" type="checkbox" [(ngModel)]="lpar" name="lparEnabled" id="lparEnabled"
              (change)="blockLpar()">
            <label class="form-check-label mb-0" for="lparEnabled">
              LPAR2RRD
            </label>
          </div>
        </p-header>
        <ng-template #blockTemplateLpar>
          <div class="block-ui-template text-center">
            <i class="fas fa-server fa-4x"></i>
            <div><strong>Xormon UI for LPAR2RRD backend is disabled</strong></div>
          </div>
        </ng-template>
        <div class="row mb-2" *blockUI="'lpar';template: blockTemplateLpar">
          <div class="col-md-6 form-group">
            <mat-radio-group [(ngModel)]="config['runtime.lpar.path']" name="lparAccess" [required]="lpar">
              <div class="mb-1">
                <mat-radio-button [value]="'path'" [disabled]="!lpar">
                  Local installation
                </mat-radio-button>
              </div>
              <div>
                <mat-radio-button [value]="'user'" [disabled]="!lpar">
                  Remote address
                </mat-radio-button>
              </div>
            </mat-radio-group>
          </div>
          <div class="col-md-6 form-group">
            <mat-checkbox [(ngModel)]="config['runtime.lpar.noUI']" name="lparUI"
              title="When checked, LPAR2RRD native web UI access is disabled and restricted to Xormon only.">Disable
              direct
              LPAR2RRD
              UI access</mat-checkbox>
          </div>

          <div class="col-lg-12 form-group" *ngIf="config['runtime.lpar.path']==='path'">
            <label>Root directory:</label>
            <input [required]="config['runtime.lpar.path']==='path'" class="form-control"
              [(ngModel)]="config['runtime.lpar.root']" placeholder="/path/to/lpar/installation"
              [disabled]="config['runtime.lpar.path']==='user' || !lpar" name="lparPath">
          </div>

          <div class="col-lg-12 form-group" *ngIf="config['runtime.lpar.path']==='user'">
            <label>Host:</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <p-dropdown [options]="httpSchemas" [(ngModel)]="config['runtime.lpar.scheme']"
                  styleClass="dropdown-grayed" name="lparScheme"></p-dropdown>
                <span class="input-group-text">://</span>
              </div>
              <input [required]="config['runtime.lpar.path']==='user'" class="form-control"
                [(ngModel)]="config['runtime.lpar.url']" placeholder="hostname or IP"
                [disabled]="config['runtime.lpar.path']==='path' || !lpar" name="lparUrl">

              <div class="input-group-prepend">
                <span class="input-group-text">/</span>
              </div>
              <input class="form-control flex-half" [(ngModel)]="config['runtime.lpar.urlPath']"
                placeholder="Optional path" pattern="^[a-z0-9\.]+(\/.*[^/])?$"
                [disabled]="config['runtime.lpar.path']==='path' || !lpar" name="lparUrlPath">

            </div>
          </div>

          <div class="col-lg-6 form-group" *ngIf="config['runtime.lpar.path']==='user'">
            <label>User:</label>
            <input class="form-control" [(ngModel)]="config['runtime.lpar.user']" name="lparUser" [disabled]="!lpar"
              placeholder="Enter administrator's login name" autocomplete="new-password"
              title="Default 'admin' will be used if empty">
          </div>

          <div class="col-lg-6 form-group" *ngIf="config['runtime.lpar.path']==='user'">
            <label>Password:</label>
            <input class="form-control" [(ngModel)]="config['runtime.lpar.psswd']" placeholder="Enter password"
              name="lparPswd" type="password" [disabled]="config['runtime.lpar.path']==='path' || !lpar"
              autocomplete="lpar-password" title="Used only if Apache authentication enabled">
          </div>
        </div>
      </p-fieldset>

      <br>

      <p-fieldset>
        <p-header>
          <div class="form-check form-check-lg">
            <input class="form-check-input" type="checkbox" [(ngModel)]="stor" name="storEnabled" id="storEnabled"
              (change)="blockStor()">
            <label class="form-check-label mb-0" for="storEnabled">
              STOR2RRD
            </label>
          </div>
        </p-header>
        <ng-template #blockTemplateStor>
          <div class="block-ui-template text-center">
            <i class="fas fa-hdd fa-4x"></i>
            <div><strong>Xormon UI for STOR2RRD backend is disabled</strong></div>
          </div>
        </ng-template>
        <div class="row" *blockUI="'stor';template: blockTemplateStor">
          <div class="col-md-6 form-group">
            <mat-radio-group [(ngModel)]="config['runtime.stor.path']" name="storAccess" [required]="stor">
              <div class="mb-1">
                <mat-radio-button [value]="'path'" [disabled]="!stor">
                  Local installation
                </mat-radio-button>
              </div>
              <div>
                <mat-radio-button [value]="'user'" [disabled]="!stor">
                  Remote address
                </mat-radio-button>
              </div>
            </mat-radio-group>
          </div>
          <div class="col-md-6 form-group">
            <mat-checkbox [(ngModel)]="config['runtime.stor.noUI']" name="storUI"
              title="When checked, STOR2RRD native web UI access is disabled and restricted to Xormon only.">Disable
              direct
              STOR2RRD
              UI access</mat-checkbox>
          </div>

          <div class="col-lg-12 form-group" *ngIf="config['runtime.stor.path']==='path'">
            <label>Root directory:</label>
            <input [required]="config['runtime.stor.path']==='path'" class="form-control"
              [(ngModel)]="config['runtime.stor.root']" placeholder="/path/to/stor/installation"
              [disabled]="config['runtime.stor.path']==='user' || !stor" name="storPath">
          </div>

          <div class="col-lg-12 form-group" *ngIf="config['runtime.stor.path']==='user'">
            <label>Host:</label>
            <div class="input-group">
              <div class="input-group-prepend">
                <p-dropdown [options]="httpSchemas" [(ngModel)]="config['runtime.stor.scheme']"
                  styleClass="dropdown-grayed" name="storScheme"></p-dropdown>
                <span class="input-group-text">://</span>
              </div>
              <input [required]="config['runtime.stor.path']==='user'" class="form-control"
                [(ngModel)]="config['runtime.stor.url']" placeholder="hostname or IP"
                [disabled]="config['runtime.stor.path']==='path' || !stor" name="storUrl">
              <div class="input-group-prepend">
                <span class="input-group-text">/</span>
              </div>
              <input class="form-control flex-half" [(ngModel)]="config['runtime.stor.urlPath']"
                placeholder="Optional path" pattern="^[a-z0-9\.]+(\/.*[^/])?$"
                [disabled]="config['runtime.stor.path']==='path' || !stor" name="storUrlPath">
            </div>
          </div>

          <div class="col-lg-6 form-group" *ngIf="config['runtime.stor.path']==='user'">
            <label>User:</label>
            <input class="form-control" [(ngModel)]="config['runtime.stor.user']" name="storUser" [disabled]="!stor"
              placeholder="Enter administrator's login name" autocomplete="new-password"
              title="Default 'admin' will be used if empty">
          </div>

          <div class="col-lg-6 form-group" *ngIf="config['runtime.stor.path']==='user'">
            <label>Password:</label>
            <input class="form-control" [(ngModel)]="config['runtime.stor.psswd']" placeholder="Enter password"
              name="storPswd" type="password" [disabled]="config['runtime.stor.path']==='path' || !stor"
              autocomplete="stor-password" title="Used only if Apache authentication enabled">
          </div>

        </div>
      </p-fieldset>
      <br>
      <button type="submit" class="btn btn-primary"
        [disabled]="saving || rtForm.invalid || rtForm.pristine">Save</button>
    </form>
  </div>

  <p-dialog [(visible)]="progressDialogShown" baseZIndex="999" [modal]="true" [dismissableMask]="false"
    [closeOnEscape]="false" [closable]="false" appendTo="body"
    stylex="{'max-width': '80%', minWidth: '80%', maxHeight: '80%', minHeight: '80%'}" [styleClass]="'rtFeedback'">
    <p-header>
      <div>
        <span class="mr-2">Setup check<span class="loading" *ngIf="progressStatus===STATUS.INPROGRESS"></span></span>
        <i *ngIf="progressStatus===STATUS.FAIL" class="fas fa-exclamation text-danger"></i>
        <i *ngIf="progressStatus===STATUS.SUCCESS || progressStatus===STATUS.FINISHED"
          class="fas fa-check text-success"></i>
      </div>
    </p-header>
    <div>
      <div style="overflow: visible; white-space: pre;" [innerHTML]="progressMessage"></div>
    </div>
    <p-footer>
      <button class="btn btn-secondary" (click)="cancelSave()" *ngIf="!checkFinished">Cancel</button>
      <button class="btn btn-secondary" (click)="progressDialogShown=false" *ngIf="checkFinished">Close</button>
    </p-footer>
  </p-dialog>
</div>
