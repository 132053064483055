<div class="card">
  <div class="card-body">
    <div class="d-flex align-items-center mb-2">
      <h3>User Management</h3>
      <button class="ml-auto btn btn-primary" (click)="addUser()">
        <i class="fas fa-user-plus"></i>
        Add new user
      </button>
    </div>
    <table class="table table-hover w-auto">
      <thead>
        <tr>
          <th></th>
          <th>Username</th>
          <th>Name</th>
          <th>Last login</th>
          <th>Enabled</th>
          <th>LDAP</th>
          <th>Local Groups</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let u of users">
          <td><a href="#" (click)="editUser(u, $event)"><i class="fas fa-user-edit"></i></a></td>
          <td>{{u.username}}</td>
          <td>{{u.name}}</td>
          <td [title]="'Last access IP: '+u.lastIp">{{u.lastLogin | date:'yyyy-MM-dd HH:mm'}}</td>
          <td>{{u.enabled}}</td>
          <td>{{u.ldap}}</td>
          <td>
            {{u.localGroupNames}}
          <td>
            <a href="#" *ngIf="u.id>1" (click)="deleteUser(u, $event)">
              <i class="fas fa-times-circle"></i>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="!users" class="spinner-wrap">
      <xormon-loading-indicator></xormon-loading-indicator>
    </div>

  </div>
</div>
