import { Component, OnInit, VERSION } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { BackendAbout } from 'src/app/model/about';
import { Product } from 'src/app/model/product';

@Component({
  selector: 'xormon-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  angularVersion = VERSION.full;
  about = Globals.ABOUT;
  lparInfo = new BackendAbout();
  storInfo = new BackendAbout();
  lpar = Globals.BACKEND_INFO.lparEnabled;
  stor = Globals.BACKEND_INFO.storEnabled;
  storPath = Product.STOR.path;
  lparPath = Product.LPAR.path;
  hostRuntime = Globals.API_HOST + Globals.RUNTIME_PATH;

  lparQuote = 'mailto:sales@lpar2rrd.com?subject=LPAR2RRD%20quote%20request&body=';
  storQuote = "mailto:sales@stor2rrd.com?subject=STOR2RRD%20quote%20request&body=";
  private readonly bodyBase = "Hello sales, \n\n" +
    "(please fill in these fields): \n" +
    "Full Name: \n" +
    "Company name: \n" +
    "Company address: \n" +
    "Support length [1/2/3 years]: \n\n" +
    "Environment: \n\n";

  constructor(private dataService: DataService) { }

  ngOnInit() {
    Product.LPAR.resolved.promise.then(() => {
      this.lparInfo = Product.LPAR.info;
      let body = this.bodyBase;
      Object.keys(Product.LPAR.info.about.vmcount).sort().forEach(platform => {
        const vmCount = Product.LPAR.info.about.vmcount[platform];
        if (vmCount) {
          body += `${platform}: ${vmCount}\n`;
        }
      });
      this.lparQuote += encodeURIComponent(body);
    });
    Product.STOR.resolved.promise.then(() => {
      this.storInfo = Product.STOR.info;
      let body = this.bodyBase;
      for (const clsKey in Product.STOR.info.about.devices) {
        if (Object.prototype.hasOwnProperty.call(Product.STOR.info.about.devices, clsKey)) {
          const cls = Product.STOR.info.about.devices[clsKey];
          Object.keys(cls).sort().forEach(deviceKey => {
            const deviceCount = cls[deviceKey];
            if (deviceCount) {
              body += deviceKey + ": " + deviceCount + '\n';
            }
          });
        }
      }
      this.storQuote += encodeURIComponent(body);
    });
  }


  greaterZero(tech) {
    return tech && tech.value > 0;
  }

}
