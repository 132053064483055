/**
 * LPAR HW TYPES
 */
export class HwType {
  static readonly ALL: HwType[] = [];

  static readonly AWS = new HwType('AWS', 'Amazon Web Services', 'aws');
  static readonly AZURE = new HwType('AZURE', 'Microsoft Azure', 'azure');
  static readonly GCLOUD = new HwType('GCLOUD', 'Google Cloud', 'gcloud');
  //static readonly HITACHI = new HwType('HITACHI', 'Hitachi Compute Blade', 'hitachi');
  static readonly KUBERNETES = new HwType('KUBERNETES', 'Kubernetes', 'kubernetes');
  static readonly LINUX = new HwType('LINUX', 'Linux', 'linux');
  static readonly NUTANIX = new HwType('NUTANIX', 'Nutanix', 'nutanix');
  static readonly OPENSHIFT = new HwType('OPENSHIFT', 'Red Hat OpenShift', 'openshift');
  static readonly ORACLEDB = new HwType('ORACLEDB', 'Oracle Database', 'oracledb');
  static readonly ORACLEVM = new HwType('ORACLEVM', 'Oracle VM', 'oraclevm');
  static readonly OVIRT = new HwType('OVIRT', 'RHV / oVirt', 'ovirt');
  static readonly POWER = new HwType('POWER', 'IBM Power Systems', 'power');
  static readonly SOLARIS = new HwType('SOLARIS', 'Oracle Solaris', 'solaris');
  static readonly VMWARE = new HwType('VMWARE', 'VMware', 'vmware');
  static readonly WINDOWS = new HwType('WINDOWS', 'Windows / Hyper-V', 'hyperv');
  static readonly XENSERVER = new HwType('XENSERVER', 'XenServer', 'xen');

  readonly name: string;
  readonly platform: string;
  readonly title: string;

  private constructor(name: string, title: string, platform: string) {
    this.name = name;
    this.platform = platform;
    this.title = title;
    HwType.ALL.push(this);
  }
}