/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./logs.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i3 from "@angular/material/tabs";
import * as i4 from "@angular/common";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "./logs.component";
import * as i7 from "@angular/common/http";
import * as i8 from "../../../logger.service";
var styles_LogsComponent = [i0.styles];
var RenderType_LogsComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LogsComponent, data: {} });
export { RenderType_LogsComponent as RenderType_LogsComponent };
function View_LogsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "pre", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.logs; _ck(_v, 1, 0, currVal_0); }); }
function View_LogsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.$implicit.html; _ck(_v, 0, 0, currVal_0); }); }
function View_LogsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 9, "mat-tab", [], null, null, null, i2.View_MatTab_0, i2.RenderType_MatTab)), i1.ɵdid(1, 770048, [[1, 4]], 2, i3.MatTab, [i1.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i1.ɵqud(603979776, 2, { templateLabel: 0 }), i1.ɵqud(335544320, 3, { _explicitContent: 0 }), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "div", [["class", "font-weight-bold text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_LogsComponent_3)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_LogsComponent_4)), i1.ɵdid(9, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.title; _ck(_v, 1, 0, currVal_0); var currVal_2 = _v.parent.context.$implicit.logs; _ck(_v, 7, 0, currVal_2); var currVal_3 = _v.parent.context.$implicit.html; _ck(_v, 9, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.text; _ck(_v, 5, 0, currVal_1); }); }
function View_LogsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LogsComponent_2)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.visible.value; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_LogsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "mat-tab-group", [["class", "mat-tab-group"], ["id", "appLogs"]], [[24, "@.disabled", 0], [2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], [[null, "selectedTabChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedTabChange" === en)) {
        var pd_0 = (_co.tabChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatTabGroup_0, i2.RenderType_MatTabGroup)), i1.ɵdid(1, 3325952, null, 1, i3.MatTabGroup, [i1.ElementRef, i1.ChangeDetectorRef, [2, i3.MAT_TABS_CONFIG], [2, i5.ANIMATION_MODULE_TYPE]], null, { selectedTabChange: "selectedTabChange" }), i1.ɵqud(603979776, 1, { _tabs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_LogsComponent_1)), i1.ɵdid(4, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.logData; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 1).dynamicHeight; var currVal_2 = (i1.ɵnov(_v, 1).headerPosition === "below"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_LogsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xormon-logs", [], null, null, null, View_LogsComponent_0, RenderType_LogsComponent)), i1.ɵdid(1, 4308992, null, 0, i6.LogsComponent, [i7.HttpClient, i8.LoggerService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LogsComponentNgFactory = i1.ɵccf("xormon-logs", i6.LogsComponent, View_LogsComponent_Host_0, {}, {}, []);
export { LogsComponentNgFactory as LogsComponentNgFactory };
