import * as tslib_1 from "tslib";
import { OnDestroy, OnInit } from '@angular/core';
import { BlockUI } from 'ng-block-ui';
import { DataService } from '../../../data.service';
import { LoggerService } from '../../../logger.service';
import { StorageGroupItem as StorageGroupItem } from '../../../model/storage-groups';
import { StorageGroupingService } from './storage-grouping.service';
export class StorageGroupingComponent {
    constructor(storageGroupingService, dataService, log) {
        this.storageGroupingService = storageGroupingService;
        this.dataService = dataService;
        this.log = log;
    }
    ngOnInit() {
        this.initStorageGroups();
        this.storageGroupingService.getStorages()
            .subscribe(storages => {
            this.storages = storages;
        }, error => this.log.error('Failed to get storage list', error));
        this.block.start();
    }
    ngOnDestroy() {
        this.block.unsubscribe();
    }
    initStorageGroups() {
        this.storageGroupingService.getStorGrList()
            .subscribe(data => this.children = data, error => this.log.error('Failed to get storage groups!', error));
    }
    deleteGroup(index) {
        if (this.children.splice(index, 1).some(c => c._selected))
            this.block.start();
    }
    showStorages(clicked) {
        this.deselect(this.children);
        clicked._selected = true;
        this.selected = clicked;
        this.storages.forEach((storage) => {
            storage.checked = this.selected.storageNames && this.selected.storageNames.some(sn => sn.object_id === storage.object_id);
        });
        this.block.stop();
    }
    saveSG() {
        this.storageGroupingService.saveStorages(this.children)
            .subscribe(data => {
            this.log.info('Storage groups saved successfully.');
            this.dataService.loadStorageGroups();
            this.initStorageGroups();
        }, error => this.log.error('Failed saving storage groups.', error));
    }
    addNewSg() {
        if (!this.children) {
            var tmp = [];
            this.children = tmp;
        }
        else {
            this.children.push(new StorageGroupItem());
        }
    }
    onClickSelect(storage) {
        if (!this.selected.storageNames) {
            this.selected.storageNames = [
                storage,
            ];
        }
        else {
            for (var i = 0; i < this.selected.storageNames.length; i++) {
                if (storage.object_id === this.selected.storageNames[i].object_id) {
                    this.selected.storageNames.splice(i, 1);
                    return;
                }
            }
            this.selected.storageNames.push(storage);
        }
    }
    deselect(nodes) {
        for (var i = 0; i < nodes.length; i++) {
            nodes[i]._selected = false;
        }
    }
}
tslib_1.__decorate([
    BlockUI('storages'),
    tslib_1.__metadata("design:type", Object)
], StorageGroupingComponent.prototype, "block", void 0);
