import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from 'src/app/globals';
import { Product } from 'src/app/model/product';

@Injectable({
  providedIn: 'root'
})
export class GlobalSearchService {

  globalSearchQuery: string;

  globalSearchResults;

  constructor(private http: HttpClient) { }

  searchLparGlobal() {
    return this.http.get<any[]>(Globals.RUNTIME_PATH + Product.LPAR.pathCgi + '/dbwrapper.sh?procname=searchGlobal&q=' + this.globalSearchQuery);
  }

  searchStorGlobal() {
    return this.http.get<any[]>(Globals.RUNTIME_PATH + Product.STOR.pathCgi + '/dbwrapper.sh?procname=searchGlobal&q=' + this.globalSearchQuery);
  }

  searchStorOld() {
    return this.http.get(Globals.RUNTIME_PATH + Product.STOR.pathCgi + '/volume-search.sh?LPAR=' + this.globalSearchQuery + '&volsrch=1&sansrch=1',
      { responseType: 'text' });
  }

  searchLparOld() {
    return this.http.get(Globals.RUNTIME_PATH + Product.LPAR.pathCgi + '/lpar-search.sh?LPAR=' + this.globalSearchQuery, { responseType: 'text' });
  }
}
