import wlabel from 'src/app/wlabel.json';
import { Globals } from '../globals';
import { LoggerService } from '../logger.service';
import { BackendAbout } from './about';
import { Deferred } from './deferred';

export interface BackendWrap {
  xormonReady: PromiseLike<any>;
  xormonVars: { user: string };
  sysInfo: { XorMon: boolean, free: string };
  //prefixCgiPathXormon(prefix: string): void;
  myreadyFunc(): void;
  getImageTitle(dataSrc: {}): string;
  getImagePeriod(dataSrc: {}): string;
}
export interface LparWrap extends BackendWrap {
  xormonVars: { vc: string, reload: () => void, user: string };
  genPdf(graphs: Record<string, string[]>): void;
  genXls(graphs: Record<string, string[]>): void;
  showPrediction(pDiv: HTMLElement): void;
}

export interface StorWrap extends BackendWrap {
  xormonVars: { user: string };
  initHeatmapCfg(ui?: any): void;
  copyServices(): void;
  snapshotting(): void;
  genPoolCapacity(storage: string, capURL: string, hwtype: string): JQuery.Promise<void>;
  genCapacity(div: JQuery): JQuery.Promise<void>;
  genCapacityPoolAll(div: JQuery): JQuery.Promise<void>;
  genCapacityStorageGroup(div: JQuery, groupList: object): JQuery.Promise<void>;
  treeMapHeatmap(): void;
}

export class Product<T extends BackendWrap = BackendWrap> {

  private static readonly VERSION_DEV = 'dev';
  private static readonly VERSION_MIN_SQLITE = [3, 8, 3];
  private static readonly SERVER_FILESYSTEM = 'Direct filesystem access';
  private static readonly STOR_TOPOLOGY_ID = 'storTopologyScriptID';
  private static readonly storTopologyUrl = Globals.API_HOST + Globals.RUNTIME_PATH + wlabel.storPath + 'jquery/graphTopology.js';

  static readonly LPAR = new Product<LparWrap>('LPAR2RRD', wlabel.lparPath, wlabel.lparPathCgi, '7.10-1');
  static readonly STOR = new Product<StorWrap>('STOR2RRD', wlabel.storPath, wlabel.storPathCgi, '7.07',
    () => Product.addScriptIfNotPresent(Product.STOR_TOPOLOGY_ID, Product.storTopologyUrl));

  // SystemJS requires full URL
  private get moduleUrl() {
    return Globals.isProd ?
      (window.location.protocol + '//' + window.location.host + window['_servlet_name'] + Globals.RUNTIME_PATH + this.path + 'jquery/mainLib.js') :
      Globals.API_HOST + '/app/assets/js/' + (this.pathCgi === wlabel.lparPathCgi ? 'lpar.js' : 'stor.js');
  }
  private readonly onResolved: () => void;
  private readonly versionMin: string;

  private _module: T;
  get module() {
    return this._module;
  }

  get versionCurrent(): string {
    return this.info.about['tool_version'];
  }

  /**
   * Product name, i.e. STOR2RRD
   */
  readonly name: string;
  /**
   * HTML URL path, i.e. /stor2rrd/
   */
  readonly path: string;
  /**
   * HTML URL fragment, i.e. stor2rrd
   */
  readonly pathBare: string;
  /**
   * URL path to scripts, i.e. /stor2rrd-cgi
   */
  readonly pathCgi: string;
  readonly info = new BackendAbout();
  /**
   * Promise if enabled, about loaded and JS available
   */
  readonly resolved = new Deferred();

  private constructor(name: string, path: string, pathCgi: string, versionMin: string, onResolved?: () => void) {
    this.name = name;
    this.path = path;
    this.pathBare = path.replace('/', '');
    this.pathCgi = pathCgi;
    this.versionMin = versionMin;
    this.onResolved = onResolved;
  }

  init(enabled: boolean) {
    System.delete(this.moduleUrl);
    this.resolved.reset();

    if (enabled) {

      let aboutLoaded = new Deferred();
      Globals.http.get(Globals.RUNTIME_PATH + this.pathCgi + '/genjson.sh?jsontype=about', { observe: 'response' }).subscribe(data => {
        this.info.about = data.body;
        this.info.server = Globals.BACKEND_INFO.storLocal ? Product.SERVER_FILESYSTEM : data.headers.get('Server');
        aboutLoaded.resolve();
      },
        error => {
          LoggerService.INSTANCE.error(`Failed to get ${this.name} about info!`, error);
          aboutLoaded.reject();
        });

      let sqliteChecked = new Deferred();
      Globals.http.get<string[]>(Globals.RUNTIME_PATH + this.pathCgi + Globals.DBWRAPPER_SH + '?procname=getSqliteVersion')
        .subscribe(version => {
          if (!version || version.length !== 1) {
            LoggerService.INSTANCE.error(`${this.name} SQLite version check failed!
                    <br><a href="${wlabel.xormonSqlite}" target="_blank">Help page</a>`, version, true);
            sqliteChecked.reject();
            return;
          }
          this.info.sqlite = version[0];
          if (!Product.isVersionValid(version[0], Product.VERSION_MIN_SQLITE)) {
            LoggerService.INSTANCE.error(`SQLite version required: ${Product.VERSION_MIN_SQLITE.join('.')}<br>
                    ${this.name} SQLite version: ${version[0]}
                  <br><a href="${wlabel.xormonSqlite}" target="_blank">Help page</a>`, null, true);
            sqliteChecked.reject();
          } else {
            sqliteChecked.resolve();
          }
        }, error => {
          LoggerService.INSTANCE.error(`${this.name} SQLite version check failed!
                  <br><a href="${wlabel.xormonSqlite}" target="_blank">Help page</a>`, error, true);
          sqliteChecked.reject();
        }
        );

      Promise.all([aboutLoaded.promise, System.import(this.moduleUrl)]).then(
        results => {
          if (!Product.isVersionValid(this.versionCurrent, this.versionMin)) {
            LoggerService.INSTANCE.error(`${this.name} version required: ${this.versionMin}<br>
                                ${this.name} version current: ${this.versionCurrent}`);
          }

          try {
            let outer = results[1].outer();
            if (outer.xormonMinVersion) {
              if (!Product.isVersionValid(Globals.ABOUT.xormonVersion, outer.xormonMinVersion)) {
                LoggerService.INSTANCE.error(`${this.name} v${this.versionCurrent} requires Xormon v${outer.xormonMinVersion}<br>
                  Xormon version current: ${Globals.ABOUT.xormonVersion}`, null, true);
                this.resolved.reject({ msg: 'Xormon update required!', error: true });
                return;
              }
            }
            outer.prefixCgiPathXormon(Globals.API_HOST + Globals.RUNTIME_PATH, this.pathCgi);
            outer.setPageScrollElementFce(Globals.getDefaultPageScrollElement.bind(Globals));
            this._module = outer.inner();

            Promise.all([sqliteChecked.promise, this.module.xormonReady]).then(() => {

              if (this.module.sysInfo.XorMon) {
                Globals.userLoaded.promise.then(() => {
                  this.module.xormonVars.user = Globals.currentUser.username;
                  if (this.onResolved) {
                    this.onResolved();
                  }
                  this.resolved.resolve();
                }, reason => this.resolved.reject({ msg: 'User load failed', error: true, reason: reason }));

              } else {
                LoggerService.INSTANCE.error(`${this.name} not in Xormon mode!<br><a href="${wlabel.xormonMode}" target="_blank">Help page</a>`, null, true);
                this.resolved.reject({ msg: `${this.name} not in Xormon mode!`, error: true });
              }

            }, reason => this.resolved.reject({ msg: this.name + ' init failed', error: true, reason: reason }));
          } catch (error) {
            this.resolved.reject({ msg: `${this.name} init failed!`, error: true, reason: error });
          }

        },
        reason => this.resolved.reject({ msg: this.name + ' JS unavailable', error: true, reason: reason })
      );
    } else {
      this.resolved.reject({ msg: this.name + ' disabled' });
    }
  }

  private static addScriptIfNotPresent(id: string, url: string) {
    let script = document.getElementById(id) as HTMLScriptElement;
    if (!script) {
      script = document.createElement('script');
      script.id = id;
      script.type = 'text/javascript';
      script.src = url;
      document.getElementsByTagName('head')[0].appendChild(script);
    }
  }

  private static isVersionValid(versionCurrent: string, versionMin: number[] | string) {
    if (versionCurrent === Product.VERSION_DEV) {
      return !Globals.isProd;
    }
    if (typeof versionMin === 'string') {
      versionMin = versionMin.split(/\.|-/g).map(c => parseInt(c));
    }
    let vc = versionCurrent.split(/\.|-/g).map(s => parseInt(s));
    for (let i = 0; i < vc.length && i < versionMin.length; i++) {
      if (vc[i] > versionMin[i])
        return true;
      else if (vc[i] < versionMin[i])
        return false;
    }
    return vc.length >= versionMin.length;
  }

}