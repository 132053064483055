export class StorageGroupItem {
  id: number;
  storageNames: StorageItem[];
  title: string;
  _selected: boolean;
}

export class StorageItem {
  checked?: boolean;
  hwType: string;
  object_id: string;
  label: string;
}

