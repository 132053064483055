import { Router } from "@angular/router";
import { DataService } from "../data.service";
import { Globals } from '../globals';
import { LoggerService } from '../logger.service';
import { MenuItems } from '../menu-items';
import { AuthService } from "./auth.service";
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../data.service";
import * as i3 from "../logger.service";
export class AuthGuard {
    constructor(router, dataService, log) {
        this.router = router;
        this.dataService = dataService;
        this.log = log;
    }
    canActivate(next, state) {
        if (Globals.authenticated) {
            return Promise.allSettled([Globals.userLoaded.promise, this.dataService.backendLoaded.promise,
                this.dataService.serverLoaded.promise, this.dataService.storageLoaded.promise, this.dataService.networkLoaded.promise])
                .then(() => {
                if (MenuItems.isVisibleRoute(next.params))
                    return true;
                if (!Globals.authenticated)
                    return false;
                this.log.error('Cannot navigate to: ' + JSON.stringify(next.params));
                if (this.router.url.startsWith(Globals.PATH_LOGIN) || !DataService.INSTANCE.selectedNode) {
                    this.router.navigateByUrl('/');
                }
                return false;
            }, error => {
                //this.log.error('Unable to decide if component view is allowed!', error);
                return false;
            });
        }
        // not logged in so redirect to login page with the return url
        this.router.navigate([Globals.PATH_LOGIN], {
            queryParams: {
                returnUrl: state.root.queryParams.returnUrl
                    ? state.root.queryParams.returnUrl
                    : AuthService.getReturnUrl(state.url)
            }
        });
        return false;
    }
}
AuthGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthGuard_Factory() { return new AuthGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.DataService), i0.ɵɵinject(i3.LoggerService)); }, token: AuthGuard, providedIn: "root" });
