import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { forkJoin, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';
import { Product } from 'src/app/model/product';
import customUrls from 'src/app/wlabel.json';

@Component({
  selector: 'xormon-platform',
  templateUrl: './platform.component.html',
  styleUrls: ['./platform.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PlatformComponent implements OnDestroy {

  private navigationSubscription = Subscription.EMPTY;
  title: string;
  page;
  platform: string;
  urls = customUrls;

  constructor(private dataService: DataService, private http: HttpClient, private sanitizer: DomSanitizer,
    private router: Router, private log: LoggerService) {
    this.navigationSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.dataService.nodeSelected.promise.then(() => {
          if (!this.navigationSubscription.closed) {
            if (Globals.isSameNavigation(e))
              return;
            this.init();
          }
        });
      });
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }

  init() {
    if (this.platform === this.dataService.selectedNode.key)
      return;
    this.title = this.dataService.selectedNode.title;
    this.platform = this.dataService.selectedNode.key;
    if (this.dataService.selectedNode.data.json) {
      const promiseForm = this.http.get(Globals.RUNTIME_PATH + Product.LPAR.pathCgi + '/hosts.sh?cmd=form&platform='
        + this.platform, { responseType: 'text' });

      forkJoin(promiseForm).subscribe(result => {
        this.page = this.sanitizer.bypassSecurityTrustHtml(result[0]);
        setTimeout(() => {
          let form = $("form[action]");
          let action = form.attr('action');
          if (action && action.startsWith(Product.LPAR.pathCgi))
            form.attr('action', Globals.API_HOST + Globals.RUNTIME_PATH + action);
          Product.LPAR.module.myreadyFunc();
        });
      }, error => this.log.error('Failed to get platform data for: ' + this.title, error));
    } else {
      this.page = null;
    }
  }

}
