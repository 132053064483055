<h1 mat-dialog-title>Test event alerting</h1>
<div mat-dialog-content>
  Save configuration at first, only saved configuration will be tested!
  <br><br>Alerting configuration will be tested.
  <br>All alertes will be raised even if no tresholds are reached.
  <br>Do you want to proceed?
  <div class="text-danger">{{testAlertError}}</div>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="onCancel()">Cancel</button>
  <button mat-button color="primary" (click)="onRun()" cdkFocusInitial>Run</button>
</div>
