<div id="ldapSetup" class="card">
  <div class="card-body">
    <div class="max-width" style="max-width: 800px;">
      <p-fieldset>
        <p-header>
          <div class="form-check form-check-lg">
            <input class="form-check-input" type="checkbox" [(ngModel)]="config.enabled" name="ldapEnabled"
              id="ldapEnabled" (change)="block()">
            <label class="form-check-label mb-0" for="ldapEnabled">
              LDAP Configurations
            </label>
          </div>
          <button class="btn btn-primary float-right save" (click)="saveConfig()" [disabled]="!canSave()"
            [title]="canSave() ? '' : 'Configurations must be tested first'">Save
            configurations</button>
        </p-header>
        <ng-template #blockTemplate>
          <div class="block-ui-template text-center">
            <i class="fas fa-user-shield fa-4x"></i>
            <div><strong>LDAP is disabled</strong></div>
          </div>
        </ng-template>
        <div *blockUI="'ldap';template: blockTemplate">
          <button class="btn" (click)="addCfg()"> <i class="fas fa-plus"></i> Add configuration</button>
          <div style="height: 400px;" *ngIf="!config.configs">
            <xormon-loading-indicator></xormon-loading-indicator>
          </div>
          <p-tabView *ngIf="config.configs" (onClose)="removeCfg($event)" [activeIndex]="activeCfgIndex"
            (onChange)="tabChanged($event)">
            <p-tabPanel *ngFor="let tab of config.configs; let i = index" [selected]="i == activeCfgIndex"
              [closable]="true">
              <ng-template pTemplate="header"><span class="move-tab"><i class="fas fa-angle-left mr-1 hover-info"
                    (click)="moveCfgLeft(i, $event)" title="Move tab left"></i>
                  <i class="fas fa-angle-right mr-2 hover-info" (click)="moveCfgRight(i, $event)"
                    title="Move tab right"></i></span>
                <span [ngClass]="{'font-italic': tab.untested}">{{
                  tab.name + (tab.untested ? '*' : '') }}</span>
              </ng-template>
              <form (ngSubmit)="testConfig(tab)" #ldapForm="ngForm" class="mb-4">
                <div class="form-row">
                  <div class="col-lg-6 form-group">
                    <label><button class="btn add-url" (click)="addUrl(tab)" type="button"
                        title="Paged results may not be supported when contacting multiple directory service URLs"> <i
                          class="fas fa-plus"></i>
                        Add URL</button></label>
                    <p-overlayPanel #opUrl appendTo="body">
                      Specify URL of LDAP server<br>e.g. <code>ldaps://localhost:636</code>
                      <br>or Global Catalog for whole AD forest<br>e.g. <code>ldaps://ad.xorux.com:3269</code>
                    </p-overlayPanel>
                    <p-listbox [options]="tab.urls" styleClass="url-box">
                      <!-- <p-header>
                        <i class="fa fa-link"></i>
                        List of URLs <button class="btn" (click)="addUrl(tab)" type="button"> + Add URL</button>
                      </p-header> -->
                      <ng-template let-url let-i="index" pTemplate="item">
                        <div class="input-group ui-helper-clearfix">
                          <div class="input-group-prepend">
                            <p-dropdown [options]="ldapSchemas" [(ngModel)]="url.scheme" styleClass="dropdown-grayed"
                              [name]="'ldapScheme'+i" appendTo="body"
                              title="Protocol scheme must be same within one configuration"
                              (onChange)="setScheme(tab.urls, url.scheme)"></p-dropdown>
                            <span class="input-group-text">://</span>
                          </div>
                          <input class="form-control" [(ngModel)]="url.id.urlPort" [name]="'url'+i"
                            placeholder="localhost:389" maxlength="250" [xormon-poptip]="opUrl"
                            [required]="tab.enabled">
                          <button class="btn hover-danger" (click)="removeUrl(tab, i)" type="button"><i
                              class="far fa-trash-alt"></i></button>
                        </div>
                      </ng-template>
                    </p-listbox>

                    <!-- <div class="input-group">
                      <div class="input-group-prepend">
                        <p-dropdown [options]="ldapSchemas" [(ngModel)]="config['ldap.scheme']"
                          styleClass="dropdown-grayed" name="ldapScheme"></p-dropdown>
                        <span class="input-group-text">://</span>
                      </div>
                      <input class="form-control" [(ngModel)]="config['ldap.url']" name="url"
                        placeholder="localhost:389" pattern="^[a-z0-9\.]+(:[0-9]{1,5})?(\/.*)?$" maxlength="250"
                        [xormon-poptip]="opUrl" [required]="enabled">
                    </div> -->
                  </div>
                  <div class="col-lg-6">
                    <div class="form-row">
                      <div class="col-12 form-group">
                        <label>Base DN (optional):</label>
                        <p-overlayPanel #opRoot appendTo="body">
                          Specify common prefix for distinguished name<br>e.g.
                          <code>dc=xorux,dc=com</code>
                          <br>If empty, base DN will be resolved from username's domain<br>e.g.
                          <code>user@xorux.com</code>
                        </p-overlayPanel>
                        <input class="form-control" [(ngModel)]="tab.baseDN" placeholder="dc=xorux,dc=com" name="root"
                          [xormon-poptip]="opRoot" maxlength="250">
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-12 form-group">
                        <label>Group Base (optional):</label>
                        <p-overlayPanel #opGroupBase appendTo="body">
                          Specify base DN for groups search<br>e.g. <code>cn=Groups</code>
                        </p-overlayPanel>
                        <input class="form-control" [(ngModel)]="tab.groupBaseDN" placeholder="ou=groups"
                          name="groupBase" [xormon-poptip]="opGroupBase" maxlength="250">
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="col-12 form-group">
                        <label>User Base (optional):</label>
                        <p-overlayPanel #opUserBase appendTo="body">
                          Specify DN identifying users' group<br>e.g. <code>cn=Users</code>
                        </p-overlayPanel>
                        <input class="form-control" [(ngModel)]="tab.userBaseDN" placeholder="ou=people" name="userBase"
                          [xormon-poptip]="opUserBase" maxlength="250">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-row">
                  <div class="col-lg-6 form-group">
                    <label>User ObjectClass:</label>
                    <p-overlayPanel #opUserID appendTo="body">
                      Specify objectClass value identifying user object
                      <br>e.g. <code>user</code>
                    </p-overlayPanel>
                    <input class="form-control" [(ngModel)]="tab.userObjectClass" [required]="tab.enabled" name="userID"
                      placeholder="organizationalPerson" [xormon-poptip]="opUserID" maxlength="250">
                  </div>
                  <div class="col-lg-6 form-group">
                    <label>User Filter Attribute:</label>
                    <p-overlayPanel #opUserFilter appendTo="body">
                      Specify user object's attribute identifying individual login name
                      <br>e.g. <code>userPrincipalName</code>
                    </p-overlayPanel>
                    <input class="form-control" [(ngModel)]="tab.userFilterAttribute" [required]="tab.enabled"
                      name="userFilter" placeholder="uid" [xormon-poptip]="opUserFilter" maxlength="250">
                  </div>
                  <!-- <div class="col-lg-6 form-group form-check">
                    <p-overlayPanel #opUserId appendTo="body">
                      Check if user login name does not contain domain path
                      <br>e.g. <code>Login: xormon - checked<br>xormon@xorux.com - unchecked</code>
                    </p-overlayPanel>
                    <input class="form-check-input" id="chkShortUid" [(ngModel)]="config['ldap.user.uid']" name="userUid"
                      tooltip="Check if user logs in with full email like credentials" type="checkbox">
                    <label for="chkShortUid" class="form-check-label" [xormon-poptip]="opUserId">User uses short UID</label>
                  </div> -->
                </div>
                <div class="form-row">

                  <div class="col-lg-6 form-group">
                    <label>Group Filter Attribute:</label>
                    <p-overlayPanel #opGroupFilter appendTo="body">
                      Specify group's object attribute for user's groups search<br>e.g.
                      <code>member</code>
                    </p-overlayPanel>
                    <input class="form-control" [(ngModel)]="tab.groupUserAttribute" [required]="tab.enabled"
                      name="groupFilter" placeholder="uniqueMember" [xormon-poptip]="opGroupFilter" maxlength="250">
                  </div>
                  <div class="col-lg-6 form-group">
                    <label>Group ObjectClass:</label>
                    <p-overlayPanel #opGroupId appendTo="body">
                      Specify objectClass value identifying group object<br>e.g.
                      <code>group</code>
                    </p-overlayPanel>
                    <input class="form-control" [(ngModel)]="tab.groupObjectClass" [required]="tab.enabled"
                      name="groupId" placeholder="groupOfUniqueNames" [xormon-poptip]="opGroupId" maxlength="250">
                  </div>
                </div>
                <button type="submit" class="btn btn-primary" [disabled]="ldapForm.invalid || !tab.urls.length">Test
                  configuration and obtain groups</button>
              </form>

              <p-table #tLdapGroups [value]="tab.groupsFresh" autoLayout="false" scrollable="true" scrollHeight="500px"
                styleClass="group-table" [paginator]="true" [rows]="100">
                <ng-template pTemplate="header">
                  <tr>
                    <th class="filter-inline"><span>Detected LDAP Groups
                        <small>{{tab.groupsFresh?.length}}</small></span>
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text"><i class="fas fa-search"></i></span>
                        </div>
                        <input pInputText class="form-control"
                          (input)="tLdapGroups.filter($event.target.value, 'dn', 'contains')"
                          placeholder="Filter by group DN">
                      </div>
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-rowData let-lg>
                  <tr [pSelectableRow]="rowData">
                    <td [title]="lg.description">
                      {{ lg.dn}}
                    </td>
                  </tr>
                </ng-template>
              </p-table>

            </p-tabPanel>
          </p-tabView>
        </div>

      </p-fieldset>

    </div>

  </div>

  <p-dialog [(visible)]="progressDialogShown" baseZIndex="999" [modal]="true" [dismissableMask]="false"
    [closeOnEscape]="false" [closable]="false" appendTo="body" [styleClass]="'ldapFeedback'">
    <p-header>
      <div>
        <span class="mr-2">Setup check<span class="loading" *ngIf="progressStatus===STATUS.INPROGRESS"></span></span>
        <i *ngIf="progressStatus===STATUS.FAIL" class="fas fa-exclamation text-danger"></i>
        <i *ngIf="progressStatus===STATUS.SUCCESS" class="fas fa-check text-success"></i>
      </div>
    </p-header>
    <div>
      <div style="overflow: visible; white-space: pre;" [innerHTML]="progressMessage"></div>
    </div>
    <p-footer>
      <button class="btn btn-secondary" (click)="cancelSaveTest()"
        *ngIf="progressStatus===STATUS.INPROGRESS">Cancel</button>
      <button class="btn btn-secondary" (click)="progressDialogShown=false"
        *ngIf="progressStatus!==STATUS.INPROGRESS">Close</button>
    </p-footer>
  </p-dialog>
</div>
