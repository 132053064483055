/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./overview-content.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./overview-content.component";
var styles_OverviewContentComponent = [i0.styles];
var RenderType_OverviewContentComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_OverviewContentComponent, data: {} });
export { RenderType_OverviewContentComponent as RenderType_OverviewContentComponent };
export function View_OverviewContentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["id", "globoverviewdiv"], ["style", "margin-top: 3em;"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.content; _ck(_v, 0, 0, currVal_0); }); }
export function View_OverviewContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xormon-overview-content", [], null, null, null, View_OverviewContentComponent_0, RenderType_OverviewContentComponent)), i1.ɵdid(1, 4308992, null, 0, i2.OverviewContentComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OverviewContentComponentNgFactory = i1.ɵccf("xormon-overview-content", i2.OverviewContentComponent, View_OverviewContentComponent_Host_0, { content: "content" }, {}, []);
export { OverviewContentComponentNgFactory as OverviewContentComponentNgFactory };
