import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { RxStompService } from '@stomp/ng2-stompjs';
import { MessageService } from 'primeng/api';
import { tap } from 'rxjs/operators';
import { UserService } from '../content/settings/user-profile/user.service';
import { DataService } from '../data.service';
import { Globals } from '../globals';
import { LoggerService } from '../logger.service';
import { ROUTES } from '../model/routes';
import { User } from '../model/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  static INSTANCE: AuthService;

  private parsedUrl: UrlTree;
  private url: string;

  public static getReturnUrl(url: string, location = false) {
    const idx = url.indexOf('(');
    let mainUrl = url.substr(0, idx > -1 ? idx : url.length);
    if (location) {
      const appIdx = mainUrl.indexOf('/app/');
      mainUrl = mainUrl.substr(appIdx + 4);
    }
    const q = url.indexOf('?');
    if (q > -1) {
      mainUrl = mainUrl + url.substr(q);
    }
    return mainUrl;
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private log: LoggerService,
    public dataService: DataService,
    private injector: Injector,
    private rxStompService: RxStompService,
    private msgService: MessageService
  ) {
    AuthService.INSTANCE = this;
    Globals.LOGOUT_EMITTER.subscribe(() => {
      this.logout();
    });
  }

  login(username, password) {
    const params = new HttpParams({
      fromObject: {
        username: username,
        password: password
      }
    });
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    return this.http
      .post<User>(Globals.PATH_LOGIN, params, { headers })
      .pipe(
        tap(
          resp => {
            this.msgService.clear();
            Globals.authenticated = true;
            this.dataService.init(resp);
            return resp;
          },
          error => {
            Globals.authenticated = false;
            if (error instanceof HttpErrorResponse) {
              if (error.status === Globals.STATUS_CODE_UNAUTHORIZED && error.url.endsWith(UserService.PATH_API_CURRENT_USER)) {
                let advice = 'Please check if cookies are enabled in browser.'
                if (window.location.protocol === 'http:') {
                  advice = 'Please switch to https protocol if you have already accessed Xormon on secured connection.';
                }
                this.log.error('Authentication not propagated!<br>' + advice, error, true);
                return;
              }
            }
            this.log.error('Login failed!', error, true);
          }
        )
      );
  }

  logout(skipReturnUrl?: boolean) {
    if (!this.dataService) {
      this.dataService = this.injector.get(DataService);
    }
    this.dataService.closeAllDialogs();
    Globals.authenticated = false;
    this.rxStompService.deactivate();
    // get rid of content outlet component
    this.parsedUrl = this.router.parseUrl(this.router.url);
    this.url = this.router.url;
    this.router.navigate([{ outlets: { content: ROUTES.DUMMY } }]).finally(() => {
      this.dataService.selectedType = null;
      this.http.get('/logout').subscribe(
        data => {
          this.afterLogout(skipReturnUrl);
        },
        error => {
          this.afterLogout(skipReturnUrl);
        }
      );
    });
  }

  naviateToLogin(returnUrl?: string) {
    if (returnUrl) {
      this.router.navigate(
        [
          {
            outlets: {
              content: null,
              primary: ['login']
            }
          }
        ],
        {
          queryParams: {
            returnUrl: decodeURI(returnUrl)
          }
        }
      );
    } else {
      this.router.navigate([
        {
          outlets: {
            content: null,
            primary: ['login']
          }
        }
      ]);
    }
    this.dataService.selectedType = null;
  }

  private afterLogout(skipReturnUrl) {
    if (skipReturnUrl) {
      this.naviateToLogin();
      return;
    }
    this.naviateToLogin(this.parsedUrl.queryParams.returnUrl
      ? this.parsedUrl.queryParams.returnUrl
      : AuthService.getReturnUrl(this.url));
  }
}
