<nav class="navbar navbar-expand-md">

  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">

      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown"
          [class.active]="activeMenu === dashboardItems.key">
          {{dashboardItems.title}}
        </a>
        <div class="dropdown-menu">
          <section *ngFor="let section of dashboardItems.children">
            <section *ngIf="isVisible(section.visible)">
              <section *ngIf="section.content">
                <a class="dropdown-item" style="padding-left: 0.75rem;"
                  [routerLink]="[{ outlets: { primary: ['dashboard', section.title.toLowerCase(), section.key], content: section.content}}]">
                  {{section.title}}</a>
              </section>
              <section *ngIf="!section.content">
                <div class="nav-sub">{{section.title}}</div>
                <section *ngFor="let item of section.children">
                  <a class="dropdown-item" *ngIf="isVisible(item.visible)"
                    [routerLink]="[{ outlets: { primary: ['dashboard', section.key, item.key], content: item.content}}]">
                    {{item.title}}</a>
                </section>
              </section>
            </section>
          </section>
        </div>
      </li>

      <li class="nav-item dropdown" *ngIf="isCustomGroup">
        <a class="nav-link dropdown-toggle" [class.active]="activeMenu === customGroupItems.key"
          [class.disabled]="!customGroupItems" href="#" role="button"
          data-toggle="dropdown">{{customGroupItems.title}}</a>
        <div class="dropdown-menu">
          <section *ngFor="let cgi of customGroupItems.children">
            <a *ngIf="isVisible(cgi.visible)" class="dropdown-item"
              [routerLink]="[{ outlets: { primary: ['cg', cgi.key], content: null}}]">{{cgi.title}}</a>
          </section>
        </div>
      </li>

      <li class="nav-item dropdown" *ngIf="isServer">
        <a class="nav-link dropdown-toggle"
          [class.active]="activeMenu === 'server' || activeMenu==='reporter' && activeMenu2==='server'"
          [class.disabled]="!serverPlatforms" href="#" role="button" data-toggle="dropdown">
          Server
        </a>
        <div class="dropdown-menu">
          <!-- <a class="dropdown-item"
            [routerLink]="[{ outlets: { primary: ['reporter', 'server', 'serRepDefinitions'], content: 'repLparDefs'}}]">Reporter</a>
          <div class="dropdown-divider"></div> -->
          <a *ngFor="let sp of serverPlatforms | keyvalue: keepOrder" class="dropdown-item" routerLinkActivex="active"
            routerLink="/server/{{sp.value.next_level.hw_type}}/">{{sp.value.title}}</a>
        </div>
      </li>

      <li class="nav-item dropdown" *ngIf="isStorage">
        <a class="nav-link dropdown-toggle"
          [ngClass]="{'active': activeMenu==='storage' || activeMenu==='storageGroups' || activeMenu==='reporter' && activeMenu2==='storage', 'disabled': !storageTypes}"
          href="#" role="button" data-toggle="dropdown">
          Storage
        </a>
        <div class="dropdown-menu">
          <a class="dropdown-item" routerLink="/storage/totals/">Totals</a>
          <!-- <a class="dropdown-item"
            [routerLink]="[{ outlets: { primary: ['reporter', 'storage', 'stoRepDefinitions'], content: 'repStorDefs'}}]">Reporter</a> -->
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" routerLink="/storage/ALL/">All Devices</a>
          <div *ngIf="(rootHolder | json) != '{}'" class="dropdown-divider"></div>
          <a *ngFor="let gr of rootHolder | keyvalue" class="dropdown-item"
            routerLink="/storageGroups/{{gr.key}}/">{{gr.key}}</a>
          <div class="dropdown-divider"></div>
          <div class="nav-sub">Storage Types</div>
          <div *ngFor="let st of storageTypes | keyvalue">
            <a *ngIf="st.key!=='ALL'" class="dropdown-item" routerLink="/storage/{{st.key}}/">{{st.value.label}}</a>
          </div>
        </div>
      </li>

      <li class="nav-item dropdown" *ngIf="isNetwork">
        <a class="nav-link dropdown-toggle" [class.active]="activeMenu === 'network'" [class.disabled]="!networkTypes"
          href="#" role="button" data-toggle="dropdown">Network</a>
        <div class="dropdown-menu">
          <a *ngFor="let nt of networkTypes | keyvalue: keepOrder" class="dropdown-item"
            routerLink="/network/{{nt.key}}/">{{nt.value.title}}</a>
        </div>
      </li>

      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown"
          [class.active]="activeMenu === reportItems.key">
          {{reportItems.title}}
        </a>
        <div class="dropdown-menu">
          <section *ngFor="let section of reportItems.children">
            <section *ngIf="isVisible(section.visible)">
              <div class="nav-sub">{{section.title}}</div>
              <section *ngFor="let item of section.children">
                <a class="dropdown-item" *ngIf="isVisible(item.visible)"
                  [routerLink]="[{ outlets: { primary: [reportItems.key, section.key, item.navKey ? item.navKey : item.key], content: item.content}}]">
                  {{item.title}}</a>
              </section>
            </section>
          </section>
        </div>
      </li>

    </ul>

    <ul class="navbar-nav icons">

      <li class="nav-item" (mouseenter)="onSearchInputEnter()" (mouseleave)="onSearchInputLeave()"
        style="position: relative;">
        <input [(ngModel)]="globalSearchText" [ngClass]="{'d-none' : !displaySearchbox}" id="globalSearchbox"
          class="nav-searchbox form-control form-control-sm input-xs" (blur)="displaySearchbox=false"
          (click)="searchInputActive=true"
          (keypress)="searchInputActive=true; $event.which===13 ? searchGlobal($event) : null"
          placeholder="Search for nodes">
        <a class="nav-link" role="button" style="cursor: pointer;" (click)="searchGlobal($event)">
          <i class="fas fa-search"></i></a>
      </li>

      <li class="nav-item dropdown" *ngIf="isAdmin.value">
        <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown"
          [class.active]="activeMenu === configItems.key">
          <i class="fas fa-cog"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right">
          <section *ngFor="let section of configItems.children">
            <section *ngIf="isVisible(section.visible)">
              <div class="nav-sub">{{section.title}}</div>
              <a class="dropdown-item" *ngFor="let item of section.children"
                [routerLink]="[{ outlets: { primary: [typeConfig, section.key, item.key], content: item.content ? item.content : null}}]">
                {{item.title}}</a>
            </section>
          </section>
        </div>
      </li>

      <li class="nav-item dropdown" [class.active]="activeMenu === 'user'" title="{{userFullName}}">
        <a class="nav-link dropdown-toggle" href="#" role="button" data-toggle="dropdown"><i
            class="fas fa-user"></i></a>
        <div class="dropdown-menu dropdown-menu-right">
          <div class="dropdown-item bg-transparent mr-3">{{userFullName}}<br><span
              class="font-weight-light">{{userLoginName}}</span></div>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item"
            [routerLink]="[{ outlets: { primary: [typeConfig, typeSettings, 'profile'], content: 'profile'}}]">Profile</a>
          <a class="dropdown-item" href="#" (click)="logout($event)">Logout</a>
        </div>
      </li>

    </ul>
  </div>
</nav>
