import { AfterViewInit, Component, ElementRef } from "@angular/core";
import { CustomMessagesService } from 'src/app/custom-messages.service';
import { DataService } from "src/app/data.service";
import { Product } from 'src/app/model/product';
import customUrls from 'src/app/wlabel.json';

@Component({
  selector: "xormon-reporter-stor-definitions",
  templateUrl: "./reporter-stor-definitions.component.html",
  styleUrls: ["./reporter-stor-definitions.component.scss"]
})
export class ReporterStorDefinitionsComponent implements AfterViewInit {

  urls = customUrls;
  //urlSupport = customUrls.storSupport;

  constructor(
    private dataService: DataService, private cmService: CustomMessagesService, private elef: ElementRef) { }

  ngAfterViewInit(): void {
    this.dataService.nodeSelected.promise.then(() => {
      Product.STOR.module.myreadyFunc();
    });
  }

}
