/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./platform.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./platform.component";
import * as i4 from "../../../data.service";
import * as i5 from "@angular/common/http";
import * as i6 from "@angular/platform-browser";
import * as i7 from "@angular/router";
import * as i8 from "../../../logger.service";
var styles_PlatformComponent = [i0.styles];
var RenderType_PlatformComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PlatformComponent, data: {} });
export { RenderType_PlatformComponent as RenderType_PlatformComponent };
function View_PlatformComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["It is the OS agent based solution, there is not any configuration necessary on the LPAR2RRD server"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Just assure that LPAR2RRD daemon is running ( ps -ef | grep lpar2rrd-daemon ) "])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a", [["class", "mr-2"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Solution overview"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "a", [["classx", "btn btn-primary"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Installation procedure"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.urls.solarisSolution; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.urls.solarisInstallation; _ck(_v, 8, 0, currVal_1); }); }
function View_PlatformComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["It is the OS agent based solution, there is not any configuration necessary on the LPAR2RRD server"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Just assure that LPAR2RRD daemon is running ( ps -ef | grep lpar2rrd-daemon ) "])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a", [["class", "mr-2"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Solution overview"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "a", [["clasxs", "btn btn-primary"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Installation procedure"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.urls.hypervSolution; _ck(_v, 6, 0, currVal_0); var currVal_1 = _co.urls.hypervInstallation; _ck(_v, 8, 0, currVal_1); }); }
function View_PlatformComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["It is the OS agent based solution, there is not any configuration necessary on the LPAR2RRD server"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Just assure that LPAR2RRD daemon is running ( ps -ef | grep lpar2rrd-daemon ) "])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "d-flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a", [["classx", "btn btn-primary"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Installation procedure"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.urls.hitachiInstallation; _ck(_v, 6, 0, currVal_0); }); }
function View_PlatformComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "inner-html"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.page; _ck(_v, 0, 0, currVal_0); }); }
export function View_PlatformComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "card"], ["id", "platformCfg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [["class", "mb-3"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", " configuration"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlatformComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlatformComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlatformComponent_3)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PlatformComponent_4)), i1.ɵdid(11, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.platform == "solaris"); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.platform == "hyperv"); _ck(_v, 7, 0, currVal_2); var currVal_3 = (_co.platform == "hitachi"); _ck(_v, 9, 0, currVal_3); var currVal_4 = _co.page; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); }); }
export function View_PlatformComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xormon-platform", [], null, null, null, View_PlatformComponent_0, RenderType_PlatformComponent)), i1.ɵdid(1, 180224, null, 0, i3.PlatformComponent, [i4.DataService, i5.HttpClient, i6.DomSanitizer, i7.Router, i8.LoggerService], null, null)], null, null); }
var PlatformComponentNgFactory = i1.ɵccf("xormon-platform", i3.PlatformComponent, View_PlatformComponent_Host_0, {}, {}, []);
export { PlatformComponentNgFactory as PlatformComponentNgFactory };
