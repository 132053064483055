export interface XormonAbout {
    xormonVersion: string;
    xormonBuildTime: string;
    osInfo: string;
    javaVersion: string;
    appServer: string;
}

export class BackendAbout {
    about = {} as any;
    server: string;
    sqlite: string;

    isEnterpriseEdition(): boolean {
        return this.about.edition && this.about.edition.startsWith('Enterprise');
    }
}

export interface About {
    xormon: XormonAbout;
    lpar: BackendAbout;
    stor: BackendAbout;
}