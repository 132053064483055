import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { CustomMessagesService } from 'src/app/custom-messages.service';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';
import { Product } from 'src/app/model/product';
import { HeatmapLocalLparComponent } from '../../lpar/heatmap-local-lpar/heatmap-local-lpar.component';

declare var treeMapHeatmap;

@Component({
  selector: 'xormon-heatmap-global',
  templateUrl: './heatmap-global.component.html',
  styleUrls: ['./heatmap-global.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeatmapGlobalComponent implements OnInit, OnDestroy {

  private navigationSubscription = Subscription.EMPTY;
  private tabSubscription = Subscription.EMPTY;
  heatmaps: { first: string, second: string }[];
  loaded = false;
  activeIndex = 0;
  private selectedIndex = -1;
  private prefix: string;
  helpUrl: string;

  constructor(private log: LoggerService, private http: HttpClient, private dataService: DataService,
    private router: Router, private cmService: CustomMessagesService, private elef: ElementRef) {
    this.navigationSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.dataService.nodeSelected.promise.then(() => {
          if (!this.navigationSubscription.closed) {
            if (Globals.isSameNavigation(e))
              return;
            this.init();
          }
        });
      });
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }

  ngOnInit() {
    this.prefix = this.cmService.getPrefix(this.elef);
  }

  init() {
    this.helpUrl = this.cmService.getMsg(this.prefix + this.dataService.selectedParent);
    if (this.dataService.isLpar()) {
      this.dataService.serverLoaded.promise.then(() => {
        this.heatmaps = [];
        for (const key in this.dataService.serverPlatforms) {
          this.dataService.getServerChildren(key).subscribe(data => {
            const platformHeatmapNode = data.find(value => value.page_type && value.page_type.toLowerCase() === 'heatmap');
            if (platformHeatmapNode) {
              this.http.get(Globals.RUNTIME_PATH + Product.LPAR.pathCgi + HeatmapLocalLparComponent.getUrlPath(key), { responseType: 'text' })
                .subscribe(heatLinks => {
                  const html = Globals.DOM_PARSER.parseFromString(heatLinks, 'text/html');
                  const links = html.querySelectorAll('div#tabs ul li a');
                  links.forEach((value) => {
                    if (value.textContent.includes('Table'))
                      return;
                    this.heatmaps.push({
                      first: key + ' | ' + value.textContent,
                      second: Globals.RUNTIME_PATH + value.getAttribute('href')
                    });
                  });
                  this.heatmaps.sort((a, b) => (a.first as string).localeCompare(b.first));
                  this.activeIndex = -1;
                  setTimeout(() => this.activeIndex = 0);
                }, error => this.log.error('Failed to get heatmap for ' + key, error));
            }
          }, error => this.log.error('Failed to get children of ' + key, error));
        }
      });
    } else {
      treeMapHeatmap = Product.STOR.module.treeMapHeatmap;
      this.http.get<any[]>('/api/heatmaps/' + this.dataService.selectedParent.toUpperCase()).subscribe(data => {
        this.heatmaps = data;
        if (this.selectedIndex >= 0 && this.selectedIndex < Object.keys(this.heatmaps).length) {
          this.updateTab(this.selectedIndex);
        }
      }, error => {
        this.log.error('Failed to get heatmaps list!', error);
      });
    }
  }

  updateTab(index) {
    if (this.heatmaps.length == 0 || this.heatmaps.length <= index)
      return;
    this.loaded = false;
    this.selectedIndex = index;
    this.tabSubscription.unsubscribe();
    this.tabSubscription = this.http.get(this.heatmaps[index].second, { responseType: 'text' }).subscribe(data => {
      const html = Globals.DOM_PARSER.parseFromString(data, 'text/html');
      const hiw = html.getElementById('hiw');
      if (hiw) {
        $(hiw).remove();
      }
      $('#tab' + index).html(html.documentElement.innerHTML);
      this.fixSize(html.documentElement.innerHTML);
      setTimeout(() => {
        $('center > table.tablesorter')
          .wrap('<div class="scroll-wrap d-inline-block stickyheaders-holder"/>');
        Globals.tableSorter($('table.tablesorter'), true);
        this.dataService.registerBacklinks();
        this.loaded = true;
      });
    }, error => this.log.error('Failed to get heatmap for ' + this.heatmaps[index].first, error));
  }

  fixSize(data) {
    const content_arr = data.match(/\.content.*?}/g);
    if (!content_arr)
      return;

    content_arr.forEach(one_class => {
      if (one_class.match(/content_legend/g)) { return }
      if (one_class && one_class.length) {
        const class_name = one_class.match(/\.[a-zA-Z&_0-9&-]*/);
        const size_tmp = one_class.match(/height.*?;/g);
        const size = size_tmp[0].match(/\d+/);
        $(class_name[0]).width(size[0]);
        $(class_name[0]).height(size[0]);
      }
    });
  }
}
