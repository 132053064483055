/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./storage-grouping.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "@angular/forms";
import * as i4 from "ng-block-ui";
import * as i5 from "./storage-grouping.component";
import * as i6 from "./storage-grouping.service";
import * as i7 from "../../../data.service";
import * as i8 from "../../../logger.service";
var styles_StorageGroupingComponent = [i0.styles];
var RenderType_StorageGroupingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StorageGroupingComponent, data: {} });
export { RenderType_StorageGroupingComponent as RenderType_StorageGroupingComponent };
function View_StorageGroupingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [["class", "d-flex p-2"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { rowActive: 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 8, "input", [["class", "form-control input-sm mr-2"], ["maxlength", "250"], ["placeholder", "Enter name of group"], ["required", ""], ["type", "text"]], [[1, "required", 0], [1, "maxlength", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "click"], [null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 5)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 5)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (_co.showStorages(_v.context.$implicit) !== false);
        ad = (pd_4 && ad);
    } if (("ngModelChange" === en)) {
        var pd_5 = ((_v.context.$implicit.title = $event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i3.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i3.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(6, 16384, null, 0, i3.RequiredValidator, [], { required: [0, "required"] }, null), i1.ɵdid(7, 540672, null, 0, i3.MaxLengthValidator, [], { maxlength: [0, "maxlength"] }, null), i1.ɵprd(1024, null, i3.NG_VALIDATORS, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i3.RequiredValidator, i3.MaxLengthValidator]), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.DefaultValueAccessor]), i1.ɵdid(10, 671744, null, 0, i3.NgModel, [[8, null], [6, i3.NG_VALIDATORS], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(12, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-sm font-size-lg"], ["title", "Delete"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteGroup(_v.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 0, "i", [["class", "fas fa-times-circle"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "d-flex p-2"; var currVal_1 = _ck(_v, 3, 0, (_co.selected == _v.context.$implicit)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_11 = ""; _ck(_v, 6, 0, currVal_11); var currVal_12 = "250"; _ck(_v, 7, 0, currVal_12); var currVal_13 = _v.context.$implicit.title; _ck(_v, 10, 0, currVal_13); }, function (_ck, _v) { var currVal_2 = (i1.ɵnov(_v, 6).required ? "" : null); var currVal_3 = (i1.ɵnov(_v, 7).maxlength ? i1.ɵnov(_v, 7).maxlength : null); var currVal_4 = i1.ɵnov(_v, 12).ngClassUntouched; var currVal_5 = i1.ɵnov(_v, 12).ngClassTouched; var currVal_6 = i1.ɵnov(_v, 12).ngClassPristine; var currVal_7 = i1.ɵnov(_v, 12).ngClassDirty; var currVal_8 = i1.ɵnov(_v, 12).ngClassValid; var currVal_9 = i1.ɵnov(_v, 12).ngClassInvalid; var currVal_10 = i1.ɵnov(_v, 12).ngClassPending; _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }); }
function View_StorageGroupingComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "block-ui-template text-center"]], null, null, null, null, null))], null, null); }
function View_StorageGroupingComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "form-check p-2"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { rowActive: 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 6, "input", [["class", "form-check-input"], ["type", "checkbox"]], [[8, "id", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "click"], [null, "ngModelChange"], [null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.onClickSelect(_v.context.$implicit) !== false);
        ad = (pd_2 && ad);
    } if (("ngModelChange" === en)) {
        var pd_3 = ((_v.context.$implicit.checked = $event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(5, 16384, null, 0, i3.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.CheckboxControlValueAccessor]), i1.ɵdid(7, 671744, null, 0, i3.NgModel, [[8, null], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { model: [0, "model"], options: [1, "options"] }, { update: "ngModelChange" }), i1.ɵpod(8, { standalone: 0 }), i1.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i1.ɵdid(10, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "label", [["class", "form-check-label mb-0"]], [[8, "htmlFor", 0]], null, null, null, null)), (_l()(), i1.ɵted(12, null, [" ", " "]))], function (_ck, _v) { var currVal_0 = "form-check p-2"; var currVal_1 = _ck(_v, 3, 0, _v.context.$implicit.checked); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_10 = _v.context.$implicit.checked; var currVal_11 = _ck(_v, 8, 0, true); _ck(_v, 7, 0, currVal_10, currVal_11); }, function (_ck, _v) { var currVal_2 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.object_id, ""); var currVal_3 = i1.ɵnov(_v, 10).ngClassUntouched; var currVal_4 = i1.ɵnov(_v, 10).ngClassTouched; var currVal_5 = i1.ɵnov(_v, 10).ngClassPristine; var currVal_6 = i1.ɵnov(_v, 10).ngClassDirty; var currVal_7 = i1.ɵnov(_v, 10).ngClassValid; var currVal_8 = i1.ɵnov(_v, 10).ngClassInvalid; var currVal_9 = i1.ɵnov(_v, 10).ngClassPending; _ck(_v, 4, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_12 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.object_id, ""); _ck(_v, 11, 0, currVal_12); var currVal_13 = _v.context.$implicit.label; _ck(_v, 12, 0, currVal_13); }); }
function View_StorageGroupingComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "fieldset", [["class", "fieldset-sg border mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "legend", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Storage"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StorageGroupingComponent_4)), i1.ɵdid(6, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.storages; _ck(_v, 6, 0, currVal_0); }, null); }
export function View_StorageGroupingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 31, "div", [["class", "card"], ["id", "storageGrouping"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 30, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Storage grouping"])), (_l()(), i1.ɵeld(4, 0, null, null, 27, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 22, "div", [["class", "col-lg-4 mb-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 17, "div", [["class", "border mb-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 9, "div", [["class", "tree-border"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 8, "div", [["class", "tree-level tree-header-level ng-scope"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 7, "div", [["class", "content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 6, "div", [["class", "content-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 5, "div", [["class", "card-header p-2 d-flex align-items-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Groups"])), (_l()(), i1.ɵeld(14, 0, null, null, 2, "button", [["class", "btn ml-auto pr-0"], ["id", "storGrBut"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addNewSg() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 0, "i", [["class", "fas fa-plus-circle font-size-lg ml-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Add new "])), (_l()(), i1.ɵeld(17, 0, null, null, 6, "div", [["class", "tree-level tree-top-level ng-scope"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 5, "div", [["class", "content ng-scope"], ["ng-class", "{selected:item._selected}"], ["ng-click", "onRowClick(item, level, $event)"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 4, "div", [["class", "content-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 3, "div", [["class", "node-content storage-tree"]], null, null, null, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 2, "div", [["class", "text-left pull-right"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StorageGroupingComponent_1)), i1.ɵdid(23, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(24, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 2, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveSG() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(26, 0, null, null, 0, "i", [["class", "fas fa-save mr-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Save storage grouping configuration "])), (_l()(), i1.ɵeld(28, 0, null, null, 3, "div", [["class", "col-lg-4"]], null, null, null, null, null)), (_l()(), i1.ɵand(0, [["blockTemplate", 2]], null, 0, null, View_StorageGroupingComponent_2)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StorageGroupingComponent_3)), i1.ɵdid(31, 81920, null, 0, i4.ɵf, [i4.ɵc, i1.ViewContainerRef, i1.TemplateRef, i1.Renderer2, i1.ComponentFactoryResolver], { blockUI: [0, "blockUI"], blockUITemplate: [1, "blockUITemplate"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.children; _ck(_v, 23, 0, currVal_0); var currVal_1 = "storages"; var currVal_2 = i1.ɵnov(_v, 29); _ck(_v, 31, 0, currVal_1, currVal_2); }, null); }
export function View_StorageGroupingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xormon-storage-grouping", [], null, null, null, View_StorageGroupingComponent_0, RenderType_StorageGroupingComponent)), i1.ɵdid(1, 245760, null, 0, i5.StorageGroupingComponent, [i6.StorageGroupingService, i7.DataService, i8.LoggerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StorageGroupingComponentNgFactory = i1.ɵccf("xormon-storage-grouping", i5.StorageGroupingComponent, View_StorageGroupingComponent_Host_0, {}, {}, []);
export { StorageGroupingComponentNgFactory as StorageGroupingComponentNgFactory };
