/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loading-indicator.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/primeng/components/progressspinner/progressspinner.ngfactory";
import * as i3 from "primeng/components/progressspinner/progressspinner";
import * as i4 from "./loading-indicator.component";
var styles_LoadingIndicatorComponent = [i0.styles];
var RenderType_LoadingIndicatorComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LoadingIndicatorComponent, data: {} });
export { RenderType_LoadingIndicatorComponent as RenderType_LoadingIndicatorComponent };
export function View_LoadingIndicatorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "loading-indicator"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "p-progressSpinner", [], null, null, null, i2.View_ProgressSpinner_0, i2.RenderType_ProgressSpinner)), i1.ɵdid(2, 49152, null, 0, i3.ProgressSpinner, [], { style: [0, "style"], strokeWidth: [1, "strokeWidth"] }, null), i1.ɵpod(3, { width: 0 })], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, "2em"); var currVal_1 = 5; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_LoadingIndicatorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xormon-loading-indicator", [], null, null, null, View_LoadingIndicatorComponent_0, RenderType_LoadingIndicatorComponent)), i1.ɵdid(1, 114688, null, 0, i4.LoadingIndicatorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoadingIndicatorComponentNgFactory = i1.ɵccf("xormon-loading-indicator", i4.LoadingIndicatorComponent, View_LoadingIndicatorComponent_Host_0, {}, {}, []);
export { LoadingIndicatorComponentNgFactory as LoadingIndicatorComponentNgFactory };
