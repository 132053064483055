/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./graph.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./b64/b64.component.ngfactory";
import * as i4 from "./b64/b64.component";
import * as i5 from "@angular/common/http";
import * as i6 from "../data.service";
import * as i7 from "./abstract-graph";
import * as i8 from "./graph-simple/graph-simple.component.ngfactory";
import * as i9 from "./graph-simple/graph-simple.component";
import * as i10 from "../logger.service";
import * as i11 from "./graph.component";
import * as i12 from "@angular/material/dialog";
import * as i13 from "@angular/router";
import * as i14 from "../content/dashboard/portal/portal.service";
var styles_GraphComponent = [i0.styles];
var RenderType_GraphComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_GraphComponent, data: {} });
export { RenderType_GraphComponent as RenderType_GraphComponent };
function View_GraphComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "csvfloat"], ["title", "Download graph data as CSV"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "fas fa-file-csv fa-lg"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.prefix + _co.graph.csv); _ck(_v, 0, 0, currVal_0); }); }
function View_GraphComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "h3", [["class", "graph-title"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "graph-title-label"]], [[8, "title", 0], [8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "graph-icons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 4, "button", [["class", "reset-zoom text-danger mr-0"], ["title", "Reset zoom"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.resetZoom($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "invisible": 0 }), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "fas fa-ban"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "graph-fav"]], [[8, "title", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.switchGraphInDashboard(_co.graph, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "span", [], [[8, "className", 0]], null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "reset-zoom text-danger mr-0"; var currVal_3 = _ck(_v, 6, 0, !_co.graph.zoomed); _ck(_v, 5, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _co.graph.origTitle, ""); var currVal_1 = _co.graph.title; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = i1.ɵinlineInterpolate(1, "", (_co.graph.fav ? "Remove this graph from Dashboard" : "Add this graph to Dashboard"), ""); _ck(_v, 8, 0, currVal_4); var currVal_5 = i1.ɵinlineInterpolate(1, "fas fa-star ", (_co.graph.fav ? "font-weight-bold" : "font-weight-normal"), ""); _ck(_v, 9, 0, currVal_5); }); }
function View_GraphComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "xormon-b64", [], null, [[null, "groupZoomStart"], [null, "groupZoomEnd"], [null, "loaded"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("groupZoomStart" === en)) {
        var pd_0 = (_co.groupZoomStart.emit() !== false);
        ad = (pd_0 && ad);
    } if (("groupZoomEnd" === en)) {
        var pd_1 = (_co.groupZoomEnd.emit($event) !== false);
        ad = (pd_1 && ad);
    } if (("loaded" === en)) {
        var pd_2 = (_co.detectChanges() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i3.View_B64Component_0, i3.RenderType_B64Component)), i1.ɵdid(1, 4308992, null, 0, i4.B64Component, [i5.HttpClient, i6.DataService, i1.NgZone], { graph: [0, "graph"] }, { groupZoomStart: "groupZoomStart", groupZoomEnd: "groupZoomEnd", loaded: "loaded" }), i1.ɵprd(2048, [[2, 4]], i7.AbstractGraph, null, [i4.B64Component])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.graph; _ck(_v, 1, 0, currVal_0); }, null); }
function View_GraphComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "xormon-graph-simple", [], null, [[null, "groupZoomStart"], [null, "groupZoomEnd"], [null, "loaded"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("groupZoomStart" === en)) {
        var pd_0 = (_co.groupZoomStart.emit() !== false);
        ad = (pd_0 && ad);
    } if (("groupZoomEnd" === en)) {
        var pd_1 = (_co.groupZoomEnd.emit($event) !== false);
        ad = (pd_1 && ad);
    } if (("loaded" === en)) {
        var pd_2 = (_co.detectChanges() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i8.View_GraphSimpleComponent_0, i8.RenderType_GraphSimpleComponent)), i1.ɵdid(1, 4440064, null, 0, i9.GraphSimpleComponent, [i10.LoggerService, i5.HttpClient, i6.DataService, i1.ChangeDetectorRef, i1.NgZone], { graph: [0, "graph"] }, { groupZoomStart: "groupZoomStart", groupZoomEnd: "groupZoomEnd", loaded: "loaded" }), i1.ɵprd(2048, [[2, 4]], i7.AbstractGraph, null, [i9.GraphSimpleComponent])], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.graph; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_GraphComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { element: 0 }), i1.ɵqud(671088640, 2, { graphComponent: 0 }), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["element", 1]], null, 8, "div", [["class", "graph"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GraphComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GraphComponent_2)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GraphComponent_3)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GraphComponent_4)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.graph.csv; _ck(_v, 4, 0, currVal_0); var currVal_1 = (_co.graph.imgSrc.indexOf("detail=") > (0 - 1)); _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.graph.base64; _ck(_v, 8, 0, currVal_2); var currVal_3 = !_co.graph.base64; _ck(_v, 10, 0, currVal_3); }, null); }
export function View_GraphComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xormon-graph", [], null, null, null, View_GraphComponent_0, RenderType_GraphComponent)), i1.ɵdid(1, 4308992, null, 0, i11.GraphComponent, [i6.DataService, i10.LoggerService, i12.MatDialog, i13.Router, i14.PortalService, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GraphComponentNgFactory = i1.ɵccf("xormon-graph", i11.GraphComponent, View_GraphComponent_Host_0, { graph: "graph", reload: "reload", outsideZoom: "outsideZoom", outsideReset: "outsideReset" }, { reset: "reset", groupZoomStart: "groupZoomStart", groupZoomEnd: "groupZoomEnd", loaded: "loaded" }, []);
export { GraphComponentNgFactory as GraphComponentNgFactory };
