import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';

@Component({
  selector: 'xormon-alerting-email',
  templateUrl: './alerting-email.component.html',
  styleUrls: ['./alerting-email.component.scss']
})
export class AlertingEmailComponent implements OnInit, AfterViewInit {
  @Input()
  ready: () => void;

  @Input()
  form: () => Observable<string>;

  constructor(private log: LoggerService
  ) { }

  ngAfterViewInit(): void {
    this.form().subscribe(page => {
      const html = Globals.DOM_PARSER.parseFromString(page, 'text/html');
      $('#optform').html(html.querySelector('#optform').innerHTML);
    }, error => this.log.error('Failed to get form!', error));
    this.ready();
  }

  ngOnInit() {
  }
}
