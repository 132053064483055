import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from 'src/app/globals';
import { Product } from 'src/app/model/product';
import { TabPage } from 'src/app/model/tabs';

@Injectable({
  providedIn: 'root'
})
export class StorageTotalsService {

  constructor(private http: HttpClient) { }

  getAllDataCap(storage) {
    if (storage !== null) {
      return this.http.get(Globals.RUNTIME_PATH + Product.STOR.pathCgi + '/detail-graph.sh?host=' + storage + '&type=POOL&name=cap_pool&item=sum&time=d', { responseType: 'text' });
    }
    else {
      return this.http.get<any>(Globals.RUNTIME_PATH + Product.STOR.pathCgi + '/genjson.sh?jsontype=caps');
    }
  }

  getOneValCap(value) {
    return this.http.get(Globals.RUNTIME_PATH + value.url, { responseType: 'text' });
  }

  getTabsHost() {
    return this.http.post<TabPage>('/api/tabs', { url: 'glob-hosts-top.html', rrdType: 'stor', type: 'totals' });
  }

  getUrlHost(url) {
    return this.http.get(Globals.RUNTIME_PATH + url, { responseType: 'text' });
  }

  getPageConfigurationLpar(url: string) {
    return this.http.get(Globals.RUNTIME_PATH + url, { responseType: 'text' });
  }

  getLocalHeatMapUrl(url) {
    return this.http.get(Globals.RUNTIME_PATH + Product.STOR.path + url, { responseType: 'text' });
  }
  getLocalHeatMap(storage) {
    return this.http.get(Globals.RUNTIME_PATH + Product.STOR.path + storage + '/heatmap.html', { responseType: 'text' });
  }
  getHeatmapTab(url) {
    return this.http.get(Globals.RUNTIME_PATH + Product.STOR.path + url, { responseType: 'text' });
  }

  getUrlNameVol(urlName) {
    return this.http.get(Globals.RUNTIME_PATH + urlName, { responseType: 'text' });
  }

  loadDataVol(postData) {
    return this.http.get(Globals.RUNTIME_PATH + Product.STOR.pathCgi + '/detail-graph.sh', { params: postData, responseType: 'text' });
  }

  mappingPage(url: string) {
    if (url) {
      if (!url.startsWith(Product.STOR.pathCgi))
        url = Product.STOR.path + url;
      return this.http.get(Globals.RUNTIME_PATH + url, { responseType: 'text' });
    }
    else {
      return this.http.post('/api/page', { url: Product.STOR.pathCgi + '/mapping.sh?host=ALL&storage=ALL', rrdType: 'stor', type: 'totals' }, { responseType: 'text' });
    }
  }

  getPage(url: string) {
    if (!url.startsWith(Product.STOR.pathCgi))
      url = Product.STOR.path + url;
    return this.http.get(Globals.RUNTIME_PATH + url, { responseType: 'text' });
  }
}
