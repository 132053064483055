import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoggerService } from 'src/app/logger.service';
import { LocalGroup } from 'src/app/model/group';

@Component({
  selector: 'xormon-user-group-edit',
  templateUrl: './user-group-edit.component.html',
  styleUrls: ['./user-group-edit.component.scss'],
})
export class UserGroupEditComponent implements OnInit {
  saving = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public group: LocalGroup,
    private http: HttpClient,
    private dialogRef: MatDialogRef<UserGroupEditComponent>,
    private log: LoggerService
  ) { }

  ngOnInit() { }

  saveGroup() {
    this.saving = true;
    document.body.style.cursor = 'wait';
    if (this.group.id) {
      this.http.put('/admin/userGroups/group', this.group).subscribe(
        (data) => {
          document.body.style.cursor = 'default'
          this.dialogRef.close(data);
        },
        (error) => {
          this.saving = false;
          this.log.error('Failed to save group: ' + this.group.name, error);
          document.body.style.cursor = 'default'
        }
      );
    } else {
      this.http.post('/admin/userGroups/group', this.group).subscribe(
        (data) => {
          document.body.style.cursor = 'default';
          this.dialogRef.close(data);
        },
        (error) => {
          document.body.style.cursor = 'default';
          this.saving = false;
          this.log.error('Failed to create group: ' + this.group.name, error);
        }
      );
    }
  }
}
