import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';
import { DashboardTab, SharedDashboard } from 'src/app/model/dashboard';
import { Deferred } from 'src/app/model/deferred';
import customUrls from 'src/app/wlabel.json';
import { PortalService } from '../portal/portal.service';
import { PortalImportDialogComponent } from './portal-import-dialog/portal-import-dialog.component';

@Component({
  selector: 'xormon-dashboards',
  templateUrl: './dashboards.component.html',
  styleUrls: ['./dashboards.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardsComponent implements OnInit {

  helpUrl = customUrls.xormonDashboard;
  referencedTabs: SharedDashboard[];
  writableTabs: DashboardTab[];
  sharedOpts: SelectItem[] = [{ label: 'All', value: null }, { label: 'Shared', value: true }, { label: 'Unshared', value: false }];
  technologiesW: SelectItem[];
  technologiesR: SelectItem[];
  usersR: SelectItem[];
  selectedTechnoW: string;
  selectedTechnoR: string;
  loadingReferenced: boolean;
  routeType: string;
  readonly defaultName = Globals.DEFAULT_DASHBOARD_NAME;

  private twInitialized = new Deferred();
  tWritable: Table;
  @ViewChild('tWritable', { static: false })
  set tw(t: Table) {
    if (t) {
      this.tWritable = t;
      this.twInitialized.resolve();
    } else
      this.twInitialized.reset();
  }

  private trInitialized = new Deferred();
  tReferenced: Table;
  @ViewChild('tReferenced', { static: false })
  set tr(t: Table) {
    if (t) {
      this.tReferenced = t;
      this.trInitialized.resolve();
    } else
      this.trInitialized.reset();
  }

  constructor(private dialog: MatDialog, private dataService: DataService, private portalService: PortalService,
    private log: LoggerService, private confirmService: ConfirmationService) { }

  ngOnInit() {
    this.routeType = this.dataService.selectedType;
    this.loadingReferenced = true;
    this.portalService.getReferencedTabs().subscribe(data => {
      this.referencedTabs = data;
      let technoSetR = new Set(data.map(sd => sd.technology));
      this.technologiesR = Array.from(technoSetR).map(s => ({ label: s, value: s }));
      this.technologiesR.unshift({ label: 'All technologies', value: null });
      this.selectedTechnoR = technoSetR.has(this.dataService.technology) ? this.dataService.technology : null;
      this.usersR = Array.from(new Set(data.map(sd => sd.user)).values()).map(s => ({ label: s, value: s }));
      this.trInitialized.promise.then(() => this.tReferenced.filter(this.selectedTechnoR, 'technology', 'equals'));
      this.loadingReferenced = false;
    }, error => this.log.error('Failed to get referenced tabs!', error));
    this.dataService.dashboardLoaded.promise.then(() => {
      this.writableTabs = this.dataService.dashboard.tabs.filter(dt => dt.writable);
      let technoSetW = new Set(this.writableTabs.map(dt => dt.technology));
      this.technologiesW = Array.from(technoSetW).map(s => ({ label: s, value: s }));
      this.technologiesW.unshift({ label: 'All technologies', value: null });
      this.selectedTechnoW = technoSetW.has(this.dataService.technology) ? this.dataService.technology : null;
      this.twInitialized.promise.then(() => this.tWritable.filter(this.selectedTechnoW, 'technology', 'equals'));
    });
  }

  switchShare(tab: DashboardTab) {
    this.portalService.saveTabs([{ ...tab, shared: !tab.shared }]).subscribe(data => {
      tab.shared = !tab.shared;
      this.writableTabs = [...this.writableTabs];
    }, error => this.log.error('Failed to change shared state!', error));
  }

  deleteTab(tab: DashboardTab) {
    this.confirmService.confirm({
      message: 'Do you want to delete your dashboard <strong>' + tab.name + '</strong>?',
      accept: () => {
        this.portalService.deleteTab(tab.id).subscribe(() => {
          this.dataService.initPortal();
          this.dataService.reloadTree();
        }, error => this.log.error('Failed to delete tab ' + tab.name, error));
      }
    });
  }

  removeTab(tab: DashboardTab) {
    this.confirmService.confirm({
      message: 'Do you want to remove dashboard <strong>' + tab.name + '</strong> from your collection?',
      accept: () => {
        this.portalService.removeTab(tab.id).subscribe(() => {
          if (tab.shared) {
            this.dataService.initPortal();
            this.dataService.reloadTree();
          }
        }, error => this.log.error('Failed to remove shared tab ' + tab.name, error));
      }
    });
  }

  importDashboards() {
    this.dialog.open(PortalImportDialogComponent).afterClosed().subscribe(result => {
      if (result) {
        this.dataService.initPortal();
        this.dataService.reloadTree();
      }
    }, error => this.log.error('Failed to import dashboards!', error));
  }

}
