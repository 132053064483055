<h3>Configure {{technologyName}} Custom Groups</h3>
<div *ngIf="notImplemented">This technology has not been implemented yet.</div>
<div class="row" *ngIf="!notImplemented" id="customGroups">
  <div class="col-xl-6">
    <div class="card">
      <div class="card-body">
        <p-treeTable [value]="customGroups" [(selection)]="selectedNode" compareSelectionBy="equals"
          [(contextMenuSelection)]="selectedNode" [contextMenu]="cm" [loading]="loading" selectionMode="single"
          contextMenuSelectionMode="joint" (onNodeSelect)="nodeSelected($event)"
          (onNodeUnselect)="nodeUnselected($event)" metaKeySelection="true" id="cgTreeTable">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th>Custom group</th>
              <th>Type</th>
              <th>Set of groups
                <a [href]="helpUrl" target="_blank" class="float-right">
                  <i class="fas fa-question-circle"></i></a>
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
            <tr [ttContextMenuRow]="rowNode" [ttSelectableRow]="rowNode">
              <td [attr.colspan]="rowData.type ? 1 : 3">
                <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
                <span *ngIf="rowNode.level==0">
                  <input placeholder="Group name" title="Name of custom group" [(ngModel)]="rowData.name" required
                    (keyup)="checkUniqueName(rowData.name)" style="width: 80%; float: right;"
                    [class.invalid]="rowData.invalid" (focus)="selectRow(rowNode)">
                  <div *ngIf="rowData.duplicit" class="text-danger" style="margin-top: 10px;">This name is already in
                    use!
                  </div>
                </span>

                <p-autoComplete *ngIf="rowNode.level==1" placeholder="Parent regexp"
                  title="Storage/server/SAN/LAN level regular expression filter" [(ngModel)]="rowData.name" required
                  [style]="{'width': '90%', 'float': 'right', 'position': 'relative'}" (onFocus)="selectRow(rowNode)"
                  [suggestions]="autoTitles" emptyMessage="No server/storage found"
                  (completeMethod)="searchForTitles($event)" [delay]="500" [baseZIndex]="100">
                </p-autoComplete>

                <p-autoComplete *ngIf="rowNode.level==2" placeholder="Child regexp"
                  title="Volume/VM/port... level regular expression filter" [(ngModel)]="rowData.name" required
                  [style]="{'width': '85%', 'float': 'right', 'position': 'relative'}" (onFocus)="selectRow(rowNode)"
                  [suggestions]="autoTitles" emptyMessage="No subsystem found" [baseZIndex]="100"
                  (completeMethod)="searchForTitles($event)" [delay]="500">
                </p-autoComplete>
              </td>
              <td *ngIf="rowData.type">
                <select [(ngModel)]="rowData.type" *ngIf="!rowData.unsaved" (change)="nodeSelected('refresh')">
                  <option *ngFor="let type of selectedTypes" value="{{type.value}}">{{type.title}}</option>
                </select>
                <select [(ngModel)]="rowData.type" *ngIf="rowData.unsaved" (change)="nodeSelected('refresh')">
                  <option *ngFor="let type of filteredTypes" value="{{type.value}}">{{type.title}}</option>
                </select>
              </td>
              <td *ngIf="rowData.type">
                <input [(ngModel)]="rowData.groupSet" placeholder="Set of groups name" style="width: 95%; float: right;"
                  title="Enter same name to each custom group you wish to group by this name (virtual folder in tree is created)">
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="3">
                No custom groups defined yet
              </td>
            </tr>
          </ng-template>
        </p-treeTable>

        <p-contextMenu #cm [model]="menuOptions" (onShow)="customizeMenu()" appendTo="body" baseZIndex="2000">
        </p-contextMenu>

        <div class="my-2 d-flex" id="buttons">
          <button (click)="addNewCG()" class="btn btn-primary">
            <i class="fas fa-plus-circle mr-1"></i>
            Add new group
          </button>
          <button class="btn btn-primary ml-auto" (click)="saveCG()" [disabled]="invalid">
            <i class="fas fa-save mr-1"></i>
            Save custom groups configuration
          </button>
        </div>
      </div>
    </div>
    <!-- <pre ng-if="savedCfg">{{savedCfg}}</pre> -->
  </div>

  <div class="col-xl-6">
    <div class="card sticky-top" *ngIf="selectedParent && selectedNode">
      <div class="card-body">
        <h5 class="card-title">
          {{selectedParent.data.type}} group live preview
          <small>
            <i class="fas fa-angle-double-right"></i> {{selectedParent.data.name}}
            <span *ngIf="selectedParent.data.level===2">
              <i class="fas fa-angle-double-right"></i> {{selectedNode.parent.data.name}}
            </span>
            <span *ngIf="selectedParent.data.level>0">
              <i class="fas fa-angle-double-right"></i> {{selectedNode.data.name}}
            </span>
          </small>
        </h5>
        <div *ngIf="inactiveTypeSelected">No {{technologyName}} configured for {{selectedParent.data.type}}!</div>
        <div *ngIf="lpLoading" style="height: 200px; position: relative;">
          <xormon-loading-indicator></xormon-loading-indicator>
        </div>
        <div class="ui-treetable">
          <table *ngIf="!lpLoading && !inactiveTypeSelected" class="ui-treetable-table" style="table-layout: auto;">
            <thead class="ui-treetable-thead">
              <tr>
                <th class="text-center text-capitalize">{{technologyName==='server' ? 'Server':'Device'}}</th>
                <th class="table-cell-xs">&nbsp;</th>
                <th class="text-center">{{selectedParent.data.type}}</th>
              </tr>
            </thead>
            <tbody class="ui-treetable-tbody">
              <tr *ngFor="let lp of lpRows | keyvalue">
                <td><span class="lp-box">{{lp.key}}</span></td>
                <td class="table-cell-xs"><i class="fas fa-angle-double-right"></i></td>
                <td><span class="lp-box">{{lp.value}}</span></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <p-dialog header="Custom group configuration save - success" [(visible)]="successDialog" [baseZIndex]="2000"
    appendTo="body" [style]="{'width': '640px'}" [modal]="true" [dismissableMask]="true">
    <p class="mb-2">Custom Groups configuration file has been successfully saved!</p>
    <p class="mb-2">
      Your changes will take effect after regular (cronned) load.sh.<br>
      If you want to apply changes immediately, enter this command as the user running {{product?.name}}:
    </p>
    <pre>{{isLpar ? '/home/lpar2rrd/lpar2rrd/load.sh' : '/home/stor2rrd/stor2rrd/load.sh'}} custom</pre>
    <p>Refresh the GUI in your web browser (Ctrl-F5) when above command finishes.</p>
  </p-dialog>
</div>
