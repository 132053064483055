import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';
import { AbstractGraph } from '../abstract-graph';

@Component({
  selector: 'xormon-graph-simple',
  templateUrl: './graph-simple.component.html',
  styleUrls: ['./graph-simple.component.scss'],
  providers: [{ provide: AbstractGraph, useExisting: GraphSimpleComponent }]
})
export class GraphSimpleComponent extends AbstractGraph implements OnInit, AfterViewInit, OnDestroy {

  private dataSubscription = Subscription.EMPTY;

  constructor(
    private log: LoggerService, private http: HttpClient, protected dataService: DataService,
    private changeDetector: ChangeDetectorRef, protected zone: NgZone) {
    super(dataService, zone);
  }

  ngOnInit() {
    this.dataSubscription = this.load(this.graph.imgSrc).subscribe({ error: error => this.graph.error = JSON.stringify(error) });

  }

  ngAfterViewInit(): void {
    this.fancyBox(this.element.nativeElement);
  }

  ngOnDestroy(): void {
    this.dataSubscription.unsubscribe();
  }

  load(url: string) {
    this.graph.loading = true;
    this.changeDetector.detectChanges();
    return this.http.get(Globals.RUNTIME_PATH + url, { responseType: 'blob', observe: 'response' })
      .pipe(map(resp => {
        let reader = new FileReader();
        reader.addEventListener('load', () => {
          this.graph.imgData = reader.result;
          this.graph.loaded = true;
          reader = undefined;
          this.graph.error = null;
          this.loaded.emit();
        }, false);
        const error = resp.headers.get(Globals.HEADER_ERROR);
        if (error) {
          let dataSrc = Globals.getParams(this.graph.imgSrc);
          this.graph.period = this.dataService.mainModule.getImagePeriod(dataSrc);
          this.graph.title = this.dataService.mainModule.getImageTitle(dataSrc) + this.graph.period;
          this.graph.error = Base64.decode(error);
          this.graph.width = 0;
        }
        if (!error && resp.body) {
          reader.readAsDataURL(resp.body);
        }

        this.parseHeaderProperties(resp.headers);

      }), finalize(() => {
        this.graph.loading = false;
      }));
  }

  get host() {
    return Globals.htmlUrlPrefix + Globals.RUNTIME_PATH;
  }
}
