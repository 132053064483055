<div #element class="graph">
  <a *ngIf="graph.csv" class="csvfloat" [href]="prefix + graph.csv" title="Download graph data as CSV"><i
      class="fas fa-file-csv fa-lg"></i></a>
  <h3 class="graph-title" *ngIf="graph.imgSrc.indexOf('detail=')>-1">
    <div class="graph-title-label" title="{{graph.origTitle}}" [innerHtml]="graph.title"></div>
    <div class="graph-icons">
      <button class="reset-zoom text-danger mr-0" [ngClass]="{'invisible': !graph.zoomed}" title="Reset zoom"
        (click)="resetZoom($event)">
        <i class="fas fa-ban"></i>
      </button>
      <button class="graph-fav" (click)="switchGraphInDashboard(graph, $event)"
        title="{{graph.fav ? 'Remove this graph from Dashboard' : 'Add this graph to Dashboard'}}">
        <span class="fas fa-star {{graph.fav ? 'font-weight-bold' : 'font-weight-normal'}}"></span>
      </button>
    </div>
  </h3>
  <xormon-b64 *ngIf="graph.base64" [graph]="graph" (groupZoomStart)="groupZoomStart.emit()"
    (groupZoomEnd)="groupZoomEnd.emit($event)" (loaded)="detectChanges()"></xormon-b64>
  <xormon-graph-simple *ngIf="!graph.base64" [graph]="graph" (groupZoomStart)="groupZoomStart.emit()"
    (groupZoomEnd)="groupZoomEnd.emit($event)" (loaded)="detectChanges()">
  </xormon-graph-simple>
</div>
