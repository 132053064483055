<div class="hw-configuration d-flex flex-grow-1 flex-column">

  <mat-tab-group [@.disabled]="true" *ngIf="tabsTps" (selectedTabChange)="onTabSelected($event)">
    <mat-tab *ngFor="let tab of tabsTps; let i = index" label={{tab.name}}>
      <div class="csvfloat">
        <div *ngFor="let item of tab.csvList" class="mb-2 bg-white">
          <a [href]="item.href" [title]="item.title"><i class="fas fa-file-csv fa-2x"></i></a>
        </div>
      </div>
      <div id="{{'tab'+i}}" class="stickyheaders-holder text-center">
        <xormon-loading-indicator></xormon-loading-indicator>
      </div>
    </mat-tab>
  </mat-tab-group>

  <div id="hwconfig" class="inner-html stickyheaders-holder position-relative" *ngIf="!tabsTps">
    <xormon-loading-indicator></xormon-loading-indicator>
  </div>
</div>
