<div class="card">
  <div class="card-body">
    <div class="row">
      <div class="col-lg-4">
        <fieldset class='estimator flexi'>
          <table id="allpooltree" class="cfgtree">
            <colgroup>
              <col width="220px">
              <col width="100px">
            </colgroup>
            <thead>
              <tr>
                <!-- <th class="d-none"></th>
                <th class="d-none"></th> -->
                <th>Pool</th>
                <th>Tier</th>
              </tr>
            </thead>
            <tbody>
            </tbody>
          </table>
        </fieldset>
        <input type='submit' name='savegrp' id='savehmcfg' class="cfgtree btn btn-primary"
          value='Save heatmap configuration'>

      </div>
      <div class="col-lg-4">
        <ul>
          <li>You can manually assign pools to tiers</li>
          <li>Default is tier1</li>
          <li>Volumes inherit tier of their pool</li>
          <li>When your pools or volumes are not listed there then <br>assure
            that their utilization is above minimal limits
          </li>
          <li>More heatmap details <b><a target="_blank" [href]='urlHeatmap'>here</a></b></li>
        </ul>
      </div>
    </div>
  </div>
</div>