import { HttpClient } from '@angular/common/http';
import { LoggerService } from 'src/app/logger.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../logger.service";
export class PortalService {
    constructor(http, log) {
        this.http = http;
        this.log = log;
    }
    getAllTabs() {
        return this.http.get('/api/portal/tabs');
    }
    saveTabs(tabs) {
        return this.http.put('/api/portal/tabs', tabs);
    }
    getReferencedTabs() {
        return this.http.get('/api/portal/referenced');
    }
    deleteTab(id) {
        return this.http.delete('/api/portal/tab/' + id);
    }
    removeTab(id) {
        return this.http.delete('/api/portal/tabReference/' + id);
    }
}
PortalService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PortalService_Factory() { return new PortalService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.LoggerService)); }, token: PortalService, providedIn: "root" });
