import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'xormon-ldap-user',
  templateUrl: './ldap-user.component.html',
  styleUrls: ['./ldap-user.component.scss']
})
export class LdapUserComponent implements OnInit {

  username: string;
  password: string;

  constructor(private dialogRef: MatDialogRef<LdapUserComponent>) { }

  ngOnInit() {
  }

  passCreds() {
    this.dialogRef.close({ username: this.username, password: this.password });
  }

}
