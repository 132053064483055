/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./test-events.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "./test-events.component";
import * as i8 from "@angular/common/http";
var styles_TestEventsComponent = [i0.styles];
var RenderType_TestEventsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TestEventsComponent, data: {} });
export { RenderType_TestEventsComponent as RenderType_TestEventsComponent };
export function View_TestEventsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Test event alerting"])), (_l()(), i1.ɵeld(3, 0, null, null, 11, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵted(-1, null, [" Save configuration at first, only saved configuration will be tested! "])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Alerting configuration will be tested. "])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["All alertes will be raised even if no tresholds are reached. "])), (_l()(), i1.ɵeld(11, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Do you want to proceed? "])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "text-danger"]], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), (_l()(), i1.ɵeld(15, 0, null, null, 7, "div", [["align", "end"], ["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(16, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(17, 0, null, null, 2, "button", [["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onCancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(18, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Cancel"])), (_l()(), i1.ɵeld(20, 0, null, null, 2, "button", [["cdkFocusInitial", ""], ["color", "primary"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onRun() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(21, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["Run"]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_6 = "primary"; _ck(_v, 21, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.testAlertError; _ck(_v, 14, 0, currVal_1); var currVal_2 = (i1.ɵnov(_v, 18).disabled || null); var currVal_3 = (i1.ɵnov(_v, 18)._animationMode === "NoopAnimations"); _ck(_v, 17, 0, currVal_2, currVal_3); var currVal_4 = (i1.ɵnov(_v, 21).disabled || null); var currVal_5 = (i1.ɵnov(_v, 21)._animationMode === "NoopAnimations"); _ck(_v, 20, 0, currVal_4, currVal_5); }); }
export function View_TestEventsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xormon-test-events", [], null, null, null, View_TestEventsComponent_0, RenderType_TestEventsComponent)), i1.ɵdid(1, 114688, null, 0, i7.TestEventsComponent, [i2.MatDialogRef, i8.HttpClient, i2.MatDialog, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TestEventsComponentNgFactory = i1.ɵccf("xormon-test-events", i7.TestEventsComponent, View_TestEventsComponent_Host_0, {}, {}, []);
export { TestEventsComponentNgFactory as TestEventsComponentNgFactory };
