<div class="row align-items-center login" style="padding-top: 15%;">
  <div class="col-sm-8 col-md-6 col-lg-5 col-xl-4 mx-auto">
    <div class="login-header p-2">
      <img src="./assets/logo-xormon.png" width="121" height="32" alt="Xormon" class="mx-auto d-block">
    </div>
    <div class="card">
      <div class="card-body">
        <h2>Login</h2>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" autocomplete="on">
          <div class="form-group">
            <label for="username">Username</label>
            <input type="text" formControlName="username" class="form-control" name="username" autofocus
              [ngClass]="{ 'is-invalid': submitted && f.username.errors }" autocomplete="username" />
            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
              <div *ngIf="f.username.errors.required">Username is required</div>
            </div>
          </div>
          <div class="form-group">
            <label for="password">Password</label>
            <input type="password" formControlName="password" class="form-control" name="password"
              [ngClass]="{ 'is-invalid': submitted && f.password.errors }" autocomplete="current-password" />
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">Password is required</div>
            </div>
          </div>
          <div class="form-group">
            <button [disabled]="loading" class="btn btn-primary" name="submit">
              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
              Login
            </button>
          </div>
        </form>
        <div class="text-danger" *ngIf="submitted && !loading">Username or password does not match</div>
      </div>
      <div *ngIf="version?.endsWith('demo')" class="card-footer demo text-muted">
        <div>Try the preview of UI with demo account</div>
        <div>Username: <span class="creds">admin@xormon.com</span></div>
        <div>Password: <span class="creds">xorux4you</span></div>
      </div>
    </div>
  </div>
</div>
