import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';
import { Product } from 'src/app/model/product';
import { StorageTotalsService } from '../storage-totals.service';

@Component({
  selector: 'xormon-san-topology',
  templateUrl: './san-topology.component.html',
  styleUrls: ['./san-topology.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SanTopologyComponent implements OnInit, OnDestroy {

  page;
  tabsSan: any[];
  private navigationSubscription = Subscription.EMPTY;

  constructor(private dataService: DataService, private totalStService: StorageTotalsService, private log: LoggerService, private router: Router) {
    this.navigationSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.dataService.nodeSelected.promise.then(() => {
          if (!this.navigationSubscription.closed) {
            if (Globals.isSameNavigation(e))
              return;
            this.init();
          }
        });
      });
  }
  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }

  ngOnInit() {

    var url = Product.STOR.pathCgi + "/graphviz.sh?procname=global_html";
    this.totalStService.getPage(url).subscribe(data => {

      var dataArr = data.split("\n");
      if (!dataArr[0].includes('id="tabs"')) {
        this.tabsSan = null;
        this.page = Globals.domSanitizer.bypassSecurityTrustHtml(data);
        return;
      }
      this.tabsSan = [];
      this.page = null;
      dataArr.forEach(line => {
        if (line.match("href")) {
          var href = line.match(/".*"/g);
          var hrefStr = href.toLocaleString();
          hrefStr = hrefStr.replace(/"/g, "");

          var tabName = line.match(/>.*<\/a>/g);
          var nameStr = tabName.toLocaleString();
          nameStr = nameStr.replace(/>|<\/a>/g, "");
          var name = nameStr.split(/"/);

          this.tabsSan.push({ name: name[2], href: hrefStr });
        }
      });
      setTimeout(() => {
        this.onTabSelected({ index: 0 });
      });
    }, error => this.log.error('Failed to hosts!', error));
  }

  private init() {
  }

  onTabSelected(indexObj) {
    var index = indexObj.index;
    var tab = $('#tab' + index);
    var urlName = this.tabsSan[index].href;

    this.totalStService.getPage(urlName).subscribe(returnPage => {
      tab.empty();
      tab.html(returnPage);
    }, error => this.log.error('Failed to get host!', error));
  }

}
