<div class="modal-email">
  <form id="optform" class="d-none"></form>
  <table id="alrttree" class="d-none">
    <thead>
      <tr>
        <th class="d-none"></th>
        <th class="d-none"></th>
        <th>
          <button title="Expand/Collapse toggle" class="ui-icon-arrowthick-2-n-s">
            +/-
          </button>
          <button class="btn btn-sm btn-secondary btn-add" id="addnewalrt">
            Add New Alert
          </button>
        </th>
        <th>Metric</th>
        <th><abbr title="limit value">Limit</abbr></th>
        <th>
          <abbr title="value is in percent (CPU &amp; CPU OS only)">%</abbr>
        </th>
        <th>
          <abbr title="time in minutes for length peak above the limit [10-unlimited]">Peak</abbr>
        </th>
        <th>
          <abbr title="minimum time in minutes between 2 alerts for the same rule [10-unlimited]">Repeat</abbr>
        </th>
        <th>
          <abbr
            title="time range in hours when the alerting is off [0-24]-[0-24]. Ex. 22-05&nbsp;(excludes alerting from 10pm to 5am)">Exclude
            hours</abbr>
        </th>
        <th>Mail group</th>
        <th></th>
      </tr>
    </thead>
    <tbody></tbody>
  </table>
  <table id="alrtgrptree" class="cfgtree center max-width" role="treegrid" aria-multiselectable="true"
    aria-readonly="true">
    <thead>
      <tr>
        <th class="d-none"></th>
        <th class="d-none"></th>
        <th id="addcgrpth">
          E-mail group &nbsp;<button id="addalrtgrp" class="btn btn-sm btn-secondary btn-add">
            Add New
          </button>
        </th>
        <th></th>
      </tr>
    </thead>
    <tbody></tbody>
  </table>
</div>
<br />
<div>
  <input class="savealrtcfg btn btn-primary" type="button" value="Save configuration" />
</div>
