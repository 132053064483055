<div style="width: 500px;">
    <h1 mat-dialog-title>Enter LDAP admin credentials</h1>
    <form (ngSubmit)="passCreds()" #f="ngForm">
        <div mat-dialog-content>
            <div classx="row">
                <div class="col-md-12 form-group">
                    <label>Username</label>
                    <input class="form-control" [(ngModel)]="username" required="required"
                        placeholder="Enter AD login name" autofocus="autofocus" name="name" #name="ngModel">
                </div>
            </div>
            <div classx="row">
                <div class="col-md-12 form-group">
                    <label>Password</label>
                    <input class="form-control" [(ngModel)]="password" placeholder="Enter AD user's password"
                        name="password" type="password" required="required">
                </div>
            </div>
        </div>
        <div mat-dialog-actions align="end">
            <button mat-button mat-dialog-close>Cancel</button>
            <button mat-button color="primary" type="submit" [disabled]="f.invalid || f.pristine">Get AD groups</button>
        </div>
    </form>
</div>