import { Injectable, ElementRef } from '@angular/core';
import customMsgs from 'src/app/wlabel.json';

@Injectable({
  providedIn: 'root'
})
export class CustomMessagesService {

  constructor() {
  }

  getMsg(name: string, elef?: ElementRef): string {
    if (elef) {
      return customMsgs[this.getPrefix(elef) + name];
    }
    return customMsgs[name];
  }

  getPrefix(elef: ElementRef) {
    return elef.nativeElement.tagName.toLowerCase() + '.';
  }

}
