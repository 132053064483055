import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DataService } from 'src/app/data.service';
import { LoggerService } from 'src/app/logger.service';
import { Product } from 'src/app/model/product';
import customUrls from 'src/app/wlabel.json';

@Component({
  selector: 'xormon-cpu-workload-estimator',
  templateUrl: './cpu-workload-estimator.component.html',
  styleUrls: ['./cpu-workload-estimator.component.scss'],
})
export class CpuWorkloadEstimatorComponent implements OnInit, AfterViewInit, OnDestroy {
  result;
  @BlockUI('newServer') blockNew: NgBlockUI;
  @BlockUI('existingServer') blockExisting: NgBlockUI;

  readonly urlCpu = customUrls.xormonCpuWorkloadEstimator;

  constructor(
    private dataService: DataService,
    private http: HttpClient,
    private log: LoggerService
  ) { }

  ngOnInit(): void {
    const self = this;
    window.onpopstate = function (event) {
      if (event.state) {
        self.result = event.state.result;
      } else {
        self.result = null;
      }
    };
  }

  ngOnDestroy(): void {
    window.onpopstate = null;
    this.blockExisting.unsubscribe();
    this.blockNew.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.init();
  }

  init() {
    const self = this;

    $('#lpartree').fancytree({
      extensions: ['persist', 'filter'],
      persist: {
        cookiePrefix: 'lpartree-',
      },
      filter: {
        mode: 'hide',
        autoApply: true,
      },
      checkbox: true,
      selectMode: 3,
      select: function (ev, data) {
        if (data.node.hasChildren()) {
          var status = data.node.isSelected();
          data.node.visit(function (node) {
            node.setSelected(status);
          });
        }
      },
      icon: false,
      clickFolderMode: 2,
      source: { url: Product.LPAR.pathCgi + '/genjson.sh?jsontype=lparselest' }
    });
    const lpartree = $.ui.fancytree.getTree('#lpartree');

    $('input[type=checkbox][name=lparset]')
      .off('change')
      .on('change', function () {
        if ($('#radios2').is(':checked')) {
          lpartree.reload({
            url: Product.LPAR.pathCgi + '/genjson.sh?jsontype=hmcsel',
          });
          $('#lparfieldset legend span').html('HMC | Server | LPAR');
        } else {
          lpartree.reload({
            url: Product.LPAR.pathCgi + '/genjson.sh?jsontype=lparselest',
          });
          $('#lparfieldset legend span').html('Server | LPAR');
        }
      });

    $('#pooltree').fancytree({
      extensions: ['persist'],
      persist: {
        cookiePrefix: 'pooltree-',
      },
      clickFolderMode: 2,
      icon: false,
      autoCollapse: false,
      source: {
        url: Product.LPAR.pathCgi + '/genjson.sh?jsontype=estpools',
      },
      disabled: true,
    });

    $.ui.fancytree.createTree($('#treetable'), {
      extensions: ['table', 'persist'],
      persist: {
        cookiePrefix: 'treetable-',
      },
      clickFolderMode: 2,
      icon: false,
      autoCollapse: true,
      source: {
        url: Product.LPAR.pathCgi + '/genjson.sh?jsontype=powersel',
      },
      renderColumns: function (event, data) {
        var node = data.node,
          $tdList = $(node.tr).find('>td');
        // (index #0 is rendered by fancytree by adding the checkbox)
        $tdList.eq(1).text(node.data.type);
        $tdList.eq(2).text(node.data.cpu);
        $tdList.eq(3).text(node.data.ghz);
        $tdList.eq(4).text(node.data.fix);
      },
    });

    $('.container-fluid .fancytree-container').addClass('fancytree-connectors').addClass('fancytree-content');

    var now = new Date();
    var twoWeeksBefore = new Date();
    var yesterday = new Date();
    var nowPlusHour = new Date();
    yesterday.setDate(now.getDate() - 1);
    twoWeeksBefore.setDate(now.getDate() - 14);
    nowPlusHour.setHours(now.getHours() + 1);

    $('#from').datetimepicker({
      defaultDate: '-2w',
      dateFormat: 'yy-mm-dd',
      maxDate: '0',
      changeMonth: true,
      changeYear: true,
      showButtonPanel: true,
      showOtherMonths: true,
      selectOtherMonths: true,
      showTimepicker: false,
      onClose: function (selectedDate) {
        $('#to').datetimepicker('option', 'minDate', selectedDate);
      },
    });
    if ($('#from').length) {
      $('#from').datetimepicker('setDate', twoWeeksBefore);
    }

    $('#to').datetimepicker({
      defaultDate: 0,
      dateFormat: 'yy-mm-dd',
      maxDate: '0',
      changeMonth: true,
      changeYear: true,
      showButtonPanel: true,
      showOtherMonths: true,
      selectOtherMonths: true,
      showTimepicker: false,
      onClose: function (selectedDate) {
        $('#from').datetimepicker('option', 'maxDate', selectedDate);
      },
    });
    if ($('#to').length) {
      $('#to').datetimepicker('setDate', now);
    }

    $('input.allcheck').on('click', function () {
      var isChecked = (this as HTMLInputElement).checked;
      lpartree.visit(function (node) {
        if (!node.hasChildren()) {
          if (!$(node.span).hasClass('fancytree-hide')) {
            node.setSelected(isChecked);
          }
        }
      });
    });

    $('#srvlparfilter')
      .off()
      .on('keyup', function (e) {
        var match = $(this).val() as string;
        var $ltree = lpartree;

        if (e.which === 27 || $.trim(match) === '') {
          $ltree.clearFilter();
          e.preventDefault();
          return;
        }
        if (e && e.which === 13) {
          e.preventDefault();
          var n = $ltree.filterNodes(function (node) {
            return new RegExp(match, 'i').test(node.title);
          }, true);
          $ltree.visit(function (node) {
            if (!$(node.span).hasClass('fancytree-hide')) {
              node.setExpanded(true);
            }
          });
        }
      })
      .trigger('focus');

    $('#radio1').on('click', function () {
      self.unblockExisting();
    });
    $('#radio2').on('click', function () {
      self.unblockNew();
    });
    this.unblockNew();
    $('.block-ui-template')
      .parent()
      .on('click', (event) => this.switchBlock());

    $('#formestimatorSubmit').on('click', function (event) {
      var lt, pt, nt;
      if (lpartree.getSelectedNodes().length === 0) {
        alert('Please select at least one LPAR for migration');
        return false;
      } else if ($('#radio1').is(':checked') && !$('#pooltree').fancytree('getActiveNode')) {
        alert('Please select an existing server/pool for migration');
        return false;
      } else if ($('#radio2').is(':checked') && !$('#treetable').fancytree('getActiveNode')) {
        alert('Please select new target server for migration');
        return false;
      } else if (
        Product.LPAR.module.sysInfo.free == '1' &&
        $('select[name=yaxis] option:selected').val() != 'c'
      ) {
        var ttmp =
          '<div><p>rPerf and CPW based estimations are not available in the free edition.</p>' +
          `<p>Please consider the <a href="${customUrls.lparSupportBenefits}" target="_blank"><b>enterprise edition</b></a> or use CPU core based estimation.</p>` +
          '<p>Note that comparing of CPU load based on CPU cores for different IBM Power systems models is only informative.</p></div>';
        $(ttmp).dialog({
          dialogClass: 'info',
          minWidth: 500,
          modal: true,
          title: 'Free version notice',
          show: {
            effect: 'fadeIn',
            duration: 500,
          },
          hide: {
            effect: 'fadeOut',
            duration: 200,
          },
          buttons: {
            OK: function () {
              $(this).dialog('close');
            },
          },
        });
        return false;
      }
      lpartree.generateFormElements(true, false, { stopOnParents: false });
      lt = '&ft_' + lpartree._id + '(_active)?=';
      if ($('#radio1').is(':checked')) {
        let pooltree = $.ui.fancytree.getTree('#pooltree');
        pooltree.generateFormElements(true, true);
        pt = '&ft_' + pooltree._id + '_active=';
      } else {
        const treetable = $.ui.fancytree.getTree('#treetable');
        treetable.generateFormElements(true, true);
        nt = '&ft_' + treetable._id + '_active=';
      }

      var fromDate = $('#from').datepicker('getDate');
      var toDate = $('#to').datepicker('getDate');
      /*$.cookie('fromField', fromDate, {
        expires: 0.04
      });
      $.cookie('toField', toDate, {
        expires: 0.04
      });
      $.cookie('srcFix', $("#srcfix").prop('checked'), {
        expires: 0.04
      });
      $.cookie('dstFix', $("#dstfix").prop('checked'), {
        expires: 0.04
      });*/

      $('#start-hour').val(now.getHours());
      $('#start-day').val(fromDate.getDate());
      $('#start-mon').val(fromDate.getMonth() + 1);
      $('#start-yr').val(fromDate.getFullYear());

      $('#end-hour').val(now.getHours());
      $('#end-day').val(toDate.getDate());
      $('#end-mon').val(toDate.getMonth() + 1);
      $('#end-yr').val(toDate.getFullYear());

      var postData = $('select,input[name!=lparset]', $('#formestimator')).serialize().replace(/\+/g, '%20');
      postData = postData.replace(/%5B%5D/g, '');

      var re = new RegExp(lt, 'g');
      postData = postData.replace(re, '&LPAR=');
      postData = postData.replace(/&LPAR=_[^&]+/g, '');
      var lparPos = postData.indexOf('&LPAR');
      var firstPart = postData.slice(0, lparPos);
      var newPos, lparPart, newPart;

      if ($('#radio1').is(':checked')) {
        postData = postData.replace(pt, '&POOL=');
        newPos = postData.indexOf('&POOL=');
        lparPart = postData.slice(lparPos, newPos);
        newPart = postData.slice(newPos);
      } else {
        postData = postData.replace(nt, '&NEW=');
        newPos = postData.indexOf('&NEW=');
        lparPart = postData.slice(lparPos, newPos);
        newPart = postData.slice(newPos);
      }

      postData = firstPart + newPart + lparPart;

      var postObj = self.queryStringToHash(postData);
      const headers = new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      });
      self.http.post('/api/CWEReportSubmit', postData, { headers }).subscribe((data) => {
        self.result = data;
        window.history.pushState({ result: data }, 'CPU Workload Estimator');
      }, error => self.log.error('Failed to submit data!', error));

    }); //submit
  }

  private unblockExisting() {
    setTimeout(() => {
      $('#pooltree').fancytree('enable');
      $('#treetable').fancytree('disable');
      this.blockNew.start();
      this.blockExisting.stop();
    });
  }

  private unblockNew() {
    setTimeout(() => {
      $('#pooltree').fancytree('disable');
      $('#treetable').fancytree('enable');
      this.blockExisting.start();
      this.blockNew.stop();
    });
  }

  switchBlock() {
    if (this.blockExisting.isActive) {
      $('#radio1').click();
    } else {
      $('#radio2').click();
    }
  }

  private queryStringToHash(query) {
    var query_string = {};
    var vars = query.split('&');
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      pair[0] = decodeURIComponent(pair[0]);
      pair[1] = decodeURIComponent(pair[1]);
      // If first entry with this name
      if (typeof query_string[pair[0]] === 'undefined') {
        query_string[pair[0]] = pair[1];
        // If second entry with this name
      } else if (typeof query_string[pair[0]] === 'string') {
        var arr = [query_string[pair[0]], pair[1]];
        query_string[pair[0]] = arr;
        // If third or later entry with this name
      } else {
        query_string[pair[0]].push(pair[1]);
      }
    }
    return query_string;
  }
}
