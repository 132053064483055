<div class="card">
  <div class="card-body">
    <xormon-alerting-lpar-header label="Options"></xormon-alerting-lpar-header>
    <div class="col-lg-8 col-xl-6">
      <br>
      <form class="form-horizontal" (ngSubmit)="saveCfg()" #lparAlerting="ngForm">
        <fieldset>
          <div class="form-group">
            <label class="control-label">Nagios alerting</label>
            <div>
              <p-overlayPanel #opNagios appendTo="body">
                Call this script from nrpe.cfg: bin/check_lpar2rrd<br>More details on <a
                  [href]="urlNagios">{{urlNagios}}</a><br>[0/1] on/off
              </p-overlayPanel>
              <input type="number" class="form-control" placeholder="0/1 off/on" max="1" min="0"
                [(ngModel)]="cfg.NAGIOS" name="nagios" #nagios="ngModel" [xormon-poptip]="opNagios">
              <div class="text-danger" *ngIf="nagios.invalid">Please insert only 1 or 0</div>
            </div>
          </div>
          <div class="form-group">
            <label class="control-label">External script for alerting</label>
            <div>
              <p-overlayPanel #opExternAlert appendTo="body">
                It will be called once an alarm appears with these 5 parameters:
                <br>
                <pre>script.sh  [storage] [volume] [metric] [actual value] [limit]</pre>- you can use
                <b>bin/external_alert_example.sh</b> as an example
                <br>- script must be placed in <b>LPAR2RRD_HOME/bin</b> and path start with <b>bin/</b>
              </p-overlayPanel>
              <input type="text" class="form-control" placeholder="Path to a script" [(ngModel)]="cfg.EXTERN_ALERT"
                name="externAlert" [xormon-poptip]="opExternAlert">
            </div>
          </div>
          <div class="form-group">
            <label class="control-label">Include graphs</label>
            <div>
              <p-overlayPanel #opEmailGraph appendTo="body">
                Include graphs into the email notification.
                <br>Any positive number gives number of hours which the graph contains. Examples:
                <br>0 - false<br>8 - last 8 hours in the graph<br>25 - last 25 hours in the graph<br>[0 - 256]
              </p-overlayPanel>
              <input type="number" class="form-control" placeholder="Duration in hours" min="0" max="256" name="graphs"
                [(ngModel)]="cfg.EMAIL_GRAPH" #graphs="ngModel" [xormon-poptip]="opEmailGraph">
              <div *ngIf="graphs.invalid" class="text-danger">Please insert number between 0 and 256</div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6">
              <label class="control-label">Default repeat time (min)</label>
              <div #el>
                <p-overlayPanel #opRepeat appendTo="body">
                  Default time in minutes which says how often you should be alerted. You can
                  specify per volume different value in
                  <b>alert repeat time</b> column of each ALERT
                  <br>[5 - 168]
                </p-overlayPanel>
                <input type="number" class="form-control" placeholder="Alerting email frequency" min="5" max="168"
                  #repeat="ngModel" name="repeat" [(ngModel)]="cfg.REPEAT_DEFAULT" [xormon-poptip]="opRepeat">
                <div class="text-danger" *ngIf="repeat.invalid">Please insert number between 5 and 168</div>
              </div>
            </div>
            <div class="form-group col-md-6">
              <label class="control-label">Default peak time (min)</label>
              <div>
                <p-overlayPanel #opDefaultPeak appendTo="body">
                  The period of time in which avg traffic utilization has to be over the
                  specified limit to generate an alert.
                  <br>You can change it per volume level in <b>time in min</b> column of each ALERT note.
                  <br> [15 - 120]
                </p-overlayPanel>
                <input type="number" class="form-control" name="defaultPeak" placeholder="Duration of peak to alert"
                  min="15" max="120" [(ngModel)]="cfg.PEAK_TIME_DEFAULT" #defaultPeak="ngModel"
                  [xormon-poptip]="opDefaultPeak">
                <div *ngIf="defaultPeak.invalid" class="text-danger">Please insert number between 15 and 120
                </div>
              </div>
            </div>
          </div>
          <div class="form-group">
            <label class="control-label">SNMP trap host</label>
            <div>
              <p-overlayPanel #opTrap appendTo="body">
                Hostname or IP of SNMP trap receiver.
              </p-overlayPanel>
              <input type="text" class="form-control" placeholder="Hostname or IP" [(ngModel)]="cfg.TRAP" name="trap"
                [xormon-poptip]="opTrap">
            </div>
          </div>
          <div class="form-group">
            <label class="control-label">Email from</label>
            <div>
              <p-overlayPanel #opEmail appendTo="body">
                Email address from which emails will appear to be sent.
              </p-overlayPanel>
              <input name="email" type="email" email class="form-control" placeholder="Valid email"
                [(ngModel)]="cfg.MAILFROM" #email="ngModel" [xormon-poptip]="opEmail">
              <div *ngIf="email.invalid" class="text-danger">Please insert valid Email</div>
            </div>
          </div>
        </fieldset>
        <div class="text-right">
          <input class="btn btn-primary" type="submit" style="top: 100px;" value="Save configuration"
            [disabled]="lparAlerting.invalid">
        </div>
      </form>
      <br>
    </div>
  </div>
</div>
