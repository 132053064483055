import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';
import { CertificateDialogComponent, CertificateResponse, CertificateResponseStatus } from '../certificate-dialog/certificate-dialog.component';

declare var reveal: any;
declare var uuencode: any;

export enum STATUS {
  INPROGRESS = 'INPROGRESS', SUCCESS = 'SUCCESS', FAIL = 'FAIL', FINISHED = 'FINISHED'
}

@Component({
  selector: 'xormon-runtime-setup',
  templateUrl: './runtime-setup.component.html',
  styleUrls: ['./runtime-setup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RuntimeSetupComponent implements OnInit, AfterViewInit, OnDestroy {

  readonly STATUS = STATUS;
  checkFinished;
  progressStatus = '';
  progressDialogShown = false;
  progressMessage = '';
  saving = false;
  config = {};
  lpar = false;
  stor = false;
  @BlockUI('stor') blockUIStor: NgBlockUI;
  @BlockUI('lpar') blockUILpar: NgBlockUI;

  private wsSubscription = Subscription.EMPTY;
  private saveSubscription = Subscription.EMPTY;

  private readonly SCHEME_HTTPS = 'https://';
  private readonly SCHEME_HTTP = 'http://';

  httpSchemas: SelectItem[] = [
    { label: 'http', value: this.SCHEME_HTTP },
    { label: 'https', value: this.SCHEME_HTTPS }
  ];

  constructor(private http: HttpClient, private log: LoggerService, private dataService: DataService, private dialog: MatDialog) { }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.blockUILpar.unsubscribe();
    this.blockUIStor.unsubscribe();
    this.wsSubscription.unsubscribe();
    this.saveSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.http.get('/admin/storLpar/readConf').subscribe((data: {}) => {
      this.config = data;
      this.config['runtime.lpar.psswd'] = reveal(this.config['runtime.lpar.psswd']);
      this.config['runtime.stor.psswd'] = reveal(this.config['runtime.stor.psswd']);
      if (data['runtime.lpar.enabled'])
        this.lpar = JSON.parse(data['runtime.lpar.enabled']);
      if (data['runtime.stor.enabled'])
        this.stor = JSON.parse(data['runtime.stor.enabled']);
      if (data['runtime.lpar.noUI'])
        this.config['runtime.lpar.noUI'] = JSON.parse(data['runtime.lpar.noUI']);
      if (data['runtime.stor.noUI'])
        this.config['runtime.stor.noUI'] = JSON.parse(data['runtime.stor.noUI']);
      if (!data['runtime.lpar.scheme']) {
        this.config['runtime.lpar.scheme'] = this.SCHEME_HTTP;
      }
      if (!data['runtime.stor.scheme']) {
        this.config['runtime.stor.scheme'] = this.SCHEME_HTTP;
      }
      this.blockLpar();
      this.blockStor();
    }, error => this.log.error('Failed to get configuration!', error));
  }

  blockLpar() {
    if (this.lpar) {
      this.blockUILpar.stop();
    } else {
      this.blockUILpar.start();
    }
  }

  blockStor() {
    if (this.stor) {
      this.blockUIStor.stop();
    } else {
      this.blockUIStor.start();
    }
  }

  cancelSave() {
    this.progressDialogShown = false;
    this.wsSubscription.unsubscribe();
    this.saveSubscription.unsubscribe();
  }

  checkSaveConfig() {
    // check
    this.checkFinished = false;
    this.progressStatus = STATUS.INPROGRESS;
    this.progressDialogShown = true;
    this.progressMessage = '';
    this.wsSubscription = this.dataService.rxStompService.watch('/topic/backend').subscribe(msg => {

      if (msg.body && msg.body.length) {
        this.progressMessage += msg.body;
        this.progressMessage += "<br>";
      }

      if (msg.headers && msg.headers.status) {
        if (this.progressStatus !== STATUS.FAIL && msg.headers.status !== STATUS.SUCCESS)
          this.progressStatus = msg.headers.status;
        if (msg.headers.status === STATUS.FINISHED) {
          this.checkFinished = true;
          this.wsSubscription.unsubscribe();
        } else {
          this.progressMessage += `<br><strong>${msg.headers.product} setup check result: ${msg.headers.status}</strong>`;
          if (msg.headers.status === STATUS.FAIL && msg.headers.advice) {
            this.progressMessage += '<br>' + msg.headers.advice;
          }
          this.progressMessage += "<br><br>";
        }
      }

      setTimeout(() => {
        let el = $('.rtFeedback .ui-dialog-content');
        if (el.length)
          el.scrollTop(el.get(0).scrollHeight);
      });
    });
    this.saveConfig();
  }

  private saveConfig() {
    this.saving = true;
    AppComponent.block.start();
    const cfg = Object.assign({}, this.config);
    cfg['runtime.lpar.psswd'] = Base64.encode(uuencode(this.config['runtime.lpar.psswd']));
    cfg['runtime.stor.psswd'] = Base64.encode(uuencode(this.config['runtime.stor.psswd']));
    cfg['runtime.lpar.enabled'] = this.lpar;
    cfg['runtime.stor.enabled'] = this.stor;

    this.saveSubscription.unsubscribe();
    this.saveSubscription = this.http.put<CertificateResponse>('/admin/storLpar/updateConf', cfg).subscribe(response => {
      if (response.status === CertificateResponseStatus.OK) {
        this.log.info('Configuration saved successfully.');
        this.saving = false;
        AppComponent.block.stop();
      } else {
        if (response.status === CertificateResponseStatus.ACCESS) {
          this.log.error('Failed to save configuration, cannot reach backend!');
          this.saving = false;
          AppComponent.block.stop();
        }
        else if (!response.certificates || response.certificates.length === 0) {
          this.log.error('Failed to save configuration due to certificate error, but no certificate was obtained!');
          this.saving = false;
          AppComponent.block.stop();
        } else {
          AppComponent.block.stop();
          this.wsSubscription.unsubscribe();
          this.progressDialogShown = false;
          this.dialog.open(CertificateDialogComponent, { data: response.certificates, width: CertificateDialogComponent.WIDTH }).afterClosed().subscribe(status => {
            if (status) {
              this.checkSaveConfig();
            } else {
              this.saving = false;
            }
          });
        }
      }
    }, error => {
      this.log.error('Failed to save configuration!', error);
      this.saving = false;
      if (error.status === Globals.STATUS_CODE_DEMO)
        this.cancelSave();
      AppComponent.block.stop();
    }, () => {
      this.saving = false;
      AppComponent.block.stop();
    });
    this.saveSubscription.add(() => {
      this.saving = false;
      AppComponent.block.stop();
    });
  }

}
