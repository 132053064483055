import * as tslib_1 from "tslib";
import { HttpClient, HttpParams } from '@angular/common/http';
import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { AbstractGraph } from 'src/app/graph/abstract-graph';
import { LoggerService } from 'src/app/logger.service';
import { Product } from 'src/app/model/product';
import { TabType } from 'src/app/model/tabs';
export class TabsComponent {
    constructor(dataService, router, log, http) {
        this.dataService = dataService;
        this.router = router;
        this.log = log;
        this.http = http;
        this.nTabOptions = [
            { label: 'OS Agent', value: 'osagent' },
            { label: 'NMON', value: 'nmon' },
        ];
        this.tabType = TabType;
        this.nTps = {};
        this.navigationSubscription = Subscription.EMPTY;
        this.dataSubscription = Subscription.EMPTY;
        this.currentTabIndex = 0;
        this.nextTabIndex = -1;
        this.loading = true;
        this.lparEnterprise = false;
        this.storEnterprise = false;
        this.navigationSubscription = this.router.events
            .pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((e) => {
            this.dataService.nodeSelected.promise.then(() => {
                if (!this.navigationSubscription.closed) {
                    if (Globals.isSameNavigation(e, this.router))
                        return;
                    this.init();
                }
            });
        });
    }
    ngAfterViewInit() {
        this.timerIdShort = window.setInterval(() => {
            this.currentTab.sections.forEach((sec) => sec.graphs.forEach((graph) => {
                if (graph.imgSrc.includes('time=d'))
                    graph.reloadSwitch = !graph.reloadSwitch;
            }));
        }, Globals.GRAPH_RELOAD_INTERVAL_SHORT);
        this.timerIdLong = window.setInterval(() => {
            for (const sec of this.currentTab.sections) {
                for (const graph of sec.graphs) {
                    if (!graph.imgSrc.includes('time=d'))
                        graph.reloadSwitch = !graph.reloadSwitch;
                }
            }
        }, Globals.GRAPH_RELOAD_INTERVAL_LONG);
    }
    ngOnDestroy() {
        this.navigationSubscription.unsubscribe();
        this.dataSubscription.unsubscribe();
        clearInterval(this.timerIdShort);
        clearInterval(this.timerIdLong);
        clearTimeout(this.timerResize);
        this.activeJson = null;
    }
    ngOnInit() {
        if (this.data) {
            AbstractGraph.externalData = true;
            this.activeJson = this.data;
            this.prepareData();
            setTimeout(() => {
                this.loading = false;
            });
        }
        else {
            AbstractGraph.externalData = false;
        }
    }
    onResize(event) {
        clearTimeout(this.timerResize);
        this.timerResize = window.setTimeout(() => {
            $('.slideGraph, .scroll-wrap').floatingScroll('update');
        }, 200);
    }
    set activeJson(data) {
        TabsComponent.activeJson = data;
    }
    get activeJson() {
        return TabsComponent.activeJson;
    }
    get regrouped() {
        return TabsComponent.regrouped;
    }
    set regrouped(status) {
        TabsComponent.regrouped = status;
    }
    init() {
        this.regrouped = false;
        this.data = null;
        this.error = null;
        this.lparEnterprise = Product.LPAR.info.isEnterpriseEdition();
        this.storEnterprise = Product.STOR.info.isEnterpriseEdition();
        if (this.dataService.selectedNode) {
            let url;
            url = this.dataService.selectedNode.data.href || this.dataService.selectedNode.data.url;
            // if (this.url === url) {
            //   return;
            // }
            this.url = url;
            this.loading = true;
            if (this.dataSubscription) {
                this.dataSubscription.unsubscribe();
            }
            let type = this.dataService.selectedType;
            if (this.dataService.selectedType === Globals.TYPE_REPORT) {
                type = this.dataService.selectedParent;
            }
            this.dataSubscription = this.http.post('/api/pageJson', {
                url,
                type: type,
            }).subscribe((data) => {
                this.error = null;
                this.activeJson = data;
                this.loading = false;
                this.prepareData();
            }, (error) => {
                this.activeJson = null;
                this.error = error;
                this.loading = false;
                this.log.error('Failed to load graphs!', error);
            });
        }
    }
    prepareData() {
        if (this.nextTabIndex >= 0) {
            this.currentTabIndex = this.nextTabIndex;
            this.nextTabIndex = -1;
        }
        const nTabs = this.activeJson.tps
            .filter((tab) => tab.name.endsWith('-N'))
            .map((tab) => (Object.assign({}, tab, { name: tab.name.substr(0, tab.name.length - 2) })));
        if (nTabs.length) {
            const nTwinTabs = this.activeJson.tps.filter((tab) => nTabs.some((value) => tab.name === value.name));
            const commonTabs = this.activeJson.tps.filter((tab) => !tab.name.endsWith('-N') && nTabs.every((value) => tab.name !== value.name));
            commonTabs.forEach((t) => (t.common = true));
            this.nTps[this.nTabOptions[0].value] = commonTabs.concat(nTwinTabs);
            this.nTps[this.nTabOptions[1].value] = commonTabs.concat(nTabs);
            this.selectedNOption = this.nTabOptions[0].value;
            this.nOptionChanged();
        }
        else {
            this.selectedNOption = null;
            this.nTps = {};
            this.nTps[this.nTabOptions[0].value] = this.activeJson.tps;
        }
        if (this.activeJson.tpCommon) {
            this.fixHtmls(this.activeJson.tpCommon.htmls);
        }
        for (const tab of this.activeJson.tps) {
            this.fixHtmls(tab.htmls);
            let idx = 0;
            for (const section of tab.sections) {
                this.fixHtmls(section.htmls);
                for (const graph of section.graphs) {
                    graph.idx = idx++;
                }
            }
        }
        let metric;
        if (this.dataService.metric) {
            metric = this.dataService.metric;
            this.dataService.metric = null;
        }
        else {
            let url = new URL(location.href);
            metric = url.searchParams.get(Globals.METRIC);
        }
        this.setMetric(metric);
        if (metric) {
            for (let i = 0; i < this.activeJson.tps.length; i++) {
                if (this.activeJson.tps[i].metric === metric) {
                    this.currentTabIndex = i;
                    break;
                }
            }
        }
        this.tabChanged();
    }
    fixHtmls(htmls) {
        if (htmls) {
            for (let i = 0; i < htmls.length; i++) {
                let doc = Globals.DOM_PARSER.parseFromString(htmls[i], 'text/html');
                let links = doc.links;
                for (let j = 0; j < links.length; j++) {
                    let link = links[j];
                    let href = link.getAttribute('href');
                    if (href && !href.startsWith('#') && !href.startsWith('http') && !href.includes('/detail.sh'))
                        link.setAttribute('href', Globals.API_HOST + Globals.RUNTIME_PATH + href);
                }
                let forms = doc.forms;
                for (let j = 0; j < forms.length; j++) {
                    let form = forms[j];
                    let action = form.getAttribute('action');
                    if (action && !action.startsWith('http')) {
                        form.action = Globals.API_HOST + Globals.RUNTIME_PATH + action;
                    }
                }
                htmls[i] = Globals.domSanitizer.bypassSecurityTrustHtml(doc.documentElement.outerHTML);
            }
        }
    }
    onLoad(g) {
        g.notDeferred = true;
    }
    onLoadPrediction(p, div) {
        if (this.dataService.isLpar()) {
            let d = $(div);
            d.html(p);
            Product.LPAR.module.showPrediction(d.children()[0]);
        }
    }
    onLoaded(graph) {
        // setTimeout(() => {
        //   const element = $(graph.element.nativeElement);
        //   let floatDiv = element.closest('.slideGraph');
        //   if (floatDiv.length === 0)
        //     floatDiv = element.closest('.scroll-wrap');
        //   floatDiv.floatingScroll('update');
        // });
        if (this.regrouped) {
            if (this.currentTab.sections.every(sec => sec.graphs.every(g => !g.zoom || !g.loading || g.error))) {
                this.block.stop();
            }
        }
    }
    loadGraphs(tab) {
        for (const section of tab.sections) {
            for (const graph of section.graphs)
                graph.notDeferred = true;
        }
    }
    groupSelectEnd(tab, selection) {
        for (const sec of this.currentTab.sections) {
            for (const graph of sec.graphs) {
                if (graph.zoom && !graph.error)
                    graph.loading = true;
            }
        }
        this.block.start();
        tab.groupSelection = null;
        setTimeout(() => {
            tab.groupSelection = selection;
        });
    }
    get currentTab() {
        if (this.activeJson)
            return this.activeJson.tps[this.currentTabIndex];
        return null;
    }
    setMetric(metric) {
        let url = new URL(location.href);
        if (metric) {
            url.searchParams.set(Globals.METRIC, metric);
        }
        else {
            url.searchParams.delete(Globals.METRIC);
        }
        window.history.replaceState({ path: url.href }, '', url.href);
    }
    tabChanged(event) {
        if (event)
            this.currentTabIndex = event.index;
        if (!this.currentTab)
            return;
        this.setMetric(this.currentTab.metric);
        if (this.currentTab.loaded)
            return;
        AbstractGraph.useFullTitle = this.currentTab.sections
            .some(sec => sec.graphs.map(g => new HttpParams({ fromString: g.imgSrc }).get('time'))
            .filter((v, i, a) => a.indexOf(v) === i).length === 1);
        if (this.currentTab.url) {
            let tab = this.currentTab;
            this.http.get(Globals.RUNTIME_PATH + (tab.url.startsWith(this.dataService.cgiPath) ? '' : this.dataService.path) +
                tab.url, { responseType: 'text' }).subscribe(page => {
                tab.urlContent = Globals.domSanitizer.bypassSecurityTrustHtml(page);
                this.postPageLoad();
            }, error => this.log.error('Failed to get tab page ' + this.currentTab.url, error));
        }
        else if (this.currentTab.itemIds && !this.currentTab.itemProperties) {
            const tab = this.currentTab;
            if (!Globals.BACKEND_INFO.storEnabled.value) {
                tab.type = TabType.storError;
                this.postPageLoad();
                return;
            }
            let itemIds = tab.itemIds;
            if (!this.storEnterprise || !this.lparEnterprise) {
                itemIds = itemIds.slice(0, 2);
            }
            const body = {
                procname: 'getItemsInfo',
                itemIDs: itemIds
            };
            this.http.post(Globals.RUNTIME_PATH + Product.STOR.pathCgi + Globals.DBWRAPPER_SH, body).subscribe(result => {
                tab.itemProperties = result;
                this.postPageLoad();
            }, error => {
                this.log.error('Failed to get ' + tab.type, error);
                this.currentTab.type = TabType.storError;
            });
        }
        else
            this.postPageLoad();
    }
    postPageLoad() {
        setTimeout(() => {
            if (!AbstractGraph.externalData && !this.hasAnyGraph() && !this.currentTab.url && !this.currentTab.type)
                this.dataService.mainModule.myreadyFunc();
            if (this.dataService.isLpar()) {
                $('.preddiv').each((index, element) => {
                    Product.LPAR.module.showPrediction(element);
                });
            }
            this.observeDatastoresTop();
            Globals.tableSorter($('table.tablesorter'), true);
            this.dataService.registerBacklinks();
            this.floatCurrentTab();
        });
    }
    observeDatastoresTop() {
        // Select the node that will be observed for mutations
        const targetNode = document.getElementById('volresults');
        if (!targetNode)
            return;
        // Options for the observer (which mutations to observe)
        const config = { attributes: false, childList: true, subtree: false };
        // Callback function to execute when mutations are observed
        const callback = (mutationsList, observer) => {
            // Use traditional 'for loops' for IE 11
            for (const mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    this.dataService.registerBacklinks();
                    break;
                }
            }
        };
        // Create an observer instance linked to the callback function
        const observer = new MutationObserver(callback);
        // Start observing the target node for configured mutations, once targetNode is removed from DOM so is this observer collected
        observer.observe(targetNode, config);
    }
    floatCurrentTab(limit = 10) {
        if (limit < 0)
            return;
        setTimeout(() => {
            if (!this.currentTab) {
                return;
            }
            if (!this.currentTab.sections ||
                this.currentTab.sections.length === 0 ||
                this.currentTab.sections
                    .map((section) => (section.graphs ? section.graphs.length : 0))
                    .reduce((prev, cur) => prev + cur, 0) === 0 ||
                this.currentTab.sections.some((section) => section.graphs.some((graph) => graph.loaded))) {
                Globals.initFloatingScroll(true, '#slideGraph, .scroll-wrap');
                //this.slider();
                this.currentTab.loaded = true;
            }
            else
                this.floatCurrentTab(limit - 1);
        }, 500);
    }
    nOptionChanged() {
        this.activeJson.tps = this.nTps[this.selectedNOption];
    }
    isNTab(tab) {
        return this.selectedNOption && !tab.common;
    }
    getPageTitle() {
        return this.dataService.getPageTitle();
    }
    hasGraphs(tab) {
        return !this.data && tab.sections && tab.sections.some((value, index, array) => value.graphs.length);
    }
    hasAnyGraph() {
        return this.currentTab && this.currentTab.sections.some((sec) => sec.graphs.length);
    }
    regroup() {
        this.block.reset();
        if (this.regrouped) {
            this.init();
            return;
        }
        const timedtNtps = {};
        for (const key in this.nTps) {
            if (Object.prototype.hasOwnProperty.call(this.nTps, key)) {
                const tps = this.nTps[key];
                const timedTps = [
                    { name: 'Daily', sections: [], time: 'd', predictions: [], common: !this.selectedNOption },
                    { name: 'Weekly', sections: [], time: 'w', predictions: [], common: !this.selectedNOption },
                    { name: 'Monthly', sections: [], time: 'm', predictions: [], common: !this.selectedNOption },
                    { name: 'Yearly', sections: [], time: 'y', predictions: [], common: !this.selectedNOption },
                ];
                for (const timedTab of timedTps) {
                    const section = { graphs: [] };
                    for (const tab of tps) {
                        for (const oldSec of tab.sections) {
                            const timedGraphs = oldSec.graphs
                                .filter((value, index, array) => value.imgSrc.includes('&time=' + timedTab.time))
                                .map((value, index, array) => (Object.assign({}, value, { imgSrc: this.regroupSrc(value.imgSrc), title: undefined, loaded: false, zoomed: false, notDeferred: false, regrouped: true })));
                            section.graphs.push(...timedGraphs);
                        }
                    }
                    timedTab.sections.push(section);
                    for (let j = 0; j < section.graphs.length; j++) {
                        section.graphs[j].idx = j;
                    }
                }
                timedtNtps[key] = timedTps;
            }
        }
        this.nTps = timedtNtps;
        this.regrouped = true;
        this.activeJson.tps = this.nTps[this.selectedNOption || this.nTabOptions[0].value];
        this.floatCurrentTab();
    }
    regroupSrc(url) {
        if (this.dataService.isLpar())
            return url + '&height=150&width=900';
        // else if (this.dataService.selectedType === Globals.TYPE_NETWORK)
        //   return url.replace('detail=9', 'detail=1');
        else
            return url.replace('detail=9', 'detail=8');
    }
    genPdf() {
        Product.LPAR.module.genPdf(this.getGraphUrls());
    }
    genXls() {
        Product.LPAR.module.genXls(this.getGraphUrls());
    }
    getGraphUrls() {
        let tabs = {};
        for (const tab of this.activeJson.tps) {
            let graphs = [];
            for (const sec of tab.sections) {
                graphs = graphs.concat(sec.graphs.map((g) => g.imgSrc));
            }
            tabs[tab.name] = graphs;
        }
        return tabs;
    }
    /**
     * Because of anonymous handlers, must be once at max
     * TODO: extract handlers to functions
     */
    slider() {
        const slider = document.querySelector('.slideGraph');
        if (slider) {
            let isDown = false;
            let startX;
            let scrollLeft;
            slider.addEventListener('mousedown', (e) => {
                isDown = true;
                slider.classList.add('active');
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
            });
            slider.addEventListener('mouseleave', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mouseup', (e) => {
                isDown = false;
                slider.classList.remove('active');
                if (startX !== e.pageX - slider.offsetLeft) {
                    AbstractGraph.SLIDER_TIMESTAMP = Date.now();
                }
            });
            slider.addEventListener('mousemove', (e) => {
                if (!isDown)
                    return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = x - startX;
                slider.scrollLeft = scrollLeft - walk;
            });
        }
    }
}
TabsComponent.regrouped = false;
tslib_1.__decorate([
    BlockUI('graphs'),
    tslib_1.__metadata("design:type", Object)
], TabsComponent.prototype, "block", void 0);
