/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./rmc-check.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./rmc-check.component";
import * as i3 from "@angular/common/http";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../../logger.service";
var styles_RmcCheckComponent = [i0.styles];
var RenderType_RmcCheckComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RmcCheckComponent, data: {} });
export { RenderType_RmcCheckComponent as RenderType_RmcCheckComponent };
export function View_RmcCheckComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "inner-html"]], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.html; _ck(_v, 2, 0, currVal_0); }); }
export function View_RmcCheckComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xormon-rmc-check", [], null, null, null, View_RmcCheckComponent_0, RenderType_RmcCheckComponent)), i1.ɵdid(1, 114688, null, 0, i2.RmcCheckComponent, [i3.HttpClient, i4.DomSanitizer, i5.LoggerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RmcCheckComponentNgFactory = i1.ɵccf("xormon-rmc-check", i2.RmcCheckComponent, View_RmcCheckComponent_Host_0, {}, {}, []);
export { RmcCheckComponentNgFactory as RmcCheckComponentNgFactory };
