import { Group, LocalGroup } from './group';

export class User {
  id: number;
  username: string;
  password: string;
  name: string;
  enabled: boolean;
  lastIp: string;
  createdAt: string;
  lastLogin: string;
  localGroups: LocalGroup[];
  adGroups: Group[];
  effectiveLocalGroups: LocalGroup[];
  admin: boolean;
  ldap: boolean;
}
