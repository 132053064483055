<h1 mat-dialog-title>{{data.heading}}</h1>
<form #f="ngForm" (ngSubmit)="ok()">
    <div mat-dialog-content style="min-width: 300px;">
        <div class="col-md-12 form-group">
            <label>{{data.inputText}}</label>
            <input class="form-control" autofocus [(ngModel)]="inputResult" required name="result" maxlength="250"
                (keyup)="nameTaken=isNameTaken()">
            <div *ngIf="nameTaken" class="text-danger">This name is already taken.</div>
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-raised-button mat-dialog-close type="button">Cancel</button>
        <button mat-raised-button color="primary" mat-primary
            [disabled]="f.invalid || f.pristine || nameTaken || !inputResult.trim().length">OK</button>
    </div>
</form>
