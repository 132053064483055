import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { CustomMessagesService } from 'src/app/custom-messages.service';

@Component({
  selector: 'xormon-alerting-stor-header',
  templateUrl: './alerting-stor-header.component.html',
  styleUrls: ['./alerting-stor-header.component.scss']
})
export class AlertingStorHeaderComponent implements OnInit {

  @Input()
  label: string;

  url: string;

  constructor(private msgService: CustomMessagesService, private elef: ElementRef) {

  }

  ngOnInit() {
    this.url = this.msgService.getMsg('href', this.elef);
  }


}
