import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Globals } from './globals';
import { LoggerService } from './logger.service';
import { Product } from './model/product';
import { AuthService } from './_security/auth.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  constructor() {
    this.doRegisterJquery();
    Globals.INIT_EMITTER.subscribe(() => {
      this.registerJquery();
    });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let apiReq;
    if (req.url.startsWith('http') || req.url.startsWith('../')) {
      apiReq = req.clone({ withCredentials: true });
    } else {
      apiReq = req.clone({ url: `${Globals.API_HOST}${req.url}`, withCredentials: true });
    }
    return next.handle(apiReq).pipe(
      catchError(err => {
        if (err.status === 401
          //|| err.status === 403
          //|| err.status === 404 && !Globals.isProd
        ) {
          // auto logout if 401 response returned from api
          if (Globals.authenticated)
            AuthService.INSTANCE.logout();
        }

        return throwError(err);
      }));
  }

  private registerJquery() {
    Promise.allSettled([Product.LPAR.resolved.promise, Product.STOR.resolved.promise]).finally(() => {
      const readies: JQuery.Thenable<any>[] = [];
      if (Globals.BACKEND_INFO.storEnabled.value && Product.STOR.module)
        readies.push(Product.STOR.module.xormonReady);
      if (Globals.BACKEND_INFO.lparEnabled.value && Product.LPAR.module)
        readies.push(Product.LPAR.module.xormonReady);
      jQuery.when(readies).then(() => {
        this.doRegisterJquery();
      });
    });
  }

  private doRegisterJquery() {
    $.ajaxSetup({
      beforeSend(xhr, settings) {
        if (settings.url.indexOf('http') !== 0 && !settings.url.startsWith(Globals.PREFIX_JQUERY)) {
          settings.url = Globals.PREFIX_JQUERY + (settings.url.startsWith('/') ? settings.url :
            (Globals.CGI_PATH === Product.LPAR.pathCgi ? Product.LPAR.path : Product.STOR.path) + settings.url);
        }
        if (settings.xhrFields) {
          settings.xhrFields.withCredentials = true;
        } else {
          settings.xhrFields = { withCredentials: true };
        }
      },
      error(xhr, status, error) {
        if (xhr.status === 401) {
          AuthService.INSTANCE.logout();
        }
      },
      complete(xhr, status) {
        let error = xhr.getResponseHeader(Globals.HEADER_ERROR);
        if (error) {
          let msg = Base64.decode(error);
          LoggerService.INSTANCE.addLog(msg);
        }
      },
      xhrFields: {
        withCredentials: true
      }
    });
  }
}
