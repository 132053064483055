/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reporter-history.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../loading-indicator/loading-indicator.component.ngfactory";
import * as i3 from "../../../loading-indicator/loading-indicator.component";
import * as i4 from "@angular/common";
import * as i5 from "./reporter-history.component";
import * as i6 from "@angular/router";
import * as i7 from "../../../data.service";
import * as i8 from "@angular/common/http";
import * as i9 from "../../../logger.service";
import * as i10 from "primeng/components/common/confirmationservice";
var styles_ReporterHistoryComponent = [i0.styles];
var RenderType_ReporterHistoryComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ReporterHistoryComponent, data: {} });
export { RenderType_ReporterHistoryComponent as RenderType_ReporterHistoryComponent };
function View_ReporterHistoryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "spinner-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "xormon-loading-indicator", [], null, null, null, i2.View_LoadingIndicatorComponent_0, i2.RenderType_LoadingIndicatorComponent)), i1.ɵdid(2, 114688, null, 0, i3.LoadingIndicatorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_ReporterHistoryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReporterHistoryComponent_1)), i1.ɵdid(1, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "card"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "tablesorter-wrapper reporter-history"], ["id", "reporterHistory"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.loading; _ck(_v, 2, 0, currVal_1); }); }
export function View_ReporterHistoryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xormon-reporter-history", [], null, null, null, View_ReporterHistoryComponent_0, RenderType_ReporterHistoryComponent)), i1.ɵdid(1, 180224, null, 0, i5.ReporterHistoryComponent, [i6.Router, i7.DataService, i8.HttpClient, i9.LoggerService, i10.ConfirmationService], null, null)], null, null); }
var ReporterHistoryComponentNgFactory = i1.ɵccf("xormon-reporter-history", i5.ReporterHistoryComponent, View_ReporterHistoryComponent_Host_0, {}, {}, []);
export { ReporterHistoryComponentNgFactory as ReporterHistoryComponentNgFactory };
