import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';
import { Product } from 'src/app/model/product';
import { TabPage } from 'src/app/model/tabs';
import { StorageTotalsService } from '../storage-totals.service';

@Component({
  selector: 'xormon-volumes-top',
  templateUrl: './volumes-top.component.html',
  styleUrls: ['./volumes-top.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class VolumesTopComponent implements OnInit, OnDestroy {

  tabsTps: any[];
  private navigationSubscription = Subscription.EMPTY;

  constructor(private totalStService: StorageTotalsService, private dataService: DataService, private router: Router,
    private log: LoggerService, private http: HttpClient) {
    this.navigationSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.dataService.nodeSelected.promise.then(() => {
          if (!this.navigationSubscription.closed) {
            if (Globals.isSameNavigation(e))
              return;
            this.init();
          }
        });
      });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }

  private init() {

    let href = this.dataService.selectedNode.data.url || this.dataService.selectedNode.data.href;

    this.http.post<TabPage>("/api/tabs", { url: href, rrdType: "stor", type: "totals" })
      .subscribe(data => {
        data.tps.splice(0, 1);
        this.tabsTps = data.tps;
        setTimeout(() => {
          this.initJS();
        });
      },
        error => {
          this.log.error('Failed to get Volumes TOP page!', error);
        });
  }

  private initJS() {

    var now = new Date();
    var twoWeeksBefore = new Date();
    var yesterday = new Date();
    var nowPlusHour = new Date();
    yesterday.setDate(now.getDate() - 1);
    twoWeeksBefore.setDate(now.getDate() - 14);
    nowPlusHour.setHours(now.getHours() + 1);

    var startDateTextBox = $('#fromTime');
    var endDateTextBox = $('#toTime');

    $("#fromTime").datetimepicker({
      defaultDate: '-1d',
      dateFormat: "yy-mm-dd",
      timeFormat: "HH:00",
      maxDate: nowPlusHour,
      changeMonth: true,
      changeYear: true,
      showButtonPanel: true,
      showOtherMonths: true,
      selectOtherMonths: true,
      showMinute: false,
      onClose: function (dateText, inst) {
        if (endDateTextBox.val() !== '') {
          var testStartDate = startDateTextBox.datetimepicker('getDate');
          var testEndDate = endDateTextBox.datetimepicker('getDate');
          if (testStartDate > testEndDate) {
            endDateTextBox.datetimepicker('setDate', testStartDate);
          }
        } else {
          endDateTextBox.val(dateText);
        }
      },
      onSelect: function (selectedDateTime) {
        endDateTextBox.datetimepicker('option', 'minDate', startDateTextBox.datetimepicker('getDate'));
      }
    });

    if ($("#fromTime").length) {
      $("#fromTime").datetimepicker("setDate", yesterday);
    }

    $("#toTime").datetimepicker({
      defaultDate: 0,
      dateFormat: "yy-mm-dd",
      timeFormat: "HH:00",
      maxDate: nowPlusHour,
      changeMonth: true,
      changeYear: true,
      showButtonPanel: true,
      showOtherMonths: true,
      selectOtherMonths: true,
      showMinute: false,
      onClose: function (dateText, inst) {
        if (startDateTextBox.val() !== '') {
          var testStartDate = startDateTextBox.datetimepicker('getDate');
          var testEndDate = endDateTextBox.datetimepicker('getDate');
          if (testStartDate > testEndDate) {
            startDateTextBox.datetimepicker('setDate', testEndDate);
          }
        } else {
          startDateTextBox.val(dateText);
        }
      },
      onSelect: function (selectedDateTime) {
        startDateTextBox.datetimepicker('option', 'maxDate', endDateTextBox.datetimepicker('getDate'));
      }
    });

    if ($("#toTime").length) {
      $("#toTime").datetimepicker("setDate", now);
    }

  }

  onTabSelected(indexObj: MatTabChangeEvent) {
    var index = indexObj.index - 1;
    var tabCont = $('#tab' + index).html();
    if ((tabCont === null || tabCont === undefined || tabCont.match(/table/g) === null) && index !== -1) {
      var tab = $('#tab' + index);

      var urlName = this.tabsTps[index].url;
      urlName = urlName.replace(Product.STOR.pathBare + "//", "");

      this.totalStService.getUrlNameVol(urlName).subscribe(page => {

        var csvOld = page.match(/<a class="csvfloat".*<\/a>/g);
        var fixedPage = page.replace(/<a class="csvfloat".*<\/a>/g, "");
        let csvPath;
        let csv = '';
        if (csvOld) {
          csvPath = csvOld[0].match(/glob-VOLUME(.*?)\.csv/g);
          csv = " <div class='d-flex mb-2' style='position: absolute; top:0px; right:0px'> <a class='ml-auto btn btn-link' href=\'" +
            Globals.htmlUrlPrefix + Globals.RUNTIME_PATH + Product.STOR.path + csvPath + "'title='CSV'> <span class='fas fa-file-csv mr-1'></span>Export to CSV</a></div>";
        }
        fixedPage = fixedPage + csv;
        let re = new RegExp('\\/' + Product.STOR.pathCgi + '\\/detail.sh.*?"', 'g');
        //fixedPage = fixedPage.replace(/\/stor2rrd-cgi\/detail.sh.*?"/g, Globals.RUNTIME_PATH + "$&");
        fixedPage = fixedPage.replace(re, Globals.RUNTIME_PATH + '$&');
        tab.empty();
        tab.html(fixedPage);
        $('center > .tablesorter')
          .wrap('<div class="scroll-wrap"/>');
        var $t = tab.find('table.tablesorter');
        if ($t.length) {
          Globals.tableSorter($($t), true);
        }
        setTimeout(() => { $('.scroll-wrap').floatingScroll(); });
        this.dataService.registerBacklinks();
      }, error => this.log.error('Failed to get volume-top tab!', error));
    } else if (index === -1) {
      $('#volresults .tablesorter-sticky-wrapper').css('visibility', 'hidden');
    }

  }
  submitForm() {
    const self = this;
    $('#volresults').empty();
    $('#volresults').append('<div style="left: 50%; line-height: 300px; position: relative; width: 50px;"><img src="css/images/sloading.gif"></div>');

    var postData = [];
    var curNode = this.dataService.selectedNode;

    if (curNode.getLevel() == 1 || this.dataService.selectedType === Globals.TYPE_REPORT) {
      postData.push({ name: "host", value: curNode.parent.title });
    } else {
      postData.push({ name: "host", value: Globals.getTopNode(curNode).title });
    }
    postData.push({ name: "type", value: "VOLUME" });
    if (curNode.getLevel() == 1 || this.dataService.selectedType === Globals.TYPE_REPORT) {
      postData.push({ name: "name", value: "topglobal" });
    } else {
      postData.push({ name: "name", value: "top" });
    }
    postData.push({ name: "item", value: $('#volcustomform select').val() });
    postData.push({ name: "time", value: "d" });
    postData.push({ name: "detail", value: 0 });

    postData.push({ name: "sunix", value: $("#fromTime").datetimepicker("getDate").getTime() / 1000 });
    postData.push({ name: "eunix", value: $("#toTime").datetimepicker("getDate").getTime() / 1000 });

    $('#volresults').load(Product.STOR.pathCgi + '/detail-graph.sh', $.param(postData), function (response, status, xhr) {
      if (status !== "success") {
        self.log.error(`Submit failed with ${status}!`, response);
        $(this).empty();
        return;
      }
      $('center > .tablesorter')
        .wrap('<div class="scroll-wrap"/>');
      var $t = $(this).find('table.tablesorter');
      if ($t.length) {
        Globals.tableSorter($t, true);
      }
      self.dataService.registerBacklinks();
    });
  }
}
