import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';
import { Product } from 'src/app/model/product';

@Component({
  selector: 'xormon-heatmap-local-lpar',
  templateUrl: './heatmap-local-lpar.component.html',
  styleUrls: ['./heatmap-local-lpar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeatmapLocalLparComponent implements OnInit {

  page;
  heatmaps: NodeListOf<Element>;
  loaded = false;
  private tabSubscription = Subscription.EMPTY;

  constructor(private http: HttpClient, private log: LoggerService, private dataService: DataService) { }

  ngOnInit() {
    this.dataService.nodeSelected.promise.then(() => {
      this.http.get(Globals.RUNTIME_PATH +
        //Product.LPAR.path + this.dataService.selectedNode.data.href
        Product.LPAR.pathCgi + HeatmapLocalLparComponent.getUrlPath(this.dataService.selectedParent)
        , { responseType: 'text' }).subscribe(data => {
          const html = Globals.DOM_PARSER.parseFromString(data, 'text/html');
          const tabs = html.querySelectorAll('div ul li a');
          this.heatmaps = tabs;
        }, error => this.log.error('Failed to get heatmaps!', error));
    });
  }

  updateTab(index) {
    this.loaded = false;
    this.tabSubscription.unsubscribe();
    this.tabSubscription = this.http.get(Globals.RUNTIME_PATH +
      //Product.LPAR.path +
      this.heatmaps.item(index).getAttribute('href'),
      { responseType: 'text' }).subscribe(data => {
        const html = Globals.DOM_PARSER.parseFromString(data, 'text/html');
        const style_tag = data.match(/<style>.*<\/style>/g);
        let class_name, size;
        if (style_tag) {
          const class_stats = style_tag[0].match(/\..*?}/);
          class_name = class_stats[0].match(/\.[a-zA-Z&_]*/);
          size = class_stats[0].match(/\d+/);
        }
        this.page = Globals.domSanitizer.bypassSecurityTrustHtml(html.body.innerHTML);
        setTimeout(() => {
          $('center > table.tablesorter')
            .wrap('<div class="scroll-wrap d-inline-block stickyheaders-holder"/>');
          Globals.tableSorter($('table.tablesorter'), true);
          this.dataService.registerBacklinks();
          this.loaded = true;
          if (class_name && size) {
            $(class_name[0]).width(size[0]);
            $(class_name[0]).height(size[0]);
          }
          setTimeout(() => {
            Globals.initFloatingScroll();
          });
        });
      }, error => this.log.error('Failed to get heatmap for ' + this.heatmaps.item(index).textContent, error));
  }

  static getUrlPath(platform: string) {
    return `/heatmap-xormon.sh?platform=${platform}&tabs=1`;
  }

}
