import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Globals } from 'src/app/globals';
import { Product } from 'src/app/model/product';

@Injectable({
  providedIn: 'root'
})
export class AlertingService {
  constructor(private http: HttpClient) { }

  getLparForm() {
    return this.http.get(Globals.RUNTIME_PATH + Product.LPAR.pathCgi + '/alcfg.sh?cmd=form', { responseType: 'text' });
  }

  getLparLogs() {
    return this.http.get('/admin/alerting/LPARlogs', { responseType: 'text' });
  }

  getLparOptions() {
    return this.http.get('/admin/alerting/cfgOptionsLpar');
  }

  saveLparPart(cfg) {
    return this.http.put('/admin/alerting/cfgPartLpar', cfg);
  }

  getStorPerfLogs() {
    return this.http.get(Globals.RUNTIME_PATH + Product.STOR.pathCgi + '/alcfg.sh', {
      params: new HttpParams().set('cmd', 'plog').set('count', '100'),
      responseType: 'text'
    });
  }

  getStorHwLogs() {
    return this.http.get(Globals.RUNTIME_PATH + Product.STOR.pathCgi + '/alcfg.sh', {
      params: new HttpParams().set('cmd', 'hlog').set('count', '100'),
      responseType: 'text'
    });
  }

  getStorCfg() {
    return this.http.get('/admin/alerting/cfgStor');
  }

  getStorForm() {
    return this.http.get(Globals.RUNTIME_PATH + Product.STOR.pathCgi + '/alcfg.sh?cmd=form', { responseType: 'text' });
  }

  saveStorCfg(cfg) {
    return this.http.put('/admin/alerting/cfgStor', cfg);
  }
}
