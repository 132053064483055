import { ElementRef } from '@angular/core';
import customMsgs from 'src/app/wlabel.json';
import * as i0 from "@angular/core";
export class CustomMessagesService {
    constructor() {
    }
    getMsg(name, elef) {
        if (elef) {
            return customMsgs[this.getPrefix(elef) + name];
        }
        return customMsgs[name];
    }
    getPrefix(elef) {
        return elef.nativeElement.tagName.toLowerCase() + '.';
    }
}
CustomMessagesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function CustomMessagesService_Factory() { return new CustomMessagesService(); }, token: CustomMessagesService, providedIn: "root" });
