<mat-tab-group (selectedTabChange)="onTabSelected($event)" [@.disabled]="true">
  <mat-tab *ngFor="let tab of tabsTps; let i = index" label={{tab.name}}>

    <div id="{{'tab'+i}}" class="stickyheaders-holder hosts-top">
      <div class="spinner-wrap">
        <xormon-loading-indicator></xormon-loading-indicator>
      </div>
    </div>

  </mat-tab>
</mat-tab-group>
