import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class UserService {
    constructor(http) {
        this.http = http;
    }
    getCurrentUser() {
        return this.http.get(UserService.PATH_API_CURRENT_USER);
    }
}
UserService.PATH_API_CURRENT_USER = '/api/currentUser';
UserService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UserService_Factory() { return new UserService(i0.ɵɵinject(i1.HttpClient)); }, token: UserService, providedIn: "root" });
