<div class="row mr-1 mb-1" id="userGroupsList">
  <div class="col-lg-3 d-flex flex-column">
    <button class="ml-auto btn btn-primary w-100 mb-1" (click)="createGroup()">
      <i class="fas fa-plus"></i>
      Add new group
    </button>
    <p-listbox [options]="selectGroups" [(ngModel)]="selectedGroup" dataKeyx="value.id" optionLabelx="name"
      filter="true" [listStyle]="{ 'flex': '1 1 auto', 'overflow': 'auto', 'height': '100px' }"
      styleClass="d-flex flex-column flex-grow-1" (onChange)="onGroupChange()">
      <p-header>Local user groups</p-header>
      <ng-template let-group pTemplate="item">
        <div class="ui-helper-clearfix text-truncate">
          <span [ngClass]="{'font-italic': group.value.changed}" [title]="group.value.name">{{
            group.value.name + (group.value.changed ? '*' : '') }}</span>
        </div>
      </ng-template>
    </p-listbox>
  </div>
  <div class="col-lg-9 d-flex flex-column">
    <div *ngIf="!selectGroups" style="position: relative; height: 400px;">
      <xormon-loading-indicator></xormon-loading-indicator>
    </div>
    <div *ngIf="selectedGroup" class="d-flex flex-column flex-grow-1 border-transparent"
      [ngClass]="{'hover-border': groupBorder}">
      <p class="with-btn-right" [title]="selectedGroup.name">
        <span>Group: <span class="font-weight-bold">{{ selectedGroup.name }}</span></span>
        <button class="btn btn-sm hover-success" style="margin-top: 2px;"
          [ngClass]="{'needs-save':selectedGroup.changed}" (click)="saveGroup(selectedGroup)" title="Save this group"
          (mouseenter)="groupBorder=true" (mouseleave)="groupBorder=false"
          [disabled]="(selectedGroup.id < 2 && selectedGroup.users.length < 1) || saving">
          <i class="fas fa-fw fa-lg fa-save"></i>
        </button>
        <button class="btn btn-sm hover-info"
          [title]="selectedGroup.id < 3 ? 'Edit disabled for this group' : 'Edit group'" (click)="editGroup()"
          [disabled]="selectedGroup.id < 3">
          <i class="fas fa-fw fa-edit"></i>
        </button>
        <button class="btn btn-sm hover-danger" [title]="selectedGroup.id < 3 ? 'Cannot delete' : 'Delete group'"
          (click)="deleteGroup()" [disabled]="selectedGroup.id < 3" (mouseenter)="groupBorder=true"
          (mouseleave)="groupBorder=false">
          <i class="fas fa-fw fa-trash-alt"></i>
        </button>
      </p>
      <p class="with-btn-right" [title]="selectedGroup.description ? selectedGroup.description : ''">
        <span>Description: {{ selectedGroup.description }}</span>
      </p>

      <p-tabView [(activeIndex)]="selectedSubTabIndex" styleClass="flex-grow-1 d-flex flex-column">

        <p-tabPanel header="Users" leftIcon="fas fa-user" tooltip="Add/remove users for this group"
          [ngClass]="{'flex-grow-1': selectedSubTabIndex===0}">
          <div class="mb-2 flex-grow-1 d-flex flex-column">
            <p-pickList class="d-flex flex-column flex-grow-1" styleClass="flex-grow-1 d-flex"
              [source]="selectedGroup.availableUsers" [target]="selectedGroup.users" sourceHeader="Available Users"
              targetHeader="Selected Users" [responsive]="true" dragdrop="true" [sourceStyle]="{ heightx: '300px' }"
              [targetStyle]="{ heightx: '300px' }" [showSourceControls]="false" [showTargetControls]="false"
              (onMoveAllToSource)="selectedGroup.changed = true" (onMoveAllToTarget)="selectedGroup.changed = true"
              (onMoveToSource)="selectedGroup.changed = true" (onMoveToTarget)="selectedGroup.changed = true"
              filterBy="username">
              <ng-template let-user pTemplate="item">
                <div class="ui-helper-clearfix">
                  <div title="{{ user.name }}">{{ user.username }}</div>
                </div>
              </ng-template>
            </p-pickList>
          </div>
        </p-tabPanel>

        <p-tabPanel header="ACL" leftIcon="fas fa-lock" [disabled]="selectedGroup.id < 3"
          tooltip="Manage backend access control" [ngClass]="{'flex-grow-1': selectedSubTabIndex===1}">
          <div class="card-group h-100">
            <ng-template #blockTemplate>
              <div class="block-ui-template text-center"></div>
            </ng-template>
            <div class="card h-100" *blockUI="'lparAcl'; template: blockTemplate">
              <div class="card-body d-flex flex-column">
                <h5 class="card-title mb-0 d-flex align-items-center p-2">
                  Server <span class="filter"><input type="text" id="lparfilter" placeholder="Filter..." /></span>
                </h5>
                <div id="lparAcl" class="card-text acl-tree seltree tree-menu"></div>

              </div>
            </div>
            <div class="card h-100" *blockUI="'storAcl'; template: blockTemplate">
              <div class="card-body d-flex flex-column">
                <h5 class="card-title mb-0 d-flex align-items-center p-2">
                  Storage <span class="filter"><input type="text" id="storfilter" placeholder="Filter..." /></span>
                </h5>
                <div id="storAcl" class="card-text acl-tree seltree tree-menu"></div>
              </div>
            </div>
          </div>
        </p-tabPanel>

        <p-tabPanel header="Storage groups" leftIcon="fas fa-object-group"
          tooltip="Manage access to Xormon storage groups" [ngClass]="{'flex-grow-1': selectedSubTabIndex===2}">
          <div class="mb-2 h-100">
            <p-table [value]="storageGroups" [(selection)]="selectedGroup.storageGroups" dataKey="id" autoLayout="true"
              (onRowSelect)="selectedGroup.changed = true" scrollable="true" scrollHeight="100%"
              (onRowUnselect)="selectedGroup.changed = true">
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 3em;">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                  </th>
                  <th style="width: 30%;">Name</th>
                  <th>Included storage</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-sg>
                <tr [pSelectableRow]="rowData">
                  <td style="width: 3em;">
                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                  </td>
                  <td style="width: 30%;">
                    {{ sg.title }}
                  </td>
                  <td>
                    <span *ngFor="let sn of sg.storageNames" class="storage-item">{{ sn.label
                      }}</span>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </p-tabPanel>

        <p-tabPanel header="LDAP groups" leftIcon="fas fa-user-shield" tooltip="Map LDAP groups to this local group"
          [ngClass]="{'flex-grow-1': selectedSubTabIndex===3}">
          <div class="mb-2 h-100">
            <p-table #tLdapGroups [value]="ldapGroups" [(selection)]="selectedGroup.ldapGroups" autoLayout="false"
              scrollable="true" scrollHeight="99%" [paginator]="true" [rows]="100" [showCurrentPageReport]="true"
              currentPageReportTemplate="Selected {{selectedGroup.ldapGroups.length}} groups of {{ldapGroups?.length}}"
              (onRowSelect)="selectedGroup.changed = true" (onRowUnselect)="selectedGroup.changed = true">
              <ng-template pTemplate="header">
                <tr>
                  <th style="width: 3em;">
                    <p-triStateCheckbox (onChange)="filterSelectedLdapGroups(tLdapGroups, selectedGroup, $event)"
                      title="Filter selected"></p-triStateCheckbox>
                    <!-- <p-tableHeaderCheckbox></p-tableHeaderCheckbox> -->
                  </th>
                  <th class="filter-inline"><span>DN</span>
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="fas fa-search"></i></span>
                      </div>
                      <input pInputText class="form-control"
                        (input)="tLdapGroups.filter($event.target.value, 'dn', 'contains')"
                        placeholder="Filter by group DN">
                    </div>
                  </th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-rowData let-lg>
                <tr [pSelectableRow]="rowData">
                  <td style="width: 3em;">
                    <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                  </td>
                  <td [title]="lg.description ? lg.description : ''">
                    {{ lg.ldapConfigName + ' / ' + lg.dn}}
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </p-tabPanel>
      </p-tabView>
    </div>
  </div>
</div>
