import { Component, ElementRef, OnInit, QueryList, ViewChildren } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';
import { CustomMessagesService } from 'src/app/custom-messages.service';
import { LoggerService } from 'src/app/logger.service';
import { AlertingService } from '../alerting.service';

@Component({
  selector: 'xormon-alerting-lpar-options',
  templateUrl: './alerting-lpar-options.component.html',
  styleUrls: ['./alerting-lpar-options.component.scss']
})
export class AlertingLparOptionsComponent implements OnInit {

  cfg: any = {
    NAGIOS: 0,
    EXTERN_ALERT: 'bin/external_alert_example.sh',
    EMAIL_GRAPH: 25,
    REPEAT_DEFAULT: 60,
    PEAK_TIME_DEFAULT: 30
  };

  clicked = false;
  urlNagios: string;

  @ViewChildren(OverlayPanel) panels !: QueryList<OverlayPanel>;

  constructor(private alertingService: AlertingService, private log: LoggerService,
    private cmService: CustomMessagesService, private elef: ElementRef) {
  }

  ngOnInit() {
    this.urlNagios = this.cmService.getMsg('nagios', this.elef);
    this.alertingService.getLparOptions().subscribe(data => {
      this.cfg = data;
    }, error => {
      this.log.error('Failed to get configuration!', error);
    });
  }

  saveCfg() {

    this.alertingService.saveLparPart(this.cfg).subscribe(data => {
      this.log.info('Options configuration saved successfully.');
    }, error => {
      this.log.error('Failed to save options configuration!', error);
    });
  }

}
