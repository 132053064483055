import { AfterViewInit, Component, ElementRef, ViewEncapsulation } from '@angular/core';
import { CustomMessagesService } from 'src/app/custom-messages.service';
import { DataService } from 'src/app/data.service';
import { Product } from 'src/app/model/product';
import * as customUrls from 'src/app/wlabel.json';

@Component({
  selector: 'xormon-reporter-lpar-definitions',
  templateUrl: './reporter-lpar-definitions.component.html',
  styleUrls: ['./reporter-lpar-definitions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReporterLparDefinitionsComponent implements AfterViewInit {

  urls = customUrls;
  // urlSupport = customUrls.lparSupport;
  // urlLparCpuWeeklyPdf = customUrls.lparCpuWeeklyPdf;
  // urlCpuPoolWeeklyPdf = customUrls.cpuPoolWeeklyPdf;
  // urlLparSanMonthlyPdf = customUrls.lparSanMonthlyPdf;
  // urlCpuPoolDailyCsv = customUrls.cpuPoolDailyCsv;
  // urlViosAllYearlyPng = customUrls.viosAllYearlyPng;

  constructor(
    private dataService: DataService, private cmService: CustomMessagesService, private elef: ElementRef
  ) { }

  ngAfterViewInit(): void {
    this.dataService.nodeSelected.promise.then(() => {
      Product.LPAR.module.myreadyFunc();
    });
  }

}
