<div style="width: 500px;">
  <h1 mat-dialog-title *ngIf="group.id">Edit group ID {{group.id}}</h1>
  <h1 mat-dialog-title *ngIf="!group.id">Create new group</h1>
  <form (ngSubmit)="saveGroup()" #f="ngForm">
    <div mat-dialog-content>
      <div classx="row">
        <div class="col-md-12 form-group">
          <label>Name</label>
          <input class="form-control" [(ngModel)]="group.name" required="required" placeholder="Enter unique group name"
            autofocus="autofocus" name="name" #name="ngModel"
            title="Name of group is in-sync with LPAR2RRD/STOR2RRD groups" maxlength="250">
        </div>
      </div>
      <div classx="row">
        <div class="col-md-12 form-group">
          <label>Description</label>
          <input class="form-control" [(ngModel)]="group.description" placeholder="Enter group's description"
            name="description" maxlength="1000">
        </div>
      </div>
      <div *ngIf="group.authorities && group.authorities.length>0">
        Users in this group have assigned authority roles:
        <span *ngFor="let authority of group.authorities">{{authority.name}} </span>
      </div>
    </div>
    <div mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button color="primary" type="submit" [disabled]="f.invalid || f.pristine || saving">Save</button>
    </div>
  </form>
</div>
