<div id="heatmaps">
  <span class="helpfloat">
    <a [href]="helpUrl" target="_blank" title="Help for heatmap"><i class="fas fa-question-circle fa-lg"></i></a>
  </span>
  <mat-tab-group [selectedIndex]="activeIndex" (selectedIndexChange)="updateTab($event)" [@.disabled]="true">
    <mat-tab *ngFor="let map of heatmaps; let i = index" label="{{map.first}}">
      <div [id]="'tab'+i" [hidden]="!loaded" class="stickyheaders-holder"></div>
      <xormon-loading-indicator *ngIf="!loaded"></xormon-loading-indicator>
    </mat-tab>
  </mat-tab-group>
</div>
