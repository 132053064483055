import { HttpClient } from '@angular/common/http';
import { EventEmitter } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ChildrenOutletContexts, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { environment } from '../environments/environment';
import { XormonAbout } from './model/about';
import { Deferred } from './model/deferred';
import { ReportTab, ReportTabPage, Tab, TabPage } from './model/tabs';
import { User } from './model/user';

export interface BooleanWrap {
  value: boolean;
}

class TRUE_WRAP implements BooleanWrap {
  readonly value = true;
}

class FALSE_WRAP implements BooleanWrap {
  readonly value = false;
}

interface BackendInfo {
  storEnabled: BooleanWrap;
  lparEnabled: BooleanWrap;
  lparLocal: boolean;
  storLocal: boolean;
}

export class Globals {
  static readonly RUNTIME_PATH = '/api/runtime';
  /**
   * Context path for production, http://localhost:8080 for dev
   */
  static readonly API_HOST: string = environment.production
    ? (window['_servlet_name'] || '')
    : 'http://localhost:8080';// 'https://localhost:8443'; // chrome 39 does not support multiple certificates on different ports
  static readonly PREFIX_JQUERY = Globals.API_HOST + Globals.RUNTIME_PATH;
  //TODO: replace htmlurlprefix with prefix_jquery, used with runtime path only
  static readonly htmlUrlPrefix = Globals.API_HOST;
  static readonly isProd = environment.production;

  static readonly AUTHENTICATED_KEY = 'isAuthenticated';
  static readonly PATH_LOGIN = '/login';

  static readonly OUTLET_CONTENT = 'content';
  static readonly OUTLET_PRIMARY = 'primary';

  static readonly DASHBOARD_INVALID_CHARACTER_MSG = 'Tab name cannot contain reserved character <strong>:</strong>';

  static readonly DBWRAPPER_SH = '/dbwrapper.sh';

  static readonly DEFAULT_DASHBOARD_NAME = 'Favorite';

  static readonly TITLE_SERVER = 'Server';
  static readonly TYPE_SERVER = Globals.TITLE_SERVER.toLowerCase();
  static readonly TITLE_STORAGE = 'Storage';
  static readonly TYPE_STORAGE = Globals.TITLE_STORAGE.toLowerCase();
  static readonly TYPE_STORAGE_UPPER = Globals.TYPE_STORAGE.toUpperCase();
  static readonly TYPE_NETWORK = 'network';
  static readonly TYPE_STORGR = 'storageGroups';
  static readonly TYPE_CONFIG = 'config';
  static readonly TITLE_SAN_TOPOLOGY = 'SAN Topology';
  static readonly TITLE_BNA_TOPOLOGY = 'BNA Topology';
  static readonly TITLE_HIST_REPORTS = 'Historical reports';
  static readonly TYPE_HIST_REP = 'historical_reports';
  static readonly TITLE_REPORT = 'Report';
  static readonly TYPE_REPORT = Globals.TITLE_REPORT.toLowerCase();
  static readonly TITLE_OTHER = 'Settings';
  static readonly TYPE_OTHER = Globals.TITLE_OTHER.toLowerCase();
  static readonly TYPE_GLOB_SEARCH = 'gs';
  static readonly TYPE_DASHBOARD = 'dashboard';
  static readonly TITLE_DASHBOARD = Globals.capitalize(Globals.TYPE_DASHBOARD);
  static readonly TYPE_MANAGE = 'management';
  static readonly TITLE_MANAGE = Globals.capitalize(Globals.TYPE_MANAGE);
  static readonly TYPE_GLOBAL = 'global';
  static readonly TITLE_GLOBAL = Globals.capitalize(Globals.TYPE_GLOBAL);
  static readonly TITLE_CUSTOM_GROUPS = "Custom Group";
  static readonly TYPE_CUSTOM_GROUPS = 'cg';
  static readonly TITLE_LAN = 'LAN';
  static readonly TYPE_LAN = Globals.TITLE_LAN.toLowerCase();
  static readonly TYPE_LAN_UPPER = Globals.TYPE_LAN.toUpperCase();
  static readonly TITLE_SAN = 'SAN';
  static readonly TYPE_SAN = Globals.TITLE_SAN.toLowerCase();
  static readonly TYPE_SAN_UPPER = Globals.TYPE_SAN.toUpperCase();
  static readonly TYPE_BNA = "BNA";
  static readonly TITLE_REPORTER = 'Reporter';
  static readonly TYPE_REPORTER = Globals.TITLE_REPORTER.toLowerCase();
  static readonly TYPE_TOTALS = 'totals';
  static readonly TYPES_STOR = [Globals.TYPE_STORAGE, Globals.TYPE_NETWORK];

  static readonly ROUTE_HOME = `/${Globals.TYPE_DASHBOARD}/${Globals.TYPE_GLOBAL}/${Globals.TYPE_GLOBAL}`;

  static readonly TITLE_FAVORITE = 'Favorite';

  static readonly FANCYTREE_SKIP = 'skip';

  static readonly HW_TYPE_ALL = 'ALL';
  //static readonly HW_TYPE_STORAGEGRID = 'STORAGEGRID';

  static readonly METRIC = 'metric';

  static readonly JSON_INDENT = 2;

  static readonly STATUS_CODE_DEMO = 418;
  static readonly STATUS_CODE_IP = 412;
  static readonly STATUS_CODE_UNAUTHORIZED = 401;

  static readonly GRAPH_RELOAD_INTERVAL_SHORT = 10 * 60 * 1000;
  static readonly GRAPH_RELOAD_INTERVAL_LONG = 60 * 60 * 1000;

  static readonly WS_RECONNECT_ATTEMPTS = 5;
  static readonly WS_NOTIFY_BACKEND_RECONFIGURED = JSON.stringify('BACKEND_RECONFIGURED');
  static readonly WS_NOTIFY_USER_UPDATED = JSON.stringify('USER_UPDATED');
  static readonly WS_NOTIFY_USER_LOGOUT = JSON.stringify('USER_LOGOUT');

  static readonly QUERY_PARAM_NAME_UPDATE_SESSION = 'updateSession';
  static readonly QUERY_PARAM_UPDATE_SESSION_FALSE = '&updateSession=false';

  static readonly HEADER_ERROR = 'X-XoruX-Error';

  static readonly DOM_PARSER = new DOMParser();

  static readonly LOGOUT_EMITTER = new EventEmitter();
  static readonly INIT_EMITTER = new EventEmitter();

  static readonly TRUE_WRAP: BooleanWrap = new TRUE_WRAP();
  static readonly FALSE_WRAP: BooleanWrap = new FALSE_WRAP();

  static readonly BACKEND_INFO: BackendInfo = {
    lparEnabled: { value: false },
    storEnabled: { value: false },
    lparLocal: null,
    storLocal: null
  };

  static readonly SERVER_PRESENT: BooleanWrap = { value: false };
  static readonly LAN_PRESENT: BooleanWrap = { value: false };
  static readonly SAN_PRESENT: BooleanWrap = { value: false };
  static readonly BNA_PRESENT: BooleanWrap = { value: false };
  static readonly STORAGE_PRESENT: BooleanWrap = { value: false };

  static ABOUT = {} as XormonAbout;

  static CGI_PATH: string;

  static http: HttpClient;

  static freeEdition = false;
  static treeWidth = 195;

  static readonly IS_USER_ADMIN: BooleanWrap = { value: false };

  /**
   * Promise when current user info loaded
   */
  static readonly userLoaded = new Deferred();
  static currentUser: User;

  static readonly TITLE_SEPARATOR = '|';
  static readonly NAV_SEPARATOR = '||';
  private static readonly SLASH_REPLACEMENT = '|';
  private static readonly SLASH_REGEXP = new RegExp('\\' + Globals.SLASH_REPLACEMENT, 'g');

  private static _CONTENT_OUTLET: RouterOutlet;
  private static _PRIMARY_OUTLET: RouterOutlet;

  private static lastUrl: string;
  private static lastTimestamp = new Date().getTime();
  private static lastComponent: Object;

  public static isSameNavigation(event: NavigationEnd, router?: Router) {
    const now = new Date().getTime();
    const cmp = router && Globals.getContentOutlet(router).component;
    if (Globals.lastUrl === event.url && cmp === Globals.lastComponent) {
      if (now < Globals.lastTimestamp + 500) {
        return true;
      }
    }
    Globals.lastTimestamp = now;
    Globals.lastUrl = event.url;
    Globals.lastComponent = cmp;
    return false;
  }

  /**
   * UI flag, false if first access or previous failed
   */
  public static get authenticated() {
    return localStorage.getItem(Globals.AUTHENTICATED_KEY) === 'true';
  }

  /**
   * UI flag to indicate login/logout state
   */
  public static set authenticated(value: boolean) {
    localStorage.setItem(Globals.AUTHENTICATED_KEY, value ? 'true' : 'false');
  }

  static slash2replacement(url: string) {
    return url.replace(/\//g, Globals.SLASH_REPLACEMENT);
  }

  /**
   * Transforms back to URL with slashes
   *
   * @param url internal URL like
   */
  static replacement2slash(url: string) {
    return url.replace(Globals.SLASH_REGEXP, '/');
  }

  static getTopNode(node: Fancytree.FancytreeNode) {
    while (!node.parent.isRootNode()) {
      node = node.parent;
    }
    return node;
  }

  private static getOutlet(router: Router, contextName: string) {
    const coc = (router as any).rootContexts as ChildrenOutletContexts;
    if (coc && coc.getContext) {
      let context = coc.getContext(contextName);
      if (context && context.outlet)
        return context.outlet;
    }
  }

  static clearContentOutlet() {
    Globals._CONTENT_OUTLET = null;
  }

  static getContentOutlet(router: Router) {
    if (!Globals._CONTENT_OUTLET) {
      Globals._CONTENT_OUTLET = Globals.getOutlet(router, Globals.OUTLET_CONTENT);
    }
    return Globals._CONTENT_OUTLET;
  }

  static hasComponent(outlet: RouterOutlet) {
    if (outlet && outlet.isActivated && outlet.component)
      return true;
    return false;
  }

  static clearPrimaryOutlet() {
    Globals._PRIMARY_OUTLET = null;
  }

  static getPrimaryOutlet(router: Router) {
    if (!Globals._PRIMARY_OUTLET) {
      Globals._PRIMARY_OUTLET = Globals.getOutlet(router, Globals.OUTLET_PRIMARY);
    }
    return Globals._PRIMARY_OUTLET;
  }

  static capitalize(title: string) {
    return title.charAt(0).toUpperCase() + title.substr(1);
  }

  static closeJqueryDialogs() {
    const jqui = $('div.ui-dialog:not([class*="ng-"]) .ui-dialog-content');
    if (jqui.length)
      jqui.dialog('destroy');
  }

  /**
   * Escapes <>"'
   */
  static escapeHtml(unsafe: string) {
    return unsafe
      //.replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;");
  }

  /**
   * Returns a map of querystring parameters
   *
   * Keys of type <fieldName>[] will automatically be added to an array
   *
   * @param url url with query string to parse
   * @param decode true if query values shall be decoded
   */
  static getParams(url: string, decode?: boolean) {
    let regex = /([^=&?]+)=([^&#]*)/g,
      params: Record<string, any> = {},
      parts: RegExpExecArray, key: string, value: string;

    while ((parts = regex.exec(url)) != null) {

      key = parts[1];
      value = parts[2];
      if (decode) {
        value = decodeURIComponent(value);
      }
      var isArray = /\[\]$/.test(key);

      if (isArray) {
        params[key] = params[key] || [];
        (params[key] as string[]).push(value);
      } else {
        params[key] = value;
      }
    }

    return params;
  }

  static domSanitizer: DomSanitizer;
  static sanitizePage(tabPage: TabPage) {
    if (!tabPage)
      return;
    for (const tab of tabPage.tps) {
      Globals.sanitizeTab(tab);
    }
    Globals.sanitizeTab(tabPage.tpCommon);
  }
  static sanitizeTab(tab: Tab) {
    if (!tab || !tab.htmls)
      return;
    tab.htmls = (tab.htmls as string[]).map(html => Globals.domSanitizer.bypassSecurityTrustHtml(html));
  }
  static sanitizeReportPage(reportTabPage: ReportTabPage) {
    if (!reportTabPage) {
      return;
    }
    if (reportTabPage.rtps) {
      for (const rtp of reportTabPage.rtps) {
        Globals.sanitizeReportTab(rtp);
      }
    }
    Globals.sanitizeReportTab(reportTabPage.rtpCommon);
  }
  static sanitizeReportTab(reportTab: ReportTab) {
    if (reportTab && reportTab.rows) {
      for (const row of reportTab.rows) {
        for (const cell of row.cells) {
          if (cell.html)
            cell.html = Globals.domSanitizer.bypassSecurityTrustHtml(cell.html as string);
        }
      }
    }
  }

  static initFloatingScroll(removeParentXscroll = false, selector: string | JQuery<any> = '.scroll-wrap') {
    let scrollContent = $(selector);
    if (!scrollContent.length)
      return;
    let scrollBody = this.getDefaultPageScrollElement();
    scrollBody.addClass('fl-scrolls-body');
    scrollContent.floatingScroll();
    if (removeParentXscroll) {
      scrollBody.css('overflow-x', 'hidden');
    }
  }

  static getDefaultPageScrollElement(selector = '.mat-tab-group .mat-tab-body-wrapper') {
    let scrollEl = $(selector);
    if (scrollEl.length === 0) {
      scrollEl = $('.scroll-flex');
      if (scrollEl.length === 0) {
        scrollEl = $('.content-main > div.container-fluid > .ng-star-inserted');
      }
    }
    return scrollEl;
  }

  static tableSorter(tabletosort, stickyHeader?: boolean, scrollSelector = '.mat-tab-group .mat-tab-body-wrapper', sortAll = false) {
    if (!tabletosort || tabletosort.length == 0 || $(tabletosort).length == 0) return;
    var sortList = {};
    var sortArray = [];
    if (!sortAll) {
      $(tabletosort)
        .find('th')
        .each(function (i, header) {
          const el = $(header);
          el.html(el.html().replace(/&nbsp;/g, ''));
          if (!$(header).hasClass('sortable')) {
            sortList[i] = {
              sorter: false
            };
          } else if (
            $(header)
              .text()
              .match(/^av.?g|^max/i)
          ) {
            sortList[i] = {
              sorter: 'digit'
            };
          }
        });
      if ($(tabletosort).data().sortby) {
        var sortcols = $(tabletosort)
          .data()
          .sortby.toString();
        var arr = sortcols.split(' ');
        $.each(arr, (index, value) => {
          if (value < 0) {
            sortArray.push([Math.abs(value) - 1, -1]);
          } else {
            sortArray.push([value - 1, 1]);
          }
        });
      }
    }
    const widgets = ['filter'];
    if (stickyHeader) {
      widgets.push('stickyHeaders');
    }

    $(tabletosort).each(function (index, el) {
      let scrollEl = $(el).closest(scrollSelector);
      if (scrollEl.length === 0) {
        scrollEl = Globals.getDefaultPageScrollElement();
      }
      let stickyHolder = $(el).closest('.stickyheaders-holder');
      $(this).tablesorter({
        sortInitialOrder: 'desc',
        widgets: widgets,
        sortList: sortArray,
        stringTo: 'bottom',
        headers: sortList,
        theme: 'ice',
        widgetOptions: {
          // css class name applied to the sticky header
          stickyHeaders: 'tablesorter-stickyHeader',
          stickyHeaders_appendTo: stickyHolder,
          stickyHeaders_attachTo: scrollEl,
          stickyHeaders_xScroll: $(this)
            .parents('.scroll-wrap')
            .get(0),
          stickyHeaders_yScroll: scrollEl,

          // filter_anyMatch options was removed in v2.15; it has been replaced by the filter_external option

          // If there are child rows in the table (rows with class name from "cssChildRow" option)
          // and this option is true and a match is found anywhere in the child row, then it will make that row
          // visible; default is false
          filter_childRows: false,

          // if true, filter child row content by column; filter_childRows must also be true
          filter_childByColumn: false,

          // if true, include matching child row siblings
          filter_childWithSibs: true,

          // if true, a filter will be added to the top of each table column;
          // disabled by using -> headers: { 1: { filter: false } } OR add class="filter-false"
          // if you set this to false, make sure you perform a search using the second method below
          filter_columnFilters: true,

          // if true, allows using "#:{query}" in AnyMatch searches (column:query; added v2.20.0)
          filter_columnAnyMatch: true,

          // extra css class name (string or array) added to the filter element (input or select)
          filter_cellFilter: '',

          // extra css class name(s) applied to the table row containing the filters & the inputs within that row
          // this option can either be a string (class applied to all filters) or an array (class applied to indexed filter)
          filter_cssFilter: '', // or []

          // add a default column filter type "~{query}" to make fuzzy searches default;
          // "{q1} AND {q2}" to make all searches use a logical AND.
          filter_defaultFilter: {},

          // filters to exclude, per column
          filter_excludeFilter: {},

          // jQuery selector (or object) pointing to an input to be used to match the contents of any column
          // please refer to the filter-any-match demo for limitations - new in v2.15
          filter_external: '',

          // class added to filtered rows (rows that are not showing); needed by pager plugin
          filter_filteredRow: 'filtered',

          // add custom filter elements to the filter row
          // see the filter formatter demos for more specifics
          filter_formatter: null,

          // add custom filter functions using this option
          // see the filter widget custom demo for more specifics on how to use this option
          filter_functions: null,

          // hide filter row when table is empty
          filter_hideEmpty: true,

          // if true, filters are collapsed initially, but can be revealed by hovering over the grey bar immediately
          // below the header row. Additionally, tabbing through the document will open the filter row when an input gets focus
          filter_hideFilters: true,

          // Set this option to false to make the searches case sensitive
          filter_ignoreCase: true,

          // if true, search column content while the user types (with a delay)
          filter_liveSearch: true,

          // a header with a select dropdown & this class name will only show available (visible) options within that drop down.
          filter_onlyAvail: 'filter-onlyAvail',

          // default placeholder text (overridden by any header "data-placeholder" setting)
          filter_placeholder: { search: '', select: '' },

          // jQuery selector string of an element used to reset the filters
          filter_reset: 'button.reset',

          // Reset filter input when the user presses escape - normalized across browsers
          filter_resetOnEsc: true,

          // Use the $.tablesorter.storage utility to save the most recent filters (default setting is false)
          filter_saveFilters: true,

          // Delay in milliseconds before the filter widget starts searching; This option prevents searching for
          // every character while typing and should make searching large tables faster.
          filter_searchDelay: 300,

          // allow searching through already filtered rows in special circumstances; will speed up searching in large tables if true
          filter_searchFiltered: true,

          // include a function to return an array of values to be added to the column filter select
          filter_selectSource: null,

          // if true, server-side filtering should be performed because client-side filtering will be disabled, but
          // the ui and events will still be used.
          filter_serversideFiltering: false,

          // Set this option to true to use the filter to find text from the start of the column
          // So typing in "a" will find "albert" but not "frank", both have a's; default is false
          filter_startsWith: false,

          // Filter using parsed content for ALL columns
          // be careful on using this on date columns as the date is parsed and stored as time in seconds
          filter_useParsedData: false,

          // data attribute in the header cell that contains the default filter value
          filter_defaultAttrib: 'data-value',

          // filter_selectSource array text left of the separator is added to the option value, right into the option text
          filter_selectSourceSeparator: '|'
        }
      });
    });
  }
}

declare var xormonPrefix;
xormonPrefix = Globals.API_HOST + Globals.RUNTIME_PATH;
