<div class="card">
  <div class="card-body">
    <div id="hiw"><a [href]="urls.lparReporter"><i class="fas fa-question-circle"></i></a></div>
    <div style='float: left; margin-right: 10px; outline: none' class="cggrpnames">
      <table id="reptable" class="cfgtree center table max-width">
        <thead>
          <tr>
            <th>Report name <button id="addrep" class="btn btn-sm btn-secondary btn-add float-right">New</button></th>
            <th>Edit</th>
            <th>Clone</th>
            <th>Run</th>
            <th>Delete</th>
            <th>Format</th>
            <th>Recurrence</th>
            <th>Next run</th>
            <th>Recipient group</th>
          </tr>
        </thead>
        <tbody>
        </tbody>
      </table>
    </div>
    <br style="clear: both">
    <div id="moderr" style="max-width: 80em; font-size: smaller; text-align: left; margin-left: 1em;"></div>
    <div id="freeinfo" style="max-width: 80em; font-size: smaller; text-align: left; margin-left: 1em; display: none">
      <p>You are using the <a [href]="urls.lparSupport">free edition</a> of LPAR2RRD.<br>
        It is restricted to only one report with an unlimited number of items at a time.<br>
        Automated reporting is disabled. You can run the report only manually.<br>
        In order to change the output format (PNG/PDF/CSV), delete your current report and create a new one.</p>
    </div>
    <div id="repexamples" style="max-width: 80em; font-size: small; text-align: left; margin-left: 1em; display: none">
      <h4 style="margin-bottom: 4px;">DEMO site: examples of above defined reports<br>You can generate it on the fly by
        selecting "Manual run"</h4>
      <table border="0">
        <tr>
          <td>LPARs CPU weekly pdf<a [href]="urls.lparCpuWeeklyPdf" target="_blank"><img src="css/images/pdf.png"></a>
          </td>
        </tr>
        <tr>
          <td>CPU POOLs weekly pdf<a [href]="urls.cpuPoolWeeklyPdf" target="_blank"><img src="css/images/pdf.png"></a>
          </td>
        </tr>
        <tr>
          <td>LPARs SAN monthly pdf<a [href]="urls.lparSanMonthlyPdf" target="_blank"><img src="css/images/pdf.png"></a>
          </td>
        </tr>
        <tr>
          <td>CPU POOLs daily CSV<a [href]="urls.cpuPoolDailyCsv" target="_blank"><img src="css/images/zip.png"></a>
          </td>
        </tr>
        <tr>
          <td>VIOS all yearly png<a [href]="urls.viosAllYearlyPng" target="_blank"><img src="css/images/zip.png"></a>
          </td>
        </tr>
      </table>
    </div>

    <br style="clear: both">
    <pre>
  <div id='aclfile' style='text-align: left; margin: auto; background: #fcfcfc; border: 1px solid #c0ccdf; border-radius: 10px; padding: 15px; display: none; overflow: auto'></div>
  </pre>

  </div>
</div>
