import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from 'src/app/model/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  static readonly PATH_API_CURRENT_USER = '/api/currentUser';

  constructor(private http: HttpClient) {

  }

  getCurrentUser() {
    return this.http.get<User>(UserService.PATH_API_CURRENT_USER);
  }
}
