import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppComponent } from 'src/app/app.component';
import { ResultDialogComponent } from './result-dialog/result-dialog.component';

@Component({
  selector: 'xormon-test-events',
  templateUrl: './test-events.component.html',
  styleUrls: ['./test-events.component.scss']
})
export class TestEventsComponent implements OnInit {

  testAlertError: string;

  constructor(private dialogRef: MatDialogRef<TestEventsComponent>, private http: HttpClient, private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public url: string) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
  }

  onCancel() {
    this.dialogRef.close();
  }

  onRun() {
    document.body.style.cursor = 'wait';
    AppComponent.block.stop();
    this.http.get(this.url, { responseType: 'text' }).subscribe(data => {
      document.body.style.cursor = 'default';
      this.dialogRef.close();
      AppComponent.block.stop();
      this.dialog.open(ResultDialogComponent, { data });
    }, error => {
      AppComponent.block.stop();
      document.body.style.cursor = 'default';
      this.testAlertError = 'Failed to execute alerting test!';
    });
  }

}
