<div id="globoverview" stylex="font-size: 0.8em" class="text-center mt-1">
  <label for="overviewsrc">Device: </label>
  <select id="overviewsrc"></select>
  <label for="overview_time" class="ml-1">Time range: </label>
  <select id="overview_time">
    <option value="">--- choose one ---</option>
    <optgroup label="Previous">
      <option value="prevHour">Previous hour</option>
      <option value="prevDay">Previous day</option>
      <option value="prevWeek">Previous week</option>
    </optgroup>
    <optgroup label="Last">
      <option value="lastHour">Last 60 minutes</option>
      <option value="lastDay">Last 24 hours</option>
      <option value="lastWeek">Last 7 days</option>
    </optgroup>
    <option value="custom"><b>Custom range</b></option>
  </select>

  <span class="d-inline-block">
    <label for="fromRange" class="customrange ml-2">From</label>
    <input type="text" id="fromRange" size="15" class="customrange">
    <label for="toRange" class="customrange ml-1">to</label>
    <input type="text" id="toRange" size="15" class="customrange">
    <button id="customoverview" class="customrange ml-1 btn px-2 py-1">Go</button>
  </span>

</div>
<xormon-overview-content></xormon-overview-content>