<div class="card">
  <div class="card-body">
    <div id="hiw"><a [href]="urls.storReporter"><i class="fas fa-question-circle"></i></a></div>
    <div style='float: left; margin-right: 10px; outline: none' class="cggrpnames">
    <table id="reptable" class="cfgtree center table max-width">
      <thead>
        <tr>
          <th>
            Report name
            <button id="addrep" class="btn btn-sm btn-secondary btn-add float-right">
              Add New
            </button>
          </th>
          <th>Edit</th>
          <th>Clone</th>
          <th>Run</th>
          <!-- 	          <th>Created</th> -->
          <!-- 	          <th>Last modified</th> -->
          <th>Delete</th>
          <th>Format</th>
          <th>Recurrence</th>
          <th>Next run</th>
          <th>Recipient group</th>
        </tr>
      </thead>
      <tbody></tbody>
    </table>
    </div>

    <br style="clear: both" />
    <div id="moderr" style="max-width: 80em; font-size: smaller; text-align: left; margin-left: 1em;"></div>
    <div id="freeinfo" style="max-width: 80em; font-size: smaller; text-align: left; margin-left: 1em; display: none">
      <p>
        You are using the
        <a [href]="urls.storSupport">free edition</a> of
        STOR2RRD.<br />
        It is restricted to only one report with an unlimited number of items at
        a time.<br />
        Automated reporting is disabled. You can run the report only
        manually.<br />
        In order to change the output format (PNG/PDF/CSV), delete your current
        report and create a new one.
      </p>
    </div>
    <div id="repexamples"
      style="max-width: 80em; font-size: smaller; text-align: left; margin-left: 1em; display: none">
      <h4 style="margin-bottom: 4px;">
        DEMO site: examples of above defined reports<br />You can generate it on
        the fly by selecting "Manual run"
      </h4>
      <table border="0">
        <tr>
          <td>
            VMAX all<a href="http://www.stor2rrd.com/userfiles/reports/VMAX_all.pdf" target="_blank"><img
                src="http://www.stor2rrd.com/pics/pdf.png" border="0" /></a>
          </td>
        </tr>
        <tr>
          <td>
            Storage POOL<a href="http://www.stor2rrd.com/userfiles/reports/Storage_POOL.pdf" target="_blank"><img
                src="http://www.stor2rrd.com/pics/pdf.png" border="0" /></a>
          </td>
        </tr>
        <tr>
          <td>
            SAN switches data in out<a href="http://www.stor2rrd.com/userfiles/reports/SAN_switches_data_in_out.pdf"
              target="_blank"><img src="http://www.stor2rrd.com/pics/pdf.png" border="0" /></a>
          </td>
        </tr>
        <tr>
          <td>
            Capacity storage<a href="http://www.stor2rrd.com/userfiles/reports/Capacity_storage-20180521_220934.pdf"
              target="_blank"><img src="http://www.stor2rrd.com/pics/pdf.png" border="0" /></a>
          </td>
        </tr>
        <tr>
          <td>
            Capacity total<a href="http://www.stor2rrd.com/userfiles/reports/all_storages_cap_total.csv"
              target="_blank"><img src="http://www.stor2rrd.com/pics/xls.png" border="0" /></a>
          </td>
        </tr>
        <tr>
          <td>
            EMC POOL IOPS<a href="http://www.stor2rrd.com/userfiles/reports/EMC_POOL_IOPS.zip" target="_blank"><img
                src="http://www.stor2rrd.com/pics/zip.jpg" border="0" /></a>
          </td>
        </tr>
        <tr>
          <td>
            EMC HOST CSV<a href="http://www.stor2rrd.com/userfiles/reports/EMC_HOST_CSV.zip" target="_blank"><img
                src="http://www.stor2rrd.com/pics/zip.jpg" border="0" /></a>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
