import { AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { Product } from 'src/app/model/product';

@Component({
  selector: 'xormon-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DevicesComponent implements OnInit, OnDestroy, AfterViewInit {

  private navigationSubscription = Subscription.EMPTY;

  constructor(private dataService: DataService, private router: Router) {
    this.navigationSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.dataService.nodeSelected.promise.then(() => {
          if (!this.navigationSubscription.closed) {
            if (Globals.isSameNavigation(e))
              return;
            this.init();
          }
        });
      });
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }

  ngOnInit() { }

  ngAfterViewInit(): void {

  }

  private init() {
    $('#devicetable').data('class', this.dataService.selectedNode.key.substring(3).toUpperCase());
    Product.STOR.module.myreadyFunc();
  }

}
