import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';
import { Product } from 'src/app/model/product';
import { TabPage } from 'src/app/model/tabs';

@Component({
  selector: 'xormon-health-status',
  templateUrl: './health-status.component.html',
  styleUrls: ['./health-status.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HealthStatusComponent implements OnInit, OnDestroy {

  tabPage: TabPage;
  hsOK = 0;
  hsNOK = 0;
  hsNA = 0;

  private navigationSubscription = Subscription.EMPTY;

  constructor(private dataService: DataService,
    private http: HttpClient, private log: LoggerService, private router: Router) {
    this.navigationSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.dataService.nodeSelected.promise.then(() => {
          if (!this.navigationSubscription.closed) {
            if (Globals.isSameNavigation(e))
              return;
            this.init();
          }
        });
      });
  }
  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }

  ngOnInit() {
  }

  private init() {
    this.tabPage = null;
    let nodeData = this.dataService.selectedNode.data;
    let href = (nodeData.url || nodeData.href) as string;
    this.http.post<TabPage>("/api/tabs", { url: href, type: "storage" }).subscribe(tp => {
      this.tabPage = tp;
      for (const tps of tp.tps) {
        if (tps.htmls) {
          tps.htmls = (tps.htmls as string[]).map(html => this.replaceHiw(html));
        }
      }
      Globals.sanitizePage(this.tabPage);
      setTimeout(() => {
        Globals.tableSorter('table.tablesorter, table#health_status, #mirroring, #snapshotting', true);
        Product.STOR.module.myreadyFunc();
        this.hsOK = $('.hsok').length;
        this.hsNOK = $('.hsnok').length;
        this.hsNA = $('.hsna').length;
        this.dataService.registerBacklinks();
      });
    }, error => this.log.error('Failed to get health status page!', error));
  }

  private replaceHiw(html: string) {
    let doc = Globals.DOM_PARSER.parseFromString(html, 'text/html');
    let hiw = doc.querySelector('#hiw');
    if (hiw) {
      let img = hiw.querySelector('img');
      if (img) {
        img.outerHTML = "<i class='fas fa-info-circle' title='How it works?' alt='How it works?'></i>";
      }
    }
    let docHtml = doc.body.innerHTML;
    return docHtml;
  }

  onTabSelected(indexObj: MatTabChangeEvent) {

    let href;
    if ((href = this.tabPage.tps[indexObj.index].url)) {
      this.http.get(Globals.RUNTIME_PATH + href, { responseType: 'text' }).subscribe(page => {

        page = this.replaceHiw(page);
        $('#tab' + indexObj.index).html(page);
        if (href.includes('?cmd=formmirr')) {
          Product.STOR.module.copyServices();
        } else if (href.includes('?cmd=formsnap')) {
          Product.STOR.module.snapshotting();
        } else {
          Globals.tableSorter('table.tablesorter', true);
        }
        this.dataService.registerBacklinks();
      }, error => this.log.error('Failed to get tab page!', error));

    } else {
      setTimeout(() => {
        Globals.tableSorter('table.tablesorter', true);
        this.dataService.registerBacklinks();
      });
    }
  }

}
