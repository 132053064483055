/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./heatmap-local.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i3 from "@angular/material/tabs";
import * as i4 from "../../../loading-indicator/loading-indicator.component.ngfactory";
import * as i5 from "../../../loading-indicator/loading-indicator.component";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/common";
import * as i8 from "./heatmap-local.component";
import * as i9 from "../storage-totals.service";
import * as i10 from "../../../data.service";
import * as i11 from "@angular/router";
import * as i12 from "../../../logger.service";
var styles_HeatmapLocalComponent = [i0.styles];
var RenderType_HeatmapLocalComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_HeatmapLocalComponent, data: {} });
export { RenderType_HeatmapLocalComponent as RenderType_HeatmapLocalComponent };
function View_HeatmapLocalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 7, "mat-tab", [], null, null, null, i2.View_MatTab_0, i2.RenderType_MatTab)), i1.ɵdid(1, 770048, [[1, 4]], 2, i3.MatTab, [i1.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i1.ɵqud(603979776, 2, { templateLabel: 0 }), i1.ɵqud(335544320, 3, { _explicitContent: 0 }), (_l()(), i1.ɵeld(4, 0, null, 0, 3, "div", [["class", "stickyheaders-holder center"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "spinner-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "xormon-loading-indicator", [], null, null, null, i4.View_LoadingIndicatorComponent_0, i4.RenderType_LoadingIndicatorComponent)), i1.ɵdid(7, 114688, null, 0, i5.LoadingIndicatorComponent, [], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.name, ""); _ck(_v, 1, 0, currVal_0); _ck(_v, 7, 0); }, function (_ck, _v) { var currVal_1 = i1.ɵinlineInterpolate(1, "", ("tab" + _v.context.index), ""); _ck(_v, 4, 0, currVal_1); }); }
function View_HeatmapLocalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "d-flex flex-grow-1 flex-column"], ["id", "heatmaps"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "mat-tab-group", [["class", "mat-tab-group"]], [[2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], [[null, "selectedTabChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedTabChange" === en)) {
        var pd_0 = (_co.onTabSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatTabGroup_0, i2.RenderType_MatTabGroup)), i1.ɵdid(2, 3325952, null, 1, i3.MatTabGroup, [i1.ElementRef, i1.ChangeDetectorRef, [2, i3.MAT_TABS_CONFIG], [2, i6.ANIMATION_MODULE_TYPE]], null, { selectedTabChange: "selectedTabChange" }), i1.ɵqud(603979776, 1, { _tabs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeatmapLocalComponent_2)), i1.ɵdid(5, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.tabs; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).dynamicHeight; var currVal_1 = (i1.ɵnov(_v, 2).headerPosition === "below"); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_HeatmapLocalComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["id", "heatPage"]], null, null, null, null, null))], null, null); }
export function View_HeatmapLocalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeatmapLocalComponent_1)), i1.ɵdid(1, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeatmapLocalComponent_3)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabs; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.tabs; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_HeatmapLocalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xormon-heatmap-local", [], null, null, null, View_HeatmapLocalComponent_0, RenderType_HeatmapLocalComponent)), i1.ɵdid(1, 180224, null, 0, i8.HeatmapLocalComponent, [i9.StorageTotalsService, i10.DataService, i11.Router, i12.LoggerService], null, null)], null, null); }
var HeatmapLocalComponentNgFactory = i1.ɵccf("xormon-heatmap-local", i8.HeatmapLocalComponent, View_HeatmapLocalComponent_Host_0, {}, {}, []);
export { HeatmapLocalComponentNgFactory as HeatmapLocalComponentNgFactory };
