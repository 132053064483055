import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';

@Component({
  selector: 'xormon-reporter-options',
  templateUrl: './reporter-options.component.html',
  styleUrls: ['./reporter-options.component.scss']
})
export class ReporterOptionsComponent implements OnDestroy {

  private navigationSubscription = Subscription.EMPTY;

  constructor(private router: Router, private dataService: DataService, private http: HttpClient,
    private log: LoggerService) {
    this.navigationSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.dataService.nodeSelected.promise.then(() => {
          if (!this.navigationSubscription.closed) {
            if (Globals.isSameNavigation(e))
              return;
            this.init();
          }
        });
      });
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }

  init() {
    this.http.get(Globals.RUNTIME_PATH + this.dataService.cgiPath + '/reporter.sh?cmd=form', { responseType: 'text' }).subscribe(data => {
      const html = Globals.DOM_PARSER.parseFromString(data, 'text/html');
      const cd = html.getElementById('csv_delim') as HTMLInputElement;
      $('#csv_delim').val(cd.value);
    }, error => this.log.error('Failed to get settings!', error));
    this.dataService.mainModule.myreadyFunc();
  }

}
