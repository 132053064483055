<div id="mapping" [hidden]="loading">
  <mat-tab-group [@.disabled]="true" (selectedTabChange)="tabChanged($event)" *ngIf="tabs">
    <mat-tab *ngFor="let tab of tabs" label="{{tab.key}}">
      <ng-template matTabContent>
        <div class="inner-html stickyheaders-holder" stylex="font-size: 0;"></div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>

  <div *ngIf="!tabs" class="stickyheaders-holder inner-html scroll-flex">
  </div>
</div>
<xormon-loading-indicator *ngIf="loading"></xormon-loading-indicator>
