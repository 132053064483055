/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./multipath.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./multipath.component";
import * as i3 from "../../../data.service";
import * as i4 from "@angular/common/http";
import * as i5 from "../../../logger.service";
var styles_MultipathComponent = [i0.styles];
var RenderType_MultipathComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MultipathComponent, data: {} });
export { RenderType_MultipathComponent as RenderType_MultipathComponent };
export function View_MultipathComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "stickyheaders-holder"], ["id", "multipath"]], null, null, null, null, null))], null, null); }
export function View_MultipathComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xormon-multipath", [], null, null, null, View_MultipathComponent_0, RenderType_MultipathComponent)), i1.ɵdid(1, 4308992, null, 0, i2.MultipathComponent, [i3.DataService, i4.HttpClient, i5.LoggerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MultipathComponentNgFactory = i1.ɵccf("xormon-multipath", i2.MultipathComponent, View_MultipathComponent_Host_0, {}, {}, []);
export { MultipathComponentNgFactory as MultipathComponentNgFactory };
