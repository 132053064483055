import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injector } from '@angular/core';
import { Router } from '@angular/router';
import { RxStompService } from '@stomp/ng2-stompjs';
import { MessageService } from 'primeng/api';
import { tap } from 'rxjs/operators';
import { UserService } from '../content/settings/user-profile/user.service';
import { DataService } from '../data.service';
import { Globals } from '../globals';
import { LoggerService } from '../logger.service';
import { ROUTES } from '../model/routes';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
import * as i3 from "../logger.service";
import * as i4 from "../data.service";
import * as i5 from "@stomp/ng2-stompjs";
import * as i6 from "primeng/components/common/messageservice";
export class AuthService {
    constructor(http, router, log, dataService, injector, rxStompService, msgService) {
        this.http = http;
        this.router = router;
        this.log = log;
        this.dataService = dataService;
        this.injector = injector;
        this.rxStompService = rxStompService;
        this.msgService = msgService;
        AuthService.INSTANCE = this;
        Globals.LOGOUT_EMITTER.subscribe(() => {
            this.logout();
        });
    }
    static getReturnUrl(url, location = false) {
        const idx = url.indexOf('(');
        let mainUrl = url.substr(0, idx > -1 ? idx : url.length);
        if (location) {
            const appIdx = mainUrl.indexOf('/app/');
            mainUrl = mainUrl.substr(appIdx + 4);
        }
        const q = url.indexOf('?');
        if (q > -1) {
            mainUrl = mainUrl + url.substr(q);
        }
        return mainUrl;
    }
    login(username, password) {
        const params = new HttpParams({
            fromObject: {
                username: username,
                password: password
            }
        });
        const headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
        });
        return this.http
            .post(Globals.PATH_LOGIN, params, { headers })
            .pipe(tap(resp => {
            this.msgService.clear();
            Globals.authenticated = true;
            this.dataService.init(resp);
            return resp;
        }, error => {
            Globals.authenticated = false;
            if (error instanceof HttpErrorResponse) {
                if (error.status === Globals.STATUS_CODE_UNAUTHORIZED && error.url.endsWith(UserService.PATH_API_CURRENT_USER)) {
                    let advice = 'Please check if cookies are enabled in browser.';
                    if (window.location.protocol === 'http:') {
                        advice = 'Please switch to https protocol if you have already accessed Xormon on secured connection.';
                    }
                    this.log.error('Authentication not propagated!<br>' + advice, error, true);
                    return;
                }
            }
            this.log.error('Login failed!', error, true);
        }));
    }
    logout(skipReturnUrl) {
        if (!this.dataService) {
            this.dataService = this.injector.get(DataService);
        }
        this.dataService.closeAllDialogs();
        Globals.authenticated = false;
        this.rxStompService.deactivate();
        // get rid of content outlet component
        this.parsedUrl = this.router.parseUrl(this.router.url);
        this.url = this.router.url;
        this.router.navigate([{ outlets: { content: ROUTES.DUMMY } }]).finally(() => {
            this.dataService.selectedType = null;
            this.http.get('/logout').subscribe(data => {
                this.afterLogout(skipReturnUrl);
            }, error => {
                this.afterLogout(skipReturnUrl);
            });
        });
    }
    naviateToLogin(returnUrl) {
        if (returnUrl) {
            this.router.navigate([
                {
                    outlets: {
                        content: null,
                        primary: ['login']
                    }
                }
            ], {
                queryParams: {
                    returnUrl: decodeURI(returnUrl)
                }
            });
        }
        else {
            this.router.navigate([
                {
                    outlets: {
                        content: null,
                        primary: ['login']
                    }
                }
            ]);
        }
        this.dataService.selectedType = null;
    }
    afterLogout(skipReturnUrl) {
        if (skipReturnUrl) {
            this.naviateToLogin();
            return;
        }
        this.naviateToLogin(this.parsedUrl.queryParams.returnUrl
            ? this.parsedUrl.queryParams.returnUrl
            : AuthService.getReturnUrl(this.url));
    }
}
AuthService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i3.LoggerService), i0.ɵɵinject(i4.DataService), i0.ɵɵinject(i0.INJECTOR), i0.ɵɵinject(i5.RxStompService), i0.ɵɵinject(i6.MessageService)); }, token: AuthService, providedIn: "root" });
