import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoggerService } from 'src/app/logger.service';

export class CertificateDetail {
  store: boolean;
  id: number;
  alias: string;
  issuedFor: CertificateSubject;
  issuedBy: CertificateSubject;
  validFrom: string;
  validTo: string;
  detail: string;
  md5: string;
  sha1: string;
  sha256: string;
}

export class CertificateSubject {
  CN: string;
  OU: string;
  O: string;
}

export enum CertificateResponseStatus {
  OK = 'OK',
  CERTIFICATE = 'CERTIFICATE',
  ACCESS = 'ACCESS'
}

export interface CertificateResponse {
  status: CertificateResponseStatus;
  certificates: CertificateDetail[];
}

@Component({
  selector: 'xormon-certificate-dialog',
  templateUrl: './certificate-dialog.component.html',
  styleUrls: ['./certificate-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CertificateDialogComponent implements OnInit {

  static readonly WIDTH = '80vw';

  activeCert: CertificateDetail;
  saving = false;

  constructor(private dialogRef: MatDialogRef<CertificateDialogComponent>, private http: HttpClient, private log: LoggerService,
    @Inject(MAT_DIALOG_DATA) public certificates: CertificateDetail[]) {
    this.activeCert = certificates[0];
  }

  ngOnInit() {
  }

  noneSelected() {
    return this.certificates.every(c => !c.store);
  }

  save() {
    this.saving = true;
    this.http.put('/admin/cert/store', this.certificates.filter(c => c.store).map(c => c.id)).subscribe(() => {
      this.log.info('Certificates stored successfully');
      this.dialogRef.close('OK');
    }, error => {
      this.log.error('Failed to store certificates!', error);
      this.dialogRef.close();
    });
  }

}
