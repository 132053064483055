import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { CustomMessagesService } from 'src/app/custom-messages.service';
import { DataService } from 'src/app/data.service';
import { StorWrap } from 'src/app/model/product';

@Component({
  selector: 'xormon-heatmap-configuration',
  templateUrl: './heatmap-configuration.component.html',
  styleUrls: ['./heatmap-configuration.component.scss']
})
export class HeatmapConfigurationComponent implements OnInit, AfterViewInit {

  urlHeatmap;

  constructor(private dataService: DataService, private cmService: CustomMessagesService, private elef: ElementRef) { }

  ngAfterViewInit(): void {
    this.dataService.nodeSelected.promise.then(() => {
      (this.dataService.mainModule as StorWrap).initHeatmapCfg();
    });
  }

  ngOnInit() {
    this.urlHeatmap = this.cmService.getMsg('heatmap', this.elef);
  }

}
