import { moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { LdapGroup } from 'src/app/model/group';
import { PromptComponent, PromptData } from 'src/app/prompt/prompt.component';
import { LoggerService } from '../../../logger.service';
import { CertificateDialogComponent, CertificateResponse, CertificateResponseStatus } from '../certificate-dialog/certificate-dialog.component';
import { STATUS } from '../runtime-setup/runtime-setup.component';
import { LdapUserComponent } from './ldap-user/ldap-user.component';

interface AdGroupCertResponse {
  cr: CertificateResponse;
  adGroups: LdapGroup[];
}

const SCHEME_LDAPS = 'ldaps://';
const SCHEME_LDAP = 'ldap://';

class LdapConfigs {
  enabled = false;
  configs: LdapConfig[];
}

class LdapConfig {
  id: number;
  name: string;
  sortOrder: number;
  enabled = true;
  dnAuthentication = false;
  baseDN = '';
  groupBaseDN = '';
  userBaseDN = '';
  groupObjectClass: string;
  groupUserAttribute: string;
  userObjectClass: string;
  userFilterAttribute: string;
  urls: LdapUrl[] = [new LdapUrl()];
  //groups: LdapGroup[] = [];
  groupsFresh: LdapGroup[] = [];
  untested = true;
  //scheme?: string;
}

class LdapUrl {
  id = new LdapUrlId();
  scheme = SCHEME_LDAPS;
}

class LdapUrlId {
  ldapConfigId: number;
  urlPort: string;
}

@Component({
  selector: 'xormon-ldap-setup',
  templateUrl: './ldap-setup.component.html',
  styleUrls: ['./ldap-setup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LdapSetupComponent implements OnInit, OnDestroy, AfterViewInit {


  readonly STATUS = STATUS;
  progressStatus = '';
  progressDialogShown = false;
  progressMessage = '';
  config = new LdapConfigs();
  activeCfgIndex = 0;
  //groups: Group[];
  //enabled = false;
  saveTestSubscription = Subscription.EMPTY;
  wsSubscription = Subscription.EMPTY;
  formSubscription = Subscription.EMPTY;
  @BlockUI('ldap') blockUI: NgBlockUI;

  @ViewChildren('ldapForm') ldapForms: QueryList<NgForm>;

  //@ViewChild('ldapForm', { static: false }) ldapForm: NgForm;

  ldapSchemas: SelectItem[] = [
    { label: 'ldap', value: SCHEME_LDAP },
    { label: 'ldaps', value: SCHEME_LDAPS }
  ];

  constructor(private http: HttpClient, private log: LoggerService,
    private dataService: DataService,
    private dialog: MatDialog) {
  }

  ngOnInit() {
    this.dataService.nodeSelected.promise.then(() => {
      this.http.get<LdapConfigs>('/admin/ldapConf/readConf').subscribe(data => {
        this.config = data;
        this.block();
        this.config.configs.forEach(lc => lc.untested = false);
        this.tabChanged()
      }, error => this.log.error('Failed to get stored LDAP configuration!', error));
      // this.http.get<Group[]>('/admin/userGroups/getAllAdGroups').subscribe(data => {
      //   this.groups = data;
      // }, error => this.log.error('Failed to get detected AD groups!', error));
    });
  }

  ngAfterViewInit(): void {
    //this.ldapForm.statusChanges.subscribe(result => console.log(result));
  }

  ngOnDestroy(): void {
    this.blockUI.unsubscribe();
  }

  tabChanged(event?) {
    setTimeout(() => {
      let idx = this.activeCfgIndex;
      if (event)
        idx = event.index;
      this.formSubscription.unsubscribe();
      if (this.ldapForms && this.ldapForms.length > idx)
        this.formSubscription = this.ldapForms.find((item, index) => index === idx).statusChanges.subscribe(result => {
          let cfg = this.config.configs[idx];
          if (cfg)
            cfg.untested = true;
        });
    });
  }

  block() {
    if (this.config.enabled) {
      this.blockUI.stop();
    } else {
      this.blockUI.start();
    }
    for (const cfg of this.config.configs) {
      cfg.untested = true;
    }
  }

  addCfg() {
    this.dialog.open<PromptComponent, PromptData, string>(PromptComponent,
      { data: { heading: 'Create new LDAP configuration', inputText: 'Name', invalidNames: this.config.configs.map(c => c.name) } }).afterClosed().subscribe(result => {
        if (result) {
          let name = result.trim();
          if (this.config.configs.some(lc => lc.name === name)) {
            this.log.warn(`Configuration name ${name} is already in use!`);
            return;
          }
          let lc = new LdapConfig();
          lc.name = name;
          //lc.scheme=this.SCHEME_LDAPS;
          //this.http.put<LdapConfig>('/admin/ldapConf/createConf', lc).subscribe(data => {
          this.config.configs.push(lc);
          //this.log.info(`Configuration ${name} created`);
          //}, error => this.log.error('Failed to create configuration ' + name, error));
        }
      });
  }

  removeCfg(event) {
    this.formSubscription.unsubscribe();
    this.config.configs.splice(event.index, 1);
    this.tabChanged();
  }

  moveCfgLeft(index: number, event: MouseEvent) {
    this.activeCfgIndex = 0;
    setTimeout(() => {
      moveItemInArray(this.config.configs, index, index - 1);
      //this.activeCfgIndex = 0;
      this.tabChanged();
    });

  }

  moveCfgRight(index: number, event: MouseEvent) {
    this.activeCfgIndex = 0;
    setTimeout(() => {
      moveItemInArray(this.config.configs, index, index + 1);
      //this.activeCfgIndex = 0;
      this.tabChanged();
    });

  }

  addUrl(lc: LdapConfig) {
    let url = new LdapUrl();
    if (lc.urls.length)
      url.scheme = lc.urls[0].scheme;
    lc.urls.push(url);
  }

  removeUrl(lc: LdapConfig, index: number) {
    lc.urls.splice(index, 1);
  }

  setScheme(urls: LdapUrl[], scheme: string) {
    for (const lu of urls) {
      lu.scheme = scheme;
    }
  }

  canSave() {
    return !this.config.enabled || !this.config.configs.some(c => c.untested);
  }

  cancelSaveTest() {
    this.progressDialogShown = false;
    this.wsSubscription.unsubscribe();
    this.saveTestSubscription.unsubscribe();
  }

  saveConfig() {
    this.startDialog('save');

    this.saveTestSubscription = this.http.put<AdGroupCertResponse>('/admin/ldapConf/updateConf', this.config)
      .subscribe(response => {
        this.handleSaveResponse(response);
      }, error => {
        this.log.error('LDAP configuration save failed!', error);
        if (error.status === Globals.STATUS_CODE_DEMO)
          this.cancelSaveTest();
      });
  }

  testConfig(lc: LdapConfig) {
    this.dialog.open(LdapUserComponent).afterClosed().subscribe(creds => {
      if (creds) {
        this.doTestConfig(creds, lc);
      }
    });
  }

  private startDialog(action: string) {
    this.saveTestSubscription.unsubscribe();
    this.wsSubscription.unsubscribe();
    this.progressStatus = STATUS.INPROGRESS;
    this.progressDialogShown = true;
    this.progressMessage = '';
    this.wsSubscription = this.dataService.rxStompService.watch('/topic/ldap').subscribe(msg => {

      if (msg.body && msg.body.length) {
        this.progressMessage += msg.body;
        this.progressMessage += "<br>";
      }

      if (msg.headers && msg.headers.status) {
        this.progressStatus = msg.headers.status;
        this.progressMessage += `<br><strong>LDAP setup ${action} result: ${msg.headers.status}</strong>`;
        if (msg.headers.advice) {
          this.progressMessage += '<br>' + msg.headers.advice;
        }
        this.wsSubscription.unsubscribe();
      }

      setTimeout(() => {
        let el = $('.ldapFeedback .ui-dialog-content');
        if (el.length)
          el.scrollTop(el.get(0).scrollHeight);
      });
    });
  }

  private handleSaveResponse(data: AdGroupCertResponse) {
    if (!data) {
      this.log.error('Failed to save LDAP configuration!');
      return;
    }
    if (data.cr) {
      if (data.cr.status === CertificateResponseStatus.ACCESS) {
        this.log.error('Failed to save LDAP configuration!');
      }
      else if (!data.cr.certificates || data.cr.certificates.length === 0) {
        this.log.error('Failed to obtain AD groups due to certificate error, but no certificate was obtained!');
      } else {
        this.cancelSaveTest();
        this.dialog.open(CertificateDialogComponent, { data: data.cr.certificates }).afterClosed().subscribe(status => {
          if (status) {
            this.saveConfig();
          }
        });
      }
    } else {
      this.log.info('LDAP configuration saved successfully');
    }
  }


  private doTestConfig(creds, lc: LdapConfig) {

    this.startDialog('test');

    this.saveTestSubscription = this.http.post<AdGroupCertResponse>('/admin/ldapConf/testConf', { config: lc, credentials: creds })
      .subscribe(response => {
        this.handleGroupRefreshResponse(response, creds, lc);
      }, error => {
        this.log.error('LDAP configuration test failed!', error);
        if (error.status === Globals.STATUS_CODE_DEMO)
          this.cancelSaveTest();
      });
  }

  private handleGroupRefreshResponse(data: AdGroupCertResponse, creds, lc: LdapConfig) {
    if (!data) {
      this.log.error('LDAP configuration test failed!');
      return;
    }
    if (data.cr) {
      if (data.cr.status === CertificateResponseStatus.ACCESS) {
        this.log.error('LDAP configuration test failed!');
      }
      else if (!data.cr.certificates || data.cr.certificates.length === 0) {
        this.log.error('Failed to obtain AD groups due to certificate error, but no certificate was obtained!');
      } else {
        this.cancelSaveTest();
        this.dialog.open(CertificateDialogComponent, { data: data.cr.certificates }).afterClosed().subscribe(status => {
          if (status) {
            this.doTestConfig(creds, lc);
          }
        });
      }
    } else {
      lc.groupsFresh = data.adGroups;
      lc.untested = false;
      this.log.info('LDAP configuration test success');
    }
  }

}
