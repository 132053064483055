import { HttpClient } from '@angular/common/http';
import { Product } from 'src/app/model/product';
import { Globals } from '../../../globals';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class StorageGroupingService {
    constructor(http) {
        this.http = http;
    }
    getUserGroups() {
        return this.http.get('/api/storageGroups');
    }
    getStorGrList() {
        return this.http.get('/admin/storageGroups/list/');
    }
    getStorages() {
        return this.http.get(Globals.RUNTIME_PATH + Product.STOR.pathCgi + '/dbwrapper.sh?procname=getObjects&class=STORAGE');
    }
    saveStorages(children) {
        return this.http.put('/admin/storageGroups/storageNames', children);
    }
}
StorageGroupingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function StorageGroupingService_Factory() { return new StorageGroupingService(i0.ɵɵinject(i1.HttpClient)); }, token: StorageGroupingService, providedIn: "root" });
