/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./overview-tabs.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../overview-content/overview-content.component.ngfactory";
import * as i3 from "../overview-content/overview-content.component";
import * as i4 from "../../../../loading-indicator/loading-indicator.component.ngfactory";
import * as i5 from "../../../../loading-indicator/loading-indicator.component";
import * as i6 from "../../../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i7 from "@angular/material/tabs";
import * as i8 from "@angular/common";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "./overview-tabs.component";
import * as i11 from "../../../../data.service";
import * as i12 from "@angular/router";
import * as i13 from "@angular/common/http";
import * as i14 from "../../../../logger.service";
var styles_OverviewTabsComponent = [i0.styles];
var RenderType_OverviewTabsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OverviewTabsComponent, data: {} });
export { RenderType_OverviewTabsComponent as RenderType_OverviewTabsComponent };
function View_OverviewTabsComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xormon-overview-content", [], null, null, null, i2.View_OverviewContentComponent_0, i2.RenderType_OverviewContentComponent)), i1.ɵdid(1, 4308992, null, 0, i3.OverviewContentComponent, [], { content: [0, "content"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.urlContent; _ck(_v, 1, 0, currVal_0); }, null); }
function View_OverviewTabsComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xormon-loading-indicator", [["class", "stickyheaders-holder"]], null, null, null, i4.View_LoadingIndicatorComponent_0, i4.RenderType_LoadingIndicatorComponent)), i1.ɵdid(1, 114688, null, 0, i5.LoadingIndicatorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_OverviewTabsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 7, "mat-tab", [], null, null, null, i6.View_MatTab_0, i6.RenderType_MatTab)), i1.ɵdid(1, 770048, [[1, 4]], 2, i7.MatTab, [i1.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i1.ɵqud(603979776, 2, { templateLabel: 0 }), i1.ɵqud(335544320, 3, { _explicitContent: 0 }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_OverviewTabsComponent_3)), i1.ɵdid(5, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_OverviewTabsComponent_4)), i1.ɵdid(7, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.name, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.urlContent; _ck(_v, 5, 0, currVal_1); var currVal_2 = !_v.context.$implicit.urlContent; _ck(_v, 7, 0, currVal_2); }, null); }
function View_OverviewTabsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "mat-tab-group", [["class", "mat-tab-group"]], [[24, "@.disabled", 0], [2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], [[null, "selectedTabChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedTabChange" === en)) {
        var pd_0 = (_co.onTabSelected($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_MatTabGroup_0, i6.RenderType_MatTabGroup)), i1.ɵdid(1, 3325952, null, 1, i7.MatTabGroup, [i1.ElementRef, i1.ChangeDetectorRef, [2, i7.MAT_TABS_CONFIG], [2, i9.ANIMATION_MODULE_TYPE]], null, { selectedTabChange: "selectedTabChange" }), i1.ɵqud(603979776, 1, { _tabs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverviewTabsComponent_2)), i1.ɵdid(4, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.tabPage.tps; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 1).dynamicHeight; var currVal_2 = (i1.ɵnov(_v, 1).headerPosition === "below"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_OverviewTabsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverviewTabsComponent_1)), i1.ɵdid(1, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.tabPage == null) ? null : _co.tabPage.tps) && _co.tabPage.tps.length); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_OverviewTabsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xormon-overview-tabs", [], null, null, null, View_OverviewTabsComponent_0, RenderType_OverviewTabsComponent)), i1.ɵdid(1, 245760, null, 0, i10.OverviewTabsComponent, [i11.DataService, i12.Router, i13.HttpClient, i14.LoggerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OverviewTabsComponentNgFactory = i1.ɵccf("xormon-overview-tabs", i10.OverviewTabsComponent, View_OverviewTabsComponent_Host_0, {}, {}, []);
export { OverviewTabsComponentNgFactory as OverviewTabsComponentNgFactory };
