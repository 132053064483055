<xormon-alerting-stor-header label="Logs"></xormon-alerting-stor-header>
<div class="row alerting-stor-logs stickyheaders-holder">
  <div class="col-xl-6" style="min-height: 100px;">
    <h5>Performance events</h5>
    <div id="perf_logs" class="scroll-wrap">
      <div class="spinner-wrap">
        <xormon-loading-indicator></xormon-loading-indicator>
      </div>
    </div>
  </div>
  <div class="col-xl-6">
    <h5>Hardware events</h5>
    <div id="hw_logs" class="scroll-wrap">
      <div class="spinner-wrap">
        <xormon-loading-indicator></xormon-loading-indicator>
      </div>
    </div>
  </div>
</div>
