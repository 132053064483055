<div id="devices" class="card">
    <div class="card-body">
        <div class="d-sm-flex inner-html mr-3 max-widthx">
            <div class="cggrpnames centerx">
                <table id="devicetable" class="cfgtree table" data-class="xxx">
                    <thead>
                        <tr>
                            <th>Alias <button class="btn btn-sm btn-secondary btn-add float-right ml-1"
                                    id="addnewhost">New</button></th>
                            <th>Edit</th>
                            <th>Clone</th>
                            <th>Delete</th>
                            <th>Device</th>
                            <th>Hostname / IP</th>
                            <th>Connection Test</th>
                        </tr>
                    </thead>
                    <tbody>
                    </tbody>
                </table>
            </div>
            <div id="cfgcomment" class="text-left" style="max-width: 80em;">
            </div>
        </div>
    </div>
</div>
