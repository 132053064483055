import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AlertingLparAlertsComponent } from './content/alerting/alerting-lpar-alerts/alerting-lpar-alerts.component';
import { AlertingLparEmailComponent } from './content/alerting/alerting-lpar-email/alerting-lpar-email.component';
import { AlertingLparLogsComponent } from './content/alerting/alerting-lpar-logs/alerting-lpar-logs.component';
import { AlertingLparOptionsComponent } from './content/alerting/alerting-lpar-options/alerting-lpar-options.component';
import { AlertingStorAlertsComponent } from './content/alerting/alerting-stor-alerts/alerting-stor-alerts.component';
import { AlertingStorEmailComponent } from './content/alerting/alerting-stor-email/alerting-stor-email.component';
import { AlertingStorHWComponent } from './content/alerting/alerting-stor-hw/alerting-stor-hw.component';
import { AlertingStorLogsComponent } from './content/alerting/alerting-stor-logs/alerting-stor-logs.component';
import { AlertingStorPerformanceComponent } from './content/alerting/alerting-stor-performance/alerting-stor-performance.component';
import { DashboardsComponent } from './content/dashboard/dashboards/dashboards.component';
import { HeatmapGlobalComponent } from './content/dashboard/heatmap-global/heatmap-global.component';
import { PortalComponent } from './content/dashboard/portal/portal.component';
import { EmptyComponent } from './content/empty/empty.component';
import { HistoricalReportComponent } from './content/historical-report/historical-report.component';
import { CpuWorkloadEstimatorComponent } from './content/lpar/cpu-workload-estimator/cpu-workload-estimator.component';
import { HeatmapLocalLparComponent } from './content/lpar/heatmap-local-lpar/heatmap-local-lpar.component';
import { MultipathComponent } from './content/lpar/multipath/multipath.component';
import { ResourceConfigAdvisorComponent } from './content/lpar/resource-config-advisor/resource-config-advisor.component';
import { RmcCheckComponent } from './content/lpar/rmc-check/rmc-check.component';
import { ReporterGroupsComponent } from './content/reporter/reporter-groups/reporter-groups.component';
import { ReporterHistoryComponent } from './content/reporter/reporter-history/reporter-history.component';
import { ReporterLparDefinitionsComponent } from './content/reporter/reporter-lpar-definitions/reporter-lpar-definitions.component';
import { ReporterOptionsComponent } from './content/reporter/reporter-options/reporter-options.component';
import { ReporterStorDefinitionsComponent } from './content/reporter/reporter-stor-definitions/reporter-stor-definitions.component';
import { AboutComponent } from './content/settings/about/about.component';
import { CustomGroupsComponent } from './content/settings/custom-groups/custom-groups.component';
import { DevicesComponent } from './content/settings/devices/devices.component';
import { GlobalSearchComponent } from './content/settings/global-search/global-search.component';
import { HeatmapConfigurationComponent } from './content/settings/heatmap-configuration/heatmap-configuration.component';
import { InfoComponent } from './content/settings/info/info.component';
import { LdapSetupComponent } from './content/settings/ldap-setup/ldap-setup.component';
import { LogsComponent } from './content/settings/logs/logs.component';
import { PlatformComponent } from './content/settings/platform/platform.component';
import { RuntimeSetupComponent } from './content/settings/runtime-setup/runtime-setup.component';
import { StorageGroupingComponent } from './content/settings/storage-grouping/storage-grouping.component';
import { UserGroupsListComponent } from './content/settings/user-groups-list/user-groups-list.component';
import { UserProfileComponent } from './content/settings/user-profile/user-profile.component';
import { UsersComponent } from './content/settings/users/users.component';
import { CapacityLocalComponent } from './content/stor/capacity-local/capacity-local.component';
import { CapacityComponent } from './content/stor/capacity/capacity.component';
import { HealthStatusComponent } from './content/stor/health-status/health-status.component';
import { HeatmapLocalComponent } from './content/stor/heatmap-local/heatmap-local.component';
import { HostsTopComponent } from './content/stor/hosts-top/hosts-top.component';
import { HwConfigurationComponent } from './content/stor/hw-configuration/hw-configuration.component';
import { MappingComponent } from './content/stor/mapping/mapping.component';
import { OverviewComponent } from './content/stor/overview/overview-all/overview.component';
import { OverviewTabsComponent } from './content/stor/overview/overview-tabs/overview-tabs.component';
import { SanTopologyComponent } from './content/stor/san-topology/san-topology.component';
import { VolumesTopComponent } from './content/stor/volumes-top/volumes-top.component';
import { TabsComponent } from './content/tabs/tabs.component';
import { Top10Component } from './content/top10/top10.component';
import { Globals } from './globals';
import { ROUTES } from './model/routes';
import { TreeComponent } from './tree/tree.component';
import { AuthGuard } from './_security/auth.guard';

const routes: Routes = [
  {
    path: `:${ROUTES.TYPE}/:${ROUTES.PARENT}/:${ROUTES.CHILD}`,
    component: TreeComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  {
    path: `:${ROUTES.TYPE}/:${ROUTES.PARENT}`,
    redirectTo: `/:${ROUTES.TYPE}/:${ROUTES.PARENT}/`,
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: Globals.ROUTE_HOME,
    pathMatch: 'full'
  },
  { path: 'login', children: [] },
  { path: 'reloading', children: [] },
  /********* COMMON CONTENT **********/
  {
    path: ROUTES.DUMMY,
    children: [],
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: ROUTES.EMPTY,
    component: EmptyComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: ROUTES.TABS,
    component: TabsComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: ROUTES.HISTORICAL_REPORTS,
    component: HistoricalReportComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: ROUTES.TOP10,
    component: Top10Component,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: ROUTES.HW_CONFIG,
    component: HwConfigurationComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  /*********** LPAR ************ */
  {
    path: 'cpu_workload_estimator',
    component: CpuWorkloadEstimatorComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: 'rmc_check',
    component: RmcCheckComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: 'resource_configuration_advisor',
    component: ResourceConfigAdvisorComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: 'heatmap_local_lpar',
    component: HeatmapLocalLparComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: ROUTES.MULTIPATH,
    component: MultipathComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  /******** STOR **************** */
  {
    path: 'heatmap_local',
    component: HeatmapLocalComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: 'capacity-local',
    component: CapacityLocalComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: ROUTES.OVERVIEW_TABS,
    component: OverviewTabsComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  /************* STOR TOTALS ********* */
  {
    path: ROUTES.VOLUMES_TOP,
    component: VolumesTopComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: 'capacity',
    component: CapacityComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: ROUTES.HOSTS_TOP,
    component: HostsTopComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: ROUTES.MAPPING,
    component: MappingComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: 'health_status',
    component: HealthStatusComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: 'graph_totals',
    component: TabsComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: ROUTES.SAN_TOPOLOGY,
    component: SanTopologyComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: 'overview',
    component: OverviewComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  /********** DASHBOARD ********* */
  {
    path: ROUTES.DASHBOARDS,
    component: DashboardsComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: ROUTES.PORTAL,
    component: PortalComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: ROUTES.HEATGLOB,
    component: HeatmapGlobalComponent,
    outlet: Globals.OUTLET_CONTENT,
  },
  /*********** SETTINGS LPAR ********* */
  {
    path: 'platform',
    component: PlatformComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  /*********** SETTINGS STOR ********* */
  {
    path: 'storageGrouping',
    component: StorageGroupingComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: 'heatmapCfg',
    component: HeatmapConfigurationComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: 'devices',
    component: DevicesComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  /*********** SETTINGS OTHER ******** */
  {
    path: ROUTES.GLOB_SEARCH,
    component: GlobalSearchComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: 'rtSetup',
    component: RuntimeSetupComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: 'ldapSetup',
    component: LdapSetupComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: 'info',
    component: InfoComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: 'userMngt',
    component: UsersComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: 'userGroups',
    component: UserGroupsListComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: 'profile',
    component: UserProfileComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: ROUTES.LOG,
    component: LogsComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: ROUTES.ABOUT,
    component: AboutComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  /********** CUSTOM GROUPS *********** */
  {
    path: 'cgConf',
    component: CustomGroupsComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  /********** ALERTING **************** */
  {
    path: 'alparLogs',
    component: AlertingLparLogsComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: 'alparAlerts',
    component: AlertingLparAlertsComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: 'alparEmails',
    component: AlertingLparEmailComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: 'alparOptions',
    component: AlertingLparOptionsComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: 'astorLogs',
    component: AlertingStorLogsComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: 'astorAlerts',
    component: AlertingStorAlertsComponent,
    outlet: Globals.OUTLET_CONTENT
  }
  , {
    path: 'astorEmails',
    component: AlertingStorEmailComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: 'astorPerf',
    component: AlertingStorPerformanceComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: 'astorHW',
    component: AlertingStorHWComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  /********** REPORTER ************* */
  {
    path: 'repHistory',
    component: ReporterHistoryComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: 'repOptions',
    component: ReporterOptionsComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: 'repGroups',
    component: ReporterGroupsComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: 'repLparDefs',
    component: ReporterLparDefinitionsComponent,
    outlet: Globals.OUTLET_CONTENT
  },
  {
    path: 'repStorDefs',
    component: ReporterStorDefinitionsComponent,
    outlet: Globals.OUTLET_CONTENT
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
