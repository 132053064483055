<div class="card flex-grow-1" id="cpuWorkloadEstimator">
  <div class="card-body d-flex flex-column">
    <h3>CPU Workload Estimator
      <a [href]="urlCpu" target="_blank" class="nowrap pull-right"><i class="fas fa-question-circle float-right"
          alt="How it works?" title="How it works?"></i></a>
    </h3>

    <div [hidden]="result">
      <form method="post" id="formestimator">
        <input type="hidden" name="start-hour" id="start-hour" size="3" value="12">
        <input type="hidden" name="start-day" id="start-day" size="3">
        <input type="hidden" name="start-mon" id="start-mon" size="3">
        <input type="hidden" name="start-yr" id="start-yr" size="3">
        <input type="hidden" name="end-hour" id="end-hour" size="3" value="12">
        <input type="hidden" name="end-day" id="end-day" size="3">
        <input type="hidden" name="end-mon" id="end-mon" size="3">
        <input type="hidden" name="end-yr" id="end-yr" size="3">
        <input type="hidden" name="type" id="type" size="3" value="m">

        <div class="d-flex align-items-center">
          <div class="form-group mr-2">
            <label for="from">From</label>
            <div class="form-time-wrap d-block">
              <input class="form-control" type="text" id="from" size="14" required>
            </div>
          </div>
          <div class="form-group mr-2">
            <label for="to">To</label>
            <div class="form-time-wrap d-block">
              <input class="form-control" type="text" id="to" size="14" required>
            </div>
          </div>
          <div class="form-group mr-2">
            <label>Graph resolution</label>
            <div class="d-flex align-items-center">
              <input class="form-control" type="number" name="HEIGHT" value="150" size="3" style="width: 6em;" required>
              <div class="mx-2">x</div>
              <input class="form-control" type="number" name="WIDTH" value="700" size="3" style="width: 6em;" required>
            </div>
          </div>
          <div class="form-group">
            <label>Y-axis</label>
            <select class="form-control" name="yaxis" required>
              <option value="r" selected>rPerf</option>
              <option value="w">CPW</option>
              <option value="c">CPU core</option>
            </select>
          </div>
        </div>
        <div class="d-xl-flex mb-2">
          <div class="flex-grow-1">
            <div class="card hover-border" style="min-width: 200px;">
              <div class="card-header card-header-light d-flex align-items-center">
                <h3 class="mr-1 mb-0">LPAR(s) for migration</h3>
                <div id="radiosrc" class="d-flex align-items-center">
                </div>
              </div>
              <div class="card-body p-3">
                <div class="d-flex align-items-center mb-3">
                  Server | LPAR
                  <div class="form-check">
                    <input type="checkbox" class="form-check-input allcheck" id="alllpars" name="alllpars"
                      value="outdata">
                    <label for="alllpars" class="form-check-label mb-0">All</label>
                  </div>
                  <div class="form-check ml-auto">
                    <input type="checkbox" class="form-check-input" name="srcfix" id="srcfix" value="1">
                    <label class="form-check-label mb-0" for="srcfix">
                      <abbr
                        title="FIX for Spectre and Meltdown (CVE-2017-5715, CVE-2017-5753 and CVE-2017-5754) or POWER9">Fix</abbr>
                    </label>
                  </div>
                </div>
                <div class="card-text" style="height: calc(100vh - 400px); overflow: auto;">
                  <input type="text" class="form-control mb-1" id="srvlparfilter" placeholder="Filter...">
                  <div id="lpartree" name="selLpars" class="seltree tree-menu">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-center h3 mb-0 p-1 rotate-xl">
            <div id="arrow" class=""></div>
          </div>
          <div class="flex-grow-1">
            <div class="card hover-border">
              <div class="card-header card-header-light d-flex align-items-center">
                <h3 class="mr-1 mb-0">Target server:</h3>
                <div id="radio" class="d-flex align-items-center">
                  <div class="form-check">
                    <input type="radio" name="newsrv" class="form-check-input" id="radio1" value="0">
                    <label for="radio1" class="form-check-label mb-0"
                      [ngClass]="{'underline':!blockExisting.isActive}">Existing</label>
                  </div>
                  <div class="form-check">
                    <input type="radio" name="newsrv" class="form-check-input" id="radio2" value="1" checked="checked">
                    <label for="radio2" class="form-check-label mb-0"
                      [ngClass]="{'underline':!blockNew.isActive}">New</label>
                  </div>
                </div>
              </div>
              <div class="card-group">
                <ng-template #blockTemplate>
                  <div class="block-ui-template text-center">
                    <i class="fas fa-times fa-7x" style="opacity: 0.5; margin-top: 1em;"></i>
                  </div>
                </ng-template>
                <div class="card border-left-0 border-top-0 border-bottom-0 col-4"
                  *blockUI="'existingServer'; template: blockTemplate;">
                  <div class="card-body p-3">
                    <div class="d-flex align-items-center mb-3">
                      Server | Pool
                      <div class="form-check ml-auto">
                        <input type="checkbox" class="form-check-input" name="dstfix" id="dstfix" value="1">
                        <label class="form-check-label mb-0" for="dstfix">
                          <abbr
                            title="Check this if fixes for Spectre and Meltdown (CVE-2017-5715, CVE-2017-5753 and CVE-2017-5754) are applied or it is POWER9">Fix</abbr>
                        </label>
                      </div>
                    </div>
                    <div id="pooltree" name="selPool" class="seltree  tree-menu card-text"
                      style="height: calc(100vh - 400px); overflow: auto;"></div>
                  </div>
                </div>
                <div class="card border-0 col-8" *blockUI="'newServer'; template: blockTemplate;">
                  <div class="card-body p-3">
                    <div class="mb-3">
                      New server type
                    </div>
                    <div id="drtree" class="seltree tree-menu card-text"
                      style="height: calc(100vh - 400px); overflow: auto;">
                      <table id="treetable">
                        <colgroup>
                          <col width="120px">
                          <col width="50px">
                          <col width="50px">
                          <col width="60px">
                          <col width="44px">
                        </colgroup>
                        <thead>
                          <tr>
                            <th>&nbsp;Platform/Model</th>
                            <th>Type</th>
                            <th>CPU</th>
                            <th>GHz</th>
                            <th><abbr
                                title="Check this if fixes for Spectre and Meltdown (CVE-2017-5715, CVE-2017-5753 and CVE-2017-5754) are applied or it is POWER9">Fix</abbr>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col text-right">
            <input type="button" class="btn btn-primary px-5" name="Report" value="Generate Report"
              alt="Generate Report" id="formestimatorSubmit">
          </div>
        </div>
      </form>
    </div>

    <div [hidden]="!result" class="result">
      <!-- <xormon-tabs [data]=result></xormon-tabs> -->
      <mat-tab-group [@.disabled]="true" class="mat-tab-graphs">
        <mat-tab *ngFor="let tab of result" label="{{tab.name}}" class="graph-wrapper">
          <ng-template matTabContent>
            <table>
              <tbody>
                <tr *ngFor="let row of tab.rows">
                  <td *ngFor="let cell of row.cells">
                    <div *ngIf="cell.html" [innerHTML]="cell.html"></div>
                    <xormon-graph *ngIf="cell.imgSrc" [graph]="cell"></xormon-graph>
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-template>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>
