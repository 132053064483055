import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';
import { Product } from 'src/app/model/product';
import { ReportTabPage } from 'src/app/model/tabs';

@Component({
  selector: 'xormon-resource-config-advisor',
  templateUrl: './resource-config-advisor.component.html',
  styleUrls: ['./resource-config-advisor.component.scss']
})
export class ResourceConfigAdvisorComponent implements OnInit {

  rca: ReportTabPage;

  constructor(private http: HttpClient, private dataService: DataService, private log: LoggerService) { }

  ngOnInit() {
    this.rca = null;
    this.dataService.nodeSelected.promise.then(() => {
      this.http.post<ReportTabPage>('/api/reportTabs', this.dataService.selectedNode.data.href).subscribe(data => {
        this.rca = data;
        Globals.sanitizeReportPage(this.rca);
        setTimeout(() => {
          this.customizePage();
        });
      }, error => this.log.error('Failed to get resource config data!', error));
    });
  }

  tabSelected(event: MatTabChangeEvent) {
    this.customizePage();
  }

  private customizePage() {
    const self = this;
    $('.rca font > a:not(.nowrap):contains("How it works")').replaceWith(function () {
      var url = (this as HTMLAnchorElement).href;
      return "<div id='hiw'><a href='" + url + "' target='_blank'><i class='fas fa-fw fa-info-circle' title='How it works?' alt='How it works?'></i></a></div>";
    });
    $("div.csvexport:not(:has(i))").each(function () {
      let el = $(this);
      var title = el.text();
      el.attr("title", title);
      el.html('<i class="fas fa-fw fa-file-csv"></i>');
      let parentLink = el.parent('a');
      parentLink.attr('href', Globals.API_HOST + Globals.RUNTIME_PATH +
        (self.dataService.cgiPath === Product.LPAR.pathCgi ? Product.LPAR.path : Product.STOR.path) + parentLink.attr('href'));
    });
    Globals.tableSorter($('table.tablesorter'));
    this.dataService.registerBacklinks();
  }

}
