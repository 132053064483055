import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { DataService } from '../data.service';
import { Globals } from '../globals';
import { LoggerService } from '../logger.service';
import { AuthService } from '../_security/auth.service';

@Component({
  selector: 'xormon-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  subscription = Subscription.EMPTY;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private log: LoggerService,
    private dataService: DataService,
    private cdr: ChangeDetectorRef
  ) {
    Globals.clearContentOutlet();
    Globals.clearPrimaryOutlet();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    if (Globals.authenticated) {
      this.authService.logout();
    } else if (!location.href.includes(Globals.PATH_LOGIN)) {
      this.authService.naviateToLogin(AuthService.getReturnUrl(location.href, true));
    }
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.subscription = this.route.queryParams.subscribe(params => this.returnUrl =
      !params.returnUrl || params.returnUrl === Globals.PATH_LOGIN ? '/' : params.returnUrl);

  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  get version() {
    return Globals.ABOUT.xormonVersion;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.cdr.detectChanges();
      return;
    }

    this.loading = true;
    this.authService.login(this.f.username.value, this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          Promise.all([this.dataService.backendLoaded.promise, Globals.userLoaded.promise]).then(() => {
            if (Globals.currentUser.admin && !Globals.BACKEND_INFO.lparEnabled.value && !Globals.BACKEND_INFO.storEnabled.value) {
              this.router.navigate(['/', Globals.TYPE_CONFIG, Globals.TYPE_OTHER, 'runtime'], { replaceUrl: true }).finally(() => {
                this.dataService.updateXormonAbout();
                this.loading = false;
                this.submitted = false;
              });
            } else {
              this.router.navigateByUrl(this.returnUrl, { replaceUrl: true }).catch(error => {
                this.router.navigate([Globals.ROUTE_HOME], { replaceUrl: true }).finally(() => {
                  this.dataService.updateXormonAbout();
                });
              }).then(() => this.dataService.updateXormonAbout())
                .finally(() => {
                  this.loading = false;
                  this.submitted = false;
                });
            }
          }, reason => {
            this.log.error('Failed to get backend/user info!', reason);
            this.loading = false;
            this.submitted = false;
          });

        },
        error => {
          // error handled in service
          this.loading = false;
        });
  }

}
