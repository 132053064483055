<h1 mat-dialog-title>Add graph to dashboard</h1>

<div mat-dialog-content style="width: 500px; height: 200px;">

  <table class="boot table table-borderless">
    <thead>
      <tr>
        <th style="width: 50%;">Dashboard</th>
        <th style="width: 50%;">Group</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let entry of entries; let i=index">
        <td>
          <select [id]="'tab'+i" class="select2 tab">
            <option *ngFor="let tab of tabs" [value]="tab.id" [disabled]="entry.selectedTab!==tab && usedTabs.has(tab)"
              [selected]="entry.selectedTab===tab">
              {{tab.text}}</option>
          </select>
        </td>
        <td>
          <select [id]="'group'+i" class="select2 group" [disabled]="!entry.selectedTab">
            <option *ngFor="let group of entry.selectedTab?.groups; let j=index" [value]="group.id"
              [selected]="entry.selectedGroup===group" [disabled]="entry.disabledGroups[j]">
              {{group.text}}</option>
          </select>
        </td>
        <td>
          <button class="btn btn-sm btn-outline-danger" (click)="removeEntry(i)"
            title="Remove graph from this dashboard">
            <i class="fas fa-times"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>

  <button class="btn btn-outline-success" (click)="addEntry()" title="Add this graph to another dashboard">
    <i class="fas fa-plus"></i>
  </button>
</div>
<div mat-dialog-actions>
  <form #f="ngForm" class="flex-grow-1">
    Width: <input type="number" min="200" [min]="minWidth" title="Width of graph for new dashboards" step="51"
      [(ngModel)]="width" name="width" style="width: 60px; margin-right: 5px;">
    Height: <input type="number" min="100" title="Height of graph for new dashboards" step="31" [(ngModel)]="height"
      name="height" style="width: 60px;">
  </form>
  <button mat-raised-button mat-dialog-close>Cancel</button>
  <button id="saveBtn" mat-raised-button color="primary" (click)="onSave()" cdkFocusInitial
    [disabled]="f.invalid || !allGroupsSelected() || saving">Save</button>
</div>
