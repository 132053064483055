import { HttpHeaders } from '@angular/common/http';
import { ElementRef, EventEmitter, Input, NgZone, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from '../data.service';
import { Globals } from '../globals';
import { Graph } from '../model/graph';

export abstract class AbstractGraph {

  static useFullTitle = false;
  static externalData = false;
  static SLIDER_TIMESTAMP = 0;

  @ViewChild('element', { static: true }) element: ElementRef;

  @Output()
  groupZoomStart = new EventEmitter<void>();

  @Output()
  groupZoomEnd = new EventEmitter<any>();

  @Output()
  loaded = new EventEmitter<void>();

  @Input()
  graph: Graph;

  get error() {
    return this.graph.error;
  }

  constructor(protected dataService: DataService, protected zone: NgZone) {
  }

  abstract load(url: string): Observable<any>;

  parseHeaderProperties(headers: HttpHeaders) {
    const header = headers.get('X-RRDGraph-Properties');
    if (header) {
      let h: string[];
      let dataSrc = Globals.getParams(this.graph.imgSrc);
      this.graph.period = this.dataService.mainModule.getImagePeriod(dataSrc);
      if (this.dataService.isLpar()) {
        h = this.splitWithTail(header, ':', 6);
        if (!this.graph.zoomed) {
          if (h && h[6]) {
            this.graph.fullTitle = Base64.decode(h[6]);
          }
          else {
            this.graph.fullTitle = this.dataService.mainModule.getImageTitle(dataSrc) + this.graph.period;
          }
          this.graph.title = (this.graph.regrouped || AbstractGraph.externalData || AbstractGraph.useFullTitle) ?
            this.graph.fullTitle : this.graph.period;
        }
      } else {
        h = header.split(':');
        if (!this.graph.zoomed) {
          this.graph.fullTitle = this.dataService.mainModule.getImageTitle(dataSrc) + this.graph.period;
          this.graph.title = (this.graph.regrouped || AbstractGraph.externalData || AbstractGraph.useFullTitle) ?
            this.graph.fullTitle : this.graph.period;
        }
      }
      this.graph.origTitle = $('<span>' + this.graph.fullTitle + '</span>').text();
      if (this.graph.zoom && h[2] && h[3]) {
        const frame = $(this.element.nativeElement).find('div.zoom');
        if (frame.length) {
          $(frame).imgAreaSelect({
            remove: true
          });
          $(frame).data('graph_start', h[4]);
          $(frame).data('graph_end', h[5]);
          $(frame).css('left', h[0] + 'px');
          $(frame).css('top', h[1] + 'px');
          $(frame).css('width', h[2] + 'px');
          $(frame).css('height', h[3] + 'px');

          this.graph.width = parseInt(h[2]);
          this.betterZoom(frame.attr('id'), h[2], h[3]);

          frame.show();
        }
      } else {
        this.graph.zoom = false;
      }
    }
  }

  selectEnd(selection, sync, callback?) {
    if (sync || selection.width) {
      if (this.error)
        return;
      this.graph.zoomed = true;
      let zoomEl = $(this.element.nativeElement).find('div.zoom');
      var imgEl = $(this.element.nativeElement).find("img"),
        selFrom: Date, selTo;
      if (sync) {
        selFrom = new Date($(sync).data().graph_start * 1000);
        selTo = new Date($(sync).data().graph_end * 1000);
      } else {
        var from = new Date(zoomEl.data().graph_start * 1000);
        var to = new Date(zoomEl.data().graph_end * 1000);
        var timePerPixel = (to.getTime() - from.getTime()) / zoomEl.width();
        selFrom = new Date(from.getTime() + selection.x1 * timePerPixel);
        selTo = new Date(selFrom.getTime() + selection.width * timePerPixel);
      }
      var zoomTitle = "<b>" + $.format.date(selFrom, 'H:mm') + "</b> " + $.format.date(selFrom, 'd-MMM-yyyy') +
        " &xrarr; " + "<b>" + $.format.date(selTo, 'H:mm') + "</b> " + $.format.date(selTo, 'd-MMM-yyyy');
      if (imgEl.width() > 600) {
        zoomTitle = imgEl.data("title") + "&nbsp;&nbsp;" + zoomTitle;
      }
      var alink = zoomEl.closest("a.detail");

      this.graph.title = zoomTitle;
      var imgSrc = ($(imgEl).data("revert_url")) ? $(imgEl).data("revert_url") : $(imgEl).data("src");
      var ahref = $(alink).attr('href');

      $(alink).closest('div.graph').find(".reset-zoom").removeClass('invisible');
      if (!$(alink).data("basehref")) {
        $(alink).data("basehref", $(alink).attr("href"));
      }
      var nonePos = imgSrc.indexOf('?');
      var params = Globals.getParams(imgSrc, true);
      var paramsDetail = Globals.getParams($(alink).data("basehref"), true);

      delete params.none;
      delete paramsDetail.none;
      var zUrl = imgSrc.slice(0, nonePos);
      var zHref = ahref.slice(0, ahref.indexOf('?'));
      params.sunix = (selFrom.getTime() / 1000) | 0;
      params.eunix = (selTo.getTime() / 1000) | 0;
      paramsDetail.sunix = params.sunix;
      paramsDetail.eunix = params.eunix;
      var zoomedUrl = zUrl + "?" + jQuery.param(params).replace(/\+/g, "%20");
      var zoomedUrlDetail = zHref + "?" + jQuery.param(paramsDetail).replace(/\+/g, "%20");
      $(alink).attr("href", zoomedUrlDetail);

      this.zone.run(() => {
        this.load(zoomedUrl).subscribe(() => {
          if (callback) callback();
        }, error => this.graph.error = JSON.stringify(error));
      });
    }
  }

  betterZoom(zoomID: string, width, height) {
    $("#" + zoomID).imgAreaSelect({
      handles: false,
      maxHeight: height,
      minHeight: height,
      maxWidth: width,
      fadeSpeed: 500,
      autoHide: true,
      onSelectStart: (img, selection) => {
        if (this.isRegrouped()) {
          this.groupZoomStart.emit();
        }
      },
      onSelectEnd: (zoomDiv, selection) => {

        this.selectEnd(selection, null, () => {
          if (this.isRegrouped()) {
            this.zone.run(() => this.groupZoomEnd.emit(zoomDiv));
          }
        });
      }
    });
  }

  isRegrouped() {
    return $(this.element.nativeElement).closest('.tabgraphs').hasClass('regrouped');
  }

  static getNone() {
    return Math.floor(new Date().getTime() / 1000);
  }

  fancyBox(nativeElement) {
    const self = this;
    $(nativeElement)
      .find('a.detail')
      .on('click', event => {
        if (Date.now() < AbstractGraph.SLIDER_TIMESTAMP + 100) {
          event.stopImmediatePropagation();
          event.preventDefault();
          return false;
        }
        return true;
      })
      .colorbox({
        href: function () {
          let href = $(this).attr('href');
          if (self.dataService.selectedType === Globals.TYPE_NETWORK)
            return href.replace('detail=8', 'detail=1');
          return href;
        },
        photo: true,
        live: false,
        speedIn: 300,
        fadeOut: 100,
        scalePhotos: true, // images won't be scaled to fit to browser's height
        maxWidth: '95%',
        initialWidth: 1200,
        opacity: 0.4,
        hideOnContentClick: true,
        title: '',
        onComplete: function () {
          $('.cboxPhoto').off().on('click', $.colorbox.close);
          if ($(this).text() === 'This image failed to load') {
            self.dataService.ping();
          }
        }
      });
  }

  private splitWithTail(str: string, delim: string, count: number) {
    var parts = str.split(delim);
    var tail = parts.slice(count).join(delim);
    var result = parts.slice(0, count);
    result.push(tail);
    return result;
  }

}
