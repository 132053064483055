/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alerting-lpar-email.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../alerting-email/alerting-email.component.ngfactory";
import * as i3 from "../alerting-email/alerting-email.component";
import * as i4 from "../../../logger.service";
import * as i5 from "../alerting-lpar-header/alerting-lpar-header.component.ngfactory";
import * as i6 from "../alerting-lpar-header/alerting-lpar-header.component";
import * as i7 from "../../../custom-messages.service";
import * as i8 from "@angular/common";
import * as i9 from "./alerting-lpar-email.component";
import * as i10 from "../../../data.service";
import * as i11 from "../alerting.service";
var styles_AlertingLparEmailComponent = [i0.styles];
var RenderType_AlertingLparEmailComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertingLparEmailComponent, data: {} });
export { RenderType_AlertingLparEmailComponent as RenderType_AlertingLparEmailComponent };
function View_AlertingLparEmailComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xormon-alerting-email", [], null, null, null, i2.View_AlertingEmailComponent_0, i2.RenderType_AlertingEmailComponent)), i1.ɵdid(1, 4308992, null, 0, i3.AlertingEmailComponent, [i4.LoggerService], { ready: [0, "ready"], form: [1, "form"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.readyFce; var currVal_1 = _co.formFce; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_AlertingLparEmailComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "xormon-alerting-lpar-header", [["label", "Emails"]], null, null, null, i5.View_AlertingLparHeaderComponent_0, i5.RenderType_AlertingLparHeaderComponent)), i1.ɵdid(3, 114688, null, 0, i6.AlertingLparHeaderComponent, [i1.ElementRef, i7.CustomMessagesService], { label: [0, "label"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["id", "email_wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertingLparEmailComponent_1)), i1.ɵdid(6, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "Emails"; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.readyFce; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_AlertingLparEmailComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xormon-alerting-lpar-email", [], null, null, null, View_AlertingLparEmailComponent_0, RenderType_AlertingLparEmailComponent)), i1.ɵdid(1, 114688, null, 0, i9.AlertingLparEmailComponent, [i10.DataService, i11.AlertingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertingLparEmailComponentNgFactory = i1.ɵccf("xormon-alerting-lpar-email", i9.AlertingLparEmailComponent, View_AlertingLparEmailComponent_Host_0, {}, {}, []);
export { AlertingLparEmailComponentNgFactory as AlertingLparEmailComponentNgFactory };
