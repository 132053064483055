import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';
import { Product } from 'src/app/model/product';
import wlabel from 'src/app/wlabel.json';
import { AlertingService } from '../alerting.service';

@Component({
  selector: 'xormon-alerting-lpar-alerts',
  templateUrl: './alerting-lpar-alerts.component.html',
  styleUrls: ['./alerting-lpar-alerts.component.scss']
})
export class AlertingLparAlertsComponent implements OnInit, AfterViewInit {

  urls = wlabel;
  free = false;

  constructor(
    private alertingService: AlertingService,
    private dataService: DataService, private log: LoggerService
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.dataService.nodeSelected.promise.then(() => {
      this.free = !Product.LPAR.info.isEnterpriseEdition();
      this.alertingService.getLparForm().subscribe(page => {
        const html = Globals.DOM_PARSER.parseFromString(page, 'text/html');
        $('#optform').html(html.querySelector('#optform').innerHTML);
      }, error => this.log.error('Failed to get form!', error));
      Product.LPAR.module.myreadyFunc();
    }, reason => console.warn(reason));
  }

  toggleNodes() {
    $.ui.fancytree
      .getTree('#alrttree')
      .getRootNode()
      .visit(function (node) {
        node.toggleExpanded();
      });
  }


}
