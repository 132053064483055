import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';
import { Deferred } from 'src/app/model/deferred';
import { Product } from 'src/app/model/product';
import { StorageTotalsService } from '../storage-totals.service';

interface CsvItem {
  title: string;
  href: string
}

@Component({
  selector: 'xormon-hw-configuration',
  templateUrl: './hw-configuration.component.html',
  styleUrls: ['./hw-configuration.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HwConfigurationComponent implements OnInit, OnDestroy, AfterViewInit {

  static readonly EXCLUDED_BACKLINK_CLASSES = ['server_quick', 'server_overview', 'server_detail', 'server_interface'];
  tabsTps: { name: string, content: string, csvList: CsvItem[] }[];
  //csvList: { title: string, href: string }[];
  private navigationSubscription = Subscription.EMPTY;
  private viewInited = new Deferred();

  constructor(private dataService: DataService, private totalStService: StorageTotalsService, private log: LoggerService,
    private router: Router, private http: HttpClient) {
    this.navigationSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.dataService.nodeSelected.promise.then(() => {
          if (!this.navigationSubscription.closed) {
            if (Globals.isSameNavigation(e))
              return;
            this.init();
          }
        });
      });
  }
  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.viewInited.resolve();
  }

  ngOnInit() {
  }

  private init() {
    if (this.dataService.selectedType === Globals.TYPE_SERVER) {
      this.totalStService.getPageConfigurationLpar(this.dataService.selectedNode.data.href).subscribe(data => {
        let csvListAll: CsvItem[] = [];
        const doc = Globals.DOM_PARSER.parseFromString(data, 'text/html');
        doc.querySelectorAll('a[href$=".csv"]').forEach(el => {
          let title = el.getAttribute('title');
          if (!title) {
            title = el.textContent;
          }
          csvListAll.push({ title, href: Globals.htmlUrlPrefix + Globals.RUNTIME_PATH + Product.LPAR.path + el.getAttribute('href') });
        });

        void this.viewInited.promise.then(() => {
          this.tabsTps = [];
          const links = doc.querySelectorAll('div#tabs ul li a');
          links.forEach(link => {
            let tab = doc.querySelector('div#tabs div' + link.getAttribute('href'));
            let csvList: CsvItem[] = [];
            tab.querySelectorAll('a[href$=".csv"]').forEach(el => {
              let title = el.getAttribute('title');
              if (!title) {
                title = el.textContent;
              }
              csvList.push({ title, href: Globals.htmlUrlPrefix + Globals.RUNTIME_PATH + Product.LPAR.path + el.getAttribute('href') });
            });
            csvListAll = csvListAll.filter(ci => !csvList.some(c => c.href === ci.href));
            this.tabsTps.push({
              name: link.textContent,
              content: tab.innerHTML,
              csvList: csvList
            });
          });
          for (const tab of this.tabsTps) {
            tab.csvList = tab.csvList.concat(csvListAll);
          }
          this.setFirstTab(this.tabsTps[0].content);
        });
      }, error => this.log.error('Failed to get HW configuration!', error));
    }
    else if (this.dataService.selectedType === Globals.TYPE_STORAGE || this.dataService.selectedType === Globals.TYPE_NETWORK
      || this.dataService.selectedType === Globals.TYPE_STORGR) {

      this.getStorageCfg(this.dataService.selectedNode.data.href);

    }
  }

  private getStorageCfg(href: string) {
    let obs: Observable<string>;
    if (href) {
      obs = this.http.get(Globals.RUNTIME_PATH + Product.STOR.path + href, { responseType: 'text' });
    } else {
      obs = this.http.post('/api/page', { url: this.dataService.selectedNode.data.url, rrdType: 'stor', type: 'totals' }, { responseType: 'text' });
    }
    obs.subscribe(data => {
      this.viewInited.promise.then(() => {
        if (this.dataService.selectedNode.data.hw_type) {
          data = data.replace(/href="/g, 'href="' + Globals.htmlUrlPrefix + Globals.RUNTIME_PATH + Product.STOR.path);
          data = data.replace(/HREF="/g, 'HREF="' + Globals.htmlUrlPrefix + Globals.RUNTIME_PATH + Product.STOR.path);
        }
        $('#hwconfig').empty();
        $('#hwconfig').html(data);
        this.dataService.registerBacklinks();
        $('#hwconfig a:contains("Text")').off().on('click', (event) => {
          event.preventDefault();
          this.http.get(Globals.RUNTIME_PATH + Product.STOR.path + $(event.currentTarget).attr('href'),
            { responseType: 'text' }).subscribe(page => {
              let div = $('#hwconfig');
              div.html(page);
              div.addClass('scroll-wrap');
              Globals.initFloatingScroll();
            });
        });
        $('#hwconfig a:contains("Detailed"), #hwconfig a:contains("Summary")').off().on('click', (event) => {
          event.preventDefault();
          this.getStorageCfg(event.currentTarget.getAttribute('href'));
        });
        $('center > table, div#hwconfig > table')
          .wrap('<div class="scroll-wrap"/>');
        var $t = $('#hwconfig').find('table.tablesorter');
        if ($t.length) {
          Globals.tableSorter($($t), true);
        }
        Globals.initFloatingScroll(true);
        DataService.fixDownloadLinks();
      });
    }, error => {
      this.log.error('Failed to get HW configuration!', error);
    });
  }

  setFirstTab(content: string) {

    setTimeout(() => {

      $('#tab0').addClass('tab-flow-buttons').html(content);
      $('center > div > .tablesorter, .stickyheaders-holder > .tablesorter, center > .tablesorter')
        .wrap('<div class="scroll-wrap"/>');
      var $t = $('#tab0').find('table.tablesorter');
      $("a.server_quick").html('<span class="fas fa-info"></span>')
        .attr('href', function () { return Globals.htmlUrlPrefix + Globals.RUNTIME_PATH + this.getAttribute('href'); });
      $("a.server_overview").html('<span class="fas fa-comment"></span>')
        .attr('href', function () { return Globals.htmlUrlPrefix + Globals.RUNTIME_PATH + this.getAttribute('href'); });
      $("a.server_detail").html('<span class="fas fa-search"></span>')
        .attr('href', function () { return Globals.htmlUrlPrefix + Globals.RUNTIME_PATH + this.getAttribute('href'); });
      $("a.server_interface").html('<span class="fas fa-exchange-alt"></span>')
        .attr('href', function () { return Globals.htmlUrlPrefix + Globals.RUNTIME_PATH + this.getAttribute('href'); });
      this.hrefHandler();
      if ($t.length) {
        Globals.tableSorter($($t), true);
      }
      Globals.initFloatingScroll(true);
      this.dataService.registerBacklinks(HwConfigurationComponent.EXCLUDED_BACKLINK_CLASSES);
    });
  }

  onTabSelected(indexObj) {

    var index = indexObj.index;
    if (index === 0) {
      this.setFirstTab(this.tabsTps[index].content);
    }
    else {
      $('#tab' + index).html(this.tabsTps[index].content);
      $('center > div > .tablesorter, .stickyheaders-holder > .tablesorter, center > .tablesorter')
        .wrap('<div class="scroll-wrap"/>');
      var $t = $('#tab' + index).find('table.tablesorter');
      $("a.server_quick").html('<span class="ui-icon ui-icon-info"></span>');
      $("a.server_overview").html('<span class="ui-icon ui-icon-comment"></span>');
      $("a.server_detail").html('<span class="ui-icon ui-icon-search"></span>');
      $("a.server_interface").html('<span class="ui-icon ui-icon-transfer-e-w"></span>');
      this.hrefHandler();
      if ($t.length) {
        Globals.tableSorter($($t), true);
      }
      Globals.initFloatingScroll(true);
      this.dataService.registerBacklinks(HwConfigurationComponent.EXCLUDED_BACKLINK_CLASSES);
    }
  }

  private backLink(pURL, event) {

    const self = this;
    var link = $(event.target).parents("td").find("a");
    var cls = "." + link.attr("class");
    if (cls) {
      var srvname = link.parents("tr").find("td").first().text();
      var idx = link.parents("td").index();
      var title = link.parents("table").find("th").eq(idx).text();
      var dwidth = (cls == ".server_interface") ? "950" : "650";
      $.get(pURL, function (data) {
        data = data.replace(/href="/g, 'href="' + Globals.RUNTIME_PATH);
        data = data.replace(/HREF="/g, 'HREF="' + Globals.RUNTIME_PATH);
        var html = $.parseHTML(data);
        var div = "<div id='srvdetail' class='stickyheaders-holder'>" + $(html).find(cls).html() + "</div>";
        $(div).dialog({
          height: 750,
          width: dwidth,
          modal: true,
          title: srvname + " - " + title,
          buttons: {
            Close: function () {
              $(this).dialog("close");
            }
          },
          create: function () {
            Globals.tableSorter($('#srvdetail table.tablesorter, #srvdetail table.tabcfgsum'), true, '#srvdetail');
            self.dataService.registerBacklinks(HwConfigurationComponent.EXCLUDED_BACKLINK_CLASSES);
          },
          close: function () {
            $(this).dialog("destroy");
          }
        });

      });

    }
  }

  private hrefHandler() {
    const self = this;
    $('#tab0 a.server_quick, #tab0 a.server_overview, #tab0 a.server_detail, #tab0 a.server_interface').off().on("click", function (ev) {
      ev.preventDefault();
      var url = $(this).attr('href');
      self.backLink(url, ev);
      return false;
    });
  }

}



