import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, NgZone, OnInit } from '@angular/core';
import { finalize, map } from 'rxjs/operators';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { Deferred } from 'src/app/model/deferred';
import { Product } from 'src/app/model/product';
import { AbstractGraph } from '../abstract-graph';

@Component({
  selector: 'xormon-b64',
  templateUrl: './b64.component.html',
  styleUrls: ['./b64.component.scss'],
  providers: [{ provide: AbstractGraph, useExisting: B64Component }]
})
export class B64Component extends AbstractGraph implements OnInit, AfterViewInit {

  private viewInited = new Deferred();

  constructor(
    private http: HttpClient,
    protected dataService: DataService, protected zone: NgZone
  ) { super(dataService, zone); }

  ngOnInit() {
    this.graph.origTitle = this.graph.title;
    this.load(this.graph.imgSrc).subscribe(() => {
    }, error => this.graph.error = JSON.stringify(error));
  }

  ngAfterViewInit(): void {
    this.fancyBox(this.element.nativeElement);
    this.viewInited.resolve();
  }

  load(url: string) {
    this.graph.loading = true;
    return this.http
      .get(Globals.RUNTIME_PATH + url, {
        observe: 'response',
        responseType: 'text'
      }).pipe(map(resp => {
        let data;
        const error = resp.headers.get(Globals.HEADER_ERROR);
        if (error) {
          let dataSrc = Globals.getParams(this.graph.imgSrc);
          this.graph.period = this.dataService.mainModule.getImagePeriod(dataSrc);
          this.graph.title = this.dataService.mainModule.getImageTitle(dataSrc) + this.graph.period;
          this.graph.error = Base64.decode(error);
          data = {};
          this.graph.width = 0;
        } else {
          try {
            data = JSON.parse(resp.body);
            this.graph.imgData = data.img;
            this.graph.error = null;
            this.graph.loaded = true;
          } catch (error) {
            this.graph.base64 = false;
            return;
          }
        }

        this.parseHeaderProperties(resp.headers);

        this.legendTable(data.table);

      }),
        finalize(() => {
          this.graph.loading = false;
          this.loaded.emit();
        })
      );
  }

  toggleTable($event) {
    const parentGraph = $($event.target).closest('.graph');
    parentGraph.toggleClass('graph-active');
  }

  get host() {
    return Globals.htmlUrlPrefix + Globals.RUNTIME_PATH;
  }

  private legendTable(table_data: string) {
    if (!table_data) return;

    this.viewInited.promise.then(() => {
      const element = this.element.nativeElement;

      const self = this;

      const legdiv = $(element).find('div.legend');

      $(legdiv).html(Base64.decode(table_data));
      self.dataService.registerBacklinks(null, legdiv);
      const $t = legdiv.find('table.tablesorter');
      if ($t.length) {
        const updated = $t.find('.tdupdated');
        if (updated) {
          legdiv
            .parents('.graph-table')
            .siblings('.updated')
            .text(updated.text());
          updated.parent().remove();
        }
        Globals.tableSorter($t, true, '.legend');
        var tableRows = $t.find('tr').length;
        if (tableRows < 6) {
          const legBtn = $(element).find(
            '.graph-table-button'
          );
          legBtn.hide();
        }
      }
      $t.find('td.legsq').each(function () {
        $(this)
          .siblings('td')
          .each(function () {
            if ($(this).text().length > 0 && !$(this).attr('title')) {
              $(this).attr('title', $(this).text()); // set tooltip (to see very long names)
            }
          });
        var bgcolor = $(this).text();
        if (bgcolor) {
          var parLink = $(this)
            .parents('.graph')
            .find('a.detail')
            .attr('href');
          var parParams = Globals.getParams(parLink);

          if (self.dataService.isLpar()) {
            trItem = parParams.item;
            if (trItem == 'sum') {
              trItem = parParams.name;
            }
            var trItem = 'lpar';
            if (
              parParams.item == 'memaggreg' ||
              parParams.item == 'customosmem' ||
              parParams.item == 'custommem'
            ) {
              trItem = 'mem';
            } else if (parParams.item == 'pagingagg') {
              trItem = 'pg1';
            } else if (parParams.item == 'memams') {
              trItem = 'ams';
            } else if (parParams.item == 'customoslan') {
              trItem = 'lan';
            } else if (parParams.item == 'job_cpu') {
              trItem = 'as4job_proc';
            } else if (parParams.item == 'waj') {
              trItem = 'as4job_core';
            } else if (parParams.item == 'disk_io') {
              trItem = 'as4job_disk';
            } else if (parParams.item == 'disk_io') {
              trItem = 'as4job_disk';
            } else if (parParams.item == 'disks') {
              trItem = 'as4job_diskio_sec';
            } else if (parParams.item == 'disk_busy') {
              trItem = 'as4job_dbusy';
            } else if (parParams.item == 'jobs') {
              trItem = 'powlin_job';
            } else if (parParams.item == 'jobs_mem') {
              trItem = 'powlin_mem';
            }
            var trTime = parParams.time;
            switch (trTime) {
              case '60':
                trTime = 'm';
                break;
              case '3600':
                trTime = 'h';
                break;
              case '86400':
                trTime = 'd';
                break;
            }
            let trLink: string;
            if (this.dataset && this.dataset.job) {
              trLink = parLink;
            } else if (this.dataset && this.dataset.url) {
              trLink = this.dataset.url;
            } else {
              trLink = $(this)
                .parent()
                .find('.clickabletd a')
                .last()
                .attr('href');
            }
            var trParams = Globals.getParams(trLink);
            if (trParams.item == 'pool') {
              if (trParams.lpar == 'pool') {
                trItem = 'pool';
              } else {
                trItem = 'shpool';
              }
            }
            if (trParams.square_item) {
              trItem = trParams.square_item;
            }

            if (this.dataset && this.dataset.job) {
              trLink =
                self.dataService.cgiPath +
                '/detail-graph.sh?host=' +
                trParams.host +
                '&server=' +
                trParams.server +
                '&lpar=' +
                trParams.lpar +
                '&item=' +
                trItem +
                '&time=' +
                trTime +
                '&type_sam=m&detail=1&upper=0&entitle=0' +
                '&none=' +
                encodeURIComponent(this.dataset.job);
            } else if (trParams.host == 'Hitachi') {
              trLink =
                Product.LPAR.pathCgi + '/detail-graph.sh?host=' +
                trParams.server +
                '&server=' +
                trParams.host +
                '&lpar=' +
                trParams.lpar +
                '&item=' +
                trItem +
                '&time=' +
                trTime +
                '&type_sam=m&detail=1&upper=0&entitle=0' +
                '&none=' +
                encodeURIComponent(this.dataset.job);
            } else if (this.dataset && this.dataset.url) {
              trLink = this.dataset.url;
            } else {
              trLink =
                Product.LPAR.pathCgi + '/detail-graph.sh?host=' +
                trParams.host +
                '&server=' +
                trParams.server +
                '&lpar=' +
                trParams.lpar +
                '&item=' +
                trItem +
                '&time=' +
                trTime +
                '&type_sam=m&detail=1&upper=0&entitle=0&sunix=' +
                parParams.sunix +
                '&eunix=' +
                parParams.eunix;
            }

            trLink = Globals.API_HOST + Globals.RUNTIME_PATH + trLink;
            if (
              parParams.item != 'memaggreg' &&
              !$(this).hasClass('noclick') &&
              trParams.lpar
            ) {
              $(this).html(
                '<a href=\'' +
                trLink +
                '\' title=\'Click to get [' +
                decodeURIComponent(trParams.lpar.replace(/\+/g, ' ')) +
                '] detail in a pop-up view\' class=\'detail\'><div class=\'innersq\' style=\'background:' +
                bgcolor +
                ';\'></div></a>'
              );
              self.fancyBox(this);

            } else {
              $(this).html(
                '<center><div class=\'innersq\' style=\'background:' +
                bgcolor +
                ';\'></div><center>'
              );
            }
          } else {
            // stor
            trItem = parParams.item;
            if (trItem == 'sum') {
              trItem = parParams.name;
            }
            var trTime = parParams.time;
            var trParams = $(this).parent().find("a.backlink").data();
            if (trParams) {
              if (trParams.popup_metric) {
                trItem = trParams.popup_metric;
              }
              var trLink = Globals.API_HOST + Globals.RUNTIME_PATH + self.dataService.cgiPath +
                "/detail-graph.sh?host=" + trParams.device_label + "&type=" + trParams.subsys + "&name=" + trParams.item_label +
                "&item=" + trItem + "&time=" + trTime + "&detail=1&none=";
              if (trParams.item_label !== undefined) {
                $(this).html("<a href='" + trLink + "' title='Click to get [" + decodeURIComponent(trParams.item_label.toString().replace(/\+/g, " ")) + "] detail in a pop-up view' class='detail'><div class='innersq' style='background:" + bgcolor + ";'></div></a>");
                self.fancyBox(this);
              } else {
                $(this).html("<div class='innersq' style='background:" + bgcolor + ";'></div>");
              }
            } else {
              $(this).html("<div class='innersq' style='background:" + bgcolor + ";'></div>");
            }
          }
        }
      });
    });
  }

}
