import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LoggerService } from 'src/app/logger.service';
import { Product } from 'src/app/model/product';

@Component({
  selector: 'xormon-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OverviewComponent implements OnInit, AfterViewInit {

  constructor(private http: HttpClient, private log: LoggerService) { }

  ngAfterViewInit(): void {
    void Product.STOR.resolved.promise.then(() => {
      setTimeout(function find_list() {
        var list = document.getElementById("overviewsrc");
        if (list && list.childElementCount == 0) {
          Product.STOR.module.myreadyFunc();
          setTimeout(find_list, 600);
        }
      }, 500)
    });
  }

  ngOnInit() {

  }

}
