import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';
import { SharedDashboard } from 'src/app/model/dashboard';
import { Deferred } from 'src/app/model/deferred';

@Component({
  templateUrl: './portal-import-dialog.component.html',
  styleUrls: ['./portal-import-dialog.component.scss']
})
export class PortalImportDialogComponent implements OnInit {

  private tInitialized = new Deferred();
  table: Table;
  @ViewChild('tt', { static: false })
  set tt(t: Table) {
    if (t) {
      this.table = t;
      this.tInitialized.resolve();
    } else
      this.tInitialized.reset();
  }

  publicDashboards: SharedDashboard[];
  selectedDashboard: SharedDashboard;
  loading = true;
  technologies: SelectItem[];
  selectedTechno: string;
  users: SelectItem[];
  userId: number;
  alreadyImported = false;

  constructor(private http: HttpClient, private log: LoggerService, private dialog: MatDialogRef<PortalImportDialogComponent>,
    private dataService: DataService) { }

  ngOnInit() {
    this.userId = Globals.currentUser.id;
    this.http.get<SharedDashboard[]>('/api/portal/allShared').subscribe(data => {
      this.publicDashboards = data;
      let technoSet = new Set(data.map(sd => sd.technology));
      this.technologies = Array.from(technoSet).map(s => ({ label: s, value: s }));
      this.technologies.unshift({ label: 'All technologies', value: null });
      this.selectedTechno = null;
      this.tInitialized.promise.then(() => {
        this.table.filter(this.selectedTechno, 'technology', 'equals');
      });
      this.users = Array.from(new Set(data.map(sd => sd.user))).map(s => ({ label: s, value: s }));
      this.loading = false;
    }, error => this.log.error('Failed to get shared dashboards!', error));
  }

  onRowSelected(event) {
    this.alreadyImported = this.dataService.dashboard.tabs.some(dt => dt.id === this.selectedDashboard.id);
  }

  importAsReadonly() {
    this.http.post('/api/portal/reference', [this.selectedDashboard.id]
    ).subscribe(() => {
      this.dialog.close('ok');
    }, error => this.log.error('Failed to import shared dashboards!', error));
  }

  importAsCopy() {
    this.http.post<string[]>('/api/portal/copy', [this.selectedDashboard.id]
    ).subscribe(result => {
      this.log.info(`Created new dashboard <strong>${result[0]}</strong>`);
      this.dialog.close('ok');
    }, error => this.log.error('Failed to copy shared dashboards!', error));
  }

}
