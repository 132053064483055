<div #element class="graph-base64">
  <div *ngIf="!error" class="graph-inner">
    <a class="link detail" [attr.href]="host + graph.imgSrc.replace('detail=9','detail=1')"
      title="Click to show detail">
      <div [ngClass]="{'d-block': !graph.loaded, 'd-inline-block': graph.loaded}">
        <img [hidden]="!graph.loaded" *ngIf="graph.loaded" [src]="graph.imgData" [attr.data-src]="graph.imgSrc"
          [attr.data-title]="graph.title" class="nolegend">
        <div [id]="'img_'+graph.idx" class="zoom" title="Click and drag to select range">
          <xormon-loading-indicator *ngIf="graph.loading"></xormon-loading-indicator>
        </div>
      </div>
    </a>
    <div class="graph-table">
      <div class="legend graph-legend center tablesorter-xs scroll-wrap stickyheaders-holder"></div>
      <button class="graph-table-button" (click)="toggleTable($event)">
        <span class="fas fa-angle-double-down"></span>
      </button>
    </div>
    <div class="updated"></div>
  </div>
  <div *ngIf="error" class="db_error_placeholder">
    {{error}}
  </div>
</div>
