<div class="card">
  <div class="card-body">
    <table id="reptable" class="cfgtree center table max-width d-none">
      <thead>
        <tr>
          <th>Report name <button id="addrep" class="btn btn-sm btn-secondary btn-add float-right">New</button></th>
          <th>Edit</th>
          <th>Clone</th>
          <th>Run</th>
          <th>Delete</th>
          <th>Format</th>
          <th>Recurrence</th>
          <th>Next run</th>
          <th>Recipient group</th>
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
    <fieldset class="cggrpnames">
      <div>
        <label for="csv_delim">CSV delimiter&nbsp;</label>
        <input id="csv_delim" name="CSV_DELIMITER" class="text medium text-center" type="text" size="1" maxlength="1"
          title="Delimiter used in CSV reports" value="">
      </div>
    </fieldset>
    <div>
      <button style='font-weight: bold; margin-top: 1em' name='saverepcfg' class='btn saverepcfg'>Save
        configuration</button>
    </div>
  </div>
</div>