import { Params } from '@angular/router';
import { BooleanWrap, Globals } from './globals';
import { HwType } from './model/hwType';
import { Product } from './model/product';
import { ROUTES } from './model/routes';

export class MenuItem {
  key: string;
  navKey?: string;
  title: string;
  visible?= [Globals.TRUE_WRAP];
  id?: number;
  tooltip?: string;
  url?: string;
  type?: string;
  expanded?: boolean;
  statusNodeType?: string;
  content?: string;
  json?: boolean;
  longname?: string;
  folder?: boolean;
  children?: MenuItem[];

  public constructor(init?: Partial<MenuItem>) {
    Object.assign(this, init);
  }
}

export class MenuItems {

  private static readonly storHistRep = new MenuItem({
    key: 'hist_rep', title: Globals.TITLE_HIST_REPORTS, content: ROUTES.HISTORICAL_REPORTS, type: Globals.TYPE_HIST_REP,
    url: Product.STOR.path + 'global_hist_reports.html'
  });
  private static readonly storSanTopology = new MenuItem({
    key: ROUTES.SAN_TOPOLOGY, title: Globals.TITLE_SAN_TOPOLOGY, content: ROUTES.SAN_TOPOLOGY,
    url: Product.STOR.pathCgi + '/graphviz.sh?procname=global_html'
  });
  private static readonly storMapping = new MenuItem({ key: ROUTES.MAPPING, title: 'Mapping', content: ROUTES.MAPPING });
  private static readonly storHostsTop = new MenuItem({
    key: ROUTES.HOSTS_TOP, title: 'Hosts TOP', content: ROUTES.HOSTS_TOP, url: 'glob-hosts-top.html'
  });
  private static readonly storVolumesTop = new MenuItem({
    key: ROUTES.VOLUMES_TOP, title: 'Volumes TOP', content: ROUTES.VOLUMES_TOP, url: 'glob-volumes-top.html',
    visible: [Globals.IS_USER_ADMIN]
  });
  private static readonly storOverview = new MenuItem({ key: 'overview', title: 'Overview', content: 'overview' });

  static readonly configItems = new MenuItem({
    key: Globals.TYPE_CONFIG,
    title: Globals.TYPE_CONFIG,
    children: [
      {
        key: Globals.TYPE_SERVER,
        title: Globals.TITLE_SERVER,
        visible: [Globals.BACKEND_INFO.lparEnabled, Globals.IS_USER_ADMIN],
        children: [
          new MenuItem({
            key: 'platforms',
            title: 'Platforms',
            folder: true,
            children: [
              MenuItems.platform(HwType.POWER),
              MenuItems.platform(HwType.VMWARE),
              MenuItems.platform(HwType.NUTANIX),
              MenuItems.platform(HwType.ORACLEDB),
              MenuItems.platform(HwType.ORACLEVM),
              MenuItems.platform(HwType.SOLARIS, { json: false }),
              MenuItems.platform(HwType.OVIRT, { longname: 'RHV (oVirt)' }),
              MenuItems.platform(HwType.XENSERVER),
              MenuItems.platform(HwType.WINDOWS, { json: false }),
              MenuItems.platform(HwType.AWS),
              MenuItems.platform(HwType.GCLOUD),
              MenuItems.platform(HwType.AZURE),
              MenuItems.platform(HwType.KUBERNETES),
              MenuItems.platform(HwType.OPENSHIFT),
              //MenuItems.platform(HwType.HITACHI, { json: false })
            ]
          }),
          {
            key: 'alertServer',
            title: 'Alerting',
            folder: true,
            children: [
              { key: 'alparAlerts', title: 'Configuration', content: 'alparAlerts' },
              { key: 'alparEmails', title: 'Email', content: 'alparEmails' },
              { key: 'alparOptions', title: 'Options', content: 'alparOptions' },
              { key: 'alparLogs', title: 'Log', content: 'alparLogs' }
            ]
          },
          { key: 'serCfg', title: Globals.TITLE_CUSTOM_GROUPS, content: 'cgConf' }
        ]
      },
      {
        key: Globals.TYPE_STORAGE,
        title: Globals.TITLE_STORAGE,
        visible: [Globals.BACKEND_INFO.storEnabled, Globals.IS_USER_ADMIN],
        children: [
          {
            key: 'devices',
            title: 'Devices',
            folder: true,
            children: [
              { key: 'devStorage', title: 'Storage', content: 'devices' },
              { key: 'devSan', title: 'SAN', content: 'devices' },
              { key: 'devLan', title: 'LAN', content: 'devices' }
            ]
          },
          {
            key: 'alertStor',
            title: 'Alerting',
            folder: true,
            children: [
              { key: 'astorAlerts', title: 'Configuration', content: 'astorAlerts' },
              { key: 'astorEmails', title: 'Email', content: 'astorEmails' },
              { key: 'astorPerf', title: 'Performance', content: 'astorPerf' },
              { key: 'astorHW', title: 'HW Event', content: 'astorHW' },
              { key: 'astorLogs', title: 'Log', content: 'astorLogs' }
            ]
          },
          { key: 'stoCfg', title: Globals.TITLE_CUSTOM_GROUPS, content: 'cgConf' },
          { key: 'groups', title: 'Grouping', content: 'storageGrouping' },
          { key: 'heatmap', title: 'Heatmap', content: 'heatmapCfg' },
        ]
      },
      {
        key: Globals.TYPE_OTHER,
        title: Globals.TITLE_OTHER,
        visible: [Globals.TRUE_WRAP],
        children: [
          { key: 'runtime', title: 'Back-end', content: 'rtSetup', visible: [Globals.IS_USER_ADMIN] },
          { key: 'ldap', title: 'LDAP', content: 'ldapSetup', visible: [Globals.IS_USER_ADMIN] },
          { key: 'profile', title: 'User Profile', content: 'profile', visible: [Globals.TRUE_WRAP] },
          { key: 'usermngt', title: 'Users', content: 'userMngt', visible: [Globals.IS_USER_ADMIN] },
          { key: 'usergroups', title: 'Groups', content: 'userGroups', visible: [Globals.IS_USER_ADMIN] },
          { key: 'info', title: 'Application', content: 'info', visible: [Globals.IS_USER_ADMIN] },
          { key: 'log', title: 'Log', content: ROUTES.LOG, visible: [Globals.IS_USER_ADMIN] },
          { key: ROUTES.ABOUT, title: 'About', content: ROUTES.ABOUT, visible: [Globals.TRUE_WRAP] }
        ]
      }
    ]
  });

  static readonly dashboardItems = new MenuItem({
    key: Globals.TYPE_DASHBOARD,
    title: Globals.TITLE_DASHBOARD,
    children: [
      {
        key: Globals.TYPE_GLOBAL,
        title: Globals.TITLE_GLOBAL,
        visible: [Globals.TRUE_WRAP],
        content: ROUTES.PORTAL
      },
      {
        key: Globals.TYPE_SERVER,
        title: Globals.TITLE_SERVER,
        folder: true,
        visible: [Globals.BACKEND_INFO.lparEnabled],
        children: [
          { key: 'sePortal', title: Globals.TITLE_FAVORITE, content: ROUTES.PORTAL, children: null, expanded: true },
          { key: 'seHeatmap', title: 'Heatmap', content: ROUTES.HEATGLOB, visible: [Globals.SERVER_PRESENT] }
        ]
      },
      {
        key: Globals.TYPE_STORAGE,
        title: Globals.TITLE_STORAGE,
        folder: true,
        visible: [Globals.BACKEND_INFO.storEnabled],
        children: [
          { key: 'stPortal', title: Globals.TITLE_FAVORITE, content: ROUTES.PORTAL, expanded: true },
          { key: 'stHeatmap', title: 'Heatmap', content: ROUTES.HEATGLOB, visible: [Globals.STORAGE_PRESENT, Globals.IS_USER_ADMIN] }
        ]
      },
      {
        key: Globals.TYPE_LAN,
        title: Globals.TITLE_LAN,
        folder: true,
        visible: [Globals.BACKEND_INFO.storEnabled, Globals.LAN_PRESENT],
        children: [
          { key: 'lanPortal', title: Globals.TITLE_FAVORITE, content: ROUTES.PORTAL, children: null, expanded: true },
        ]
      },
      {
        key: Globals.TYPE_SAN,
        title: Globals.TITLE_SAN,
        folder: true,
        visible: [Globals.BACKEND_INFO.storEnabled],
        children: [
          { key: 'sanPortal', title: Globals.TITLE_FAVORITE, content: ROUTES.PORTAL, children: null, expanded: true },
          { key: 'sanHeatmap', title: 'Heatmap', content: ROUTES.HEATGLOB, visible: [Globals.SAN_PRESENT, Globals.IS_USER_ADMIN] }
        ]
      },
      {
        key: Globals.TYPE_MANAGE,
        title: Globals.TITLE_MANAGE,
        visible: [Globals.TRUE_WRAP],
        content: ROUTES.DASHBOARDS
      }
    ]
  });

  static readonly customGroupItems = new MenuItem({
    key: Globals.TYPE_CUSTOM_GROUPS,
    title: Globals.TITLE_CUSTOM_GROUPS,
    children: [
      {
        id: 3,
        key: Globals.TYPE_SERVER,
        title: Globals.TITLE_SERVER,
        folder: true,
        visible: [Globals.BACKEND_INFO.lparEnabled],
        children: [
        ]
      },
      {
        id: -3,
        key: Globals.TYPE_STORAGE,
        title: Globals.TITLE_STORAGE,
        folder: true,
        visible: [Globals.BACKEND_INFO.storEnabled],
        children: [
        ]
      },
      {
        id: -5,
        key: Globals.TYPE_LAN,
        title: Globals.TITLE_LAN,
        folder: true,
        statusNodeType: 'nodata',
        visible: [Globals.FALSE_WRAP],
        children: [
        ]
      },
      {
        id: -4,
        key: Globals.TYPE_SAN,
        title: Globals.TITLE_SAN,
        folder: true,
        visible: [Globals.BACKEND_INFO.storEnabled],
        children: [
        ]
      }
    ]
  });

  // remove me
  static readonly reporterItems = new MenuItem({
    key: Globals.TYPE_REPORTER,
    title: Globals.TITLE_REPORTER,
    children: [
      {
        key: Globals.TYPE_SERVER,
        title: Globals.TITLE_SERVER,
        visible: [Globals.BACKEND_INFO.lparEnabled],
        children: [
          { key: 'serRepDefinitions', title: 'Definitions', content: 'repLparDefs' },
          { key: 'serRepGroups', title: 'Groups', content: 'repGroups' },
          { key: 'serRepOptions', title: 'Options', content: 'repOptions' },
          { key: 'serRepHistory', title: 'History', content: 'repHistory' }
        ]
      },
      {
        key: Globals.TYPE_STORAGE,
        title: Globals.TITLE_STORAGE,
        visible: [Globals.BACKEND_INFO.storEnabled],
        children: [
          { key: 'stoRepDefinitions', title: 'Definitions', content: 'repStorDefs' },
          { key: 'stoRepGroups', title: 'Groups', content: 'repGroups' },
          { key: 'stoRepOptions', title: 'Options', content: 'repOptions' },
          { key: 'stoRepHistory', title: 'History', content: 'repHistory' },
        ]
      },
      {
        key: Globals.TYPE_SERVER,
        title: Globals.TITLE_SERVER,
        visible: [Globals.BACKEND_INFO.lparEnabled],
        children: [
          {
            key: 'hist_repPower', title: HwType.POWER.title, content: ROUTES.HISTORICAL_REPORTS,
            type: Globals.TYPE_HIST_REP, url: Product.LPAR.pathCgi + '/histrep.sh?platform=Power&type=historical_reports'
          },
          {
            key: 'hist_repVmWare', title: HwType.VMWARE.title, content: ROUTES.HISTORICAL_REPORTS,
            type: Globals.TYPE_HIST_REP, url: Product.LPAR.pathCgi + '/histrep.sh?mode=globalvm'
          },
          {
            key: 'hist_repLinux', title: HwType.LINUX.title, content: ROUTES.HISTORICAL_REPORTS,
            type: Globals.TYPE_HIST_REP, url: Product.LPAR.pathCgi + '/histrep.sh?platform=Linux&type=historical_reports'
          },
        ]
      },
      {
        key: Globals.TYPE_SAN,
        title: Globals.TITLE_STORAGE,
        visible: [Globals.BACKEND_INFO.storEnabled],
        children: [
          { key: 'stoRepDefinitionsSan', title: 'Definitions', content: 'repStorDefs' },
          { key: 'stoRepGroupsSan', title: 'Groups', content: 'repGroups' },
          { key: 'stoRepOptionsSan', title: 'Options', content: 'repOptions' },
          { key: 'stoRepHistorySan', title: 'History', content: 'repHistory' },
        ]
      },
      {
        key: Globals.TYPE_LAN,
        title: Globals.TITLE_STORAGE,
        visible: [Globals.BACKEND_INFO.storEnabled],
        children: [
          { key: 'stoRepDefinitionsLan', title: 'Definitions', content: 'repStorDefs' },
          { key: 'stoRepGroupsLan', title: 'Groups', content: 'repGroups' },
          { key: 'stoRepOptionsLan', title: 'Options', content: 'repOptions' },
          { key: 'stoRepHistoryLan', title: 'History', content: 'repHistory' },
        ]
      }
    ]
  });

  static readonly reportItems = new MenuItem({
    key: Globals.TYPE_REPORT,
    title: Globals.TITLE_REPORT,
    children: [
      {
        key: Globals.TYPE_SERVER,
        title: Globals.TITLE_SERVER,
        folder: true,
        visible: [Globals.BACKEND_INFO.lparEnabled],
        children: [
          {
            key: 'seRepTop',
            navKey: MenuItems.reporterItems.children[0].children[0].key,
            title: Globals.TITLE_REPORTER,
            content: ROUTES.REPORT_LPAR,
            children: MenuItems.reporterItems.children[0].children
          },
          {
            key: 'seHisTop',
            navKey: MenuItems.reporterItems.children[2].children[0].key,
            title: Globals.TITLE_HIST_REPORTS,
            content: ROUTES.HISTORICAL_REPORTS,
            children: MenuItems.reporterItems.children[2].children
          },
          {
            key: 'seTopTop',
            navKey: 'seTopTop',
            title: 'TOP',
            content: ROUTES.TOP10,
            children: [
              {
                key: 'seTopPower',
                title: 'IBM Power LPAR',
                content: ROUTES.TOP10,
                url: Product.LPAR.pathCgi + '/detail.sh?platform=Power&type=top10_global',
              },
              {
                key: 'seTopVMWareVM',
                title: 'VMWare VM',
                content: ROUTES.TOP10,
                url: Product.LPAR.pathCgi + '/detail.sh?platform=Vmware&type=topten_vm',
              },
              {
                key: 'seTopVMWareDat',
                title: 'VMWare Datastore',
                content: ROUTES.TABS,
                url: Product.LPAR.pathCgi + '/detail.sh?platform=Vmware&type=dstr-table-top',
              },
              {
                key: 'seTopKub',
                title: 'Kubernetes Pod',
                content: ROUTES.TABS,
                url: Product.LPAR.pathCgi + '/detail.sh?platform=Kubernetes&type=top',
              },
              {
                key: 'seTopShif',
                title: 'OpenShift Pod',
                content: ROUTES.TABS,
                url: Product.LPAR.pathCgi + '/detail.sh?platform=Openshift&type=top',
              }
            ],
          },
          {
            key: ROUTES.MULTIPATH,
            title: 'Multipath',
            content: ROUTES.MULTIPATH,
            url: Product.LPAR.pathCgi + '/multi-gen.sh?cmd=multipath'
          }
        ]
      },
      {
        key: Globals.TYPE_STORAGE,
        title: Globals.TITLE_STORAGE,
        folder: true,
        visible: [Globals.BACKEND_INFO.storEnabled, Globals.STORAGE_PRESENT],
        children: [
          {
            key: 'stRepTop',
            navKey: MenuItems.reporterItems.children[1].children[0].key,
            title: Globals.TITLE_REPORTER,
            content: ROUTES.REPORT_STOR,
            children: MenuItems.reporterItems.children[1].children
          },
          MenuItems.storHistRep,
          MenuItems.storOverview,
          MenuItems.storMapping,
          {
            key: 'stHosVolTop',
            navKey: ROUTES.VOLUMES_TOP,
            title: 'TOP',
            content: ROUTES.VOLUMES_TOP,
            visible: [Globals.IS_USER_ADMIN],
            children: [
              MenuItems.storVolumesTop,
              { key: ROUTES.HOSTS_TOP, title: 'Hosts TOP', content: ROUTES.HOSTS_TOP, visible: [Globals.IS_USER_ADMIN], url: 'glob-hosts-top.html' },
            ],
          },
        ]
      },
      {
        key: Globals.TYPE_SAN,
        title: Globals.TITLE_SAN,
        folder: true,
        visible: [Globals.BACKEND_INFO.storEnabled, Globals.SAN_PRESENT],
        children: [
          {
            key: 'stRepTopSan',
            navKey: MenuItems.reporterItems.children[3].children[0].key,
            title: Globals.TITLE_REPORTER,
            content: ROUTES.REPORT_STOR,
            children: MenuItems.reporterItems.children[3].children
          },
          {
            key: 'hrSan',
            title: Globals.TITLE_HIST_REPORTS,
            content: ROUTES.HISTORICAL_REPORTS,
            url: Product.STOR.path + 'hist_reports-SAN.html'
          },
          MenuItems.storSanTopology,
          {
            key: 'san_topology_bna', title: Globals.TITLE_BNA_TOPOLOGY, content: ROUTES.SAN_TOPOLOGY,
            url: Product.STOR.pathCgi + '/graphviz.sh?procname=global_html_bna', visible: [Globals.BNA_PRESENT]
          }
        ]
      },
      {
        key: Globals.TYPE_LAN,
        title: Globals.TITLE_LAN,
        folder: true,
        visible: [Globals.BACKEND_INFO.storEnabled, Globals.LAN_PRESENT],
        children: [
          {
            key: 'stRepTopLan',
            navKey: MenuItems.reporterItems.children[4].children[0].key,
            title: Globals.TITLE_REPORTER,
            content: ROUTES.REPORT_STOR,
            children: MenuItems.reporterItems.children[4].children
          },
          {
            key: 'hrLan',
            title: Globals.TITLE_HIST_REPORTS,
            content: ROUTES.HISTORICAL_REPORTS,
            url: Product.STOR.path + 'hist_reports-LAN.html'
          }
        ]
      },
    ]
  });

  /**
   * hw_type: [ { title, lazy, level, key, object_id, class, hw_type } ]
   */
  static readonly storageTotal = new MenuItem({
    key: Globals.TYPE_TOTALS,
    title: 'Totals',
    visible: [Globals.BACKEND_INFO.storEnabled],
    children: [
      { key: 'capacity', title: 'Capacity', content: 'capacity' },
      { key: 'health_status', title: 'Health status', content: 'health_status', url: Product.STOR.pathCgi + '/glob_hs.sh' },
      {
        key: 'graph_totals', title: 'Totals', content: ROUTES.TABS, visible: [Globals.IS_USER_ADMIN],
        url: Product.STOR.pathCgi + '/detail.sh?host=ALL&type=STORAGE&name=io&storage=ALL&item=sum&gui=1&none=none'
      },
      { key: 'hw_config', title: 'HW Configuration', content: ROUTES.HW_CONFIG, url: Product.STOR.pathCgi + '/configuration.sh' },
      MenuItems.storOverview,
      MenuItems.storHostsTop,
      MenuItems.storVolumesTop,
      MenuItems.storMapping,
      MenuItems.storSanTopology,
      //MenuItems.storHistRep,
    ]
  });

  private static readonly NO_MATCH = -9;

  static isVisibleWraps(wraps: BooleanWrap[]) {
    if (!wraps) {
      return true;
    }
    if (!wraps.length) {
      return false;
    }
    return wraps.every(b => b.value);
  }

  static isVisibleRoute(params: Params) {
    if (params[ROUTES.TYPE] === Globals.TYPE_DASHBOARD &&
      (params[ROUTES.CHILD] === Globals.DEFAULT_DASHBOARD_NAME || params[ROUTES.CHILD].toLowerCase() === Globals.TYPE_GLOBAL)) {
      return true;
    }
    if (params[ROUTES.TYPE] === Globals.TYPE_CONFIG && params[ROUTES.PARENT] === Globals.TYPE_OTHER && params[ROUTES.CHILD] === Globals.TYPE_GLOB_SEARCH) {
      return true;
    }
    const paraArray = [params[ROUTES.TYPE], params[ROUTES.PARENT], params[ROUTES.CHILD]];
    for (const key in MenuItems) {
      const prop = MenuItems[key];
      if (prop instanceof MenuItem) {
        let result = this.isVisibleRecur(prop, paraArray, 0);
        if (result >= paraArray.length - 1)
          return true;
        if (result > MenuItems.NO_MATCH)
          return false;
      }
    }
    return true;
  }

  private static isVisibleRecur(mi: MenuItem, params: string[], idx: number) {
    if (!params || !params.length || idx > params.length) {
      return MenuItems.NO_MATCH;
    }
    const index = idx === params.length ? params.length - 1 : idx;
    if (!params[index]) {
      return params.length - 1;
    }
    if (mi.key !== params[index]) {
      if (index !== params.length - 1 || !mi.children || !mi.children.length) {
        return MenuItems.NO_MATCH;
      }
    } else {
      let visible = this.isVisibleWraps(mi.visible);
      if (!visible)
        return index - 1;
      if (index === params.length - 1 || !mi.children || !mi.children.length) {
        return visible ? params.length - 1 : index - 1;
      }
    }
    for (const child of mi.children) {
      let result = this.isVisibleRecur(child, params, index + 1);
      if (result > MenuItems.NO_MATCH) {
        return result;
      }
    }
    // assumes params.length>1 will fail at key check
    return mi.key === params[index] ? index - 1 : MenuItems.NO_MATCH;
  }

  private static platform(hwType: HwType, extra = {}) {
    return new MenuItem({ key: hwType.platform, title: hwType.title, content: 'platform', json: true, ...extra });
  }
}
