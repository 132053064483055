/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./global-search.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i3 from "@angular/material/tabs";
import * as i4 from "../../../loading-indicator/loading-indicator.component.ngfactory";
import * as i5 from "../../../loading-indicator/loading-indicator.component";
import * as i6 from "@angular/common";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./global-search.component";
import * as i9 from "@angular/router";
import * as i10 from "./global-search.service";
import * as i11 from "../../../logger.service";
import * as i12 from "../../../data.service";
import * as i13 from "@angular/platform-browser";
var styles_GlobalSearchComponent = [i0.styles];
var RenderType_GlobalSearchComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_GlobalSearchComponent, data: {} });
export { RenderType_GlobalSearchComponent as RenderType_GlobalSearchComponent };
function View_GlobalSearchComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 5, "mat-tab", [["label", "Loading"]], null, null, null, i2.View_MatTab_0, i2.RenderType_MatTab)), i1.ɵdid(1, 770048, [[1, 4]], 2, i3.MatTab, [i1.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i1.ɵqud(603979776, 2, { templateLabel: 0 }), i1.ɵqud(335544320, 3, { _explicitContent: 0 }), (_l()(), i1.ɵeld(4, 0, null, 0, 1, "xormon-loading-indicator", [], null, null, null, i4.View_LoadingIndicatorComponent_0, i4.RenderType_LoadingIndicatorComponent)), i1.ɵdid(5, 114688, null, 0, i5.LoadingIndicatorComponent, [], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = "Loading"; _ck(_v, 1, 0, currVal_0); _ck(_v, 5, 0); }, null); }
function View_GlobalSearchComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Search results for "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "i", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["\"", "\""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.q; _ck(_v, 3, 0, currVal_0); }); }
function View_GlobalSearchComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No search results for "])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "i", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["\"", "\""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.q; _ck(_v, 3, 0, currVal_0); }); }
function View_GlobalSearchComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 10, "mat-tab", [], null, null, null, i2.View_MatTab_0, i2.RenderType_MatTab)), i1.ɵdid(1, 770048, [[1, 4]], 2, i3.MatTab, [i1.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i1.ɵqud(603979776, 4, { templateLabel: 0 }), i1.ɵqud(335544320, 5, { _explicitContent: 0 }), (_l()(), i1.ɵeld(4, 0, null, 0, 6, "div", [["class", "stickyheaders-holder"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GlobalSearchComponent_3)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GlobalSearchComponent_4)), i1.ɵdid(8, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 0, "hr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["class", "search-results"], ["stylex", "position: relative; overflow: hidden;"]], [[8, "id", 0], [8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.key, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.value; _ck(_v, 6, 0, currVal_1); var currVal_2 = !_v.context.$implicit.value; _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var currVal_3 = (_v.context.$implicit.key + "_SearchResults"); var currVal_4 = _v.context.$implicit.value; _ck(_v, 10, 0, currVal_3, currVal_4); }); }
export function View_GlobalSearchComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "mat-tab-group", [["class", "global-search mat-tab-group"]], [[24, "@.disabled", 0], [2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], [[null, "selectedTabChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedTabChange" === en)) {
        var pd_0 = (_co.tabChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatTabGroup_0, i2.RenderType_MatTabGroup)), i1.ɵdid(1, 3325952, null, 1, i3.MatTabGroup, [i1.ElementRef, i1.ChangeDetectorRef, [2, i3.MAT_TABS_CONFIG], [2, i7.ANIMATION_MODULE_TYPE]], null, { selectedTabChange: "selectedTabChange" }), i1.ɵqud(603979776, 1, { _tabs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_GlobalSearchComponent_1)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_GlobalSearchComponent_2)), i1.ɵdid(6, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i6.KeyValuePipe, [i1.KeyValueDiffers])], function (_ck, _v) { var _co = _v.component; var currVal_3 = !_co.results; _ck(_v, 4, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 6, 0, i1.ɵnov(_v, 7).transform(_co.results)); _ck(_v, 6, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 1).dynamicHeight; var currVal_2 = (i1.ɵnov(_v, 1).headerPosition === "below"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_GlobalSearchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xormon-global-search", [], null, null, null, View_GlobalSearchComponent_0, RenderType_GlobalSearchComponent)), i1.ɵdid(1, 245760, null, 0, i8.GlobalSearchComponent, [i9.ActivatedRoute, i10.GlobalSearchService, i11.LoggerService, i9.Router, i12.DataService, i13.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var GlobalSearchComponentNgFactory = i1.ɵccf("xormon-global-search", i8.GlobalSearchComponent, View_GlobalSearchComponent_Host_0, {}, {}, []);
export { GlobalSearchComponentNgFactory as GlobalSearchComponentNgFactory };
