<mat-tab-group [@.disabled]="true" (selectedTabChange)="tabChanged($event)" class="global-search">
  <mat-tab label="Loading" *ngIf="!results">
    <xormon-loading-indicator></xormon-loading-indicator>
  </mat-tab>
  <mat-tab *ngFor="let tab of results | keyvalue" label="{{tab.key}}">
    <div class="stickyheaders-holder">
      <h3 *ngIf="tab.value">Search results for <i>"{{q}}"</i></h3>
      <h3 *ngIf="!tab.value">No search results for <i>"{{q}}"</i></h3>
      <hr>
      <div [id]="tab.key+'_SearchResults'" class="search-results" [innerHTML]="tab.value"
        stylex="position: relative; overflow: hidden;">
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
