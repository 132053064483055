import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { CustomMessagesService } from 'src/app/custom-messages.service';

@Component({
  selector: 'xormon-alerting-lpar-header',
  templateUrl: './alerting-lpar-header.component.html',
  styleUrls: ['./alerting-lpar-header.component.scss']
})
export class AlertingLparHeaderComponent implements OnInit {

  @Input()
  label: string;
  url: string;

  constructor(private elef: ElementRef, private cmService: CustomMessagesService) { }

  ngOnInit() {
    this.url = this.cmService.getMsg('href', this.elef);
  }

}
