<mat-tab-group *ngIf="tabsTps" (selectedTabChange)="onTabSelected($event)" [@.disabled]="true" class="volumes-top">
  <mat-tab label="custom">
    <div class="stickyheaders-holder">
      <form id="volcustomform" class="pt-2">
        <input type='hidden' name='dstr-top' value='12'>
        <div class="d-flex align-items-end mb-2">
          <div class="form-group mr-2 mb-0">
            <label for='from' class="d-block">From</label>
            <div class="form-time-wrap">
              <input type='text' id='fromTime' class="form-control" name='fromTime'>
            </div>
          </div>
          <div class="form-group mr-2 mb-0">
            <label for='to' class="d-block">To</label>
            <div class="form-time-wrap">
              <input type='text' id='toTime' name='toTime' class="form-control">
            </div>
          </div>
          <div class="form-group mr-2 mb-0">
            <select class="form-control">
              <option value="avg">AVG</option>
              <option value="max">MAX</option>
            </select>
          </div>
          <input (click)="submitForm()" type='submit' class='btn btn-primary' id='showvolumes' value='Show top volumes'>
        </div>
      </form>
      <div id="volresults"></div>
    </div>
  </mat-tab>
  <mat-tab *ngFor="let tab of tabsTps; let i = index" label={{tab.name}}>

    <div id="{{'tab'+i}}" class="stickyheaders-holder volumes-top">
      <div class="spinner-wrap">
        <xormon-loading-indicator></xormon-loading-indicator>
      </div>
    </div>

  </mat-tab>
</mat-tab-group>
