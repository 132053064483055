/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./portal.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./portal.component";
import * as i4 from "../../../data.service";
import * as i5 from "./portal.service";
import * as i6 from "../../../logger.service";
import * as i7 from "@angular/router";
import * as i8 from "primeng/components/common/confirmationservice";
var styles_PortalComponent = [i0.styles];
var RenderType_PortalComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PortalComponent, data: {} });
export { RenderType_PortalComponent as RenderType_PortalComponent };
function View_PortalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please select some graphs you want to see here."])), (_l()(), i1.ɵeld(4, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Use this "])), (_l()(), i1.ɵeld(6, 0, null, null, 0, "i", [["class", "far fa-star"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" at top right corner of each graph to place the graph into the dashboard. "]))], null, null); }
export function View_PortalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PortalComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (!_co.currentTab || !_co.currentTab.groups.length); _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dbContent; _ck(_v, 0, 0, currVal_0); }); }
export function View_PortalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xormon-portal", [], null, null, null, View_PortalComponent_0, RenderType_PortalComponent)), i1.ɵdid(1, 4440064, null, 0, i3.PortalComponent, [i4.DataService, i5.PortalService, i6.LoggerService, i7.Router, i8.ConfirmationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PortalComponentNgFactory = i1.ɵccf("xormon-portal", i3.PortalComponent, View_PortalComponent_Host_0, {}, {}, []);
export { PortalComponentNgFactory as PortalComponentNgFactory };
