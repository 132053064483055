import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';
import { StorageTotalsService } from '../storage-totals.service';

@Component({
  selector: 'xormon-mapping',
  templateUrl: './mapping.component.html',
  styleUrls: ['./mapping.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MappingComponent implements OnInit, OnDestroy {

  loading = true;
  tabs: any[];

  private navigationSubscription = Subscription.EMPTY;

  constructor(private dataService: DataService, private totalStService: StorageTotalsService, private router: Router,
    private log: LoggerService) {
    this.navigationSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.dataService.nodeSelected.promise.then(() => {
          if (!this.navigationSubscription.closed) {
            if (Globals.isSameNavigation(e))
              return;
            this.init();
          }
        });
      });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }

  private init() {
    this.tabs = null;

    this.totalStService.mappingPage(this.dataService.selectedNode.data.href).subscribe(data => {

      let html = Globals.DOM_PARSER.parseFromString(data, 'text/html');
      let tabs = html.getElementById('tabs');
      if (tabs) {
        this.tabs = [];
        tabs.querySelectorAll('ul li a').forEach(value => {
          let href = value.getAttribute('href');
          if (href.startsWith('#'))
            this.tabs.push({
              key: value.textContent,
              value: this.fixCsv(tabs.querySelector(href).innerHTML)
            });
          else {
            this.totalStService.getPage(href).subscribe(html => {
              this.tabs.push({ key: value.textContent, value: html });
            }, error => this.log.error('Failed to get mapping tab!', error));
          }
        });
        setTimeout(() => {
          $('.inner-html').html(this.tabs[0].value);
          Globals.tableSorter($('table'), true);
          this.dataService.registerBacklinks();
        });
      } else {
        $('.inner-html').html(this.fixCsv(data));
        $('.inner-html > .tablesorter')
          .wrap('<div class="scroll-wrap"/>');
        setTimeout(() => {
          Globals.tableSorter($('table'), true);
          Globals.initFloatingScroll(true);
          this.dataService.registerBacklinks();
        });
      }
      this.loading = false;
    }, error => {
      this.log.error('Failed to obtain mapping page!', error);
    });

  }

  tabChanged(event: MatTabChangeEvent) {
    setTimeout(() => {
      $('.inner-html').html(this.tabs.find(t => t.key === event.tab.textLabel).value);
      Globals.tableSorter($('table'), true);
      this.dataService.registerBacklinks();
    });
    //this.dataService.mainModule.myreadyFunc();
  }

  private fixCsv(data) {
    data = data.replace(/(<br>)*/g, '');
    var href = data.match(/<a class="csvfloat".*<\/a>/g);
    if (href) {
      href = href[0].match(/href="(.*?)"/g);
      href = href[0].replace(/href=/g, "");
      href = href.replace(/"/g, "");
      var csv = " <a class='csvfloat btn btn-link' href=\'" + Globals.htmlUrlPrefix + Globals.RUNTIME_PATH + href + "' title='CSV'> <span class='fas fa-file-csv fa-2x'></span></a>";
      data = data.replace(/<a class="csvfloat".*<\/a>/g, "");
      return csv + data;
    }
    return data;
  }

}
