import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialogRef } from '@angular/material/dialog';
import { LoggerService } from 'src/app/logger.service';

@Component({
  selector: 'xormon-user-profile-password',
  templateUrl: './user-profile-password.component.html',
  styleUrls: ['./user-profile-password.component.scss']
})
export class UserProfilePasswordComponent implements OnInit {

  pass = { current: null, newPwd: null, confirm: null };
  error;

  constructor(private http: HttpClient, private dialogRef: MatDialogRef<UserProfilePasswordComponent>,
    private log: LoggerService) { }

  ngOnInit() {
  }

  save() {
    this.http.post('/api/changePassword', this.pass).subscribe(data => {
      this.log.info('Password changed successfully.');
      this.dialogRef.close();
    }, error => {
      this.error = 'Failed to change new password! Is current password entered correctly?';
      this.log.error(this.error, error);
    })
  }

}
