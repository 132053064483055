import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';
import { DEFERRED_STATUS } from 'src/app/model/deferred';
import { Product } from 'src/app/model/product';
import { GlobalSearchService } from './global-search.service';

@Component({
  selector: 'xormon-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GlobalSearchComponent implements OnInit, OnDestroy {

  q;
  results;
  private navigationSubscription = Subscription.EMPTY;

  constructor(private route: ActivatedRoute, private searchService: GlobalSearchService, private log: LoggerService,
    private router: Router, private dataService: DataService, private sanitizer: DomSanitizer) {
    this.navigationSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.dataService.nodeSelected.promise.then(() => {
          if (!this.navigationSubscription.closed) {
            if (Globals.isSameNavigation(e))
              return;
            this.init();
          }
        });
      });
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }

  ngOnInit() {
  }

  init() {
    this.dataService.backendLoaded.promise.then(() => {
      this.q = this.route.snapshot.queryParamMap.get('q');
      if (this.searchService.globalSearchResults) {
        this.results = this.searchService.globalSearchResults;
        this.onTab(Object.keys(this.results)[0]);
      } else {
        if (this.q) {
          this.searchService.globalSearchQuery = this.q;
        }
        this.results = this.searchService.globalSearchResults = null;
        const observables: Observable<string>[] = [];
        const tabs: string[] = [];
        if (Globals.BACKEND_INFO.lparEnabled.value && Product.LPAR.resolved.status === DEFERRED_STATUS.resolved) {
          observables.push(this.searchService.searchLparOld());
          tabs.push(Product.LPAR.name);
        }
        if (Globals.BACKEND_INFO.storEnabled.value && Product.STOR.resolved.status === DEFERRED_STATUS.resolved) {
          observables.push(this.searchService.searchStorOld());
          tabs.push(Product.STOR.name);
        }
        forkJoin(observables).subscribe(data => {
          const result = {};
          for (let i = 0; i < data.length; i++) {
            result[tabs[i]] = this.sanitizer.bypassSecurityTrustHtml(data[i]);
          }
          this.results = this.searchService.globalSearchResults = result;
          this.onTab(tabs[0]);
        }, error => {
          this.log.error('Failed to search query!', error);
          this.results = { 'Loading failed': null };
        });
      }
    }, reason => console.warn(reason));
  }

  private fixCsv() {
    $('a.csvfloat').each((index, element) => {
      let href = element.getAttribute('href');
      if (href && !href.startsWith('http'))
        element.setAttribute('href', Globals.PREFIX_JQUERY + element.getAttribute('href'));
    });
  }

  tabChanged(event: MatTabChangeEvent) {
    this.onTab(event.tab.textLabel);
  }

  private onTab(label: string) {
    setTimeout(() => {
      $('#hiw img').replaceWith('<i class="fas fa-question-circle"></i>');
      $('center > .tablesorter, .search-results > .tablesorter')
        .wrap('<div class="scroll-wrap"/>');
      Globals.tableSorter($('.tablesorter'), true);
      Globals.initFloatingScroll(true);
      this.dataService.registerBacklinks(null, null, this.getCgi(label));
      this.fixCsv();
    });
  }

  private getCgi(tabName: string) {
    if (Product.LPAR.name === tabName)
      return Product.LPAR.pathCgi;
    else
      return Product.STOR.pathCgi;
  }

}
