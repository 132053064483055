<block-ui id="app-cmp">
  <as-split direction="horizontal" unit="pixel" *ngIf="!isLogin">
    <as-split-area size="195">
      <router-outlet></router-outlet>
    </as-split-area>
    <as-split-area class="content-main d-flex flex-column overflow-hidden flex-extra" [size]="'*'">
      <xormon-top-menu></xormon-top-menu>
      <div class="container-fluid position-relative flex-grow-1 d-flex flex-column flex-last content-outlet">
        <router-outlet name="content"></router-outlet>
        <div *ngIf="!isContent">
          <xormon-loading-indicator></xormon-loading-indicator>
        </div>
      </div>
    </as-split-area>
  </as-split>

  <div *ngIf="isLogin" class="container">
    <xormon-login></xormon-login>
    <div class="version mr-1" title="{{buildTime}}">{{versionLabel + version}}</div>
  </div>

  <p-toast baseZIndex="9999">
    <ng-template let-message pTemplate="message">
      <div (click)="message.sticky=true">
        <a *ngIf="message.data" (click)="showError(message, $event)" href="#" class="text-dark">
          <span class="ui-toast-icon pi" [ngClass]="{'pi-info-circle': message.severity == 'info', 'pi-exclamation-triangle': message.severity == 'warn',
              'pi-times-circle': message.severity == 'error', 'pi-check' :message.severity == 'success'}"></span>
          <div class="ui-toast-message-text-content">
            <div class="ui-toast-summary">{{message.summary}}</div>
            <div class="ui-toast-detail" [innerHTML]="message.detail"></div>
          </div>
        </a>
        <a *ngIf="message.data?.status===0" href="#" (click)="reloadPage($event)">Try to reload page.</a>
        <div *ngIf="!message.data">
          <span class="ui-toast-icon pi"
            [ngClass]="{'pi-info-circle': message.severity == 'info', 'pi-exclamation-triangle': message.severity == 'warn',
                            'pi-times': message.severity == 'error', 'pi-check' :message.severity == 'success'}"></span>
          <div class="ui-toast-message-text-content">
            <div class="ui-toast-summary">{{message.summary}}</div>
            <div class="ui-toast-detail" [innerHTML]="message.detail"></div>
          </div>
        </div>
      </div>
    </ng-template>
  </p-toast>

  <p-dialog [(visible)]="errorDialogShown" baseZIndex="9999" modal="true" dismissableMask="true"
    [style]="{'max-width': '95%'}">
    <p-header>
      <i class="fas fa-exclamation-circle mr-2"></i> Error detail
      <a id="errorCopy" class="float-right pointer hover-info mr-3" title="Copy to clipboard"
        (click)="errorToClipboard(errorMessage)"><i class="far fa-copy"></i></a>
    </p-header>
    <div>
      <div [innerHTML]="errorMessage?.detail"></div>
      <pre style="overflow: visible;">{{errorMessage?.data | json}}</pre>
    </div>
  </p-dialog>

  <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle" appendTo="body" [transitionOptions]="'0ms'"
    rejectIcon="null" acceptIcon="null">
  </p-confirmDialog>
</block-ui>
