import { AfterViewInit, Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { Product } from 'src/app/model/product';
import wlabel from 'src/app/wlabel.json';

@Component({
  selector: 'xormon-alerting-stor-alerts',
  templateUrl: './alerting-stor-alerts.component.html',
  styleUrls: ['./alerting-stor-alerts.component.scss']
})
export class AlertingStorAlertsComponent implements OnInit, AfterViewInit {

  urls = wlabel;
  free = false;

  constructor(
    private dataService: DataService,
  ) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.dataService.nodeSelected.promise.then(() => {
      this.free = !Product.STOR.info.isEnterpriseEdition();
      Product.STOR.module.myreadyFunc();
    }, reason => console.warn(reason));
  }

}
