import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoggerService } from 'src/app/logger.service';
import { Group } from 'src/app/model/group';
import { User } from 'src/app/model/user';

export class GroupsUser {
  user: User;
  groups: Group[];
}

interface ExtendedUser extends User {
  passwordConfirm?: string;
}

@Component({
  selector: 'xormon-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit, AfterViewInit {

  saving = false;
  user: ExtendedUser;
  sourceGroups: Group[];
  remail;
  adminTitle = '';
  activeRadio = 0;
  init = true;

  constructor(@Inject(MAT_DIALOG_DATA) gu: GroupsUser, private http: HttpClient,
    private dialogRef: MatDialogRef<UserEditComponent>, private log: LoggerService, private zone: NgZone) {
    this.user = gu.user;
    //this.user.password = this.user.passwordConfirm = '';
    this.sourceGroups = gu.groups;
    if (this.user.id === 1)
      this.adminTitle = 'Note that default System admin account cannot be delete or have admin group removed. Although you can change its password.';
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.init = false;
    }, 500);
  }

  ngOnInit() {

  }

  saveUser() {
    this.saving = true;
    document.body.style.cursor = 'wait';
    if (this.user.id) {
      if (this.user.password === '')
        this.user.password = null;
      if (this.user.passwordConfirm === '')
        this.user.passwordConfirm = null;
      this.http.put('/admin/users/update', this.user).subscribe(data => {
        this.dialogRef.close('OK');
        document.body.style.cursor = 'default';
      }, error => {
        this.saving = false;
        document.body.style.cursor = 'default';
        this.log.error('Failed to update user with ID ' + this.user.id, error);
      });
    } else { // create
      this.http.post('/admin/users/create', this.user).subscribe(data => {
        this.dialogRef.close('OK');
        document.body.style.cursor = 'default';
      }, error => {
        this.log.error('Failed to create user ' + this.user.username, error);
        this.saving = false;
        document.body.style.cursor = 'default';
      });
    }
  }

  defaultAdminIsNotAdmin() {
    return this.user.id === 1 && !this.user.localGroups.find(g => g.id === 1);
  }

}
