import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';

@Component({
  selector: 'xormon-reporter-groups',
  templateUrl: './reporter-groups.component.html',
  styleUrls: ['./reporter-groups.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReporterGroupsComponent implements OnDestroy {

  private navigationSubscription = Subscription.EMPTY;

  constructor(private router: Router, private dataService: DataService) {
    this.navigationSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.dataService.nodeSelected.promise.then(() => {
          if (!this.navigationSubscription.closed) {
            if (Globals.isSameNavigation(e))
              return;
            this.dataService.mainModule.myreadyFunc();
          }
        });
      });
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }
}
