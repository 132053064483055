import { SafeHtml } from '@angular/platform-browser';
import { Graph } from './graph';

export enum TabType {
    'storError', 'lpar2volumes'
}

export interface TabPage {
    tps: Tab[];
    tpCommon?: Tab;
}

export interface Tab {
    /**
     * Label
     */
    name: string;
    /**
     * To obtain content on this URL
     */
    url?: string;
    /**
     * Result of URL get
     */
    urlContent?;
    htmls?: string[] | SafeHtml[];
    sections: Section[];
    /**
     * Grouping of d|w|m|y graphs
     */
    time: string;
    /**
     * Group zoom reset on change
     */
    resetSwitch?: boolean;
    /**
     * Selection event for regrouped graphs
     */
    groupSelection?: any;
    /**
     * True if tab has been displayed
     */
    loaded?: boolean;
    type?: TabType;
    /**
     * Array of itemIDs to lookup in STOR
     */
    itemIds?: string[];
    /**
     * Looked-up properties for itemIDs
     */
    itemProperties?: any[];
    help?: TabHelp;
    /**
     * Array of prediction div html
     */
    predictions: string[];
    /**
     * True if tab is common for NMON and OS agent
     */
    common: boolean;
    /**
     * What metric type this tab represents
     */
    metric?: string;
}

export interface Section {
    name?: string;
    htmls?: any[];
    graphs: Graph[];
}

export interface ReportTabPage {
    rtps: ReportTab[];
    rtpCommon?: ReportTab;
}

export interface ReportTab {
    name: string;
    rows: Row[];
}

interface Row {
    cells: Cell[];
}

interface Cell {
    csvLink?: string;
    html?: string | SafeHtml;
    imgSrc?: string;
    base64?: boolean;
}

interface TabHelp {
    title: string;
    url: string;
}