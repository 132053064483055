import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';
import { Product } from 'src/app/model/product';
import { StorageTotalsService } from '../storage-totals.service';

@Component({
  selector: 'xormon-capacity',
  templateUrl: './capacity.component.html',
  styleUrls: ['./capacity.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CapacityComponent implements OnInit, AfterViewInit {

  private divCapacity = $('<div>');
  private divPool = $('<div>');
  private divGroup = $('<div>');

  private readonly divs = [
    ['#capacity', this.divCapacity],
    ['#capacityGroup', this.divGroup],
    ['#capacityPool', this.divPool]
  ];

  heatmapPage;
  runtime = Globals.RUNTIME_PATH;
  htmlUrlPrefix = Globals.htmlUrlPrefix;
  cgi: string;
  private storageGroups = [];

  constructor(private dataService: DataService, private http: HttpClient, private totalStService: StorageTotalsService, private log: LoggerService) { }

  ngOnInit() {
    void Product.STOR.resolved.promise.then(() => {

      this.cgi = Product.STOR.pathCgi;

      void Product.STOR.module.genCapacity(this.divCapacity).then(() => {
        this.dataService.registerBacklinks();
      }, error => {
        this.log.error('Failed to get capacity!', error);
      }).always(() => {

        var keys = Object.keys(this.dataService.sgHolder);
        var count = $('.captab').children('tr').length;
        if (count > 0) { return }
        var groupObj = {};
        this.storageGroups = [];
        keys.forEach(storage => {
          var hosts = "";
          let sg = { name: storage, storages: [] };
          if (this.dataService.sgHolder[storage].length === 0) { return }
          this.dataService.sgHolder[storage].forEach(array => {
            hosts += "host=" + array.title + "&";
            sg.storages.push(array.title);
          });
          groupObj[storage] = hosts;
          this.storageGroups.push(sg);
        });
        Product.STOR.module.genCapacityStorageGroup(this.divGroup, groupObj).then(() => {
          this.dataService.registerBacklinks();
        }, error => this.log.error('Failed to get storage capacity!', error));

        Product.STOR.module.genCapacityPoolAll(this.divPool).then(() => {
          this.dataService.registerBacklinks();
        }, error => this.log.error('Failed to get capacity pool!', error));

        this.http.get(Globals.RUNTIME_PATH + Product.STOR.path + 'heatmap-capacity.html', { responseType: 'text' }).subscribe(page => {
          let doc = Globals.DOM_PARSER.parseFromString(page, 'text/html');
          let help = doc.createElement('i');
          help.classList.add('fas', 'fa-question-circle');
          let hiw = doc.querySelector('#hiw');
          hiw.classList.add('btn', 'btn-link');
          $(hiw.querySelector('img')).replaceWith(help);
          this.heatmapPage = Globals.domSanitizer.bypassSecurityTrustHtml(doc.body.innerHTML);
          this.dataService.registerBacklinks();
        }, error => this.log.error('Failed to get heatmap!', error));
      });
    });
  }

  ngAfterViewInit(): void {
    $(this.divs[0][0] as string).append(this.divs[0][1]);
  }

  onTabChange(event: MatTabChangeEvent) {
    if (event.index < this.divs.length) {
      let divID = $(this.divs[event.index][0] as string);
      if (divID.children().length === 0) {
        divID.append(this.divs[event.index][1]);
      }
    }

    setTimeout(() => {
      this.dataService.registerBacklinks();
    });
  }

  downloadGroupCsv(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    this.http.post(Globals.RUNTIME_PATH + this.cgi + '/detail-graph.sh',
      { type: 'STORAGE-GROUP', name: 'cap_total', item: 'sum', csv: 1, time: 'd', host: 'ALL', groups: this.storageGroups },
      { responseType: 'blob' }).subscribe(blob => {

        const url = window.URL.createObjectURL(blob);
        const downlink = document.createElement('a');
        downlink.style.display = 'none';
        downlink.href = url;
        downlink.download = 'capacity-group.csv';
        document.body.append(downlink);
        downlink.click();

        setTimeout(() => {
          URL.revokeObjectURL(url);
          downlink.remove();
        });

      }, error => this.log.error('Failed to download capacity-group.csv!', error));
  }
}