<mat-tab-group (selectedTabChange)="onTabSelected($event)" [@.disabled]="true" *ngIf="tabsSan">
  <mat-tab *ngFor="let tab of tabsSan; let i = index" label={{tab.name}}>

    <div id="{{'tab'+i}}" class="stickyheaders-holder hosts-top">
      <xormon-loading-indicator></xormon-loading-indicator>
    </div>

  </mat-tab>
</mat-tab-group>

<div *ngIf="page" [innerHTML]="page" class="san-topology"></div>
