import { Globals } from '../globals';
import { StorNodeParamsData, TreeItem } from './treeItem';
export class StorBacklinkParams {

  private static readonly LEVEL_ITEM = 'item';
  private static readonly LEVEL_SUBSYS = 'subsys';
  private static readonly LEVEL_OBJECT = 'object';
  private static readonly SUBSYS_DEVICE = 'DEVICE';
  private static readonly SUBSYS_SITE = 'SITE';
  private static readonly CLASS_STORAGE = 'STORAGE';

  device_class: string;
  device_hwtype: string;
  device_label: string;
  subsys: string;
  parent_device?: string;
  total_type?: string;
  item_label?: string;
  item_id?: string;
  metric?: string;

  constructor(strOrNode?: string | Fancytree.FancytreeNode | object) {
    if (!strOrNode)
      return;
    if (typeof strOrNode === 'string') {
      let params = strOrNode.split('&');
      for (const param of params) {
        if (param.length) {
          let keyValue = param.split('=');
          this[keyValue[0]] = keyValue[1];
        }
      }
    } else if (strOrNode instanceof $.ui.fancytree._FancytreeNodeClass) {
      this.setParamsFromNode(strOrNode);
      if (!this.device_class && !this.device_hwtype) {
        this.device_hwtype = Globals.TYPE_SAN;
      }
    } else {
      for (const key in strOrNode) {
        if (Object.prototype.hasOwnProperty.call(strOrNode, key)) {
          this[key] = strOrNode[key];
        }
      }
    }
  }

  toString() {
    if (this.item_id) {
      return '&item_id=' + this.item_id;
    }
    let result = '';
    for (const key in this) {
      if (Object.prototype.hasOwnProperty.call(this, key)) {
        result += `&${key}=${this[key]}`;
      }
    }
    return result;
  }

  private findNodeInNet(node: Fancytree.FancytreeNode) {
    let data = node.data as StorNodeParamsData;
    if (this.device_label && this.device_hwtype === data.hw_type && this.device_label === node.title && node.children) {
      for (const subsys of node.children) {
        let dataSub = subsys.data as StorNodeParamsData;
        if (this.subsys === StorBacklinkParams.SUBSYS_DEVICE && subsys.title === this.total_type) {
          return subsys;
        } else if (dataSub.level === StorBacklinkParams.LEVEL_SUBSYS && dataSub.subsys === this.subsys && subsys.children) {
          for (const item of subsys.children) {
            let dataItem = item.data as StorNodeParamsData;
            if ((this.item_id && dataItem.item_id === this.item_id) || this.item_label && (item.title === this.item_label || dataItem.name === this.item_label))
              return item;
          }
        }
      }
    } else if (!this.device_label && this.device_class === data.class && node.title === this.total_type) {
      return node;
    }
  }

  private findByItemId(nodes: Fancytree.FancytreeNode[]): Fancytree.FancytreeNode {
    if (!nodes)
      return;
    for (const node of nodes) {
      if (node.data.item_id === this.item_id)
        return node;
      let result = this.findByItemId(node.children);
      if (result)
        return result;
    }
  }

  findNode(nodes: Fancytree.FancytreeNode[]) {
    if (!nodes.length)
      return;

    if (this.item_id) {
      return this.findByItemId(nodes);
    }

    if (nodes[0].data.class !== StorBacklinkParams.CLASS_STORAGE) {
      for (const networkOrGroup of nodes) {
        let dataNet = networkOrGroup.data as StorNodeParamsData;
        if (dataNet.group && networkOrGroup.children) {
          for (const net of networkOrGroup.children) {
            let result = this.findNodeInNet(net);
            if (result)
              return result;
          }
        }
        else {
          let result = this.findNodeInNet(networkOrGroup);
          if (result)
            return result;
        }
      }
    } else {
      for (const device of nodes) {
        let dataDev = device.data as StorNodeParamsData;
        if (dataDev.hw_type) {
          let result = this.findNodeInStorage(device);
          if (result)
            return result;
        } else {
          for (const group of device.children) {
            let result = this.findNodeInStorage(group);
            if (result)
              return result;
          }
        }
      }
    }
  }

  private findNodeInStorage(node: Fancytree.FancytreeNode) {
    let data = node.data as StorNodeParamsData;
    if (data.hw_type === this.device_hwtype &&
      (this.parent_device && node.title === this.parent_device || !this.parent_device && node.title === this.device_label) && node.children) {
      for (const subsys of node.children) {
        let dataSub = subsys.data as StorNodeParamsData;
        if (!this.parent_device) {
          let result = this.findNodeInSub(subsys);
          if (result)
            return result;
        } else if (dataSub.level === StorBacklinkParams.LEVEL_SUBSYS && subsys.children) {
          for (const site of subsys.children) {
            let dataSite = site.data as StorNodeParamsData;
            if (this.subsys === dataSite.subsys && site.title === this.total_type && !dataSite.level && !site.children) {
              return site;
            }
            else if (dataSite.level === StorBacklinkParams.LEVEL_OBJECT && site.children && this.device_label === dataSite.device_label) {
              for (const siteSub of site.children) {
                let resultSite = this.findNodeInSub(siteSub);
                if (resultSite)
                  return resultSite;
              }
            }
          }
        }
      }
    }
  }

  private findNodeInSub(subsys: Fancytree.FancytreeNode) {
    const dataSub = subsys.data as StorNodeParamsData;
    if (this.subsys === StorBacklinkParams.SUBSYS_DEVICE && this.total_type &&
      (subsys.title === this.total_type || dataSub.name === this.total_type)) {
      return subsys;
    }
    else if (dataSub.level === StorBacklinkParams.LEVEL_SUBSYS && (!dataSub.subsys || dataSub.subsys === this.subsys) && subsys.children) {
      for (const total of subsys.children) {
        let dataTot = total.data as StorNodeParamsData;
        if (this.total_type && (dataTot.name === this.total_type || total.title === this.total_type))
          return total;
        else if (dataTot.subsystem && (this.item_label && (total.title === this.item_label || dataTot.name === this.item_label)
          || this.item_id && dataTot.item_id === this.item_id))
          return total;
        else if ((this.item_label || this.item_id) && dataTot.level === StorBacklinkParams.LEVEL_ITEM && total.children) {
          for (const item of total.children) {
            let dataItem = item.data as StorNodeParamsData;
            if (this.item_label && (item.title === this.item_label || dataItem.name === this.item_label)
              || this.item_id && dataItem.item_id === this.item_id)
              return item;
          }
        }
      }
    }
  }

  setParamFromItem(item: TreeItem) {
    return this.setParam(item, item);
  }

  private setParamsFromNode(node: Fancytree.FancytreeNode) {
    if (!node || node.isRootNode())
      return;

    this.setParam(node.data, node);

    this.setParamsFromNode(node.getParent());
  }

  private setParam(paramData: StorNodeParamsData, param: { title: string, children?: any[] }) {
    if (!paramData || !param)
      return this;

    if ((paramData.item_id || paramData.subsystem) && !param.children) {
      if (paramData.item_id) {
        this.item_id = paramData.item_id;
      } else {
        if (paramData.name)
          this.item_label = paramData.name;
        else
          this.item_label = param.title;
        if (paramData.parent_device) {
          this.parent_device = paramData.parent_device;
          this.device_label = paramData.device_label;
        }
      }
    }
    else if (!paramData.object_id && !paramData.group && !param.children) {
      if (paramData.class || paramData.subsys || paramData.item || paramData.name) {
        if (paramData.class) {
          this.device_class = paramData.class;
        }
        if (paramData.subsys)
          this.subsys = paramData.subsys;
        if (paramData.parent_device) {
          this.parent_device = paramData.parent_device;
          this.device_label = paramData.device_label;
        }
        if (paramData.name) {
          this.total_type = paramData.name;
        } else {
          this.total_type = param.title;
        }
      }
    }
    else if (paramData.device_label) {
      this.device_label = paramData.device_label;
    }
    else if (paramData.subsys && paramData.level === StorBacklinkParams.LEVEL_SUBSYS)
      this.subsys = paramData.subsys;
    else if (paramData.hw_type) {
      this.device_hwtype = paramData.hw_type;
      if (!this.device_label)
        this.device_label = param.title;
    }

    return this;
  }
}