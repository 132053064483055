import { HttpClient } from '@angular/common/http';
import { Globals } from 'src/app/globals';
import { Product } from 'src/app/model/product';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class GlobalSearchService {
    constructor(http) {
        this.http = http;
    }
    searchLparGlobal() {
        return this.http.get(Globals.RUNTIME_PATH + Product.LPAR.pathCgi + '/dbwrapper.sh?procname=searchGlobal&q=' + this.globalSearchQuery);
    }
    searchStorGlobal() {
        return this.http.get(Globals.RUNTIME_PATH + Product.STOR.pathCgi + '/dbwrapper.sh?procname=searchGlobal&q=' + this.globalSearchQuery);
    }
    searchStorOld() {
        return this.http.get(Globals.RUNTIME_PATH + Product.STOR.pathCgi + '/volume-search.sh?LPAR=' + this.globalSearchQuery + '&volsrch=1&sansrch=1', { responseType: 'text' });
    }
    searchLparOld() {
        return this.http.get(Globals.RUNTIME_PATH + Product.LPAR.pathCgi + '/lpar-search.sh?LPAR=' + this.globalSearchQuery, { responseType: 'text' });
    }
}
GlobalSearchService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GlobalSearchService_Factory() { return new GlobalSearchService(i0.ɵɵinject(i1.HttpClient)); }, token: GlobalSearchService, providedIn: "root" });
