import { Component, OnInit } from '@angular/core';
import { UserService } from './user.service';
import { LoggerService } from 'src/app/logger.service';
import { MatDialog } from '@angular/material/dialog';
import { UserProfilePasswordComponent } from './user-profile-password/user-profile-password.component';
import { User } from 'src/app/model/user';

@Component({
  selector: 'xormon-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  user = new User();
  adGroups = '';
  localGroups = '';

  constructor(private userService: UserService, private log: LoggerService, private dialog: MatDialog) { }

  ngOnInit() {
    this.userService.getCurrentUser().subscribe(data => {
      this.user = data;
      this.adGroups = this.user.adGroups.map(g => g.name).join(', ');
      this.localGroups = this.user.localGroups.map(g => g.name).join(', ');
    }, error => this.log.error('Failed to get current user data!', error));
  }

  changePassword() {
    this.dialog.open(UserProfilePasswordComponent);
  }
}
