import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationService } from 'primeng/api';
import { LoggerService } from 'src/app/logger.service';
import { Group } from 'src/app/model/group';
import { User } from 'src/app/model/user';
import { GroupsUser, UserEditComponent } from './user-edit/user-edit.component';

interface ExtendedUser extends User {
  localGroupNames: string;
}

@Component({
  selector: 'xormon-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  users: ExtendedUser[];
  groups: Group[];

  constructor(private http: HttpClient, private log: LoggerService, private confirmService: ConfirmationService,
    private dialog: MatDialog) {

  }

  ngOnInit() {

    this.loadUsers();

    this.http.get<Group[]>('/admin/userGroups/getAllLocalGroups').subscribe(data => this.groups = data,
      error => this.log.warn('Failed to get user groups!'));
  }

  private loadUsers() {
    this.users = null;
    this.http.get<ExtendedUser[]>('/admin/users/list').subscribe(data => {
      this.users = data;
      for (const user of this.users) {
        user.localGroupNames = user.localGroups.map(g => g.name).join(', ');
      }
    },
      error => this.log.error('Failed to get user list!', error));
  }

  addUser() {
    let u = new User();
    u.enabled = true;
    u.localGroups = [];
    this.dialog.open(UserEditComponent, {
      data: {
        groups: [...this.groups],
        user: u
      }
    }).afterClosed().subscribe(data => {
      if (data)
        this.loadUsers();
    });
  }

  editUser(user: ExtendedUser, event) {
    event.preventDefault();
    let ucopy = { ...user };
    ucopy.localGroups = [...ucopy.localGroups];
    this.dialog.open(UserEditComponent, {
      data:
        {
          groups: [...this.groups.filter(value => user.localGroups.every(val => val.id !== value.id))],
          user: ucopy
        } as GroupsUser

    }).afterClosed().subscribe(data => {
      if (data)
        this.loadUsers();
    });
  }

  deleteUser(user: ExtendedUser, event) {
    event.preventDefault();
    this.confirmService.confirm({
      message: 'Are you sure to delete user ' + user.name + '?',
      accept: () => {
        this.http.delete('/admin/users/delete/' + user.id).subscribe(data => {
          this.loadUsers();
          this.log.info('Successfully delete user with ID ' + user.id);
        },
          error => this.log.error('Failed to delete user with ID ' + user.id, error));
      }
    });
  }

}
