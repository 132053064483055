<div class="card">
  <div class="card-body">
    <xormon-alerting-stor-header label="HW Events Options"></xormon-alerting-stor-header>
    <div class="row">
      <div class="col-lg-8 col-xl-6">
        <form class="form-horizontal" (ngSubmit)="saveCfg()" #storAlertingHW="ngForm">
          <fieldset>
            <div class="form-group">
              <label class="control-label">Nagios alerting</label>
              <div>
                <p-overlayPanel #opNagiosEvent appendTo="body">
                  Call this script from nrpe.cfg: bin/check_stor2rrd<br>More details on <a
                    [href]="urlNagios">{{urlNagios}}</a><br>[0/1] on/off
                </p-overlayPanel>
                <input type="number" class="form-control" placeholder="0/1 on/off" max="1" min="0" name="nagios"
                  [(ngModel)]="cfg.NAGIOS_EVENT" #nagiosEvent [xormon-poptip]="opNagiosEvent">
                <div *ngIf="nagiosEvent.invalid" class="text-danger">Please insert only numbers 0 and 1</div>
              </div>
            </div>
            <div class="form-group">
              <label class="control-label">External script for alerting</label>
              <div>
                <p-overlayPanel #opExternAlertEvent appendTo="body">
                  It will be called once an alarm appears with these 5 parameters:<br>
                  <pre>script.sh  [storage] [volume] [metric] [actual value] [limit]</pre>- you can use
                  <b>bin/external_alert_example.sh</b> as an example<br>- script must be placed in
                  <b>STOR2RRD_HOME/bin</b> and path start with <b>bin/</b>
                </p-overlayPanel>
                <input type="text" class="form-control" placeholder="Path to a script"
                  [(ngModel)]="cfg.EXTERN_ALERT_EVENT" name="externAlertEvent" [xormon-poptip]="opExternAlertEvent">
              </div>
            </div>
            <div class="form-group">
              <label class="control-label">E-mail targets</label>
              <div>
                <p-overlayPanel #opEmailEvent appendTo="body">
                  Define email targets separated by a space
                </p-overlayPanel>
                <input type="text" class="form-control" placeholder="Space separated emails"
                  [(ngModel)]="cfg.EMAIL_EVENT" name="emailEvent" [xormon-poptip]="opEmailEvent">
              </div>
            </div>
            <div class="form-group">
              <label class="control-label">SNMP trap host</label>
              <div>
                <p-overlayPanel #opTrapEvent appendTo="body">
                  Hostname or IP of SNMP trap receiver
                </p-overlayPanel>
                <input type="text" class="form-control" placeholder="Hostname or IP" [(ngModel)]="cfg.TRAP_EVENT"
                  name="trapEvent" [xormon-poptip]="opTrapEvent">
              </div>
            </div>
          </fieldset>
          <input class="btn btn-secondary mr-1" type="button" value="Test event alerting" (click)="testEvents()">
          <input class="btn btn-primary" type="submit" (click)="saveCfg()" value="Save configuration">
        </form>
        <p>
          <br>
          Alert is raised when any device status is changed to red color in the health status page.<br>
          This setup is used even for <a [href]="urlCopy">Copy Services</a>
          alerting.
        </p>
      </div>
    </div>
  </div>
</div>
