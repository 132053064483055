import { HttpErrorResponse } from '@angular/common/http';
import { OnDestroy } from '@angular/core';
import { RxStompService } from '@stomp/ng2-stompjs';
import { MessageService } from 'primeng/api';
import customMsgs from 'src/app/wlabel.json';
import { format } from 'util';
import { Globals } from './globals';
import { RingBuffer } from './lib/ring-buffer';
import * as i0 from "@angular/core";
import * as i1 from "primeng/components/common/messageservice";
import * as i2 from "@stomp/ng2-stompjs";
export class LoggerService {
    constructor(msgService, rxStompService) {
        this.msgService = msgService;
        this.rxStompService = rxStompService;
        this.latestLogs = new RingBuffer(LoggerService.MAX_ERRORS_KEPT);
        this.consoleErrors = new RingBuffer(LoggerService.MAX_ERRORS_KEPT);
        this.timeoutError = 6000;
        LoggerService.SINGLETON = this;
        this.oldConsoleError = window.console.error;
        console.error = function (message, ...optionalParams) {
            LoggerService.SINGLETON.oldConsoleError.apply(console, arguments);
            let e = new Error();
            let msg = { msg: format(message, optionalParams), stack: e.stack };
            LoggerService.SINGLETON.consoleErrors.add(msg);
            LoggerService.SINGLETON.logRemotely(JSON.stringify(msg, null, Globals.JSON_INDENT));
        };
    }
    /**
     * Singleton getter, because there is problem injecting service into HttpInterceptor
     */
    static get INSTANCE() {
        return LoggerService.SINGLETON;
    }
    ngOnDestroy() {
        if (this.oldConsoleError) {
            console.error = this.oldConsoleError;
        }
    }
    clearAll() {
        this.msgService.clear();
    }
    logRemotely(msg) {
        this.rxStompService.publish({ destination: '/app/log', body: msg });
    }
    getConsoleErrors() {
        return this.consoleErrors.toArray();
    }
    getLatestLogs() {
        return this.latestLogs.toArray().reverse();
    }
    addLog(log) {
        this.latestLogs.add(log);
        this.logRemotely(JSON.stringify(log, null, Globals.JSON_INDENT));
    }
    error(msg, error, sticky = false) {
        if (error) {
            if (error instanceof Error) {
                error = { name: error.name, message: error.message, stack: error.stack };
            }
            else if (error instanceof HttpErrorResponse) {
                switch (error.status) {
                    case Globals.STATUS_CODE_UNAUTHORIZED:
                        if (!sticky)
                            return;
                        break;
                    case Globals.STATUS_CODE_IP:
                        error.msg = msg;
                        msg = `Xormon's host not allowed in backend configuration!<br><a href="${customMsgs.xormonMode}" target="_blank">Help page</a>`;
                        break;
                    case Globals.STATUS_CODE_DEMO:
                        // demo access
                        let tmp = msg;
                        msg = error.error.message;
                        error.error.message = tmp;
                        break;
                    default:
                }
            }
            else if (typeof error !== "object") {
                error = { detail: error };
            }
        }
        this.latestLogs.add({ error: msg, detail: Object.assign({}, error) });
        if (error) {
            error.userAgent = window.navigator.userAgent;
            error.about = Globals.ABOUT;
            error.date = new Date();
            error.console = this.consoleErrors.toArray();
            if (error.status === 0) {
                msg = 'Server inaccessible! ' + msg;
                sticky = true;
            }
            if (sticky)
                this.msgService.clear();
            this.msgService.add({
                severity: 'error',
                summary: 'Error (click for detail)',
                detail: msg,
                life: this.timeoutError,
                sticky: sticky,
                data: error,
            });
            if (!(error instanceof HttpErrorResponse))
                this.logRemotely(`${msg}: ${JSON.stringify(error, null, Globals.JSON_INDENT)}`);
        }
        else {
            if (sticky)
                this.msgService.clear();
            this.msgService.add({ severity: 'error', summary: 'Error', detail: msg, life: this.timeoutError, sticky: sticky });
            this.logRemotely(msg);
        }
    }
    warn(msg) {
        this.msgService.add({ severity: 'warn', summary: 'Warning', detail: msg, life: 5000 });
    }
    info(msg) {
        this.msgService.add({ severity: 'info', summary: 'Info', detail: msg });
    }
}
LoggerService.MAX_ERRORS_KEPT = 9;
LoggerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LoggerService_Factory() { return new LoggerService(i0.ɵɵinject(i1.MessageService), i0.ɵɵinject(i2.RxStompService)); }, token: LoggerService, providedIn: "root" });
