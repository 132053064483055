import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';
import { StorageTotalsService } from '../storage-totals.service';

@Component({
  selector: 'xormon-heatmap-local',
  templateUrl: './heatmap-local.component.html',
  styleUrls: ['./heatmap-local.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeatmapLocalComponent implements OnDestroy {

  tabs: any[];
  private navigationSubscription = Subscription.EMPTY;

  constructor(private totalStService: StorageTotalsService, private dataService: DataService, private router: Router,
    private log: LoggerService) {
    this.navigationSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.dataService.nodeSelected.promise.then(() => {
          if (!this.navigationSubscription.closed) {
            if (Globals.isSameNavigation(e))
              return;
            this.init();
          }
        });
      });
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }

  private init() {

    let heatmapObs: Observable<string>;

    heatmapObs = this.totalStService.getLocalHeatMapUrl(this.dataService.selectedNode.data.href);

    heatmapObs.subscribe(data => {
      var tabs = data.match(/<li>.*<\/li>/g);
      if (tabs) {
        this.tabs = [];
        for (var i = 0; i < tabs.length; i++) {
          this.tabs[i] = {};
          this.tabs[i].url = tabs[i].match(/<a(.*?)>/g);
          this.tabs[i].url = this.tabs[i].url[0];
          this.tabs[i].name = tabs[i].match(/<a(.*?)</g);
          this.tabs[i].name = this.tabs[i].name[0];
          this.tabs[i].name = this.tabs[i].name.split(">");
          this.tabs[i].name = this.tabs[i].name[1];
          this.tabs[i].name = this.tabs[i].name.replace("<", "");
        }
        var urlName = this.tabs[0].url;
        this.callUrl(urlName, '#tab0');
      } else {
        this.tabs = null;
        data = this.replaceHelp(data);
        setTimeout(() => {
          $('#heatPage').html(data);
          this.fixSize(data);
          this.dataService.registerBacklinks();
        });
      }
    }, error => this.log.error('Failed to get heatmaps!', error));

  }

  private callUrl(urlName: string, id: string) {

    urlName = urlName.replace("<a href=", "");
    urlName = urlName.replace(">", "");
    urlName = urlName.replace(/"/g, "");

    this.totalStService.getHeatmapTab(urlName).subscribe(page => {

      page = this.replaceHelp(page);
      let div = $(id);
      div.empty();
      div.html(page);
      this.fixSize(page);
      var $t = div.find('table.tablesorter');
      if ($t.length) {
        Globals.tableSorter($($t), true);
      }
      this.dataService.registerBacklinks();
    }, error => this.log.error('Failed to get heatmap!', error));
  }

  private replaceHelp(html: string) {
    return html.replace(/<img (.*?)>/g, '<i class="fas fa-info-circle" title="Help for heatmap" alt="Heatmap"></i>');
  }

  fixSize(data) {
    const style_tag = data.match(/<style>.*<\/style>/g);
    if (!style_tag)
      return;
    const class_stats = style_tag[0].match(/\..*tier.*?}/g);
    if (!class_stats)
      return;
    const classes_stats = class_stats[0].split("}");
    if (!classes_stats)
      return;
    classes_stats.forEach(one_class => {
      if (one_class && one_class.length) {
        const class_name = one_class.match(/\.[a-zA-Z&_0-9&-]*/);
        const size_tmp = one_class.match(/height.*?;/g);
        const size = size_tmp[0].match(/\d+/);
        $(class_name[0]).width(size[0]);
        $(class_name[0]).height(size[0]);
      }
    });
  }

  onTabSelected(indexObj) {
    var index = indexObj.index;
    var tabCont = $('#tab' + index).html();
    if ((tabCont === null || tabCont === undefined || tabCont.match(/table/g) === null) && index !== -1) {

      this.callUrl(this.tabs[index].url, '#tab' + index);

    }

  }

}
