<div class="card">
  <div class="card-body">
    <table class="table max-width w-auto">
      <tbody>
        <tr>
          <td>Name:</td>
          <td class="font-weight-bold">{{user.name}}</td>
        </tr>
        <tr>
          <td>Username:</td>
          <td class="font-weight-bold">{{user.username}}</td>
        </tr>
        <tr>
          <td>Account created at:</td>
          <td class="font-weight-bold">{{user.createdAt | date:'yyyy-MM-dd HH:mm'}}</td>
        </tr>
        <tr>
          <td>ADMIN authority:</td>
          <td class="font-weight-bold">{{user.admin}}</td>
        </tr>
        <tr>
          <td>Assigned local groups:</td>
          <td class="font-weight-bold">
            {{localGroups}}
          </td>
        </tr>
        <tr *ngIf="user.ldap">
          <td>Assigned LDAP groups:</td>
          <td class="font-weight-bold">
            {{adGroups}}
          </td>
        </tr>
        <tr *ngIf="user.ldap">
          <td>Effective local groups:</td>
          <td class="font-weight-bold">
            <span *ngFor="let g of user.effectiveLocalGroups">
              {{g.name}}&nbsp;
            </span>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="form-group" *ngIf="!user.ldap">
      <button class="btn btn-link px-0" (click)="changePassword()"><u>Change password ...</u></button>
    </div>

  </div>
</div>
