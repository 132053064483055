import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { RxStompService } from '@stomp/ng2-stompjs';
import { MessageService } from 'primeng/api';
import customMsgs from 'src/app/wlabel.json';
import { format } from 'util';
import { Globals } from './globals';
import { RingBuffer } from './lib/ring-buffer';

interface ErrorLog {
  msg: string;
  stack: string;
}

@Injectable({
  providedIn: 'root'
})
export class LoggerService implements OnDestroy {

  static readonly MAX_ERRORS_KEPT = 9;

  private static SINGLETON: LoggerService;

  /**
   * Singleton getter, because there is problem injecting service into HttpInterceptor
   */
  static get INSTANCE() {
    return LoggerService.SINGLETON;
  }

  private readonly latestLogs = new RingBuffer<any>(LoggerService.MAX_ERRORS_KEPT);
  private readonly consoleErrors = new RingBuffer<ErrorLog>(LoggerService.MAX_ERRORS_KEPT);

  private readonly timeoutError = 6000;

  private readonly oldConsoleError;

  constructor(private msgService: MessageService, private rxStompService: RxStompService) {
    LoggerService.SINGLETON = this;
    this.oldConsoleError = window.console.error;
    console.error = function (message?, ...optionalParams) {
      LoggerService.SINGLETON.oldConsoleError.apply(console, arguments);
      let e = new Error();
      let msg = { msg: format(message, optionalParams), stack: e.stack };
      LoggerService.SINGLETON.consoleErrors.add(msg);
      LoggerService.SINGLETON.logRemotely(JSON.stringify(msg, null, Globals.JSON_INDENT));
    };
  }

  ngOnDestroy(): void {
    if (this.oldConsoleError) {
      console.error = this.oldConsoleError;
    }
  }

  clearAll() {
    this.msgService.clear();
  }

  private logRemotely(msg: string) {
    this.rxStompService.publish({ destination: '/app/log', body: msg });
  }

  getConsoleErrors() {
    return this.consoleErrors.toArray();
  }

  getLatestLogs() {
    return this.latestLogs.toArray().reverse();
  }

  addLog(log) {
    this.latestLogs.add(log);
    this.logRemotely(JSON.stringify(log, null, Globals.JSON_INDENT));
  }

  error(msg: string, error?, sticky = false) {
    if (error) {
      if (error instanceof Error) {
        error = { name: error.name, message: error.message, stack: error.stack };
      } else if (error instanceof HttpErrorResponse) {
        switch (error.status) {
          case Globals.STATUS_CODE_UNAUTHORIZED:
            if (!sticky)
              return;
            break;
          case Globals.STATUS_CODE_IP:
            (error as any).msg = msg;
            msg = `Xormon's host not allowed in backend configuration!<br><a href="${customMsgs.xormonMode}" target="_blank">Help page</a>`;
            break;
          case Globals.STATUS_CODE_DEMO:
            // demo access
            let tmp = msg;
            msg = error.error.message;
            error.error.message = tmp;
            break;
          default:
        }
      } else if (typeof error !== "object") {
        error = { detail: error };
      }
    }
    this.latestLogs.add({ error: msg, detail: { ...error } });
    if (error) {
      error.userAgent = window.navigator.userAgent;
      error.about = Globals.ABOUT;
      error.date = new Date();
      error.console = this.consoleErrors.toArray();
      if (error.status === 0) {
        msg = 'Server inaccessible! ' + msg;
        sticky = true;
      }
      if (sticky)
        this.msgService.clear();
      this.msgService.add({
        severity: 'error',
        summary: 'Error (click for detail)',
        detail: msg,
        life: this.timeoutError,
        sticky: sticky,
        data: error,
      });
      if (!(error instanceof HttpErrorResponse))
        this.logRemotely(`${msg}: ${JSON.stringify(error, null, Globals.JSON_INDENT)}`);
    }
    else {
      if (sticky)
        this.msgService.clear();
      this.msgService.add({ severity: 'error', summary: 'Error', detail: msg, life: this.timeoutError, sticky: sticky });
      this.logRemotely(msg);
    }
  }

  warn(msg: string) {
    this.msgService.add({ severity: 'warn', summary: 'Warning', detail: msg, life: 5000 });
  }

  info(msg: string) {
    this.msgService.add({ severity: 'info', summary: 'Info', detail: msg });
  }
}
