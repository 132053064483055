import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { forkJoin } from 'rxjs';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';
import { AlertingService } from '../alerting.service';

@Component({
  selector: 'xormon-alerting-stor-logs',
  templateUrl: './alerting-stor-logs.component.html',
  styleUrls: ['./alerting-stor-logs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AlertingStorLogsComponent implements OnInit {
  constructor(private alertingService: AlertingService, private log: LoggerService, private dataService: DataService) { }

  ngOnInit() {
    this.dataService.nodeSelected.promise.then(() => {
      forkJoin(this.alertingService.getStorPerfLogs(), this.alertingService.getStorHwLogs()).subscribe(
        results => {
          $('#perf_logs').html(results[0]);
          Globals.tableSorter($('#perf_logs table'), true);

          $('#hw_logs').html(results[1]);
          Globals.tableSorter($('#hw_logs table'), true);

          $('#hw_logs > div.floatThead-container').css('overflow-y', 'hidden');
        },
        error => this.log.error('Failed to get logs!', error)
      );
    });
  }
}
