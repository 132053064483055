import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';
import { ReportTabPage } from 'src/app/model/tabs';

@Component({
  selector: 'xormon-top10',
  templateUrl: './top10.component.html',
  styleUrls: ['./top10.component.scss']
})
export class Top10Component implements OnInit, OnDestroy {

  tops: ReportTabPage;
  private navigationSubscription = Subscription.EMPTY;

  constructor(private http: HttpClient, private log: LoggerService, private dataService: DataService,
    private router: Router) {
    this.navigationSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.dataService.nodeSelected.promise.then(() => {
          if (!this.navigationSubscription.closed) {
            if (Globals.isSameNavigation(e))
              return;
            this.init();
          }
        });
      });
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }

  ngOnInit() {
  }

  private init() {
    this.tops = null;
    var href = this.dataService.selectedNode.data.href;
    if ( ! this.dataService.selectedNode.data.href && this.dataService.selectedNode.data.url) {
      href = this.dataService.selectedNode.data.url;
    }
    this.http.post<ReportTabPage>('/api/reportTabs', href).subscribe(data => {
      this.tops = data;
      Globals.sanitizeReportPage(this.tops);
      this.tabChanged(null);
    }, error => this.log.error('Failed to get TOPs data!', error));
  }

  tabChanged(event: MatTabChangeEvent) {
    setTimeout(() => {
      this.dataService.registerBacklinks();
      Globals.initFloatingScroll();
    });
  }
}
