import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/data.service';
import { Product } from 'src/app/model/product';
import { AlertingService } from '../alerting.service';

@Component({
  selector: 'xormon-alerting-lpar-email',
  templateUrl: './alerting-lpar-email.component.html',
  styleUrls: ['./alerting-lpar-email.component.scss']
})
export class AlertingLparEmailComponent implements OnInit {
  readyFce;
  formFce;

  constructor(private dataService: DataService, private alertingService: AlertingService) { }

  ngOnInit(): void {
    this.dataService.nodeSelected.promise.then(() => {
      this.readyFce = Product.LPAR.module.myreadyFunc;
      this.formFce = this.alertingService.getLparForm.bind(this.alertingService);
    });
  }
}
