<div class="nobase64 graph-inner" #element>
  <a [href]="host + graph.imgSrc.replace('detail=9','detail=1')" style="line-height: 200px;"
    title="Click to show detail" class="detail" *ngIf="!error">
    <div [ngClass]="{'d-block': !graph.loaded, 'd-inline-block': graph.loaded}">
      <img *ngIf="graph.loaded" [src]="graph.imgData" [attr.data-src]="graph.imgSrc" [attr.data-title]="graph.title">
      <div [id]="'img_'+graph.idx" [ngClass]="{'zoom': graph.zoom, 'nozoom': !graph.zoom}"
        [title]="graph.zoom ? 'Click and drag to select range' : ''">
        <xormon-loading-indicator *ngIf="graph.loading"></xormon-loading-indicator>
      </div>
    </div>
  </a>
  <div *ngIf="error" class="db_error_placeholder">
    {{error}}
  </div>
</div>
