/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./heatmap-local-lpar.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i3 from "@angular/material/tabs";
import * as i4 from "../../../loading-indicator/loading-indicator.component.ngfactory";
import * as i5 from "../../../loading-indicator/loading-indicator.component";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/common";
import * as i8 from "./heatmap-local-lpar.component";
import * as i9 from "@angular/common/http";
import * as i10 from "../../../logger.service";
import * as i11 from "../../../data.service";
var styles_HeatmapLocalLparComponent = [i0.styles];
var RenderType_HeatmapLocalLparComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_HeatmapLocalLparComponent, data: {} });
export { RenderType_HeatmapLocalLparComponent as RenderType_HeatmapLocalLparComponent };
function View_HeatmapLocalLparComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 7, "mat-tab", [], null, null, null, i2.View_MatTab_0, i2.RenderType_MatTab)), i1.ɵdid(1, 770048, [[1, 4]], 2, i3.MatTab, [i1.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i1.ɵqud(603979776, 2, { templateLabel: 0 }), i1.ɵqud(335544320, 3, { _explicitContent: 0 }), (_l()(), i1.ɵeld(4, 0, null, 0, 0, "div", [["classx", "scroll-wrap stickyheaders-holder"]], [[8, "hidden", 0], [8, "innerHTML", 1]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, 0, 2, "div", [["class", "spinner-wrap"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "xormon-loading-indicator", [], null, null, null, i4.View_LoadingIndicatorComponent_0, i4.RenderType_LoadingIndicatorComponent)), i1.ɵdid(7, 114688, null, 0, i5.LoadingIndicatorComponent, [], null, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.textContent, ""); _ck(_v, 1, 0, currVal_0); _ck(_v, 7, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = !_co.loaded; var currVal_2 = _co.page; _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_3 = _co.loaded; _ck(_v, 5, 0, currVal_3); }); }
export function View_HeatmapLocalLparComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "mat-tab-group", [["class", "mat-tab-group"], ["id", "heatmaps"]], [[24, "@.disabled", 0], [2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], [[null, "selectedIndexChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedIndexChange" === en)) {
        var pd_0 = (_co.updateTab($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatTabGroup_0, i2.RenderType_MatTabGroup)), i1.ɵdid(1, 3325952, null, 1, i3.MatTabGroup, [i1.ElementRef, i1.ChangeDetectorRef, [2, i3.MAT_TABS_CONFIG], [2, i6.ANIMATION_MODULE_TYPE]], null, { selectedIndexChange: "selectedIndexChange" }), i1.ɵqud(603979776, 1, { _tabs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeatmapLocalLparComponent_1)), i1.ɵdid(4, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.heatmaps; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 1).dynamicHeight; var currVal_2 = (i1.ɵnov(_v, 1).headerPosition === "below"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
export function View_HeatmapLocalLparComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xormon-heatmap-local-lpar", [], null, null, null, View_HeatmapLocalLparComponent_0, RenderType_HeatmapLocalLparComponent)), i1.ɵdid(1, 114688, null, 0, i8.HeatmapLocalLparComponent, [i9.HttpClient, i10.LoggerService, i11.DataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeatmapLocalLparComponentNgFactory = i1.ɵccf("xormon-heatmap-local-lpar", i8.HeatmapLocalLparComponent, View_HeatmapLocalLparComponent_Host_0, {}, {}, []);
export { HeatmapLocalLparComponentNgFactory as HeatmapLocalLparComponentNgFactory };
