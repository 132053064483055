<div class="card">
  <div class="card-body">

    <div style='float: left; margin-right: 10px; outline: none' class="cggrpnames">
    <table id="repgrptable" class="cfgtree center table table-hover thead-light max-width">
      <thead>
        <tr>
          <th><button id="addgrp" class="btn btn-sm btn-secondary btn-add">New group</button></th>
          <th>Edit</th>
          <th>Delete</th>
          <th>Description</th>
          <th>E-mails</th>
          <!--th><button id="cgcfg-help-button" title="Help on usage">?</button></th-->
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>

     </div>
  </div>
</div>
