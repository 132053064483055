import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { ActivationStart, Router } from '@angular/router';
import { BlockUI } from 'ng-block-ui';
import { MessageService } from 'primeng/api';
import { filter } from 'rxjs/operators';
import { DataService } from './data.service';
import { Globals } from './globals';
import { LoggerService } from './logger.service';
export class AppComponent {
    constructor(router, dataService, log, msgService) {
        this.router = router;
        this.dataService = dataService;
        this.log = log;
        this.msgService = msgService;
        this.errorDialogShown = false;
        this.copyingClipboard = false;
        this.router.events.pipe(filter(e => e instanceof ActivationStart && e.snapshot.outlet === Globals.OUTLET_CONTENT))
            .subscribe((e) => {
            const outlet = Globals.getContentOutlet(this.router);
            if (outlet)
                outlet.deactivate();
        });
    }
    ngOnInit() {
        AppComponent.block = this.blockUI;
    }
    get treeWidth() {
        return Globals.treeWidth;
    }
    get isLogin() {
        return !Globals.authenticated
            || this.router.url.startsWith(Globals.PATH_LOGIN);
    }
    get version() {
        return Globals.ABOUT.xormonVersion;
    }
    get buildTime() {
        return Globals.ABOUT.xormonBuildTime;
    }
    get versionLabel() {
        return AppComponent.VERSION_LABEL;
    }
    get isContent() {
        if (this.dataService.selectedNode)
            return true;
        return Globals.hasComponent(Globals.getContentOutlet(this.router));
    }
    showError(error, event) {
        event.preventDefault();
        this.errorMessage = error;
        this.errorDialogShown = true;
        this.msgService.clear();
    }
    errorToClipboard(error) {
        if (this.copyingClipboard)
            return;
        this.copyingClipboard = true;
        let str = JSON.stringify(Object.assign({}, error.data, { detail: error.detail }), null, Globals.JSON_INDENT);
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = str;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        try {
            if (document.execCommand('copy')) {
                let link = $('#errorCopy i');
                link.effect({ effect: "bounce", complete: () => this.copyingClipboard = false });
            }
            else {
                this.log.error('Failed to store detail in clipbboard!');
                this.copyingClipboard = false;
            }
        }
        catch (err) {
            console.error(err);
            this.log.error('Failed to store detail in clipboard!');
            this.copyingClipboard = false;
        }
        document.body.removeChild(selBox);
    }
    reloadPage(event) {
        event.preventDefault();
        location.reload();
    }
}
AppComponent.VERSION_LABEL = 'Version: ';
tslib_1.__decorate([
    BlockUI(),
    tslib_1.__metadata("design:type", Object)
], AppComponent.prototype, "blockUI", void 0);
