<div class="card">
  <div class="card-body">
    <div class="alerting_wrapper" style="float:left; width: 900px; max-width: 1200px;">
    <xormon-alerting-lpar-header [label]="'Alerts'"></xormon-alerting-lpar-header>
    <form id="optform" class="d-none"></form>
    <table id="alrtgrptree" class="d-none">
      <thead>
        <tr>
          <th class="d-none"></th>
          <th class="d-none"></th>
          <th id="addcgrpth">
            E-mail group &nbsp;<button id="addalrtgrp" class="btn btn-sm btn-secondary btn-add">
              Add New
            </button>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody></tbody>
    </table>
    <div class="modal-alert" style="font-size: smaller;">
      <table id="alrttree" class="cfgtree center table max-width" tabindex="0" role="treegrid"
        aria-multiselectable="true" aria-readonly="true">
        <thead>
          <tr>
            <th class="d-none"></th>
            <th class="d-none"></th>
            <th>
              <button title="Expand/Collapse toggle" class="ui-icon-arrowthick-2-n-s" (click)="toggleNodes()">
                +/-
              </button>
              <button class="btn btn-sm btn-secondary btn-add" id="addnewalrt">
                Add New Alert
              </button>
            </th>
            <th>Metric</th>
            <th><abbr title="limit value">Limit</abbr></th>
            <th>
              <abbr title="value is in percent (CPU &amp; CPU OS only)">%</abbr>
            </th>
            <th>
              <abbr title="time in minutes for length peak above the limit [10-unlimited]">Peak</abbr>
            </th>
            <th>
              <abbr title="minimum time in minutes between 2 alerts for the same rule [10-unlimited]">Repeat</abbr>
            </th>
            <th>
              <abbr
                title="time range in hours when the alerting is off [0-24]-[0-24]. Ex. 22-05&nbsp;(excludes alerting from 10pm to 5am)">Exclude
                hours</abbr>
            </th>
            <th>Mail group</th>
            <th></th>
          </tr>
        </thead>
        <tbody></tbody>
      </table>
    </div>
    <div>
      <!--                    <input class="btn btn-default" type="button" ng-click="testAlerting()" value="Test alerting" /> -->
      <input class="savealrtcfg btn btn-primary" type="button" value="Save configuration" />
      <br /><br />
    </div>
    <pre id="aclfilepre" class="d-none">
      <div class="hidden log-result" id="aclfile" ></div>
    </pre>
    <div id="freeinfo" *ngIf="free">
      <p>You are using LPAR2RRD Free Edition, only 3 top items are allowed. Consider to upgrade to the <a
          [href]="urls.lparSupport" target="_blank"><b>enterprise edition</b></a>.</p>
    </div>
  </div>
</div>
</div>
