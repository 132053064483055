import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoggerService } from 'src/app/logger.service';
import { DashboardTab, SharedDashboard } from 'src/app/model/dashboard';

@Injectable({
  providedIn: 'root'
})
export class PortalService {

  constructor(private http: HttpClient, private log: LoggerService) {
  }

  public getAllTabs() {
    return this.http.get<DashboardTab[]>('/api/portal/tabs');
  }

  public saveTabs(tabs: DashboardTab[]) {
    return this.http.put<DashboardTab[]>('/api/portal/tabs', tabs);
  }

  public getReferencedTabs() {
    return this.http.get<SharedDashboard[]>('/api/portal/referenced');
  }

  public deleteTab(id) {
    return this.http.delete('/api/portal/tab/' + id);
  }

  public removeTab(id) {
    return this.http.delete('/api/portal/tabReference/' + id);
  }

}
