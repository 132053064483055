<h1 mat-dialog-title *ngIf="user.id">Edit user ID {{user.id}}</h1>
<h1 mat-dialog-title *ngIf="!user.id">Add new user</h1>
<form (ngSubmit)="saveUser()" #f="ngForm" [matchValue]="['password', 'confirmPassword']">
  <div mat-dialog-content style="width: 600px; overflow: hidden;">
    <div class="row">
      <div class="col-md-6 form-group">
        <label>Username</label>
        <input [hidden]="user.ldap" class="form-control" [(ngModel)]="user.username" type="email" email
          required="required" placeholder="Enter unique login email" autofocus="autofocus" name="remail"
          #remail="ngModel" [disabled]="user.id" maxlength="250">
        <div *ngIf="remail?.invalid" class="text-danger">Please insert valid email</div>
        <input *ngIf="user.ldap" class="form-control" [(ngModel)]="user.username" disabled="disabled" type="text"
          required="required" placeholder="Enter unique login email" name="remail">
      </div>
      <div class="col-md-6 form-group">
        <label>Full name</label>
        <input class="form-control" [(ngModel)]="user.name" required="required"
          placeholder="Enter user's first name and surname" name="name" maxlength="250">
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 form-group">
        <label>Password:</label>
        <input class="form-control" [disabled]="user.ldap" [(ngModel)]="user.password" type="password" maxlength="50"
          [required]="!user.id" placeholder="Enter user's password" minlength="6" name="password" #password="ngModel"
          autocomplete="new-password">
        <div *ngIf="password?.invalid" class="text-danger">Minimal length is 6 characters, max 50</div>
      </div>
      <div class="col-md-6 form-group">
        <label>Confirm password:</label>
        <input class="form-control" [disabled]="user.ldap" [(ngModel)]="user.passwordConfirm" type="password"
          [required]="!!(!user.id || user.password)" #confirmPassword="ngModel" placeholder="Repeat user's password"
          name="confirmPassword" autocomplete="new-password">
        <div *ngIf="confirmPassword?.invalid" class="text-danger">Passwords do not match</div>
      </div>
    </div>
    <div class="row" [title]="adminTitle">
      <div class="col-md-6">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="checkbox" id="userEnabled" name="userEnabled" [(ngModel)]="user.enabled"
            [disabled]="user.id==1">
          <label class="form-check-label" for="userEnabled">Account Enabled</label>
        </div>
      </div>
      <div class="col-md-6">

      </div>
    </div>
    <p-pickList id="list" [source]="sourceGroups" [target]="user.localGroups" sourceHeader="Available Groups"
      targetHeader="Selected Groups" [responsive]="true" [dragdrop]="true" [sourceStyle]="{'height':'200px'}"
      [targetStyle]="{'height':'200px'}" [showSourceControls]="false" [showTargetControls]="false">
      <ng-template let-group pTemplate="item">
        <div class="ui-helper-clearfix">
          <div title="{{group.description}}">{{group.name}}</div>
        </div>
      </ng-template>
    </p-pickList>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button color="primary" type="submit" [disabled]="f.invalid || saving || defaultAdminIsNotAdmin()"
      cdkFocusInitial>Save</button>
  </div>
</form>
