<div class="card" id="content">
  <div class="card-body">
    <div class="configuration_wrapper">
    <xormon-alerting-stor-header label="Alerts"></xormon-alerting-stor-header>
    <form id="optform" class="d-none"></form>
    <table id="alrtgrptree" class="d-none">
      <thead>
        <tr>
          <th class="d-none"></th>
          <th class="d-none"></th>
          <th id="addcgrpth">
            E-mail group &nbsp;<button id="addalrtgrp" class="btn btn-sm btn-secondary btn-add">
              Add New
            </button>
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody></tbody>
    </table>
    <table id="alrttree" class="cfgtree center" tabindex="0" role="treegrid" aria-multiselectable="true"
      aria-readonly="true">
      <!-- <colgroup>
        <col width="2px">
        <col width="2px">
        <col width="250px">
        <col width="100px">
        <col width="45px">
        <col width="45px">
        <col width="45px">
        <col width="45px">
        <col width="110px">
        <col width="120px">
        <col width="10px">
        <col width="20px">
      </colgroup> -->
      <thead>
        <tr>
          <th class="d-none"></th>
          <th class="d-none"></th>
          <th id="addcgrpth">Alert <button id="addnewalrt" class="btn btn-sm btn-secondary btn-add">Add New</button>
          </th>
          <th>Subsys/Metric</th>
          <th style="text-align: center"><abbr title="Threshold value in MB/sec, IOPS or milliseconds">Limit</abbr></th>
          <th style="text-align: center"><abbr
              title="time in minutes for (length ;peak ;above )the limit [5-120]">Peak</abbr></th>
          <th style="text-align: center"><abbr
              title="minimum time in minutes between 2 alerts for (the ;same ;rule [5-168])">Repeat</abbr></th>
          <th style="text-align: center"><abbr
              title="IO per second threshold for (response ;time ;metrics )only. Alert is issued only if (IOPS )is higher than this threshold. Empty field or 0 means no threshold limit.">IO&nbsp;thold</abbr>
          </th>
          <th><abbr
              title="time range in hours when the alerting is off [0-24]-[0-24]. Ex. 22-05&nbsp;(excludes ,alerting ,from ,10,pm ,to ,5,am)">Exclude
              hours</abbr></th>
          <th>Mail group</th>
          <th></th>
          <!--th><button id="cgcfg-help-button" title="Help on usage">?</button></th-->
        </tr>
      </thead>
      <tbody>
      </tbody>
    </table>
    <div class="mb-4">
      <input class="btn btn-secondary mr-2" type="button" value="Test alerting" id="testalrtcfg">
      <input class="savealrtcfg btn btn-primary" type="button" value="Save configuration">
    </div>
    <pre id="aclfilepre" class="d-none">
      <div class="hidden log-result" id="aclfile"></div>
    </pre>
    </div>
    <div id="freeinfo" *ngIf="free">
      <p>You are using STOR2RRD Free Edition, only 3 top items are allowed. Consider to upgrade to the <a
          [href]="urls.storSupport" target="_blank"><b>enterprise edition</b></a>.</p>
    </div>
  </div>
</div>
