<div class="card">
  <div class="card-body">
    <xormon-alerting-lpar-header label="Logs"></xormon-alerting-lpar-header>
    <div class="tablesorter-wrapper">
      <div id="lparLogs" class="table-height-md">
        <div class="spinner-wrap">
          <xormon-loading-indicator></xormon-loading-indicator>
        </div>
      </div>
    </div>
  </div>
</div>
