import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Product } from 'src/app/model/product';
import { Globals } from '../../../globals';
import { StorageGroupItem, StorageItem } from '../../../model/storage-groups';


@Injectable({
  providedIn: 'root'
})
export class StorageGroupingService {

  constructor(private http: HttpClient) { }

  getUserGroups() {
    return this.http.get<StorageGroupItem[]>('/api/storageGroups');
  }

  getStorGrList(): Observable<StorageGroupItem[]> {
    return this.http.get<StorageGroupItem[]>('/admin/storageGroups/list/');
  }

  getStorages() {
    return this.http.get<StorageItem[]>(Globals.RUNTIME_PATH + Product.STOR.pathCgi + '/dbwrapper.sh?procname=getObjects&class=STORAGE');
  }

  saveStorages(children: StorageGroupItem[]): Observable<StorageGroupItem[]> {
    return this.http.put<StorageGroupItem[]>('/admin/storageGroups/storageNames', children);
  }

}

