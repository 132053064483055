/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alerting-lpar-header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./alerting-lpar-header.component";
import * as i4 from "../../../custom-messages.service";
var styles_AlertingLparHeaderComponent = [i0.styles];
var RenderType_AlertingLparHeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertingLparHeaderComponent, data: {} });
export { RenderType_AlertingLparHeaderComponent as RenderType_AlertingLparHeaderComponent };
function View_AlertingLparHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["- ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 1, 0, currVal_0); }); }
export function View_AlertingLparHeaderComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Server Alerting "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertingLparHeaderComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "a", [["class", "float-right heading-icon"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "i", [["alt", "More info about alerting"], ["class", "fas fa-info-circle"], ["title", "More info about the alerting"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.url; _ck(_v, 4, 0, currVal_1); }); }
export function View_AlertingLparHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xormon-alerting-lpar-header", [], null, null, null, View_AlertingLparHeaderComponent_0, RenderType_AlertingLparHeaderComponent)), i1.ɵdid(1, 114688, null, 0, i3.AlertingLparHeaderComponent, [i1.ElementRef, i4.CustomMessagesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertingLparHeaderComponentNgFactory = i1.ɵccf("xormon-alerting-lpar-header", i3.AlertingLparHeaderComponent, View_AlertingLparHeaderComponent_Host_0, { label: "label" }, {}, []);
export { AlertingLparHeaderComponentNgFactory as AlertingLparHeaderComponentNgFactory };
