<div id="health_status" *ngIf="tabPage">
  <mat-tab-group (selectedTabChange)="onTabSelected($event)" [@.disabled]="true"
    *ngIf="tabPage.tps && tabPage.tps.length">
    <mat-tab *ngFor="let tab of tabPage.tps; let i = index" label={{tab.name}}>
      <div *ngIf="!i && (hsOK || hsNOK || hsNA)" class="text-center font-weight-bold"><span class="ok"
          title="OK">{{hsOK}}</span> | <span class="nok" title="Not OK">{{hsNOK}}</span> | <span class="na"
          title="Not available">{{hsNA}}</span></div>
      <div id="{{'tab'+i}}" class="stickyheaders-holder center mt-1">
        <div *ngIf="tab.htmls">
          <div *ngFor="let html of tab.htmls" [innerHTML]="html"></div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div *ngIf="tabPage.tpCommon" class="stickyheaders-holder">
    <div *ngFor="let html of tabPage.tpCommon.htmls" [innerHTML]="html"></div>
  </div>
</div>
<xormon-loading-indicator *ngIf="!tabPage"></xormon-loading-indicator>
