import { HttpClient, HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, HostListener, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { NavigationEnd, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { SelectItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { AbstractGraph } from 'src/app/graph/abstract-graph';
import { GraphComponent } from 'src/app/graph/graph.component';
import { LoggerService } from 'src/app/logger.service';
import { Graph } from 'src/app/model/graph';
import { Product } from 'src/app/model/product';
import { Section, Tab, TabPage, TabType } from 'src/app/model/tabs';

@Component({
  selector: 'xormon-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TabsComponent implements OnInit, OnDestroy, AfterViewInit {

  static activeJson: TabPage;
  static regrouped = false;

  @BlockUI('graphs')
  block: NgBlockUI;

  @Input()
  data: TabPage;

  nTabOptions: SelectItem[] = [
    { label: 'OS Agent', value: 'osagent' },
    { label: 'NMON', value: 'nmon' },
  ];

  tabType = TabType;
  selectedNOption: string;
  nTps: Record<string, Tab[]> = {};
  private navigationSubscription = Subscription.EMPTY;
  private dataSubscription = Subscription.EMPTY;
  currentTabIndex = 0;
  nextTabIndex = -1;
  loading = true;
  private timerIdLong: number;
  private timerIdShort: number;
  private timerResize: number;
  error;
  url: string;
  lparEnterprise = false;
  storEnterprise = false;

  constructor(
    private dataService: DataService,
    private router: Router,
    private log: LoggerService,
    private http: HttpClient
  ) {
    this.navigationSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.dataService.nodeSelected.promise.then(() => {
          if (!this.navigationSubscription.closed) {
            if (Globals.isSameNavigation(e, this.router))
              return;
            this.init();
          }
        });
      });
  }

  ngAfterViewInit(): void {
    this.timerIdShort = window.setInterval(() => {
      this.currentTab.sections.forEach((sec) =>
        sec.graphs.forEach((graph) => {
          if (graph.imgSrc.includes('time=d')) graph.reloadSwitch = !graph.reloadSwitch;
        })
      );
    }, Globals.GRAPH_RELOAD_INTERVAL_SHORT);
    this.timerIdLong = window.setInterval(() => {
      for (const sec of this.currentTab.sections) {
        for (const graph of sec.graphs) {
          if (!graph.imgSrc.includes('time=d')) graph.reloadSwitch = !graph.reloadSwitch;
        }
      }
    }, Globals.GRAPH_RELOAD_INTERVAL_LONG);
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
    this.dataSubscription.unsubscribe();
    clearInterval(this.timerIdShort);
    clearInterval(this.timerIdLong);
    clearTimeout(this.timerResize);
    this.activeJson = null;
  }

  ngOnInit() {
    if (this.data) {
      AbstractGraph.externalData = true;
      this.activeJson = this.data;
      this.prepareData();
      setTimeout(() => {
        this.loading = false;
      });
    } else {
      AbstractGraph.externalData = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    clearTimeout(this.timerResize);
    this.timerResize = window.setTimeout(() => {
      $('.slideGraph, .scroll-wrap').floatingScroll('update');
    }, 200);
  }

  set activeJson(data: TabPage) {
    TabsComponent.activeJson = data;
  }

  get activeJson() {
    return TabsComponent.activeJson;
  }

  get regrouped() {
    return TabsComponent.regrouped;
  }

  set regrouped(status: boolean) {
    TabsComponent.regrouped = status;
  }

  init() {
    this.regrouped = false;
    this.data = null;
    this.error = null;
    this.lparEnterprise = Product.LPAR.info.isEnterpriseEdition();
    this.storEnterprise = Product.STOR.info.isEnterpriseEdition();

    if (this.dataService.selectedNode) {
      let url;
      url = this.dataService.selectedNode.data.href || this.dataService.selectedNode.data.url;
      // if (this.url === url) {
      //   return;
      // }
      this.url = url;
      this.loading = true;
      if (this.dataSubscription) {
        this.dataSubscription.unsubscribe();
      }
      let type = this.dataService.selectedType;
      if (this.dataService.selectedType === Globals.TYPE_REPORT) {
        type = this.dataService.selectedParent;
      }
      this.dataSubscription = this.http.post<TabPage>('/api/pageJson', {
        url,
        type: type,
      }).subscribe(
        (data) => {
          this.error = null;
          this.activeJson = data;
          this.loading = false;
          this.prepareData();
        },
        (error) => {
          this.activeJson = null;
          this.error = error;
          this.loading = false;
          this.log.error('Failed to load graphs!', error);
        }
      );
    }
  }

  private prepareData() {
    if (this.nextTabIndex >= 0) {
      this.currentTabIndex = this.nextTabIndex;
      this.nextTabIndex = -1;
    }
    const nTabs: Tab[] = this.activeJson.tps
      .filter((tab) => tab.name.endsWith('-N'))
      .map((tab) => ({ ...tab, name: tab.name.substr(0, tab.name.length - 2) }));
    if (nTabs.length) {
      const nTwinTabs = this.activeJson.tps.filter((tab) => nTabs.some((value) => tab.name === value.name));
      const commonTabs: Tab[] = this.activeJson.tps.filter(
        (tab) => !tab.name.endsWith('-N') && nTabs.every((value) => tab.name !== value.name)
      );
      commonTabs.forEach((t) => (t.common = true));
      this.nTps[this.nTabOptions[0].value] = commonTabs.concat(nTwinTabs);
      this.nTps[this.nTabOptions[1].value] = commonTabs.concat(nTabs);

      this.selectedNOption = this.nTabOptions[0].value;
      this.nOptionChanged();
    } else {
      this.selectedNOption = null;
      this.nTps = {};
      this.nTps[this.nTabOptions[0].value] = this.activeJson.tps;
    }

    if (this.activeJson.tpCommon) {
      this.fixHtmls(this.activeJson.tpCommon.htmls);
    }

    for (const tab of this.activeJson.tps) {
      this.fixHtmls(tab.htmls);
      let idx = 0;
      for (const section of tab.sections) {
        this.fixHtmls(section.htmls);
        for (const graph of section.graphs) {
          graph.idx = idx++;
        }
      }
    }

    let metric: string;
    if (this.dataService.metric) {
      metric = this.dataService.metric;
      this.dataService.metric = null;
    } else {
      let url = new URL(location.href);
      metric = url.searchParams.get(Globals.METRIC);
    }
    this.setMetric(metric);
    if (metric) {
      for (let i = 0; i < this.activeJson.tps.length; i++) {
        if (this.activeJson.tps[i].metric === metric) {
          this.currentTabIndex = i;
          break;
        }
      }
    }

    this.tabChanged();
  }

  private fixHtmls(htmls: any[]) {
    if (htmls) {
      for (let i = 0; i < htmls.length; i++) {
        let doc = Globals.DOM_PARSER.parseFromString(htmls[i], 'text/html');
        let links = doc.links;
        for (let j = 0; j < links.length; j++) {
          let link = links[j];
          let href = link.getAttribute('href');
          if (href && !href.startsWith('#') && !href.startsWith('http') && !href.includes('/detail.sh'))
            link.setAttribute('href', Globals.API_HOST + Globals.RUNTIME_PATH + href);
        }
        let forms = doc.forms;
        for (let j = 0; j < forms.length; j++) {
          let form = forms[j];
          let action = form.getAttribute('action');
          if (action && !action.startsWith('http')) {
            form.action = Globals.API_HOST + Globals.RUNTIME_PATH + action;
          }
        }
        htmls[i] = Globals.domSanitizer.bypassSecurityTrustHtml(doc.documentElement.outerHTML);
      }
    }
  }

  onLoad(g: Graph) {
    g.notDeferred = true;
  }

  onLoadPrediction(p: string, div: HTMLElement) {
    if (this.dataService.isLpar()) {
      let d = $(div);
      d.html(p);
      Product.LPAR.module.showPrediction(d.children()[0]);
    }
  }

  onLoaded(graph: GraphComponent) {
    // setTimeout(() => {
    //   const element = $(graph.element.nativeElement);
    //   let floatDiv = element.closest('.slideGraph');
    //   if (floatDiv.length === 0)
    //     floatDiv = element.closest('.scroll-wrap');
    //   floatDiv.floatingScroll('update');
    // });
    if (this.regrouped) {
      if (this.currentTab.sections.every(sec => sec.graphs.every(g => !g.zoom || !g.loading || g.error))) {
        this.block.stop();
      }
    }
  }

  loadGraphs(tab: Tab) {
    for (const section of tab.sections) {
      for (const graph of section.graphs) graph.notDeferred = true;
    }
  }

  groupSelectEnd(tab: Tab, selection) {
    for (const sec of this.currentTab.sections) {
      for (const graph of sec.graphs) {
        if (graph.zoom && !graph.error)
          graph.loading = true;
      }
    }
    this.block.start();
    tab.groupSelection = null;
    setTimeout(() => {
      tab.groupSelection = selection;
    });

  }

  private get currentTab() {
    if (this.activeJson)
      return this.activeJson.tps[this.currentTabIndex];
    return null;
  }

  private setMetric(metric: string) {
    let url = new URL(location.href);
    if (metric) {
      url.searchParams.set(Globals.METRIC, metric);
    } else {
      url.searchParams.delete(Globals.METRIC);
    }
    window.history.replaceState({ path: url.href }, '', url.href);
  }

  tabChanged(event?: MatTabChangeEvent) {
    if (event) this.currentTabIndex = event.index;
    if (!this.currentTab)
      return
    this.setMetric(this.currentTab.metric);
    if (this.currentTab.loaded) return;

    AbstractGraph.useFullTitle = this.currentTab.sections
      .some(sec => sec.graphs.map(g => new HttpParams({ fromString: g.imgSrc }).get('time'))
        .filter((v, i, a) => a.indexOf(v) === i).length === 1);

    if (this.currentTab.url) {
      let tab = this.currentTab;
      this.http.get(Globals.RUNTIME_PATH + (tab.url.startsWith(this.dataService.cgiPath) ? '' : this.dataService.path) +
        tab.url, { responseType: 'text' }).subscribe(page => {
          tab.urlContent = Globals.domSanitizer.bypassSecurityTrustHtml(page);
          this.postPageLoad();
        }, error => this.log.error('Failed to get tab page ' + this.currentTab.url, error));
    } else if (this.currentTab.itemIds && !this.currentTab.itemProperties) {
      const tab = this.currentTab;
      if (!Globals.BACKEND_INFO.storEnabled.value) {
        tab.type = TabType.storError;
        this.postPageLoad();
        return;
      }
      let itemIds = tab.itemIds;
      if (!this.storEnterprise || !this.lparEnterprise) {
        itemIds = itemIds.slice(0, 2);
      }
      const body = {
        procname: 'getItemsInfo',
        itemIDs: itemIds
      };
      this.http.post<any[]>(Globals.RUNTIME_PATH + Product.STOR.pathCgi + Globals.DBWRAPPER_SH, body).subscribe(result => {
        tab.itemProperties = result;
        this.postPageLoad();
      }, error => {
        this.log.error('Failed to get ' + tab.type, error);
        this.currentTab.type = TabType.storError;
      });
    }
    else
      this.postPageLoad();
  }

  private postPageLoad() {
    setTimeout(() => {
      if (!AbstractGraph.externalData && !this.hasAnyGraph() && !this.currentTab.url && !this.currentTab.type)
        this.dataService.mainModule.myreadyFunc();

      if (this.dataService.isLpar()) {
        $('.preddiv').each((index, element) => {
          Product.LPAR.module.showPrediction(element);
        });
      }

      this.observeDatastoresTop();

      Globals.tableSorter($('table.tablesorter'), true);
      this.dataService.registerBacklinks();
      this.floatCurrentTab();
    });
  }

  private observeDatastoresTop() {
    // Select the node that will be observed for mutations
    const targetNode = document.getElementById('volresults');

    if (!targetNode)
      return;

    // Options for the observer (which mutations to observe)
    const config: MutationObserverInit = { attributes: false, childList: true, subtree: false };

    // Callback function to execute when mutations are observed
    const callback: MutationCallback = (mutationsList, observer) => {
      // Use traditional 'for loops' for IE 11
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          this.dataService.registerBacklinks();
          break;
        }
      }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations, once targetNode is removed from DOM so is this observer collected
    observer.observe(targetNode, config);

  }

  private floatCurrentTab(limit = 10) {
    if (limit < 0) return;
    setTimeout(() => {
      if (!this.currentTab) {
        return;
      }
      if (
        !this.currentTab.sections ||
        this.currentTab.sections.length === 0 ||
        this.currentTab.sections
          .map((section) => (section.graphs ? section.graphs.length : 0))
          .reduce((prev, cur) => prev + cur, 0) === 0 ||
        this.currentTab.sections.some((section) => section.graphs.some((graph) => graph.loaded))
      ) {
        Globals.initFloatingScroll(true, '#slideGraph, .scroll-wrap');
        //this.slider();
        this.currentTab.loaded = true;
      } else this.floatCurrentTab(limit - 1);
    }, 500);
  }

  nOptionChanged() {
    this.activeJson.tps = this.nTps[this.selectedNOption];
  }

  isNTab(tab: Tab) {
    return this.selectedNOption && !tab.common;
  }

  getPageTitle() {
    return this.dataService.getPageTitle();
  }

  hasGraphs(tab: Tab) {
    return !this.data && tab.sections && tab.sections.some((value, index, array) => value.graphs.length);
  }

  private hasAnyGraph() {
    return this.currentTab && this.currentTab.sections.some((sec) => sec.graphs.length);
  }

  regroup() {
    this.block.reset();
    if (this.regrouped) {
      this.init();
      return;
    }

    const timedtNtps: Record<string, Tab[]> = {};
    for (const key in this.nTps) {
      if (Object.prototype.hasOwnProperty.call(this.nTps, key)) {
        const tps = this.nTps[key];
        const timedTps: Tab[] = [
          { name: 'Daily', sections: [], time: 'd', predictions: [], common: !this.selectedNOption },
          { name: 'Weekly', sections: [], time: 'w', predictions: [], common: !this.selectedNOption },
          { name: 'Monthly', sections: [], time: 'm', predictions: [], common: !this.selectedNOption },
          { name: 'Yearly', sections: [], time: 'y', predictions: [], common: !this.selectedNOption },
        ];
        for (const timedTab of timedTps) {
          const section: Section = { graphs: [] };
          for (const tab of tps) {
            for (const oldSec of tab.sections) {
              const timedGraphs = oldSec.graphs
                .filter((value, index, array) => value.imgSrc.includes('&time=' + timedTab.time))
                .map((value, index, array) => ({
                  ...value,
                  imgSrc: this.regroupSrc(value.imgSrc),
                  title: undefined,
                  loaded: false,
                  zoomed: false,
                  notDeferred: false,
                  regrouped: true,
                }));
              section.graphs.push(...timedGraphs);
            }
          }
          timedTab.sections.push(section);
          for (let j = 0; j < section.graphs.length; j++) {
            section.graphs[j].idx = j;
          }
        }
        timedtNtps[key] = timedTps;
      }
    }

    this.nTps = timedtNtps;
    this.regrouped = true;
    this.activeJson.tps = this.nTps[this.selectedNOption || this.nTabOptions[0].value];
    this.floatCurrentTab();
  }

  private regroupSrc(url: string) {
    if (this.dataService.isLpar())
      return url + '&height=150&width=900';
    // else if (this.dataService.selectedType === Globals.TYPE_NETWORK)
    //   return url.replace('detail=9', 'detail=1');
    else
      return url.replace('detail=9', 'detail=8');
  }

  genPdf() {
    Product.LPAR.module.genPdf(this.getGraphUrls());
  }

  genXls() {
    Product.LPAR.module.genXls(this.getGraphUrls());
  }

  private getGraphUrls() {
    let tabs: Record<string, string[]> = {};
    for (const tab of this.activeJson.tps) {
      let graphs = [];
      for (const sec of tab.sections) {
        graphs = graphs.concat(sec.graphs.map((g) => g.imgSrc));
      }
      tabs[tab.name] = graphs;
    }
    return tabs;
  }

  /**
   * Because of anonymous handlers, must be once at max
   * TODO: extract handlers to functions
   */
  private slider() {
    const slider = document.querySelector<HTMLElement>('.slideGraph');
    if (slider) {
      let isDown = false;
      let startX;
      let scrollLeft;

      slider.addEventListener('mousedown', (e: MouseEvent) => {
        isDown = true;
        slider.classList.add('active');
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener('mouseleave', () => {
        isDown = false;
        slider.classList.remove('active');
      });
      slider.addEventListener('mouseup', (e: MouseEvent) => {
        isDown = false;
        slider.classList.remove('active');
        if (startX !== e.pageX - slider.offsetLeft) {
          AbstractGraph.SLIDER_TIMESTAMP = Date.now();
        }
      });
      slider.addEventListener('mousemove', (e: MouseEvent) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = x - startX;
        slider.scrollLeft = scrollLeft - walk;
      });
    }
  }
}
