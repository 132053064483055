import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface PromptData {
  heading: string;
  inputText: string;
  invalidNames?: string[];
}

@Component({
  templateUrl: './prompt.component.html',
  styleUrls: ['./prompt.component.scss']
})
export class PromptComponent implements OnInit {

  inputResult = '';
  nameTaken = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: PromptData, private dialogRef: MatDialogRef<PromptComponent>) { }

  ngOnInit() {
  }

  ok() {
    this.dialogRef.close(this.inputResult);
  }

  isNameTaken() {
    if (!this.data.invalidNames || !this.inputResult)
      return false;
    let input = this.inputResult.trim();
    return this.data.invalidNames.some(name => name === input);
  }

}
