import { Directive, Input } from '@angular/core';
import { FormGroup, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: '[matchValue]',
  providers: [{ provide: NG_VALIDATORS, useExisting: MatchValueDirective, multi: true }]
})
export class MatchValueDirective implements Validator {

  @Input('matchValue') matchValueFields: string[] = [];

  constructor() { }

  validate(formGroup: FormGroup): ValidationErrors {
    return this.matchValue(this.matchValueFields[0], this.matchValueFields[1])(formGroup);
  }

  private matchValue(firstControlName: string, secondControlName: string) {
    return (formGroup: FormGroup) => {
      const firstControl = formGroup.controls[firstControlName];
      const secondControl = formGroup.controls[secondControlName];
      // return null if controls haven't initialised yet
      if (!firstControl || !secondControl || !firstControl.value || !secondControl.value) {
        return null;
      }
      if (secondControl.errors && !secondControl.errors.matchValueError) {
        return null;
      }
      if (firstControl.value !== secondControl.value) {
        secondControl.setErrors({ matchValueError: true });
      } else {
        secondControl.setErrors(null);
      }
    }
  }

}
