<mat-tab-group [@.disabled]="true" (selectedTabChange)="onTabChange($event)" id="capacityGlobal">
  <mat-tab label="Capacity">
    <a class='btn btn-link csvfloat'
      href='{{htmlUrlPrefix}}{{runtime}}{{cgi}}/detail-graph.sh?host=ALL&type=ALL&name=cap_total&item=sum&time=d::::'
      title='CSV'>
      <i class="fas fa-file-csv fa-2x mr-1"></i>
    </a>
    <div class="content center">
      <div id="capacity" class="stickyheaders-holder"></div>
    </div>
  </mat-tab>
  <mat-tab label="Capacity Group">
    <a class='btn btn-link csvfloat' href='#' title='CSV' (click)="downloadGroupCsv($event)">
      <i class="fas fa-file-csv fa-2x mr-1"></i>
    </a>
    <div class="content center">
      <div id="capacityGroup" class="stickyheaders-holder"></div>
    </div>
  </mat-tab>
  <mat-tab label="Capacity Pool">
    <a class='btn btn-link csvfloat'
      href='{{htmlUrlPrefix}}{{runtime}}{{cgi}}/detail-graph.sh?host=ALL&type=ALL&name=cap_pool&item=sum&time=d::::'
      title='CSV'>
      <i class="fas fa-file-csv fa-2x mr-1"></i>
    </a>
    <div class="content center">
      <div id="capacityPool" class="stickyheaders-holder"></div>
    </div>
  </mat-tab>
  <mat-tab label="Heatmap">
    <div id="heatmap" [innerHTML]="heatmapPage"></div>
  </mat-tab>
</mat-tab-group>
