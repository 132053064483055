<ng-template #blockTemplate>
  <div class="block-ui-template">
  </div>
</ng-template>
<div class="d-flex flex-grow-1 flex-column" *blockUI="'graphs'; template:blockTemplate">
  <mat-tab-group *ngIf="activeJson" [@.disabled]="true" (selectedTabChange)="tabChanged($event)" class="mat-tab-graphs"
    [selectedIndex]="currentTabIndex">
    <mat-tab *ngFor="let tab of activeJson?.tps" label="{{tab.name}}">
      <xormon-loading-indicator *ngIf="loading"></xormon-loading-indicator>
      <div class="stickyheaders-holder tab-page pt-2">
        <h3 class="mb-1 mt-1 text-center ml-0">
          <button class="btn btn-default regroup" (click)="regroup()" title="Regroup" *ngIf="hasGraphs(tab)">
            <i *ngIf="!regrouped" class="fas fa-layer-group"></i>
            <i *ngIf="regrouped" class="fas fa-undo"></i>
          </button>
          {{getPageTitle()}}
          <a *ngIf="tab.help" class="helpfloat mr-1" [href]="tab.help.url" target="_blank" [title]="tab.help.title">
            <i class="fas fa-question-circle fa-lg"></i>
          </a>
          <span *ngIf="data" class="pdffloat mr-1" title="Create PDF report from all tabs" (click)="genPdf()">
            <i class="fas fa-file-pdf fa-lg" alt="Create PDF report"></i>
          </span>
          <span *ngIf="data" class="xlsfloat mr-1" title="Create XLS report from all tabs" (click)="genXls()">
            <i class="fas fa-file-excel fa-lg" alt="Create XLS report"></i>
          </span>
          <p-selectButton [options]="nTabOptions" [(ngModel)]="selectedNOption" (onChange)="nOptionChanged($event)"
            styleClass="n-switch mr-1" *ngIf="isNTab(tab)"></p-selectButton>
        </h3>
        <div id="slideGraph">
          <div *ngFor="let section of tab.sections">
            <h4 *ngIf="section.name">{{section.name}}</h4>
            <div class="row m-0 tabgraphs overflow-visible" [ngClass]="{'regrouped': regrouped}">
              <div *ngFor="let graph of section.graphs; let i = index"
                class="text-center d-flexx flexx-grow-1 graph-parent px-0 overflow-visible" [ngClass]="{'col-xl-6': graph.imgSrc.indexOf('detail=0')<0 && !regrouped && graph.width<=480,
            'col-xl-12': graph.imgSrc.indexOf('detail=0')>-1 || regrouped || graph.width>480}">
                <div (deferLoad)="onLoad(graph)" class="h-100 graph-deferred">
                  <xormon-graph *ngIf="graph.notDeferred" [graph]="graph" #graphEl
                    (reset)="tab.resetSwitch=!tab.resetSwitch;tab.groupSelection=null;" [outsideReset]="tab.resetSwitch"
                    (groupZoomStart)="loadGraphs(tab)" (groupZoomEnd)="groupSelectEnd(tab, $event)"
                    (loaded)="onLoaded(graphEl)" [outsideZoom]="tab.groupSelection" [reload]="graph.reloadSwitch"
                    classx="d-flex flex-grow-1">
                  </xormon-graph>
                </div>
              </div>
              <div *ngIf="section.htmls && section.htmls.length>0" class="extraHtml center">
                <div class="stickyheaders-holder">
                  <div *ngFor="let html of section.htmls" [innerHTML]="html" class="scroll-wrap"></div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="tab.url" class="extraHtml stickyheaders-holder" [innerHTML]="tab.urlContent">
          </div>

          <div *ngFor="let prediction of tab.predictions" class="">
            <div class="prediction">
              <div (deferLoad)="onLoadPrediction(prediction, predictionDiv)" #predictionDiv class="center">
              </div>
            </div>
          </div>

          <div *ngIf="tab.htmls && tab.htmls.length>0" class="extraHtml scroll-wrap">
            <div *ngFor="let html of tab.htmls" [innerHTML]="html"></div>
          </div>

          <div *ngIf="tab.itemIds" [id]="tab.type" class="center">
            <div class="stickyheaders-holder">
              <div *ngIf="!tab.itemProperties && tab.type!==tabType.storError">Loading STOR2RRD VOLUMEs...</div>
              <div *ngIf="!tab.itemProperties && tab.type===tabType.storError">
                Could not get volume information from STOR2RRD.
                Please check Xormon's back-end setup.
              </div>
              <div *ngIf="tab.itemProperties">
                <table class="tablesorter mb-1">
                  <thead>
                    <tr>
                      <th class="sortable">Storage</th>
                      <th class="sortable">Volume</th>
                      <th class="sortable">Pool</th>
                      <th class="sortable">Volume ID</th>
                      <th class="sortable">Serial</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of tab.itemProperties">
                      <td>{{item.storageName}}</td>
                      <td>
                        <span *ngIf="item.volumeNoAcl===1"
                          title="You don't have ACL permission to navigate to this volume">{{item.volumeName}}</span>
                        <a *ngIf="item.volumeNoAcl!==1" [href]="'stor@'+item.volumeId"
                          (click)="nextTabIndex=0">{{item.volumeName}}</a>
                      </td>
                      <td>
                        <span *ngIf="item.poolNoAcl===1"
                          title="You don't have ACL permission to navigate to this pool">{{item.poolName}}</span>
                        <a *ngIf="item.poolNoAcl!==1" [href]="'stor@'+item.poolId"
                          (click)="nextTabIndex=0">{{item.poolName}}</a>
                      </td>
                      <td>{{item.volumeId ? item.volumeId : item.origId}}</td>
                      <td>{{item.serial}}</td>
                    </tr>
                  </tbody>
                </table>
                Click on Volume or Pool name to see related performance statistics from storage back-end.
                <div *ngIf="!lparEnterprise || !storEnterprise">Only 2 volumes are listed for STOR2RRD/LPAR2RRD free
                  edition.<br> Consider upgrading to the
                  <a *ngIf="!lparEnterprise" href="https://www.lpar2rrd.com/support.php" target="_blank"
                    class="mr-1"><strong>LPAR2RRD Enterprise Edition</strong></a>
                  <a *ngIf="!storEnterprise" href="https://stor2rrd.com/support.php" target="_blank"><strong>STOR2RRD
                      Enterprise Edition</strong></a>
                </div>
              </div>
            </div>
          </div>
          <div *ngFor="let html of activeJson.tpCommon?.htmls" [innerHTML]="html" class="row extraHtml"></div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
  <div *ngIf="!activeJson && !error">
    <xormon-loading-indicator></xormon-loading-indicator>
  </div>
  <pre *ngIf="error" class="text-danger fit">Error loading {{url}}
{{error | json}}</pre>
</div>
