/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./top10.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i4 from "@angular/material/tabs";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "../../loading-indicator/loading-indicator.component.ngfactory";
import * as i7 from "../../loading-indicator/loading-indicator.component";
import * as i8 from "./top10.component";
import * as i9 from "@angular/common/http";
import * as i10 from "../../logger.service";
import * as i11 from "../../data.service";
import * as i12 from "@angular/router";
var styles_Top10Component = [i0.styles];
var RenderType_Top10Component = i1.ɵcrt({ encapsulation: 0, styles: styles_Top10Component, data: {} });
export { RenderType_Top10Component as RenderType_Top10Component };
function View_Top10Component_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.html; _ck(_v, 0, 0, currVal_0); }); }
function View_Top10Component_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "td", [["style", "vertical-align: top;"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Top10Component_5)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.html; _ck(_v, 2, 0, currVal_0); }, null); }
function View_Top10Component_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Top10Component_4)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.cells; _ck(_v, 2, 0, currVal_0); }, null); }
function View_Top10Component_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.html; _ck(_v, 0, 0, currVal_0); }); }
function View_Top10Component_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "td", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Top10Component_8)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.html; _ck(_v, 2, 0, currVal_0); }, null); }
function View_Top10Component_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Top10Component_7)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit.cells; _ck(_v, 2, 0, currVal_0); }, null); }
function View_Top10Component_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 9, "mat-tab", [], null, null, null, i3.View_MatTab_0, i3.RenderType_MatTab)), i1.ɵdid(1, 770048, [[1, 4]], 2, i4.MatTab, [i1.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i1.ɵqud(603979776, 2, { templateLabel: 0 }), i1.ɵqud(335544320, 3, { _explicitContent: 0 }), (_l()(), i1.ɵeld(4, 0, null, 0, 5, "div", [["class", "scroll-wrap"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "table", [["style", "height: calc(100vh - 200px);"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Top10Component_3)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Top10Component_6)), i1.ɵdid(9, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.name, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.context.$implicit.rows; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.tops.rtpCommon.rows; _ck(_v, 9, 0, currVal_2); }, null); }
function View_Top10Component_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "mat-tab-group", [["class", "mat-tab-group"]], [[24, "@.disabled", 0], [2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], [[null, "selectedTabChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedTabChange" === en)) {
        var pd_0 = (_co.tabChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatTabGroup_0, i3.RenderType_MatTabGroup)), i1.ɵdid(1, 3325952, null, 1, i4.MatTabGroup, [i1.ElementRef, i1.ChangeDetectorRef, [2, i4.MAT_TABS_CONFIG], [2, i5.ANIMATION_MODULE_TYPE]], null, { selectedTabChange: "selectedTabChange" }), i1.ɵqud(603979776, 1, { _tabs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Top10Component_2)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.tops.rtps; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = true; var currVal_1 = i1.ɵnov(_v, 1).dynamicHeight; var currVal_2 = (i1.ɵnov(_v, 1).headerPosition === "below"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_Top10Component_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xormon-loading-indicator", [], null, null, null, i6.View_LoadingIndicatorComponent_0, i6.RenderType_LoadingIndicatorComponent)), i1.ɵdid(1, 114688, null, 0, i7.LoadingIndicatorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_Top10Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_Top10Component_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_Top10Component_9)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tops; _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.tops; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_Top10Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xormon-top10", [], null, null, null, View_Top10Component_0, RenderType_Top10Component)), i1.ɵdid(1, 245760, null, 0, i8.Top10Component, [i9.HttpClient, i10.LoggerService, i11.DataService, i12.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Top10ComponentNgFactory = i1.ɵccf("xormon-top10", i8.Top10Component, View_Top10Component_Host_0, {}, {}, []);
export { Top10ComponentNgFactory as Top10ComponentNgFactory };
