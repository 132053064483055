import { Component, ElementRef, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CustomMessagesService } from 'src/app/custom-messages.service';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';
import { Product } from 'src/app/model/product';
import { AlertingService } from '../alerting.service';
import { TestEventsComponent } from '../test-events/test-events.component';

@Component({
  selector: 'xormon-alerting-stor-hw',
  templateUrl: './alerting-stor-hw.component.html',
  styleUrls: ['./alerting-stor-hw.component.scss']
})
export class AlertingStorHWComponent implements OnInit {

  cfg: any = {
  };

  urlNagios: string;
  urlCopy: string;

  constructor(private alertingService: AlertingService, private log: LoggerService,
    private dialog: MatDialog,
    private cmService: CustomMessagesService, private elef: ElementRef) {
  }

  ngOnInit() {
    this.urlNagios = this.cmService.getMsg('nagios', this.elef);
    this.urlCopy = this.cmService.getMsg('copy', this.elef);
    this.alertingService.getStorCfg().subscribe((data: any) => {
      this.cfg = data;
    }, error => {
      this.log.error('Failed to get configuration!', error);
    });
  }

  testEvents() {
    this.dialog.open(TestEventsComponent, { data: Globals.RUNTIME_PATH + Product.STOR.pathCgi + '/alert-test-events.sh' });
  }

  saveCfg() {
    this.alertingService.saveStorCfg(this.cfg).subscribe(data => {
      this.log.info('Configuration saved successfully');
    }, error => {
      this.log.error('Failed to save configuration!', error);
    });
  }
}
