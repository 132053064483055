import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';
import { Product } from 'src/app/model/product';

@Component({
  selector: 'xormon-logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LogsComponent implements OnInit, AfterViewInit {

  readonly logData =
    [
      { title: 'Xormon - Web UI', text: 'Last ' + LoggerService.MAX_ERRORS_KEPT + ' errors:', logs: 'Loading...', visible: Globals.TRUE_WRAP },
      { title: 'Xormon - Server', text: "Last 1000 lines of today's logs:", logs: 'Loading...', url: '/admin/info/logs', visible: Globals.TRUE_WRAP },
      { title: Product.LPAR.name, html: null, url: Globals.RUNTIME_PATH + Product.LPAR.pathCgi + '/log-cgi.sh?name=errlog', visible: Globals.BACKEND_INFO.lparEnabled },
      { title: Product.STOR.name, html: null, url: Globals.RUNTIME_PATH + Product.STOR.pathCgi + '/log-cgi.sh?name=errlog', visible: Globals.BACKEND_INFO.storEnabled }
    ];

  constructor(private http: HttpClient, private log: LoggerService, private cdr: ChangeDetectorRef) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.tabChanged({ index: 0, tab: null });
  }

  private getLog(index: number) {
    let ld = this.logData[index];
    if (ld.visible.value)
      return ld;
    return this.getLog(index + 1);
  }

  tabChanged(event: MatTabChangeEvent) {
    const current = this.getLog(event.index);
    if (event.index === 0) {
      current.logs = "UI:\n" + JSON.stringify(this.log.getLatestLogs(), null, Globals.JSON_INDENT);
      current.logs += "\nConsole:\n" + JSON.stringify(this.log.getConsoleErrors(), null, Globals.JSON_INDENT);
      this.cdr.detectChanges();
    } else {
      this.http.get(current.url, { responseType: 'text' }).subscribe(logs => {
        if (current.logs) {
          current.logs = logs;
        } else {
          current.html = Globals.domSanitizer.bypassSecurityTrustHtml(logs);
        }
      }, error => this.log.error('Failed to get logs for ' + current.title, error));
    }
  }

}
