import { HttpParams } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Options } from 'select2';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';
import { DashboardGroup, DashboardTab } from 'src/app/model/dashboard';
import { PortalService } from '../portal.service';

export interface GraphData {
  url: string;
  title: string;
  technology: string;
  backlink: string;
}

interface Tab extends DashboardTab {
  selected: boolean;
  text: string;
  groups: Group[];
  disabled?: boolean;
}

interface Group extends DashboardGroup {
  text: string;
}

interface Entry {
  selectedTab?: Tab;
  selectedGroup?: Group;
  disabledGroups: boolean[];
}

@Component({
  selector: 'xormon-portal-dialog',
  templateUrl: './portal-dialog.component.html',
  styleUrls: ['./portal-dialog.component.scss']
})
export class PortalDialogComponent implements OnInit, AfterViewInit {

  static readonly ID_NEW = -1;

  tabs: Tab[];
  entries: Entry[] = [];
  private existingTabs: Tab[] = [];
  private tabOptions: Options;
  private groupOptions: Options;
  saving = false;
  readonly usedTabs = new Set<Tab>();
  private owner: string;
  private url: string;
  readonly minWidth = 200;
  readonly minHeight = 100;
  width = this.minWidth;
  height = this.minHeight;
  private readonly DASHBOARD_GRAPH_WIDTH = 'dashboard_graph_width';
  private readonly DASHBOARD_GRAPH_HEIGHT = 'dashboard_graph_height';

  constructor(private portalService: PortalService, private log: LoggerService, private dialogRef: MatDialogRef<PortalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GraphData, private dataService: DataService) {

  }

  ngOnInit() {
    let width = localStorage.getItem(this.DASHBOARD_GRAPH_WIDTH);
    if (width) {
      this.width = parseInt(width);
    }
    let height = localStorage.getItem(this.DASHBOARD_GRAPH_HEIGHT);
    if (height) {
      this.height = parseInt(height);
    }

    let urlSplit = this.data.url.split('?');
    if (urlSplit.length === 1) {
      this.url = urlSplit[0];
    } else {
      let params = new HttpParams({ fromString: urlSplit[1] });
      params = params.delete('width').delete('height').delete('none');
      this.url = urlSplit[0] + '?' + params.toString();
    }
    this.owner = Globals.currentUser.name;
    this.tabs =
      [...this.dataService.dashboard.tabs.filter((value, index, arr) => (value.technology === this.data.technology ||
        value.technology === Globals.TYPE_GLOBAL) && value.writable)
        .map(dt => ({ ...dt, selected: false, text: dt.name, groups: dt.groups.map(g => ({ ...g, selected: false, text: g.name })) }))];
    if (!this.tabs.some(t => t.name === Globals.DEFAULT_DASHBOARD_NAME)) {
      this.tabs.unshift({
        groups: [], id: PortalDialogComponent.ID_NEW, name: Globals.DEFAULT_DASHBOARD_NAME, selected: false, shared: false,
        technology: this.data.technology, text: Globals.DEFAULT_DASHBOARD_NAME, writable: true, owner: this.owner
      });
    }
    if (!this.tabs.some(t => t.name === Globals.TITLE_GLOBAL)) {
      this.tabs.unshift({
        groups: [], id: PortalDialogComponent.ID_NEW, name: Globals.TITLE_GLOBAL, selected: false, shared: false,
        technology: Globals.TYPE_GLOBAL, text: Globals.TITLE_GLOBAL, writable: true, owner: this.owner
      });
    }

    for (const tab of this.tabs) {
      for (const group of tab.groups) {
        for (const graph of group.graphs) {
          if (this.url === graph.id.graphUrl)
            this.addEntry({ selectedTab: tab, selectedGroup: group, disabledGroups: [] });
        }
      }
    }
    if (this.entries.length < 1)
      this.addEntry();
  }

  ngAfterViewInit(): void {
    const self = this;
    this.tabOptions = {
      placeholder: 'Select/create dashboard',
      language: {
        noResults: () => 'Create new dashboard'
      },
      maximumInputLength: 250,
      tags: true,
      createTag: function (params) {
        if (params.term.includes(':')) {
          this.log.warn(Globals.DASHBOARD_INVALID_CHARACTER_MSG);
          return null;
        }
        let select = this.$element[0] as HTMLSelectElement;
        let index = parseInt(select.id.substr(3));
        let term = params.term.trim();
        if (term.length < 1)
          return null;
        for (const tab of self.usedTabs) {
          if (tab.name === term)
            return null;
        };
        return {
          id: self.getId(index) + '',
          text: term
        };
      },
      dropdownAutoWidth: true,
      width: '100%',
      dropdownParent: $('.mat-dialog-content'),
    };
    this.groupOptions = {
      placeholder: 'Select/create group',
      language: {
        noResults: () => 'Type name of new group'
      },
      maximumInputLength: 250,
      tags: true,
      dropdownAutoWidth: true,
      width: '100%',
      dropdownParent: $('.mat-dialog-content'),
      createTag: function (params) {
        let select = this.$element[0] as HTMLSelectElement;
        let index = parseInt(select.id.substr(5));
        let entry = self.entries[index];
        let term = params.term.trim();
        if (term.length < 1 || entry.selectedTab && entry.selectedTab.groups.some(g => g.name === term))
          return null;
        return {
          id: self.getId(index) + '',
          text: term
        };
      }
    };

    this.applySelect2();
  }

  private getId(index: number) {
    return -index - 2;
  }

  private updateDisabledGroups(tab: Tab) {
    let entries = this.entries.filter(e => e.selectedTab === tab);
    for (const entry of entries) {
      entry.disabledGroups = [];
      let others = entries.filter(e => e.selectedGroup !== entry.selectedGroup);
      for (let i = 0; i < entry.selectedTab.groups.length; i++) {
        let group = entry.selectedTab.groups[i];
        entry.disabledGroups.push(others.some(e => e.selectedGroup === group));
      }
    }
  }

  private applySelect2() {
    const self = this;
    const noVal = null;

    $('.select2.tab').select2(this.tabOptions).off('select2:select').on('select2:select', function (e) {
      const tt = e.params.data.text.trim();
      let index = parseInt(this.id.substr(3));
      let id = self.getId(index);
      let entry = self.entries[index];
      let tabPrev = entry.selectedTab;
      let groupPrev = entry.selectedGroup;
      entry.selectedTab = self.tabs.find((value, index, obj) => value.name === tt);
      if (!entry.selectedTab) {
        entry.selectedTab = self.tabs.find((value, index, obj) => value.id == id);
        if (entry.selectedTab) {
          entry.selectedTab.name = entry.selectedTab.text = tt;
        } else {
          entry.selectedTab = {
            technology: self.data.technology, id: id, selected: true,
            name: tt, text: tt, groups: [], shared: false, writable: true, owner: self.owner
          };
          self.tabs.push(entry.selectedTab);
        }
        $(this).find('option[data-select2-tag="true"]').remove();
        setTimeout(() => {
          $(`#tab${index}.select2`).val(entry.selectedTab.id).select2(self.tabOptions).trigger('change');
        });
      }
      if (tabPrev && groupPrev && tabPrev !== entry.selectedTab) {
        groupPrev.graphs = groupPrev.graphs.filter(g => g.id.graphUrl !== self.url);
      }
      entry.selectedGroup = null;
      self.usedTabs.delete(tabPrev);
      self.usedTabs.add(entry.selectedTab);
      self.updateDisabledGroups(entry.selectedTab);
      setTimeout(() => {
        $(`#group${index}.select2`).val(noVal).trigger('change').select2('open');
      });
    }).off('select2:close').on('select2:close', function (e) {
      let index = parseInt(this.id.substr(3));
      let id = self.getId(index);
      let entry = self.entries[index];
      let tabPrev = entry.selectedTab;
      const newOption = $(this).find('option[data-select2-tag="true"]');
      if (newOption.length) {
        let label = newOption[newOption.length - 1].innerText.trim();
        let newTab = self.tabs.find((value, index, obj) => value.id === id
          && value.name !== Globals.DEFAULT_DASHBOARD_NAME && value.name !== Globals.TITLE_GLOBAL);
        if (entry.selectedTab && entry.selectedGroup && entry.selectedTab !== newTab) {
          entry.selectedGroup.graphs = entry.selectedGroup.graphs.filter(g => g.id.graphUrl !== self.url);
        }
        if (!newTab) {
          entry.selectedTab = {
            technology: self.data.technology,
            id: id,
            selected: true,
            text: label,
            name: label,
            groups: [],
            shared: false,
            writable: false,
            owner: self.owner
          };
          self.tabs.push(entry.selectedTab);
          entry.selectedGroup = null;
        } else {
          newTab.text = newTab.name = label;
        }
        self.usedTabs.delete(tabPrev);
        self.usedTabs.add(entry.selectedTab);
        self.updateDisabledGroups(entry.selectedTab);
        newOption.remove();
        setTimeout(() => {
          $(`#tab${index}.select2`).val(entry.selectedTab.id).select2(self.tabOptions).trigger('change');
          $(`#group${index}.select2`).trigger('change');
          $(`#group${index}.select2`).val(noVal).select2('open');
        });
      }
    });
    for (let i = 0; i < this.entries.length; i++) {
      if (!this.entries[i].selectedTab)
        $('#tab' + i + '.select2').val(noVal).trigger('change');
    }

    $('.select2.group').select2(this.groupOptions).off('select2:select').on('select2:select', function (e: any) {
      const tt = e.params.data.text.trim();
      let index = parseInt(this.id.substr(5));
      let id = self.getId(index);
      let entry = self.entries[index];
      let groupPrev = entry.selectedGroup;
      entry.selectedGroup = entry.selectedTab.groups.find((value, index, obj) => value.text === tt);;
      if (!entry.selectedGroup) {
        entry.selectedGroup = entry.selectedTab.groups.find((value, index, obj) => value.id === id && !entry.disabledGroups[index]);
        if (entry.selectedGroup) {
          entry.selectedGroup.text = entry.selectedGroup.name = tt;
        } else {
          entry.selectedGroup = { id: id, text: tt, name: tt, sortOrder: null, height: 600, width: 450, graphs: [] };
          entry.selectedTab.groups.push(entry.selectedGroup);
        }
        $(this).find('option[data-select2-tag="true"]').remove();
        setTimeout(() => $(`#group${index}.select2`).val(entry.selectedGroup.id).select2(self.groupOptions).trigger('change'));
      }
      if (groupPrev && entry.selectedGroup !== groupPrev)
        groupPrev.graphs = groupPrev.graphs.filter(g => g.id.graphUrl !== self.url);
      self.updateDisabledGroups(entry.selectedTab);
      setTimeout(() => $('#saveBtn').focus());
    }).off('select2:close').on('select2:close', function (e: any) {
      const newOption = $(this).find('option[data-select2-tag="true"]');
      let index = parseInt(this.id.substr(5));
      let id = self.getId(index);
      let entry = self.entries[index];
      if (newOption.length) {
        let group = entry.selectedTab.groups.find(g => g.id === id);
        if (entry.selectedGroup && entry.selectedGroup !== group)
          entry.selectedGroup.graphs = entry.selectedGroup.graphs.filter(g => g.id.graphUrl !== self.url);
        let label = newOption[newOption.length - 1].innerText.trim();
        if (entry.selectedTab.groups.some(g => g.name === label))
          return;
        if (group) {
          entry.selectedGroup = group;
          group.text = group.name = label;
        } else {
          entry.selectedGroup = {
            id: id,
            text: label,
            name: label,
            height: null,
            width: null,
            sortOrder: null,
            graphs: []
          };
          entry.selectedTab.groups.push(entry.selectedGroup);
        }
        self.updateDisabledGroups(entry.selectedTab);
        newOption.remove();
        setTimeout(() => {
          $(`#group${index}.select2`).val(entry.selectedGroup.id).select2(self.groupOptions).trigger('change');
          $('#saveBtn').focus();
        });
      }
    });

    let lastEntry = this.entries[this.entries.length - 1];
    if (!lastEntry.selectedTab)
      $(`#tab${this.entries.length - 1}.select2`).select2('open');
  }

  allGroupsSelected() {
    return !this.entries.length || this.entries.every(e => e.selectedGroup);
  }

  addEntry(entry?: Entry) {
    if (entry) {
      this.entries.push(entry);
      if (entry.selectedTab) {
        this.existingTabs.push(entry.selectedTab);
        this.usedTabs.add(entry.selectedTab);
      }
    }
    else
      this.entries.push({ disabledGroups: [] });
    setTimeout(() => {
      this.applySelect2();
    });
  }

  removeEntry(index: number) {
    if (index >= this.entries.length)
      return;
    let entry = this.entries[index];
    if (entry.selectedTab && this.existingTabs.includes(entry.selectedTab)) {
      entry.selectedGroup.graphs = entry.selectedGroup.graphs.filter(g => g.id.graphUrl !== this.url);
    }
    this.entries.splice(index, 1);
    this.usedTabs.delete(entry.selectedTab);
    this.updateDisabledGroups(entry.selectedTab);
  }

  onSave() {
    this.saving = true;
    for (const entry of this.entries) {
      if (entry.selectedGroup.graphs.some(g => g.id.graphUrl === this.url))
        continue;
      entry.selectedGroup.graphs.push({
        id: { graphUrl: this.url, groupId: entry.selectedGroup.id },
        title: this.data.title,
        backlink: this.data.backlink,
        height: this.height,
        width: this.width,
        sortOrder: null,
        lpar: this.data.technology === Globals.TYPE_SERVER
      });
    }
    this.portalService.saveTabs(this.tabs).subscribe(data => {
      this.dataService.updateDashboards(data);
      this.dialogRef.close('OK');
    }, error => {
      this.log.error('Failed to save tabs!', error);
    }, () => this.saving = false);
    localStorage.setItem(this.DASHBOARD_GRAPH_HEIGHT, this.height + '');
    localStorage.setItem(this.DASHBOARD_GRAPH_WIDTH, this.width + '');
  }

}
