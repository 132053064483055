<div class="card" id="storageGrouping">
  <div class="card-body">
    <h4>Storage grouping</h4>

    <div class="row">
      <div class="col-lg-4 mb-4">

        <div class="border mb-2">
          <div class=tree-border>
            <div class="tree-level tree-header-level ng-scope">
              <div class="content">
                <div class="content-holder">
                  <div class="card-header p-2 d-flex align-items-center">
                    <strong>Groups</strong>
                    <button class="btn ml-auto pr-0" id="storGrBut" (click)="addNewSg()">
                      <i class="fas fa-plus-circle font-size-lg ml-1"></i>
                      Add new
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="tree-level tree-top-level ng-scope">
            <div class="content ng-scope" ng-click="onRowClick(item, level, $event)"
              ng-class="{selected:item._selected}">
              <div class="content-holder">
                <div class="node-content storage-tree">
                  <!-- TODO FIXME: add div context-menu!-->
                  <div class="text-left pull-right">
                    <div *ngFor="let child of children; let idx = index" class="d-flex p-2"
                      [ngClass]="{rowActive: this.selected == child}">
                      <input type="text" class="form-control input-sm mr-2" placeholder="Enter name of group"
                        (click)="showStorages(child)" [(ngModel)]="child.title" maxlength="250" required>
                      <button (click)="deleteGroup(idx)" class="btn btn-sm font-size-lg" title="Delete">
                        <i class="fas fa-times-circle"></i>
                      </button>
                      <!--Add ng-style here!-->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <button class="btn btn-primary" (click)="saveSG()">
            <i class="fas fa-save mr-1"></i>
            Save storage grouping configuration
          </button>
        </div>
      </div>


      <div class="col-lg-4">
        <ng-template #blockTemplate>
          <div class="block-ui-template text-center">
          </div>
        </ng-template>
        <div *blockUI="'storages'; template: blockTemplate;">
          <fieldset class="fieldset-sg border mb-2">
            <legend>Storage</legend>
            <div>
              <div class="form-check p-2" *ngFor="let storage of storages" [ngClass]="{rowActive: storage.checked}">
                <input (click)="onClickSelect(storage);" [(ngModel)]="storage.checked" type="checkbox"
                  [ngModelOptions]="{standalone: true}" id="{{storage.object_id}}" class="form-check-input">
                <label class="form-check-label mb-0" for="{{storage.object_id}}">
                  {{storage.label}}
                </label>
              </div>
            </div>
            <!---storCheckedStyle=something dela bordel!  storChecked && storCheckedStyle={'color':'#3879D9', 'font-style':'italic'} -->
          </fieldset>
        </div>
      </div>
    </div>
  </div>
</div>
