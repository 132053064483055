<div class="sidebar">
  <nav class="navbar navbar-expand-lg navbar-dark">
    <div class="navbar-header">
      <a class="navbar-brand" href="https://xormon.com/" target="_blank" title="Xormon">
        <img [src]="logoUrl" width="121" height="32" alt="Xormon">
      </a>
    </div>
  </nav>
  <div class="sidebar-tree">
    <mat-form-field floatLabelx="never" [hidden]="!isFilter">
      <mat-label>Filter items...</mat-label>
      <input #filter matInput placeholder="Filter..." [matAutocomplete]="auto" [formControl]="titleCtrl"
        (keyup)="onSearchKey($event)" (click)="autoClicked()">
      <span (mouseenter)="searchHover=true" (mouseleave)="searchHover=false">
        <button type="button" class="btn btn-xs clear-filter hover-info"
          [hidden]="!searchHover || !titleCtrl.value.length" (click)="clearSearch()" title="Clear filter"><i
            class="fas fa-times"></i></button>
        <button class="btn btn-xs input-group-text hover-info" type="button" (click)="searchText()" title="Filter tree">
          <i class="fas fa-search"></i>
        </button>
      </span>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="searchText()">
        <mat-option *ngFor="let item of searchHistoryList | async" [value]="item">
          <span [title]="item">{{item}}</span>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <h4 *ngIf="isHeading" class="sidebar-heading">{{heading}}</h4>
    <div class="scrollable-container">
      <div class="scrollable">
        <div class="tree-menu" id="menuTree" disabled="false" checkbox="false" debuglevel="0"></div>
      </div>
    </div>
    <div id="xorux_footer">
      <a href="https://www.xorux.com" target="_blank">XORUX</a> apps&nbsp;family <a *ngIf="isFreeEdition"
        href="https://xormon.com/support.php" target="_blank" class="edition">Free&nbsp;Edition</a>
    </div>
  </div>
</div>
