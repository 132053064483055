import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { DataService } from "../data.service";
import { Globals } from '../globals';
import { LoggerService } from '../logger.service';
import { MenuItems } from '../menu-items';
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root"
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private dataService: DataService, private log: LoggerService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (Globals.authenticated) {
      return Promise.allSettled([Globals.userLoaded.promise, this.dataService.backendLoaded.promise,
      this.dataService.serverLoaded.promise, this.dataService.storageLoaded.promise, this.dataService.networkLoaded.promise])
        .then(() => {
          if (MenuItems.isVisibleRoute(next.params))
            return true;
          if (!Globals.authenticated)
            return false;
          this.log.error('Cannot navigate to: ' + JSON.stringify(next.params));
          if (this.router.url.startsWith(Globals.PATH_LOGIN) || !DataService.INSTANCE.selectedNode) {
            this.router.navigateByUrl('/');
          }
          return false;
        },
          error => {
            //this.log.error('Unable to decide if component view is allowed!', error);
            return false;
          });
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate([Globals.PATH_LOGIN], {
      queryParams: {
        returnUrl: state.root.queryParams.returnUrl
          ? state.root.queryParams.returnUrl
          : AuthService.getReturnUrl(state.url)
      }
    });
    return false;
  }
}
