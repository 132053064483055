<h1 mat-dialog-title>Select certificates to store in Xormon keystore</h1>

<div mat-dialog-content style="display: flex; flex-flow: column; height: 70vh;">
  Certificate exception occured while establishing connection to backend. Please select obtained server certificates
  to trust:
  <div class="row mt-2" style="flex: 1 1 auto;">

    <div class="col-md-3 list-group">
      <div class="form-check list-group-item" [ngClass]="{'active': cert===activeCert}"
        *ngFor="let cert of certificates" (click)="activeCert=cert">
        <input class="form-check-input" type="checkbox" [(ngModel)]="cert.store" [attr.id]="'cert'+cert.id">
        <label class="form-check-label" [attr.for]="'cert'+cert.id"></label>
        <label class="form-check-label">
          {{cert.id + '[' + cert.alias + ']'}}
        </label>
      </div>
    </div>
    <div id="certFlex" class="col-md-9" style="display: flex; flex-flow: column;">

      <p-tabView [style]="{'display': 'flex', 'flex-flow': 'column', 'flex': '1 1 auto'}">
        <p-tabPanel header="Info">
          <p-scrollPanel>
            <div class="row">
              <div class="h4 col-md-12">Issued for:</div>
              <div class="ml-2 col-md-12">
                <div class="row">
                  <div class="col-md-4">Common name (CN)</div>
                  <div class="col-md-8">{{activeCert.issuedFor.CN}}</div>
                  <div class="col-md-4">Organization (O)</div>
                  <div class="col-md-8">{{activeCert.issuedFor.O}}</div>
                  <div class="col-md-4">Organization unit (OU)</div>
                  <div class="col-md-8">{{activeCert.issuedFor.OU}}</div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="h4 col-md-12">Issued by:</div>
              <div class="ml-2 col-md-12">
                <div class="row">
                  <div class="col-md-4">Common name (CN)</div>
                  <div class="col-md-8">{{activeCert.issuedBy.CN}}</div>
                  <div class="col-md-4">Organization (O)</div>
                  <div class="col-md-8">{{activeCert.issuedBy.O}}</div>
                  <div class="col-md-4">Organization unit (OU)</div>
                  <div class="col-md-8">{{activeCert.issuedBy.OU}}</div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="h4 col-md-12">Validity period:</div>
              <div class="ml-2 col-md-12">
                <div class="row">
                  <div class="col-md-4">Issue date</div>
                  <div class="col-md-8">{{activeCert.validFrom}}</div>
                  <div class="col-md-4">Valid to</div>
                  <div class="col-md-8">{{activeCert.validTo}}</div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="h4 col-md-12">Digital signatures:</div>
              <div class="ml-2 col-md-12">
                <div class="row">
                  <div class="col-md-4">SHA-256</div>
                  <div class="col-md-8">{{activeCert.sha256}}</div>
                  <div class="col-md-4">SHA-1</div>
                  <div class="col-md-8">{{activeCert.sha1}}</div>
                  <div class="col-md-4">MD-5</div>
                  <div class="col-md-8">{{activeCert.md5}}</div>
                </div>
              </div>
            </div>
          </p-scrollPanel>
        </p-tabPanel>

        <p-tabPanel header="Detail">
          <p-scrollPanel>
            <pre style="overflow: visible;">{{activeCert.detail}}</pre>
          </p-scrollPanel>
        </p-tabPanel>

      </p-tabView>
    </div>
  </div>
</div>
<div mat-dialog-actions class="text-right" align="end">
  <button mat-raised-button mat-dialog-close>Cancel</button>
  <button id="saveBtn" mat-raised-button color="primary" (click)="save()" cdkFocusInitial
    [disabled]="saving || noneSelected()">Save</button>
</div>