import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Table } from 'primeng/table';
import { forkJoin, Observable } from 'rxjs';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';
import { LdapGroup, LocalGroup } from 'src/app/model/group';
import { Product } from 'src/app/model/product';
import { StorageGroupItem } from 'src/app/model/storage-groups';
import { TreeItem } from 'src/app/model/treeItem';
import { User } from 'src/app/model/user';
import { StorageGroupingService } from '../storage-grouping/storage-grouping.service';
import { UserGroupEditComponent } from './user-group-edit/user-group-edit.component';

class ExtendedGroup extends LocalGroup {
  availableUsers: User[];
  users: User[];
  changed: boolean;
  storageGroups: StorageGroupItem[];
}

interface AclTree {
  group: Record<string, AclGroup>;
}

class AclGroup {
  acl: AclObj;
}

interface AclObj extends Record<string, AclObj | boolean> {

}

enum SelectionType {
  ALL, PART, NONE
}

@Component({
  selector: 'xormon-user-groups-list',
  templateUrl: './user-groups-list.component.html',
  styleUrls: ['./user-groups-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserGroupsListComponent implements OnInit, OnDestroy {

  private static readonly GRANTED = 'granted';

  @BlockUI('lparAcl') blockLpar: NgBlockUI;
  @BlockUI('storAcl') blockStor: NgBlockUI;

  groupBorder = false;
  storageGroups: StorageGroupItem[];
  selectedGroup: ExtendedGroup;
  selectGroups: SelectItem[];
  availableUsers: User[];
  ldapGroups: LdapGroup[];
  selectedSubTabIndex = 0;
  storAclTree: Fancytree.Fancytree;
  storAclItems: AclTree;
  storAclItemsCache: AclTree;
  lparAclTree: Fancytree.Fancytree;
  lparAclItems: AclTree;
  lparAclItemsCache: AclTree;
  saving = false;

  constructor(private dataService: DataService, private http: HttpClient, private sgService: StorageGroupingService,
    private dialog: MatDialog, private confirmService: ConfirmationService, private log: LoggerService) {
  }

  ngOnDestroy(): void {
    this.blockLpar.unsubscribe();
    this.blockStor.unsubscribe();
  }

  ngOnInit() {
    this.dataService.nodeSelected.promise.then(() => {

      const observables: any[] = [
        this.http.get<ExtendedGroup[]>('/admin/userGroups/allGroupsWithUsers'),
        this.http.get<User[]>('/admin/users/list'),
        this.http.get<LdapGroup[]>('/admin/userGroups/getAllAdGroups'),
        this.sgService.getStorGrList()
      ];
      const storAclObs = this.http.get<TreeItem[]>(Globals.RUNTIME_PATH + Product.STOR.pathCgi + '/dbwrapper.sh?procname=getACLTree&getItems=true');
      const storAclGroupsObs = this.http.get(Globals.RUNTIME_PATH + Product.STOR.pathCgi + '/aclx.sh?cmd=json');
      const lparAclObs = this.http.get<TreeItem[]>(Globals.RUNTIME_PATH + Product.LPAR.pathCgi + '/dbwrapper.sh?procname=getLazyACLFolder&level=root');
      const lparAclGroupsObs = this.http.get(Globals.RUNTIME_PATH + Product.LPAR.pathCgi + '/aclx.sh?cmd=json');

      if (Globals.BACKEND_INFO.storEnabled.value) {
        observables.push(storAclObs);
        observables.push(storAclGroupsObs);
      } else {
        this.blockStor.start();
      }

      if (Globals.BACKEND_INFO.lparEnabled.value) {
        observables.push(lparAclObs);
        observables.push(lparAclGroupsObs);
      } else {
        this.blockLpar.start();
      }

      forkJoin(observables).subscribe(responses => {
        const self = this;
        this.selectGroups = (responses[0] as ExtendedGroup[]).map(eg => ({ label: eg.name, value: eg }));
        this.selectedGroup = responses[0][0];
        this.availableUsers = responses[1] as User[];
        for (const sg of this.selectGroups) {
          sg.value.availableUsers = this.availableUsers.filter(user => sg.value.users.every(u => u.id !== user.id));
        }
        this.ldapGroups = (responses[2] as LdapGroup[]).sort((left, right) => (left.ldapConfigName + left.name).localeCompare(right.ldapConfigName + right.name));//.map(g => g.name).sort();
        this.storageGroups = responses[3] as StorageGroupItem[];

        let nextIndex = 4;
        if (Globals.BACKEND_INFO.storEnabled.value) {
          let treeData = responses[nextIndex++];
          setTimeout(() => {
            let tree: any = $('#storAcl').fancytree({
              source: treeData,
              checkbox: true,
              selectMode: 3,
              icon: false,
              tooltip: true,
              extensions: ['filter'],
              filter: {
                autoApply: true,   // Re-apply last filter if lazy data is loaded
                autoExpand: true, // Expand all branches that contain matches while filtered
                fuzzy: false,      // Match single characters in order, e.g. 'fb' will match 'FooBar'
                hideExpanders: true,       // Hide expanders if all child nodes are hidden by filter
                leavesOnly: false, // Match end nodes only
                nodata: true,      // Display a 'no data' status node if result is empty
                mode: 'hide'       // Grayout unmatched nodes (pass "hide" to remove unmatched node instead)
              },
              clickFolderMode: 2,
              autoCollapse: false,
              select: function (event, data) {
                if (data.targetType == 'checkbox') {
                  //let selNodes = $(this).fancytree("getTree").getSelectedNodes(true);
                  //var group = $("#aclxgrptree").fancytree("getActiveNode").title;
                  const group = self.selectedGroup;
                  group.changed = true;
                  const groupLabel = group.name;
                  setDeep(self.storAclItemsCache, ['group', groupLabel, 'acl'], {}, true);
                  $(this).fancytree('getTree').visit(function (node) {
                    if (node.data.level == 'class') {
                      if (node.isSelected()) {
                        setDeep(self.storAclItemsCache, ['group', groupLabel, 'acl', 'class', node.data.class, UserGroupsListComponent.GRANTED], true, true);

                      } else {
                        $.each(node.getChildren(), function (x, device) {
                          if (device.isSelected()) {
                            setDeep(self.storAclItemsCache, ['group', groupLabel, 'acl', 'class', node.data.class, 'device', device.data.object_id, UserGroupsListComponent.GRANTED], true, true);

                          } else {
                            $.each(device.getChildren(), function (y, subsys) {
                              if (subsys.isSelected()) {
                                setDeep(self.storAclItemsCache, ['group', groupLabel, 'acl', 'class', node.data.class, 'device', device.data.object_id, 'subsys', subsys.data.subsys, UserGroupsListComponent.GRANTED], true, true);

                              } else {
                                $.each(subsys.getChildren(), function (z, item) {
                                  if (item.isSelected()) {
                                    setDeep(self.storAclItemsCache, ['group', groupLabel, 'acl', 'class', node.data.class, 'device', device.data.object_id, 'subsys', subsys.data.subsys, 'item', item.data.item_id], true, true);

                                  }
                                });
                              }
                            });
                          }
                        });
                      }

                      return Globals.FANCYTREE_SKIP;
                    }
                  });
                }
              }
            });
            tree.find('.fancytree-container').addClass('fancytree-connectors').addClass('fancytree-content');
            this.storAclTree = $.ui.fancytree.getTree('#storAcl');
          });

          this.storAclItems = responses[nextIndex++] as AclTree;
          if (!this.storAclItems.group)
            this.storAclItems.group = {};
          this.storAclItemsCache = JSON.parse(JSON.stringify(this.storAclItems));
        }
        if (Globals.BACKEND_INFO.lparEnabled.value) {
          let treeData = responses[nextIndex++];
          setTimeout(() => {
            let tree: any = $('#lparAcl').fancytree({
              source: treeData,
              checkbox: true,
              selectMode: 3,
              icon: false,
              tooltip: true,
              extensions: ['filter'],
              filter: {
                autoApply: true,   // Re-apply last filter if lazy data is loaded
                autoExpand: true, // Expand all branches that contain matches while filtered
                fuzzy: false,      // Match single characters in order, e.g. 'fb' will match 'FooBar'
                hideExpanders: true,       // Hide expanders if all child nodes are hidden by filter
                leavesOnly: false, // Match end nodes only
                nodata: true,      // Display a 'no data' status node if result is empty
                mode: 'hide'       // Grayout unmatched nodes (pass "hide" to remove unmatched node instead)
              },
              clickFolderMode: 2,
              autoCollapse: false,
              lazyLoad(event, data) {
                const node = data.node;
                data.result = {
                  url: Product.LPAR.pathCgi + '/dbwrapper.sh?procname=getLazyACLFolder',
                  data: node.data.next_level,
                  cache: false
                };
              },
              loadChildren: (event, data) => {
                if (!data.node.isRootNode() && !data.node.isPartsel()) {
                  let selected = data.node.isSelected();
                  for (const child of data.node.children) {
                    child.setSelected(selected);
                  }
                  return;
                }
                for (const child of data.node.children) {
                  switch (this.getLparSelection(child)) {
                    case SelectionType.ALL:
                      child.setSelected(true);
                      child.partsel = false;
                      break;
                    case SelectionType.NONE:
                      child.setSelected(false);
                      child.partsel = false;
                      break;
                    case SelectionType.PART:
                      child.partsel = true;
                      break;
                  }
                }
                if (!data.node.isRootNode())
                  self.fixMissingNodes(self.getTopNode(data.node));
              },
              select: function (event, data) {
                if (data.targetType == 'checkbox') {
                  const group = self.selectedGroup;
                  group.changed = true;
                  const groupLabel = group.name;
                  if (!self.lparAclItemsCache.group[groupLabel]) {
                    self.lparAclItemsCache.group[groupLabel] = new AclGroup();
                  }
                  if (!self.lparAclItemsCache.group[groupLabel].acl) {
                    self.lparAclItemsCache.group[groupLabel].acl = {};
                  }
                  data.node.partsel = false;
                  self.replaceAclPart(data.node, self.lparAclItemsCache.group[groupLabel].acl);
                  data.node.render();
                }
              }
            });
            tree.find('.fancytree-container').addClass('fancytree-connectors').addClass('fancytree-content');
            this.lparAclTree = $.ui.fancytree.getTree('#lparAcl');
          });

          this.lparAclItems = responses[nextIndex++] as AclTree;
          if (!this.lparAclItems.group)
            this.lparAclItems.group = {};
          this.lparAclItemsCache = JSON.parse(JSON.stringify(this.lparAclItems));
        }
      }, error => this.log.error('Failed to get groups', error));
    }, reason => console.warn(reason));
  }

  private getTopNode(node: Fancytree.FancytreeNode) {
    if (node.parent.isRootNode())
      return node;
    return this.getTopNode(node.parent);
  }

  private fixMissingNodes(node: Fancytree.FancytreeNode) {

    if (node.isSelected())
      return SelectionType.ALL;
    if (!node.isPartsel())
      return SelectionType.NONE;
    if (!node.children)
      return SelectionType.PART;
    let all = false;
    let none = false;
    for (const child of node.children) {
      switch (this.fixMissingNodes(child)) {
        case SelectionType.PART:
          return SelectionType.PART;
        case SelectionType.ALL:
          if (none)
            return SelectionType.PART;
          else
            all = true;
          break;
        case SelectionType.NONE:
          if (all)
            return SelectionType.PART;
          else
            none = true;
          break;
      }
    }

    if (all) {
      node.setSelected(true);
      node.partsel = false;
      return SelectionType.ALL;
    }
    node.setSelected(false);
    node.partsel = false;
    return SelectionType.NONE;
  }

  private currentAcl: AclObj | boolean;
  private replaceAclPart(node: Fancytree.FancytreeNode, acl: AclObj) {
    if (node.parent.isRootNode()) {
      this.currentAcl = acl;
      return this.setAclNode(node);
    }
    if (this.replaceAclPart(node.parent, acl)) {
      return true;
    }
    return this.setAclNode(node);
  }
  private setAclNode(node: Fancytree.FancytreeNode) {
    let id = this.getNodeId(node);
    if (node.isSelected()) {
      this.currentAcl[id] = true;
      return true;
    }
    if (node.isPartsel()) {
      let a = this.currentAcl[id];
      if (a === true || !a) {
        this.currentAcl = this.currentAcl[id] = {};
        //if (node.children) {
        for (const child of node.children) {
          if (child.isSelected()) {
            this.currentAcl[this.getNodeId(child)] = true;
          }
        }
        //}
      } else {
        this.currentAcl = a;
      }
      return;
    }
    delete this.currentAcl[id];
    return true;
  }

  private getLparSelection(node: Fancytree.FancytreeNode) {
    if (node.parent.isRootNode()) {
      let group = this.lparAclItemsCache.group[this.selectedGroup.name];
      if (!group || !group.acl)
        return SelectionType.NONE;
      this.currentAcl = group.acl[this.getHwType(node)];
      if (!this.currentAcl)
        return SelectionType.NONE;
      if (this.currentAcl === true)
        return SelectionType.ALL;
      return SelectionType.PART;
    }
    let selection = this.getLparSelection(node.parent);
    if (selection !== SelectionType.PART)
      return selection;
    this.currentAcl = this.currentAcl[this.getNodeId(node)];
    if (!this.currentAcl)
      return SelectionType.NONE;
    if (this.currentAcl === true)
      return SelectionType.ALL;
    return SelectionType.PART;
  }

  onGroupChange() {
    const self = this;
    if (this.selectedGroup.id < 3 && this.selectedSubTabIndex === 1)
      this.selectedSubTabIndex = 0;
    if (this.storAclTree) {
      $('#storfilter').off().on('keyup', function (e) {
        var match = $(this).val() as string;

        if (e.which === 27 || $.trim(match) === '') {
          self.storAclTree.clearFilter();
          e.preventDefault();
          return;
        }
        if (e && e.which === 13) {
          e.preventDefault();
          var n = self.storAclTree.filterNodes(function (node) {
            return new RegExp(match, 'i').test(node.title);
          }, true);
          self.storAclTree.visit(function (node) {
            if (!$(node.span).hasClass('fancytree-hide')) {
              node.setExpanded(true);
            }
          });
        }
      });

      self.storAclTree.clearFilter();
      self.storAclTree.selectAll(false);
      for (const node of self.storAclTree.getRootNode().getChildren()) {
        node.partsel = false;
      }
      var curgrp = this.selectedGroup.name;
      if (this.storAclItemsCache.group && this.storAclItemsCache.group[curgrp]) {
        var curgrpacl = this.storAclItemsCache.group[curgrp];
        self.storAclTree.visit(function (node) {

          if (node.data.level == 'class') {
            if (checkNested(curgrpacl, 'acl', 'class', node.data.class, UserGroupsListComponent.GRANTED)) {
              node.setSelected();
            }
          } else if (node.data.level == 'device') {
            if (checkNested(curgrpacl, 'acl', 'class', node.data.class, 'device', node.data.object_id, UserGroupsListComponent.GRANTED)) {
              node.setSelected();
            }
          } else if (node.data.level == 'subsys') {
            if (checkNested(curgrpacl, 'acl', 'class', node.data.class, 'device', node.data.object_id, 'subsys', node.data.subsys, UserGroupsListComponent.GRANTED)) {
              node.setSelected();

            }
          } else if (node.data.level == 'item') {
            if (checkNested(curgrpacl, 'acl', 'class', node.data.class, 'device', node.data.object_id, 'subsys', node.data.subsys, 'item', node.data.item_id)) {
              node.setSelected();

            }
          }
        });
      }
      self.storAclTree.render();
    }
    if (this.lparAclTree) {
      $('#lparfilter').off().on('keyup', function (e) {
        var match = $(this).val() as string;
        if (e.which === 27 || $.trim(match) === '') {
          self.lparAclTree.clearFilter();
          e.preventDefault();
          return;
        }
        if (e && e.which === 13) {
          e.preventDefault();
          var n = self.lparAclTree.filterNodes(function (node) {
            return new RegExp(match, 'i').test(node.title);
          }, true);
          self.lparAclTree.visit(function (node) {
            if (!$(node.span).hasClass('fancytree-hide')) {
              node.setExpanded(true);
            }
          });
        }
      });
      self.lparAclTree.clearFilter();
      self.lparAclTree.selectAll(false);
      for (const node of self.lparAclTree.getRootNode().getChildren()) {
        node.partsel = false;
      }
      var curgrp = this.selectedGroup.name;
      if (this.lparAclItemsCache.group && this.lparAclItemsCache.group[curgrp]) {
        var curgrpacl = this.lparAclItemsCache.group[curgrp];
        if (curgrpacl.acl) {
          self.lparAclTree.visit(function (node) {
            if (self.checkSelected(curgrpacl.acl, node))
              return Globals.FANCYTREE_SKIP;
          });
          for (const child of self.lparAclTree.getRootNode().children) {
            self.fixMissingNodes(child);
          }
        }
      }
      self.lparAclTree.render();
    }
  }

  createGroup() {
    this.dialog.open<UserGroupEditComponent, {}, ExtendedGroup>(UserGroupEditComponent, { data: {} }).afterClosed().subscribe(data => {
      if (data) {
        data.availableUsers = [...this.availableUsers];
        data.users = [];
        data.storageGroups = [];
        this.selectGroups.push({ label: data.name, value: data });
        this.selectedGroup = data;
        this.onGroupChange();
      }
    });
  }

  editGroup() {
    this.dialog.open<UserGroupEditComponent, ExtendedGroup, ExtendedGroup>(UserGroupEditComponent, { data: { ...this.selectedGroup } }).afterClosed().subscribe(data => {
      if (data) {
        if (this.selectedGroup.name !== data.name) {
          const observables: Observable<unknown>[] = [];

          if (Globals.BACKEND_INFO.storEnabled.value) {
            this.storAclItems.group[data.name] = JSON.parse(JSON.stringify(this.storAclItemsCache.group[this.selectedGroup.name] || {}));
            this.storAclItemsCache.group[data.name] = this.storAclItemsCache.group[this.selectedGroup.name];
            delete this.storAclItemsCache.group[this.selectedGroup.name];
            delete this.storAclItems.group[this.selectedGroup.name];
            let params = new HttpParams({ fromObject: { cmd: 'saveacl', acl: JSON.stringify(this.storAclItems, null, 2) } });
            const saveStorAcl = this.http.post(Globals.RUNTIME_PATH + Product.STOR.pathCgi + '/aclx.sh', params);
            observables.push(saveStorAcl);
          }

          if (Globals.BACKEND_INFO.lparEnabled.value) {
            this.lparAclItems.group[data.name] = JSON.parse(JSON.stringify(this.lparAclItemsCache.group[this.selectedGroup.name] || {}));
            this.lparAclItemsCache.group[data.name] = this.lparAclItemsCache.group[this.selectedGroup.name];
            delete this.lparAclItems.group[this.selectedGroup.name];
            delete this.lparAclItemsCache.group[this.selectedGroup.name];
            let params = new HttpParams({ fromObject: { cmd: 'saveacl', acl: JSON.stringify(this.lparAclItems, null, Globals.JSON_INDENT) } });
            const saveLparAcl = this.http.post(Globals.RUNTIME_PATH + Product.LPAR.pathCgi + '/aclx.sh', params);
            observables.push(saveLparAcl);
          }

          this.saving = true;
          const group = this.selectedGroup;

          forkJoin(observables).subscribe(results => {
            document.body.style.cursor = 'default';
            group.changed = false;
            this.saving = false;
            this.log.info(`Group ${group.name} saved successfully.`);
          }, error => {
            document.body.style.cursor = 'default';
            this.saving = false;
            this.log.error('Failed to change ACL for group ID ' + group.id, error);
          });
        }
        this.selectedGroup.name = data.name;
        this.selectedGroup.description = data.description;
      }
    });
  }

  deleteGroup() {
    this.confirmService.confirm({
      message: `Are you sure to delete group <strong>${this.selectedGroup.name}</strong>?`,
      accept: () => {
        document.body.style.cursor = 'wait';
        this.http.delete('/admin/userGroups/group/' + this.selectedGroup.id).subscribe(data => {
          document.body.style.cursor = 'default';
          this.selectGroups = this.selectGroups.filter(g => g.value.id !== this.selectedGroup.id);
          this.selectedGroup = this.selectGroups[this.selectGroups.length - 1].value;
          this.onGroupChange();
        }, error => {
          document.body.style.cursor = 'default';
          this.log.error('Failed to delete group ID ' + this.selectedGroup.id, error);
        });
      }
    });
  }

  saveGroup(group: ExtendedGroup) {
    document.body.style.cursor = 'wait';
    this.saving = true;
    const observables = [this.http.put('/admin/userGroups/group', group)];

    if (Globals.BACKEND_INFO.storEnabled.value) {
      this.storAclItems.group[group.name] = JSON.parse(JSON.stringify(this.storAclItemsCache.group[group.name] || {}));
      let params = new HttpParams({ fromObject: { cmd: 'saveacl', acl: JSON.stringify(this.storAclItems, null, 2) } });
      const saveStorAcl = this.http.post(Globals.RUNTIME_PATH + Product.STOR.pathCgi + '/aclx.sh', params);
      observables.push(saveStorAcl);
    }


    if (Globals.BACKEND_INFO.lparEnabled.value) {
      this.lparAclItems.group[group.name] = JSON.parse(JSON.stringify(this.lparAclItemsCache.group[group.name] || {}));
      let params = new HttpParams({ fromObject: { cmd: 'saveacl', acl: JSON.stringify(this.lparAclItems, null, 2) } });
      const saveLparAcl = this.http.post(Globals.RUNTIME_PATH + Product.LPAR.pathCgi + '/aclx.sh', params);
      observables.push(saveLparAcl);
    }

    forkJoin(observables).subscribe(results => {
      document.body.style.cursor = 'default';
      group.changed = false;
      this.log.info(`Group ${group.name} saved successfully.`);
    }, error => {
      document.body.style.cursor = 'default';
      this.log.error('Failed to save group ID ' + group.id, error);
    }, () => this.saving = false);
  }

  filterSelectedLdapGroups(table: Table, group: ExtendedGroup, event) {
    if (event.value === true)
      table.filteredValue = group.ldapGroups;
    else if (event.value === false)
      table.filteredValue = this.ldapGroups.filter(g => group.ldapGroups.every(selected => selected.id !== g.id));
    else
      table.filteredValue = null;
  }

  private getNodeId(node: Fancytree.FancytreeNode) {
    if (node.parent.isRootNode()) {
      return this.getHwType(node);
    }
    if (node.data.item_id)
      return node.data.item_id;
    if (node.data.next_level && node.data.next_level.path_ids) {
      let id = node.data.next_level.path_ids.split(',')[0];
      if (node.parent.data.next_level && node.parent.data.next_level.path_ids) {
        let parentId = node.parent.data.next_level.path_ids.split(',')[0];
        if (id === parentId)
          return node.title;
      }
      return id;
    }
    return node.title;
  }

  private getHwType(node: Fancytree.FancytreeNode) {
    if (!node) {
      return null;
    }
    if (node.data.next_level) {
      return node.data.next_level.hw_type;
    }
    return node.title;
  }

  private currentObj;
  private checkSelected(obj, node: Fancytree.FancytreeNode) {
    if (node.isRootNode())
      return;
    if (node.getParent().isRootNode()) {
      this.currentObj = obj[this.getHwType(node)];
      return this.checkSelectedNode(node);
    }
    if (this.checkSelected(obj, node.getParent()))
      return true;
    this.currentObj = this.currentObj[this.getNodeId(node)];
    return this.checkSelectedNode(node);
  }
  private checkSelectedNode(node: Fancytree.FancytreeNode) {
    if (this.currentObj === true) {
      node.setSelected(true);
      node.partsel = false;
      return true;
    }
    if (this.currentObj) {
      node.partsel = true;
      return;
    }
    node.partsel = false;
    return true;
  }

  private setObjectByNode(obj, node: Fancytree.FancytreeNode, level: string, id: string) {

    if (node.isSelected()) {
      obj[id] = true;
    } else {
      if (node.isPartsel()) {
        let objId = obj[id];
        if (!objId) {
          objId = obj[id] = {};
        }
        node.getChildren().forEach(child => {
          this.setObjectByNode(objId, child, child.data.level, child.data.item_id || child.title);
        });
      }
    }
  }
}

/**
 * Dynamically sets a deeply nested value in an object.
 * Optionally "bores" a path to it if its undefined.
 * @function
 * @param {!object} obj  - The object which contains the value you want to change/set.
 * @param {!array} path  - The array representation of path to the value you want to change/set.
 * @param {!mixed} value - The value you want to set it to.
 * @param {boolean} setrecursively - If true, will set value of non-existing path as well.
 */
function setDeep(obj, path, value, setrecursively = false) {
  let level = 0;

  path.reduce((a, b) => {
    level++;

    if (setrecursively && typeof a[b] === 'undefined' && level !== path.length) {
      a[b] = {};
      return a[b];
    }

    if (level === path.length) {
      a[b] = value;
      return value;
    } else {
      return a[b];
    }
  }, obj);
}

function checkNested(obj, ...params: any[] /*, level1, level2, ... levelN*/) {
  var args = Array.prototype.slice.call(arguments, 1);

  for (var i = 0; i < args.length; i++) {
    if (!obj || !obj.hasOwnProperty(args[i])) {
      return false;
    }
    obj = obj[args[i]];
  }
  return true;
}