import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivationStart, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Message, MessageService } from 'primeng/api';
import { filter } from 'rxjs/operators';
import { DataService } from './data.service';
import { Globals } from './globals';
import { LoggerService } from './logger.service';

@Component({
  selector: 'xormon-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {

  static readonly VERSION_LABEL = 'Version: ';

  static block: NgBlockUI;

  @BlockUI() blockUI: NgBlockUI;

  errorMessage: Message;
  errorDialogShown = false;
  copyingClipboard = false;

  constructor(private router: Router, private dataService: DataService, private log: LoggerService,
    private msgService: MessageService) {
    this.router.events.pipe(filter(e => e instanceof ActivationStart && e.snapshot.outlet === Globals.OUTLET_CONTENT))
      .subscribe((e: ActivationStart) => {
        const outlet = Globals.getContentOutlet(this.router);
        if (outlet)
          outlet.deactivate();
      });
  }

  ngOnInit(): void {
    AppComponent.block = this.blockUI;
  }

  get treeWidth() {
    return Globals.treeWidth;
  }

  get isLogin() {
    return !Globals.authenticated
      || this.router.url.startsWith(Globals.PATH_LOGIN);
  }

  get version() {
    return Globals.ABOUT.xormonVersion;
  }

  get buildTime() {
    return Globals.ABOUT.xormonBuildTime;
  }

  get versionLabel() {
    return AppComponent.VERSION_LABEL;
  }

  get isContent() {
    if (this.dataService.selectedNode)
      return true;
    return Globals.hasComponent(Globals.getContentOutlet(this.router));
  }

  showError(error: Message, event: Event) {
    event.preventDefault();
    this.errorMessage = error;
    this.errorDialogShown = true;
    this.msgService.clear();
  }

  errorToClipboard(error: Message) {
    if (this.copyingClipboard)
      return;

    this.copyingClipboard = true;
    let str = JSON.stringify({ ...error.data, detail: error.detail }, null, Globals.JSON_INDENT);
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = str;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();

    try {
      if (document.execCommand('copy')) {
        let link = $('#errorCopy i');
        link.effect({ effect: "bounce", complete: () => this.copyingClipboard = false });
      } else {
        this.log.error('Failed to store detail in clipbboard!');
        this.copyingClipboard = false;
      }
    } catch (err) {
      console.error(err);
      this.log.error('Failed to store detail in clipboard!');
      this.copyingClipboard = false;
    }

    document.body.removeChild(selBox);
  }

  reloadPage(event: Event) {
    event.preventDefault();
    location.reload();
  }

}
