import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, ParamMap, Params, Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, startWith } from 'rxjs/operators';
import wlabel from 'src/app/wlabel.json';
import { AppComponent } from '../app.component';
import { PortalService } from '../content/dashboard/portal/portal.service';
import { DataService } from '../data.service';
import { Globals } from '../globals';
import { LoggerService } from '../logger.service';
import { MenuItem, MenuItems } from '../menu-items';
import { Deferred } from '../model/deferred';
import { HwType } from '../model/hwType';
import { Product } from '../model/product';
import { ROUTES } from '../model/routes';
import { StorBacklinkParams } from '../model/storBacklinkParams';
import { TreeItem } from '../model/treeItem';
import { PromptComponent, PromptData } from '../prompt/prompt.component';


@Component({
  selector: 'xormon-tree.xormon-tree',
  templateUrl: './tree.component.html',
  styleUrls: ['./tree.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TreeComponent implements OnInit, OnDestroy {

  private typeResolved = new Deferred();
  private parentResolved = new Deferred();
  private navigationSubscription = Subscription.EMPTY;
  private lastActiveNode: Fancytree.FancytreeNode;

  logoUrl = wlabel.xormonLogo;
  heading: string;
  replaceUrl = false;
  searchHistoryList: Observable<string[]>;
  searchHover = false;

  @ViewChild(MatAutocompleteTrigger, { static: true })
  filterAuto: MatAutocompleteTrigger;

  @ViewChild('filter', { static: true }) filterInput: ElementRef<HTMLInputElement>;

  titleCtrl = new FormControl();

  constructor(private http: HttpClient, private route: ActivatedRoute, private dataService: DataService,
    private router: Router, private log: LoggerService, private confirmService: ConfirmationService,
    private portalService: PortalService, private dialog: MatDialog, private zone: NgZone) {
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }

  ngOnInit() {
    const self = this;
    this.titleCtrl.valueChanges
      .pipe(
        debounceTime(500),
        startWith(''),
      ).subscribe(title => {
        if (!this.dataService.selectedParent || !this.dataService.selectedType) {
          return;
        } else if (title && title !== '') {
          this.searchHistoryList = this.getTitles(title);
        } else if (title == null || title === '') {
          this.searchHistoryList = this.searchHistory();
        }
      });
    //TODO: remove first call to searchHistory with undefined/undefined
    this.titleCtrl.setValue('');

    $('div#menuTree').fancytree({
      extensions: ['filter', 'edit'],
      filter: {
        mode: 'hide',
        autoApply: true,
        autoExpand: false
      },
      toggleEffect: { effect: "slideToggle", duration: 100, complete: null },
      edit: {
        beforeEdit: function (event, data: Fancytree.EventData) {
          if (self.dataService.selectedType === Globals.TYPE_DASHBOARD &&
            (data.node.parent.title === Globals.TITLE_FAVORITE || data.node.parent.title === Globals.TITLE_GLOBAL)) {
            const tab = self.dataService.dashboard.tabs.find(value => (value.id as any) == data.node.key);
            return tab && tab.writable;
          }
          return false;
        },
        edit: function (event, data) {
          data.input.attr('maxlength', '250');
          data.input.css('width', '100%');
        },
        beforeClose: function (event, data) {
          if (data.save) {
            let title = data.input.val().trim();
            let msg: string;
            if (Globals.DEFAULT_DASHBOARD_NAME === title || title === Globals.TITLE_GLOBAL) {
              msg = 'Dashboard name <strong>' + title + '</strong> is reserved';
            } else if (title.includes(":")) {
              msg = Globals.DASHBOARD_INVALID_CHARACTER_MSG;
            } else if (data.node.parent.children.some(n => n.title === title)) {
              msg = 'Dashboard <strong>' + title + '</strong> already exists in this context';
            }
            if (msg) {
              self.zone.run(() => self.log.warn(msg));
              return false;
            }
          }
          return true;
        },
        save: function (event, data) {
          const tab = self.dataService.dashboard.tabs.find(value => value.id == data.node.key);
          tab.name = tab.name = data.input.val();
          self.portalService.saveTabs([tab]).subscribe(resp => {
          }, error => {
            self.log.error('Failed to rename tab!', error);
            data.node.setTitle(data.orgTitle);
          });
        },
      },
      source: [],
      icon: false,
      tooltip: true,
      lazyLoad(event, data) {
        const node = data.node;
        data.result = {
          url: self.dataService.cgiPath + '/dbwrapper.sh?procname=getLazyMenuFolder',
          data: self.dataService.isLpar() ? node.data.next_level : node.data,
          cache: false
        };
      },
      createNode(event, data) {
        if (self.dataService.selectedType === Globals.TYPE_DASHBOARD &&
          (data.node.parent.title === Globals.TITLE_FAVORITE || data.node.parent.title === Globals.TITLE_GLOBAL)) {
          const tab = self.dataService.dashboard.tabs.find(value => value.id == data.node.key as any);
          if (!tab)
            return;
          data.node.addClass('contextMenu');
          let items;
          if (tab.writable) {
            setTimeout(() => {
              $(data.node.span).find('.fancytree-title').attr('title', 'Right click for context menu');
            });
            items = {
              "group": {
                name: "Add group",
                icon: "fas fa-plus",
                className: 'before-info',
                callback: function (itemKey, opt, e) {
                  self.dialog.open<PromptComponent, PromptData, string>(PromptComponent,
                    { data: { heading: 'Create new group in tab: ' + data.node.title, inputText: 'Name' } })
                    .afterClosed().subscribe(result => {
                      if (result) {
                        let id = parseInt(data.node.key);
                        const tab = self.dataService.dashboard.tabs.find(t => t.id === id);
                        tab.groups.push(
                          { id: -1, width: null, height: null, name: result, sortOrder: null, graphs: [] }
                        );
                        self.portalService.saveTabs([tab]).subscribe(resp => {
                          if (data.node.isActive()) {
                            self.dataService.reloadCurrentComponent();
                          }
                        }, error => {
                          self.log.error('Failed to save tab with new group!', error);
                        });
                      }
                    });
                }
              },
              "edit": {
                name: 'Rename',
                icon: 'edit',
                className: 'before-info',
                callback: (itemKey, opt, e) => {
                  data.node.editStart();
                }
              },
              "delete": {
                name: "Delete",
                icon: "delete",
                className: 'before-danger',
                callback: function (itemKey, opt, e) {
                  self.confirmService.confirm({
                    message: 'Are you sure to delete dashboard "' + data.node.title + '" and all its groups?',
                    accept: () => {
                      self.portalService.deleteTab(data.node.key).subscribe(resp => {
                        self.dataService.initPortal();
                        let parent = data.node.parent;
                        self.setChild(parent.key);
                        data.node.remove();
                        parent.render(true, true);
                      }, error => self.log.error('Failed to delete dashboard: ' + data.node.title, error));
                    }
                  });
                }
              },
            };
          } else {
            items = {
              "remove": {
                name: "Remove",
                icon: "fas fa-times",
                className: 'before-danger',
                callback: function (itemKey, opt, e) {
                  self.confirmService.confirm({
                    message: 'Are you sure to remove shared dashboard "' + data.node.title + '" from your collection?',
                    accept: () => {
                      self.portalService.removeTab(data.node.key).subscribe(resp => {
                        self.dataService.initPortal();
                        self.setChild(data.node.parent.key);
                        data.node.remove();
                      }, error => self.log.error('Failed to remove dashbaord: ' + data.node.title, error));
                    }
                  });
                }
              }
            };
          }
          setTimeout(() => {
            $(data.node.span).contextMenu({
              selector: 'span',
              items: items
            });
          });
        }
      },
      click(event, data) {
        if (data.node === self.dataService.tree.getActiveNode()) {
          if (data.node.hasChildren()) {
            data.node.toggleExpanded();
          } else {
            self.dataService.reloadCurrentComponent();
          }
          return false;
        }
        return true;
      },
      beforeActivate(event, data) {
        if (self.dataService.selectedType === Globals.TYPE_CUSTOM_GROUPS && !data.node.data.href && !data.node.hasChildren())
          return false;
        return true;
      },
      activate(event, data) {
        self.activate(event, data);
      },
      expand(event, data) {
        if (data.node.isActive() && !data.node.data.content) {
          let child = self.getFirstNode(data.node.children);
          if (child) {
            self.activateNode(child, true, { activeVisible: true });
          }
        }
      }
    });

    $('.fancytree-container').addClass('fancytree-connectors');

    this.dataService.tree = $.ui.fancytree.getTree('div#menuTree');

    this.route.paramMap.subscribe((params: ParamMap) => {
      this.navigate(params.get(ROUTES.TYPE), params.get(ROUTES.PARENT), params.get(ROUTES.CHILD));
    });
  }

  private isParentFromTree() {
    return this.dataService.selectedType === Globals.TYPE_DASHBOARD ||
      this.dataService.selectedType === Globals.TYPE_CUSTOM_GROUPS ||
      this.dataService.selectedType === Globals.TYPE_REPORTER || this.dataService.selectedType === Globals.TYPE_REPORT;
  }

  private activate(event: JQueryEventObject, data: Fancytree.EventData) {
    if (data.node.data.href || !data.node.children && !data.node.lazy) {
      let parent = this.dataService.selectedParent;
      if (this.isParentFromTree()) {
        parent = Globals.getTopNode(data.node).title.toLowerCase();
      }
      let key = this.dataService.getKey(data.node);
      this.navigate(this.dataService.selectedType, parent, key);
    } else if (this.dataService.selectedType === Globals.TYPE_DASHBOARD &&
      (data.node.title === Globals.TITLE_FAVORITE || data.node.title === Globals.TITLE_GLOBAL)) {
      let key = this.dataService.getKey(data.node);
      if (data.node === this.lastActiveNode) {
        if (data.node.isExpanded()) {
          data.node.setExpanded(false);
        }
        else
          data.node.setExpanded(true, { noEvents: true });
      }
      this.navigate(this.dataService.selectedType, Globals.getTopNode(data.node).title.toLowerCase(), key);

    } else if (data.node.isExpanded()) {
      data.node.setExpanded(false);
    } else if (data.node.lazy) {
      data.node.setExpanded(true);
    } else {
      setTimeout(() => {
        this.activateNode(this.getFirstNode(data.node.children), true, { noEvents: false, activeVisible: true });
      });
    }
    this.lastActiveNode = data.node;
  }

  private activateNode(node: Fancytree.FancytreeNode, flag?: boolean, opts?: object) {

    node.setActive(flag, opts);

    //this.lastActiveNode = node;
  }

  get isFreeEdition() {
    return Globals.freeEdition;
  }

  get isHeading() {
    return this.dataService.selectedType !== Globals.TYPE_DASHBOARD &&
      this.dataService.selectedType !== Globals.TYPE_CUSTOM_GROUPS;
  }

  get isFilter() {
    return this.dataService.selectedType === Globals.TYPE_SERVER ||
      (this.dataService.selectedType === Globals.TYPE_STORAGE && this.dataService.selectedParent !== Globals.TYPE_TOTALS)
      || this.dataService.selectedType === Globals.TYPE_STORGR
      || this.dataService.selectedType === Globals.TYPE_NETWORK;
  }

  private get isContentUrl() {
    return this.router.url.includes(`(${Globals.OUTLET_CONTENT}:`);
  }

  private navigate(type, parent, child) {
    child = decodeURIComponent(child);
    child = Globals.replacement2slash(child);
    if (this.dataService.navigationMenu) {
      this.replaceUrl = true;
    }
    if (this.dataService.selectedType === type) {
      if (this.dataService.selectedParent === parent && !this.dataService.navigationMenu) {
        if (this.dataService.selectedNode && this.dataService.getKey(this.dataService.selectedNode) === child && this.isContentUrl) {
        } else {
          this.setChild(child);
        }
      } else {
        // set parent from route
        this.setParent(parent);

        // set first child or from route
        this.setChild(child);
      }
    } else {
      // set type from route
      this.setType(type);
      // set parent from route
      this.setParent(parent);
      // set child from route or first
      this.setChild(child);
    }
  }

  private setType(menuType) {
    this.typeResolved.reset();
    if (Globals.TYPE_SERVER === menuType) {
      this.dataService.cgiPath = Product.LPAR.pathCgi;
    } else if (Globals.TYPE_STORAGE === menuType || Globals.TYPE_NETWORK === menuType || Globals.TYPE_STORGR === menuType) {
      this.dataService.cgiPath = Product.STOR.pathCgi;
    } else {
      this.dataService.cgiPath = null;
    }
    if (menuType === Globals.TYPE_DASHBOARD) {
      Promise.allSettled([this.dataService.dashboardLoaded.promise,
      this.dataService.backendLoaded.promise, this.dataService.serverLoaded.promise, this.dataService.networkLoaded.promise,
      this.dataService.storageLoaded.promise]).then(() => {
        this.heading = MenuItems.dashboardItems.title;
        this.dataService.tree.reload(this.filterVisibleTree(MenuItems.dashboardItems.children));
        this.typeResolved.resolve();
      }, reason => console.warn(reason));
    } else if (menuType === Globals.TYPE_CUSTOM_GROUPS) {
      this.heading = MenuItems.customGroupItems.title;
      this.dataService.getCGs().subscribe(data => {
        this.dataService.backendLoaded.promise.then(() => {
          this.dataService.tree.reload(this.filterVisibleTree(data));
          this.typeResolved.resolve();
        }, reason => console.warn(reason));
      }, error => this.log.error('Failed to get custom group items!', error)
      );
    } else if (menuType === Globals.TYPE_REPORTER) {
      this.dataService.backendLoaded.promise.then(() => {
        this.heading = MenuItems.reporterItems.title;
        this.dataService.tree.reload(this.filterVisibleTree(MenuItems.reporterItems.children));
        this.typeResolved.resolve();
      }, reason => console.warn(reason));
    } else if (menuType === Globals.TYPE_REPORT) {
      this.dataService.backendLoaded.promise.then(() => {
        this.heading = MenuItems.reportItems.title;
        this.dataService.tree.reload(this.filterVisibleTree(MenuItems.reportItems.children));
        this.typeResolved.resolve();
      }, reason => console.warn(reason));
    } else {
      this.typeResolved.resolve();
    }
    this.dataService.selectedType = menuType;

  }

  private setParent(treeName: string) {
    this.clearSearch();
    this.parentResolved.reset();
    this.dataService.selectedParent = treeName;
    if (this.dataService.selectedType === Globals.TYPE_SERVER) {
      // wait for server data
      this.dataService.serverLoaded.promise.then((done) => {
        if (!this.dataService.serverPlatforms[treeName]) {
          this.parentResolved.reject();
          this.log.warn('No such platform: ' + treeName);
          return;
        }
        this.heading = this.dataService.serverPlatforms[treeName].title;
        if (this.dataService.navigationMenu) {
          let data = this.dataService.navigationMenu;
          if (treeName === HwType.POWER.name) {
            //TODO: remove once nmon implemented
            data = data.filter(ti => ti.href !== 'nmonfile.html')
          }
          this.dataService.tree.reload(data);
          this.dataService.navigationMenu = null;
          this.parentResolved.resolve();
        } else {
          this.dataService.getServerChildren(treeName).subscribe(data => {
            if (treeName === HwType.POWER.name) {
              //TODO: remove once nmon implemented
              data = data.filter(ti => ti.href !== 'nmonfile.html')
            }
            this.dataService.tree.reload(data);
            this.parentResolved.resolve();
          }, error => {
            this.log.error('Failed to get menu for ' + treeName, error);
            this.parentResolved.reject();
          });
        }
      }, error => this.parentResolved.reject());
    } else if (this.dataService.selectedType === Globals.TYPE_STORAGE) {
      // wait for stor data
      if (treeName === "totals") {
        Product.STOR.resolved.promise.then(() => {
          this.heading = MenuItems.storageTotal.title;
          this.dataService.tree.reload(this.filterVisibleTree(MenuItems.storageTotal.children));
          this.parentResolved.resolve();
        }, error => this.parentResolved.reject());
      } else {
        this.dataService.storageLoaded.promise.then(() => {
          let st = this.dataService.storageTypes[treeName];
          if (!st) {
            this.parentResolved.reject();
            this.log.warn('No such device: ' + treeName);
            return;
          }
          this.heading = st.label;
          if (this.dataService.navigationMenu) {
            this.dataService.tree.reload(this.dataService.navigationMenu);
            this.dataService.navigationMenu = null;
          } else {
            this.dataService.tree.reload(st.items);
          }
          this.parentResolved.resolve();
        }, error => this.parentResolved.reject());
      }
    } else if (this.dataService.selectedType === Globals.TYPE_NETWORK) {
      // wait for network data
      this.dataService.networkLoaded.promise.then(() => {
        let nt = this.dataService.networkTypes[treeName];
        if (!nt) {
          this.parentResolved.reject();
          this.log.warn('No such network type: ' + treeName);
          return;
        }
        this.heading = nt.title;
        if (this.dataService.navigationMenu) {
          this.dataService.networkChildren = this.dataService.navigationMenu.filter(x => x.object_id).map(x => x.object_id);
          this.dataService.tree.reload(this.dataService.navigationMenu);
          this.dataService.navigationMenu = null;
          this.parentResolved.resolve();
        } else {
          this.dataService.getNetworkChildren(treeName).subscribe(data => {
            this.dataService.networkChildren = data.filter(x => x.object_id).map(x => x.object_id);
            this.dataService.tree.reload(data);
            this.parentResolved.resolve();
          }, error => {
            this.log.error('Failed to get network items!', error);
            this.parentResolved.reject();
          });
        }
      });
    } else if (this.dataService.selectedType === Globals.TYPE_STORGR) {
      this.dataService.storageGrLoaded.promise.then(() => {
        //TODO: backlink menu
        this.heading = treeName;
        this.dataService.tree.reload(this.dataService.sgHolder[treeName]);
        this.parentResolved.resolve();
      });
    } else if (this.dataService.selectedType === Globals.TYPE_CONFIG) {
      Promise.all([this.dataService.backendLoaded.promise, Globals.userLoaded.promise]).then(() => {
        const cfg = MenuItems.configItems.children.find(item => item.key === treeName);
        if (!cfg) {
          this.parentResolved.reject();
          this.log.warn('No such configuration: ' + treeName);
          return;
        }
        this.heading = cfg.title + ' Configuration';
        this.dataService.tree.reload(this.filterVisibleTree(cfg.children));
        if (Globals.TYPE_SERVER === treeName) {
          this.dataService.cgiPath = Product.LPAR.pathCgi;
          this.dataService.serverLoaded.promise.then(() => this.parentResolved.resolve());
        } else if (Globals.TYPE_STORAGE === treeName) {
          this.dataService.cgiPath = Product.STOR.pathCgi;
          Product.STOR.resolved.promise.then(() => this.parentResolved.resolve());
        } else {
          this.dataService.cgiPath = null;
          this.parentResolved.resolve();
        }
      }, reason => console.warn(reason));
    } else if (this.isParentFromTree()) {
      if (Globals.TYPE_MANAGE === treeName || Globals.TYPE_GLOBAL === treeName) {
        this.parentResolved.resolve();
      } else if (Globals.TYPE_SERVER === treeName) {
        Product.LPAR.resolved.promise.then(() => {
          this.dataService.cgiPath = Product.LPAR.pathCgi;
          this.parentResolved.resolve();
        }).catch(() => {
          this.parentResolved.reject();
          this.dataService.cgiPath = null;
        });
      } else {
        Product.STOR.resolved.promise.then(() => {
          this.dataService.cgiPath = Product.STOR.pathCgi;
          this.parentResolved.resolve();
        }).catch(() => {
          this.dataService.cgiPath = null;
          this.parentResolved.reject();
        });
      }
    } else {
      // something wrong
      this.parentResolved.reject();
    }
  }

  private setChild(key) {
    this.dataService.nodeSelected.reset();
    Promise.all([this.typeResolved.promise, this.parentResolved.promise]).then(results => {
      this.dataService.selectedNode = null;
      let ready: PromiseLike<any>;
      if (this.dataService.cgiPath) {
        ready = this.dataService.mainModule.xormonReady;
      } else {
        ready = $.ready;
      }
      if (key && key !== ROUTES.EMPTY) {
        if (key === Globals.TYPE_GLOB_SEARCH && this.dataService.selectedParent === Globals.TYPE_OTHER
          && this.dataService.selectedType === Globals.TYPE_CONFIG) {
          if (!this.dataService.isGlobSearch()) {
            this.replaceUrl = true;
            this.doNavigate(ROUTES.GLOB_SEARCH, Globals.TYPE_GLOB_SEARCH);
          }
          this.dataService.nodeSelected.resolve();
          return;
        }
        let node: Fancytree.FancytreeNode, nodeKey;
        if (this.dataService.selectedType === Globals.TYPE_DASHBOARD &&
          (key === Globals.DEFAULT_DASHBOARD_NAME || key === Globals.TITLE_GLOBAL)) {
          node = this.findNode(this.dataService.selectedParent, this.dataService.tree.getRootNode().children);
        } else if (this.dataService.isBacklinkTree()) {
          if (this.dataService.isLpar())
            node = this.dataService.selectedNode = this.findNodeByHref(key, this.dataService.tree.getRootNode().children);
          else
            node = this.dataService.selectedNode = this.findNodeByParams(key, this.dataService.tree.getRootNode().children);
        } else {
          node = this.dataService.selectedNode = this.findNode(key, this.dataService.tree.getRootNode().children);
        }
        nodeKey = this.dataService.getKey(node);
        if (node) {
          if (key !== this.dataService.getKey(node) || !this.isContentUrl) {
            this.replaceUrl = true;
          }
          if (node.data.href) {
            this.activateNode(node, true);
            const type = node.data.page_type;
            const title = node.title.toLowerCase();
            if (type === 'cpu_workload_estimator') {
              this.doNavigate('cpu_workload_estimator', nodeKey);
            } else if (type === Globals.TYPE_HIST_REP || type === 'report') {
              this.doNavigate(ROUTES.HISTORICAL_REPORTS, nodeKey);
            } else if (type === 'top10_global' || type === 'topten' || type === 'topten_vm') {
              this.doNavigate(ROUTES.TOP10, nodeKey);
            } else if (type === 'rmc_check') {
              this.doNavigate('rmc_check', nodeKey);
            } else if (type === 'resource_configuration_advisor') {
              this.doNavigate('resource_configuration_advisor', nodeKey);
            } else if (title === 'top') {
              this.doNavigate('volumes_top', nodeKey);
            } else if (title === 'capacity' && !node.data.href.includes('detail.sh')) {
              this.doNavigate('capacity-local', nodeKey);
            } else if (title === 'mapping') {
              this.doNavigate('mapping', nodeKey);
            } else if (title === 'configuration') {
              this.doNavigate(ROUTES.HW_CONFIG, nodeKey);
            } else if (title === 'health status' && Globals.CGI_PATH === Product.STOR.pathCgi) {
              this.doNavigate('health_status', nodeKey);
            } else if (type && type.toLowerCase() === 'heatmap') {
              this.doNavigate('heatmap_local_lpar', nodeKey);
            } else if (title === 'heatmap') {
              this.doNavigate('heatmap_local', nodeKey);
            } else if (title === 'san topology') {
              this.doNavigate('san_topology', nodeKey);
            } else if (title === 'overview' && Globals.CGI_PATH === Product.STOR.pathCgi) {
              this.doNavigate(ROUTES.OVERVIEW_TABS, nodeKey);
            } else {
              this.doNavigate(ROUTES.TABS, nodeKey);
            }
            ready.then(() => {
              this.dataService.nodeSelected.resolve();
            });
          } else if (node.data.content) {
            this.activateNode(node);
            this.doNavigate(node.data.content, nodeKey);
            ready.then(() => {
              this.dataService.nodeSelected.resolve();
            });
          } else if (node.children) {
            this.setChild(this.dataService.getKey(this.getFirstNode(node.children)));
          }
        } else {
          this.dataService.navigateToBacklink(key, true, this.route);
        }
      } else {
        this.replaceUrl = true;
        if (this.dataService.selectedType === Globals.TYPE_STORAGE && this.dataService.selectedParent !== Globals.TYPE_TOTALS) {
          if (!key) {
            this.doNavigate(ROUTES.EMPTY, ROUTES.EMPTY);
          }
          ready.then(() => {
            this.dataService.nodeSelected.resolve();
          });
          return;
        }
        // load first
        let children = this.dataService.tree.getRootNode().children;
        if (this.dataService.selectedType === Globals.TYPE_CUSTOM_GROUPS || this.dataService.selectedType === Globals.TYPE_DASHBOARD) {
          let child = children.find(fn => fn.title.toLowerCase() === this.dataService.selectedParent.toLowerCase());
          if (child)
            children = [child];
          else {
            this.log.warn('Cannot find relevant node in tree');
            return;
          }
        }
        let firstNode = this.getFirstNode(children);
        if (!firstNode) {
          if (!children[0].lazy)
            this.log.warn('Cannot find node in tree');
          return;
        }
        let firstKey = this.dataService.getKey(firstNode);
        if (firstKey) {
          this.setChild(firstKey);
        } else {
          this.activateNode(firstNode);
          ready.then(() => {
            this.dataService.nodeSelected.resolve();
          });
        }
      }
    }, error => {
      this.dataService.navigationMenu = null;
      this.log.warn(`Navigation failed:  ${this.dataService.selectedType}/${this.dataService.selectedParent}/${key}`);
      this.dataService.navigateOnFail();
    });
  }

  private doNavigate(contentOutlet, key) {
    const replacedKey = Globals.slash2replacement(key);
    let params: Params;
    if (this.sameOrNoneContent(contentOutlet)) {
      params = this.route.snapshot.queryParams;
    }
    this.router.navigate([
      {
        outlets: {
          content: [contentOutlet],
          primary: [this.dataService.selectedType, this.dataService.selectedParent, replacedKey]
        }
      }], { replaceUrl: this.replaceUrl, queryParams: params }
    );
    this.replaceUrl = false;
  }

  private sameOrNoneContent(content: string) {
    if (this.router.url.includes(`(${Globals.OUTLET_CONTENT}:`)) {
      if (this.router.url.includes(`(${Globals.OUTLET_CONTENT}:${content})`))
        return true;
      else
        return false;
    } else {
      return true;
    }
  }

  private filterVisibleTree(treeData: MenuItem[]) {
    if (!treeData || !treeData.length)
      return treeData;
    return this._filterVisibleTree(JSON.parse(JSON.stringify(treeData)));
  }

  private _filterVisibleTree(treeData: MenuItem[]) {
    if (!treeData || !treeData.length)
      return treeData;
    return treeData.filter((value, index, array) => {
      if (value.visible) {
        let visible = value.visible.every(b => b.value);
        if (visible)
          value.children = this.filterVisibleTree(value.children);
        return visible;
      }
      return true;
    });
  }

  private findNodeByParams(params: string, nodes: Fancytree.FancytreeNode[]) {
    if (!nodes || !params) {
      return null;
    }

    let sbp = new StorBacklinkParams(params);

    return sbp.findNode(nodes);
  }

  private findNodeByHref(href, nodes) {
    if (!nodes || !href) {
      return null;
    }
    for (const node of nodes) {
      if (node.data.href === href) {
        return node;
      } else if (node.children) {
        const result = this.findNodeByHref(href, node.children);
        if (result) {
          return result;
        }
      }
    }
  }

  private findNode(id: string, nodes: Fancytree.FancytreeNode[]) {
    if (!nodes || !id) {
      return null;
    }
    for (const node of nodes) {
      if (node.key === id) {
        if (node.children && !node.data.content)
          return this.getFirstNode(node.children);
        return node;
      } else if (node.children) {
        const result = this.findNode(id, node.children);
        if (result) {
          return result;
        }
      } else if (node.lazy) {
        // expand, load
      }
    }
    return null;
  }

  private getFirstNode(nodes: Fancytree.FancytreeNode[]): Fancytree.FancytreeNode {
    if (!nodes || nodes.length < 1) {
      return;
    }
    let selectedNode: Fancytree.FancytreeNode;
    if (this.dataService.selectedType === Globals.TYPE_STORAGE || this.dataService.selectedType === Globals.TYPE_STORGR) {
      selectedNode = nodes.find(n => (n.hasChildren() || n.isLazy()) && /pool/ig.test(n.title));
    }
    if (!selectedNode) {
      for (const node of nodes) {
        if (node.extraClasses && node.extraClasses.includes('menufolderdefault')) {
          selectedNode = node;
          break;
        }
      }
    }
    if (!selectedNode) {
      selectedNode = nodes[0];
    }
    if (selectedNode.data.content) {
      return selectedNode;
    } else if (selectedNode.children) {
      return this.getFirstNode(selectedNode.children);
    } else if (selectedNode.lazy) {
      this.activateNode(selectedNode);
      return;
    } else {
      return selectedNode;
    }
  }

  ////////////// SEARCH //////////////////////////

  searchHistory() {
    return this.http.get<string[]>('/api/search/history', {
      params: { subTree: this.getSubTree() }
    });
  }

  autoClicked() {
    this.filterAuto.openPanel();
  }

  private getSubTree() {
    return `${this.dataService.selectedType}/${this.dataService.selectedParent}`;
  }

  getTitles(val) {
    return this.http.get<string[]>(Globals.RUNTIME_PATH + this.dataService.cgiPath + Globals.DBWRAPPER_SH, {
      params: {
        procname: 'getTitles',
        regexp: val,
        parents: this.dataService.getMenuParents(),
        type: this.dataService.selectedParent
      }
    });
  }

  onSearchKey(keyEvent: KeyboardEvent) {
    switch (keyEvent.which) {
      case 13:
        // enter
        this.searchText();
        break;
      case 27:
        // escape
        this.clearSearch();
        break;
      default:
        if (!this.titleCtrl.value.length) {
          this.dataService.tree.clearFilter();
        }
        break;
    }
  }

  clearSearch() {
    this.titleCtrl.setValue('');
    this.dataService.tree.clearFilter();
    setTimeout(() => {
      this.filterInput.nativeElement.blur();
      this.filterAuto.closePanel();
    });
  }

  searchSelected() {
    this.searchText();
  }

  searchText() {
    this.dataService.tree.clearFilter();
    if (this.dataService.selectedType !== Globals.TYPE_SERVER && this.dataService.selectedType !== Globals.TYPE_STORAGE
      && this.dataService.selectedType !== Globals.TYPE_NETWORK && this.dataService.selectedType !== Globals.TYPE_STORGR) {
      this.filterTree();
      return;
    }

    AppComponent.block.start();
    this.filterAuto.closePanel();

    let body = {
      procname: 'filterMenuParent',
      regexp: this.titleCtrl.value,
    }
    if (this.dataService.isLpar()) {
      body['hw_type'] = this.dataService.getMenuParents();
    } else {
      body['parents'] = this.dataService.getMenuParents();
    }

    this.http.post<TreeItem[]>(Globals.RUNTIME_PATH + this.dataService.cgiPath + Globals.DBWRAPPER_SH, body).subscribe(data => {
      this.http.put('/api/search/history', {
        subTree: this.getSubTree(),
        search: this.titleCtrl.value
      }).subscribe(status => {
      }, error => {
        this.log.warn('Failed to store search into user history.');
      });
      this.filterTree(data);
      AppComponent.block.stop();
    }, error => {
      AppComponent.block.stop();
      this.log.error('Tree filter search failed!', error);
    });
  }

  private filterTree(data?: TreeItem[]) {
    if (data && data.length > 0) {
      this.dataService.merge(this.dataService.tree.getRootNode().children, data);
    }
    const re = new RegExp(this.titleCtrl.value, 'i');
    this.dataService.tree.filterNodes(function (node: Fancytree.FancytreeNode) {
      const matched = re.test(node.title);
      if (matched && !node.children && !node.lazy) {
        node.makeVisible({ noAnimation: true, noEvents: true, scrollIntoView: false });
      }
      return matched;
    });
  }

}
