import { HttpClient, HttpParams } from '@angular/common/http';
import { NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { RxStompService } from '@stomp/ng2-stompjs';
import { RxStompState } from '@stomp/rx-stomp';
import $ from 'jquery';
import Muuri from 'muuri';
import { forkJoin } from 'rxjs';
import { inspect } from 'util';
import { PortalService } from './content/dashboard/portal/portal.service';
import { StorageGroupingService } from './content/settings/storage-grouping/storage-grouping.service';
import { UserService } from './content/settings/user-profile/user.service';
import { Globals } from './globals';
import { LoggerService } from './logger.service';
import { MenuItems } from './menu-items';
import { Deferred, DEFERRED_STATUS } from './model/deferred';
import { HwType } from './model/hwType';
import { Product } from './model/product';
import { ROUTES } from './model/routes';
import { StorBacklinkParams } from './model/storBacklinkParams';
import { XORMON_RX_STOPM_CONFIG } from './xormon-rx-stomp.config';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "./content/settings/storage-grouping/storage-grouping.service";
import * as i3 from "./logger.service";
import * as i4 from "@angular/router";
import * as i5 from "./content/dashboard/portal/portal.service";
import * as i6 from "./content/settings/user-profile/user.service";
import * as i7 from "@angular/material/dialog";
import * as i8 from "@stomp/ng2-stompjs";
import * as i9 from "@angular/platform-browser";
export class DataService {
    constructor(http, sgService, log, router, portalService, userService, dialogRef, rxStompService, sanitizer, zone) {
        this.http = http;
        this.sgService = sgService;
        this.log = log;
        this.router = router;
        this.portalService = portalService;
        this.userService = userService;
        this.dialogRef = dialogRef;
        this.rxStompService = rxStompService;
        this.sanitizer = sanitizer;
        this.zone = zone;
        // TODO: class for types?
        /**
         * platform: { title, lazy, object_type, platform }
         */
        this.serverPlatforms = {};
        this.storageTypes = {};
        /**
         * class: { title, lazy, level, key, class }
         */
        this.networkTypes = {};
        this.networkChildren = [];
        /**
         * Storage groups tree holder
         */
        this.sgHolder = {};
        this.storageNames = {};
        /**
         * Promise when LPAR loaded
         */
        this.serverLoaded = new Deferred();
        /**
         * Promise when STOR storages loaded
         */
        this.storageLoaded = new Deferred();
        /**
         * Promise when storage groups loaded
         */
        this.storageGrLoaded = new Deferred();
        /**
         * Promise when STOR networks loaded
         */
        this.networkLoaded = new Deferred();
        /**
         * Promise when selected tree node resolved
         */
        this.nodeSelected = new Deferred();
        /**
         * Promise when dashboard loaded
         */
        this.dashboardLoaded = new Deferred();
        /**
         * Promise when backend info loaded
         */
        this.backendLoaded = new Deferred();
        this.dashboard = { tabs: [] };
        DataService.SINGLETON = this;
        Globals.domSanitizer = this.sanitizer;
        Globals.http = this.http;
        $.fn.bindFirst = function (name, fn) {
            this.on(name, fn);
            this.each(function () {
                var handlers = $._data(this, 'events')[name.split('.')[0]];
                var handler = handlers.pop();
                handlers.splice(0, 0, handler);
            });
        };
        $(document).on("click", "a.backlink", event => {
            event.stopPropagation();
            event.preventDefault();
            let href = event.currentTarget.getAttribute("href");
            this.zone.run(() => {
                if (href === "#") {
                    let params = $(event.currentTarget).data();
                    this.navigateToBacklink(new StorBacklinkParams(params));
                }
                else {
                    this.navigateToBacklink(href);
                }
            });
        });
        // XXX: potentialy dangerous
        const origToString = Object.prototype.toString;
        let inspecting = false;
        Object.prototype.toString = function () {
            if (typeof this === 'object' && this.constructor.name === 'Object' && !inspecting) {
                try {
                    inspecting = true;
                    const str = inspect(this, false, null); // JSON.stringify(this);
                    inspecting = false;
                    return str;
                }
                catch (error) {
                    inspecting = false;
                }
            }
            else
                return origToString.call(this);
        };
        Muuri.Dragger._mouseEvents.cancel = 'click';
        let remainingReconnectAttempts = Globals.WS_RECONNECT_ATTEMPTS;
        this.rxStompService.connectionState$.pipe().subscribe(state => {
            if (!this.rxStompService.active)
                return;
            if (state === RxStompState.CLOSING || state === RxStompState.CLOSED) {
                if (!Globals.authenticated)
                    this.rxStompService.deactivate();
                else {
                    this.pingObs().subscribe(() => {
                        if (--remainingReconnectAttempts < 0) {
                            this.log.warn('Unable to establish WebSocket connection, disabeling WS!');
                            this.rxStompService.deactivate();
                        }
                    }, error => {
                        if (--remainingReconnectAttempts < -Globals.WS_RECONNECT_ATTEMPTS) {
                            this.log.error('Cannot establish WebSocket connection, disabeling WS!', error);
                            this.rxStompService.deactivate();
                        }
                    });
                }
            }
            else if (state === RxStompState.OPEN) {
                remainingReconnectAttempts = Globals.WS_RECONNECT_ATTEMPTS;
            }
        });
        this.rxStompService.watch('/user/topic/notify').subscribe(msg => {
            switch (msg.body) {
                case Globals.WS_NOTIFY_USER_LOGOUT:
                    this.log.warn('Your account has been disabled, logging out!');
                    Globals.LOGOUT_EMITTER.emit();
                    break;
                case Globals.WS_NOTIFY_USER_UPDATED:
                    this.log.warn('Your ACL may have changed, re-initializing...');
                    this.init();
                    break;
            }
        }, error => this.log.error('Failed to recieve user web socket message!', error));
        this.rxStompService.watch('/topic/notify').subscribe(msg => {
            if (msg.body === Globals.WS_NOTIFY_BACKEND_RECONFIGURED) {
                this.log.warn('Backend configuration has changed, re-initializing...');
                this.init();
            }
        }, error => log.error('Failed to recieve web socket message!', error));
        this.updateXormonAbout();
        this.init();
    }
    /**
     * Singleton getter, because there is problem injecting service into HttpInterceptor
     */
    static get INSTANCE() {
        return DataService.SINGLETON;
    }
    updateXormonAbout() {
        this.http.get('/public/about').subscribe(data => {
            if (Globals.ABOUT.xormonVersion && Globals.ABOUT.xormonVersion !== data.xormonVersion) {
                this.log.warn(`Current Xormon version ${Globals.ABOUT.xormonVersion} is different from version ${data.xormonVersion} on server. Reloading...`);
                window.location.reload();
            }
            Globals.ABOUT = data;
        }, error => this.log.error('Failed to get Xormon about info!', error));
    }
    initWebSocket() {
        if (this.rxStompService.connected())
            return;
        this.rxStompService.configure(XORMON_RX_STOPM_CONFIG);
        this.rxStompService.activate();
    }
    init(currentUser) {
        if (!Globals.authenticated || (!currentUser && window.location.href.includes(Globals.PATH_LOGIN))) {
            return;
        }
        this.initWebSocket();
        Globals.userLoaded.reset();
        if (currentUser) {
            Globals.IS_USER_ADMIN.value = currentUser.admin;
            Globals.currentUser = currentUser;
            Globals.userLoaded.resolve();
        }
        else {
            this.userService.getCurrentUser().subscribe(user => {
                Globals.IS_USER_ADMIN.value = user.admin;
                Globals.currentUser = user;
                Globals.userLoaded.resolve();
            }, error => {
                this.log.error('Failed to get current user info!', error);
                Globals.userLoaded.reject();
            });
        }
        this.initPortal();
        this.backendLoaded.reset();
        this.http.get('/api/backendInfo').subscribe(data => {
            this.storageGrLoaded.reset();
            this.storageLoaded.reset();
            this.networkLoaded.reset();
            this.serverLoaded.reset();
            Globals.BACKEND_INFO.lparEnabled.value = data.lparEnabled;
            Globals.BACKEND_INFO.lparLocal = data.lparLocal;
            Globals.BACKEND_INFO.storEnabled.value = data.storEnabled;
            Globals.BACKEND_INFO.storLocal = data.storLocal;
            this.backendLoaded.resolve();
            Globals.INIT_EMITTER.emit();
            Product.STOR.init(data.storEnabled);
            Product.LPAR.init(data.lparEnabled);
            Product.LPAR.resolved.promise.then(() => {
                Product.LPAR.module.xormonVars.reload = this.reloadCurrentComponent.bind(this);
                this.updateLparRoot();
            }, reason => {
                Globals.userLoaded.promise.then(() => {
                    if (Globals.currentUser.admin && !reason.error)
                        this.log.warn(reason.msg);
                    else if (reason.error)
                        this.log.error(reason.msg, reason.reason);
                }, () => console.warn(reason));
                this.serverLoaded.reject();
            });
            Product.STOR.resolved.promise.then(() => {
                this.updateStorRoot();
                this.updateStorages();
                this.loadStorageGroups();
            }, reason => {
                Globals.userLoaded.promise.then(() => {
                    if (Globals.currentUser.admin && !reason.error)
                        this.log.warn(reason.msg);
                    else if (reason.error)
                        this.log.error(reason.msg, reason.reason);
                }, () => console.warn(reason));
                this.storageLoaded.reject();
                this.networkLoaded.reject();
            });
            Promise.allSettled([Product.LPAR.resolved.promise, Product.STOR.resolved.promise]).finally(() => {
                Globals.freeEdition = Product.LPAR.resolved.status === DEFERRED_STATUS.resolved && !Product.LPAR.info.isEnterpriseEdition()
                    || Product.STOR.resolved.status === DEFERRED_STATUS.resolved && !Product.STOR.info.isEnterpriseEdition();
                if (Globals.freeEdition) {
                    Globals.treeWidth = 220;
                }
                else {
                    Globals.treeWidth = 195;
                }
            });
        }, error => {
            let msg = 'Failed to get backend info!';
            this.log.error(msg, error);
            this.backendLoaded.reject(msg);
            Product.STOR.resolved.reject(msg);
            Product.LPAR.resolved.reject(msg);
        });
    }
    get mainModule() {
        return this.cgiPath === Product.STOR.pathCgi ? Product.STOR.module : Product.LPAR.module;
    }
    get cgiPath() {
        return Globals.CGI_PATH;
    }
    set cgiPath(value) {
        Globals.CGI_PATH = value;
    }
    get path() {
        return Globals.CGI_PATH === Product.LPAR.pathCgi ? Product.LPAR.path : Product.STOR.path;
    }
    isLpar() {
        return this.cgiPath === Product.LPAR.pathCgi;
    }
    initPortal() {
        this.dashboardLoaded.reset();
        this.portalService.getAllTabs().subscribe(data => {
            this.updateDashboards(data);
            this.dashboardLoaded.resolve();
        }, error => this.log.error('Failed to get dashboard!', error));
    }
    updateDashboards(tabs) {
        this.dashboard.tabs = tabs;
        if (!this.dashboard.tabs || this.dashboard.tabs.length < 1) {
            this.dashboard.tabs = [];
        }
        for (const tab of this.dashboard.tabs) {
            tab.groups.sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : 1));
            for (const group of tab.groups) {
                group.graphs.sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : 1));
            }
        }
        for (const di of MenuItems.dashboardItems.children) {
            let miParent;
            if (di.key === Globals.TYPE_GLOBAL) {
                miParent = di;
            }
            else if (!di.children)
                continue;
            else {
                miParent = di.children[0];
            }
            const technoTabs = tabs.filter((value, index, obj) => value.technology === di.key
                && (value.name !== Globals.DEFAULT_DASHBOARD_NAME || !value.writable) &&
                (value.name !== Globals.TITLE_GLOBAL || !value.writable));
            miParent.children = technoTabs.map((value, index, arr) => ({
                key: value.id.toString(),
                title: value.writable ? value.name : (value.name + ':' + this.getInitials(value.owner)),
                tooltip: value.writable ? value.name : ('Author: ' + value.owner),
                content: ROUTES.PORTAL
            }));
        }
    }
    getInitials(name) {
        return name.split(/\s+/).map(s => s.charAt(0)).join('');
    }
    loadStorageGroups() {
        this.storageGrLoaded.reset();
        this.sgService.getUserGroups().subscribe(storages => {
            this.storageLoaded.promise.then(() => {
                this.clearObject(this.sgHolder);
                let ti;
                storages.forEach(storage => {
                    this.sgHolder[storage.title] = this.sgHolder[storage.title] || [];
                    for (const sn of storage.storageNames) {
                        let st = this.storageTypes[sn.hwType];
                        if (!st || st.items.every(ti => ti.title !== sn.label))
                            continue;
                        ti = {
                            object_id: sn.object_id,
                            title: sn.label,
                            level: 'object',
                            lazy: true,
                            class: 'STORAGE',
                            hw_type: sn.hwType
                        };
                        this.sgHolder[storage.title].push(ti);
                    }
                });
                this.storageGrLoaded.resolve();
            }, reason => {
                console.warn(reason);
                this.storageGrLoaded.reject(reason);
            });
        }, error => {
            this.log.error('Failed to load storage groups!', error);
            this.storageGrLoaded.reject(error);
        });
    }
    updateStorRoot() {
        const storTopMenuObs = this.http.get(Globals.RUNTIME_PATH + Product.STOR.pathCgi + Globals.DBWRAPPER_SH + '?procname=getLazyMenuFolder&level=root');
        forkJoin([storTopMenuObs]).subscribe(results => {
            this.clearObject(this.networkTypes);
            Globals.SAN_PRESENT.value = false;
            Globals.BNA_PRESENT.value = false;
            Globals.LAN_PRESENT.value = false;
            Globals.STORAGE_PRESENT.value = false;
            results[0].forEach(menuItem => {
                if (menuItem.level === 'class') {
                    if (menuItem.class === Globals.TYPE_STORAGE_UPPER) {
                        Globals.STORAGE_PRESENT.value = true;
                    }
                    else {
                        menuItem.procname = 'getLazyMenuFolder';
                        this.networkTypes[menuItem.class.toLowerCase()] = menuItem;
                        if (menuItem.class === Globals.TYPE_SAN_UPPER) {
                            Globals.SAN_PRESENT.value = true;
                        }
                        else if (menuItem.class === Globals.TYPE_BNA) {
                            Globals.BNA_PRESENT.value = true;
                            Globals.SAN_PRESENT.value = true;
                        }
                        else if (menuItem.class === Globals.TYPE_LAN_UPPER) {
                            Globals.LAN_PRESENT.value = true;
                        }
                    }
                }
            });
            this.networkLoaded.resolve();
        }, error => {
            this.log.error('STOR2RRD connection failed!', error);
            this.networkLoaded.reject(error);
        });
    }
    updateLparRoot() {
        const lparTopMenuObs = this.http.get(Globals.RUNTIME_PATH + Product.LPAR.pathCgi + '/dbwrapper.sh?procname=getLazyMenuFolder&level=root');
        forkJoin(lparTopMenuObs).subscribe(results => {
            this.clearObject(this.serverPlatforms);
            Globals.SERVER_PRESENT.value = false;
            results[0].forEach(menuItem => {
                if (menuItem.level === 'root') {
                    menuItem.next_level.procname = 'getLazyMenuFolder';
                    if (HwType.ALL.some(ht => ht.name === menuItem.next_level.hw_type)) {
                        this.serverPlatforms[menuItem.next_level.hw_type] = menuItem;
                        Globals.SERVER_PRESENT.value = true;
                    }
                    else {
                        this.log.warn('Unsupported type: ' + menuItem.next_level.hw_type);
                    }
                }
            });
            this.serverLoaded.resolve();
        }, error => {
            if (error.status === 500) {
                this.log.error('LPAR2RRD connection failed! Please, check runtime setup values.', error);
                if (Globals.currentUser.admin) {
                    void this.router.navigate([
                        {
                            outlets: {
                                content: ['rtSetup'],
                                primary: [Globals.TYPE_CONFIG, Globals.TYPE_OTHER, 'runtime']
                            }
                        }
                    ], { replaceUrl: true });
                }
            }
            else if (error.status === 0) {
                this.log.error('LPAR2RRD connection failed! Server may not be accessible.', error);
            }
            else {
                this.log.error('LPAR2RRD connection failed!', error);
            }
            this.serverLoaded.reject(error);
        });
    }
    updateStorages() {
        forkJoin(this.http.get(Globals.RUNTIME_PATH + Product.STOR.pathCgi + Globals.DBWRAPPER_SH + '?procname=geStorageLabels'), this.http
            .get(Globals.RUNTIME_PATH + Product.STOR.pathCgi + '/dbwrapper.sh?procname=getLazyMenuFolder&level=class&class=STORAGE'))
            .subscribe((results) => {
            for (const hwLabel of results[0]) {
                this.storageNames[hwLabel.hw_type] = hwLabel['hw_label'];
            }
            this.clearObject(this.storageTypes);
            this.storageTypes[Globals.HW_TYPE_ALL] = { label: 'All Devices', items: [] };
            for (const storage of results[1]) {
                this.storageTypes[storage.hw_type] = this.storageTypes[storage.hw_type] || { label: this.storageNames[storage.hw_type] || storage.hw_type, items: [] };
                this.storageTypes[storage.hw_type].items.push(storage);
                this.storageTypes[Globals.HW_TYPE_ALL].items.push(storage);
            }
            this.storageLoaded.resolve();
        }, error => {
            this.log.error('Failed to update storage list!', error);
            this.storageLoaded.reject(error);
        });
    }
    reloadCurrentComponent() {
        if (!this.selectedNode) {
            this.selectedNode = this.tree.getActiveNode();
            void this.router.navigate([{
                    outlets: {
                        content: null,
                        primary: [this.selectedType, this.selectedParent, this.getKey(this.selectedNode)]
                    }
                }]);
            return;
        }
        let url = this.router.url;
        void this.router.navigate([
            {
                outlets: {
                    content: [ROUTES.DUMMY],
                    primary: [this.selectedType, this.selectedParent, this.getKey(this.selectedNode)]
                }
            }
        ], { skipLocationChange: true }).then(() => {
            void this.router.navigateByUrl(url);
        });
    }
    reloadTree() {
        let type = this.selectedType;
        let parent = this.selectedParent;
        let key = this.getKey(this.selectedNode);
        this.selectedType = this.selectedParent = this.selectedNode = null;
        void this.router.navigateByUrl('/reloading', { skipLocationChange: true }).then(() => {
            void this.router.navigate([
                {
                    outlets: {
                        content: null,
                        primary: [type, parent, key]
                    }
                }
            ], { skipLocationChange: true });
        });
    }
    /**
     * Returns true if left tree backend lazy tree
     */
    isBacklinkTree() {
        return this.selectedType === Globals.TYPE_SERVER
            || (this.selectedType === Globals.TYPE_STORAGE && this.selectedParent !== Globals.TYPE_TOTALS)
            || this.selectedType === Globals.TYPE_NETWORK || this.selectedType === Globals.TYPE_STORGR;
    }
    getKey(node) {
        if (!node)
            return;
        if (this.isBacklinkTree()) {
            return this.isLpar() ? node.data.href : new StorBacklinkParams(node).toString();
        }
        return node.key;
    }
    registerBacklinks(excludedClasses, scope, cgiPath) {
        let links;
        if (scope) {
            links = $(scope).find('a[href]:not(.backlink)');
        }
        else {
            links = $('.content-outlet a[href]:not(.backlink)');
        }
        links.each((index, element) => {
            const href = element.getAttribute('href');
            if (!href || href.startsWith('http') || href.startsWith(Globals.PREFIX_JQUERY) || element.classList.contains('csvfloat'))
                return;
            if (excludedClasses) {
                for (const cls of excludedClasses) {
                    if (element.classList.contains(cls)) {
                        return;
                    }
                }
            }
            $(element)
                .off('click')
                .on('click', event => {
                event.preventDefault();
                this.navigateToBacklink(event.currentTarget.getAttribute('href'), null, null, cgiPath);
            });
        });
    }
    closeAllDialogs() {
        // how to close primeng dialogs & confirmations?
        // close material dialogs
        this.dialogRef.closeAll();
        // jqueryUI is not present in dataService as $ is imported to be preloaded
        Globals.closeJqueryDialogs();
    }
    isTreeEmpty() {
        if (this.tree) {
            let children = this.tree.getRootNode().getChildren();
            if (children) {
                if (children.length === 1 && children[0].statusNodeType === 'nodata') {
                    return true;
                }
                return children.length < 1;
            }
        }
        return true;
    }
    navigateOnFail() {
        if (this.isTreeEmpty())
            void this.router.navigate([Globals.ROUTE_HOME], { replaceUrl: true });
        else if (!this.selectedNode || !Globals.hasComponent(Globals.getContentOutlet(this.router))) {
            void this.router.navigate([
                {
                    outlets: {
                        content: [ROUTES.EMPTY],
                        primary: [this.selectedType, this.selectedParent, ROUTES.EMPTY]
                    }
                }
            ]);
        }
    }
    findNode(data) {
        let root = this.tree.getRootNode();
        if (this.isLpar()) {
        }
        else {
            return data.findNode(root.children);
        }
    }
    findNodeByProps(propNames, propVals, nodes, title) {
        for (const child of nodes) {
            if ((!title || title === child.title) && propNames.every((value, index) => child.data[value] === propVals[index]))
                return child;
        }
    }
    navigateToBacklink(urlOrObject, replaceUrl, route, cgiPathSuggested) {
        let cgiPath;
        let params;
        let type;
        let parent;
        if (typeof urlOrObject === 'string') {
            if (urlOrObject.startsWith(Globals.NAV_SEPARATOR)) {
                let nav = urlOrObject.split(Globals.NAV_SEPARATOR);
                type = nav[1];
                parent = nav[2];
                urlOrObject = nav[3];
                if (Globals.TYPE_TOTALS === parent || Globals.TYPE_CUSTOM_GROUPS === type) {
                    this.router.navigate([
                        {
                            outlets: {
                                content: null,
                                primary: [type, parent, urlOrObject]
                            }
                        }
                    ], { replaceUrl });
                    return;
                }
                if (Globals.TYPE_SERVER === type) {
                    cgiPathSuggested = Product.LPAR.pathCgi;
                }
                else {
                    cgiPathSuggested = Product.STOR.pathCgi;
                }
            }
            if (urlOrObject.startsWith('&')) {
                urlOrObject = new StorBacklinkParams(urlOrObject);
                cgiPathSuggested = Product.STOR.pathCgi;
            }
        }
        if (typeof urlOrObject === 'string') {
            let url = urlOrObject;
            params = new HttpParams({ fromObject: { procname: 'getMenuPath' } });
            if (url.startsWith('stor@')) {
                cgiPath = Product.STOR.pathCgi;
                params = params.set('item_id', url.substr(5));
            }
            else {
                const urlReplaced = Globals.replacement2slash(url);
                if (!this.cgiPath) {
                    if (urlReplaced.startsWith(Product.LPAR.pathCgi)) {
                        cgiPath = Product.LPAR.pathCgi;
                    }
                    else if (urlReplaced.startsWith(Product.STOR.pathCgi)) {
                        cgiPath = Product.STOR.pathCgi;
                    }
                    else if (cgiPathSuggested) {
                        cgiPath = cgiPathSuggested;
                    }
                    else {
                        this.log.warn('Cannot detect CGI call for ' + url);
                        return;
                    }
                }
                else {
                    // TODO: check before call if present, this does not work because old/new URL translation on backend
                    let node = this.tree.findFirst(node => node.data.href === urlReplaced);
                    if (node) {
                        void node.setActive();
                        return;
                    }
                    cgiPath = this.cgiPath;
                }
                params = params.set('url', encodeURIComponent(urlReplaced));
            }
        }
        else {
            let obj = urlOrObject;
            let node = this.findNode(obj);
            this.metric = obj.metric;
            if (node) {
                void node.setActive();
                return;
            }
            params = new HttpParams({ fromObject: obj });
            params = params.set('procname', 'getMenuPath');
            if (this.cgiPath) {
                cgiPath = this.cgiPath;
            }
            else if (!cgiPathSuggested) {
                cgiPath = Product.STOR.pathCgi;
            }
            else {
                cgiPath = cgiPathSuggested;
            }
        }
        this.http
            .get(Globals.RUNTIME_PATH + cgiPath + Globals.DBWRAPPER_SH, {
            params: params
        })
            .subscribe(menu => {
            if (!menu || !menu[0]) {
                this.log.error(`Failed navigation to ${urlOrObject}. Is load finished?`);
                this.navigateOnFail();
                return;
            }
            if (menu[0].error) {
                if (!Globals.isProd)
                    console.error(urlOrObject);
                this.log.error(`Failed navigation with backend error: ${menu[0].error}`);
                this.navigateOnFail();
                return;
            }
            this.closeAllDialogs();
            const activeMenu = this.getActiveMenu(menu);
            if (!activeMenu) {
                this.log.warn('No tree returned from backend for ' + urlOrObject);
                this.navigateOnFail();
                return;
            }
            let amc;
            if (!type)
                type = this.getType(activeMenu);
            if (cgiPath === Product.LPAR.pathCgi) {
                amc = activeMenu.children;
                parent = parent || this.getParent(activeMenu, cgiPath);
            }
            else {
                if (type === Globals.TYPE_STORAGE) {
                    amc = [activeMenu];
                    if (this.selectedParent === Globals.HW_TYPE_ALL) {
                        parent = Globals.HW_TYPE_ALL;
                    }
                }
                else
                    amc = activeMenu.children;
                if (this.selectedType === Globals.TYPE_STORGR &&
                    this.sgHolder[this.selectedParent].some(ti => ti.hw_type === activeMenu.hw_type && ti.title === activeMenu.title)) {
                    parent = parent || this.selectedParent;
                    type = Globals.TYPE_STORGR;
                }
                if (!parent) {
                    try {
                        parent = this.getParent(activeMenu, cgiPath);
                    }
                    catch (error) {
                        this.log.error('Failed to navigate to node with params: ' + params, error);
                        return;
                    }
                    for (const ti of menu) {
                        if (ti.hw_type && ti.hw_type === activeMenu.hw_type && !ti.children) {
                            amc.push(ti);
                        }
                    }
                }
            }
            if (this.selectedType === type && this.selectedParent === parent) {
                this.merge(this.tree.getRootNode().children, amc);
                let activeNode;
                if (cgiPath === Product.LPAR.pathCgi)
                    activeNode = this.tree.findFirst(node => node.data.href === menu[0].active_href);
                else if (cgiPath === Product.STOR.pathCgi) {
                    activeNode = this.tree.getNodeByKey(menu[0].key_to_be_activated);
                }
                else
                    activeNode = this.tree.getNodeByKey(menu[0].active_href);
                if (!activeNode) {
                    this.log.warn('Node not found: ' + menu[0].active_href);
                    return;
                }
                activeNode.setActive();
            }
            else {
                if (cgiPath === Product.LPAR.pathCgi) {
                    let hrefReplaced = Globals.slash2replacement(menu[0].active_href);
                    if (hrefReplaced.match(/&metric=[0-9].*/g)) {
                        let lparHref = hrefReplaced.match(/&metric=[0-9].*/g);
                        lparHref = lparHref[0].match(/[0-9].*/g);
                        hrefReplaced = hrefReplaced.replace(/&metric=[0-9].*/, "");
                        if (lparHref[0]) {
                            this.metric = lparHref[0];
                        }
                    }
                    this.navigationMenu = amc;
                    this.router.navigate(['/' + type, parent, hrefReplaced], { replaceUrl });
                }
                else {
                    let nav = this.getItemNavigationByKey(amc, menu[0].key_to_be_activated);
                    if (nav) {
                        this.navigationMenu = amc;
                        this.router.navigate(['/' + type, parent, nav.toString()], { replaceUrl });
                    }
                    else
                        this.log.warn(`No key ${menu[0].key_to_be_activated} found in navigation tree!`);
                }
            }
        }, error => this.log.error('Failed to navigate to backlink!', error));
    }
    getItemNavigationByKey(menu, key) {
        for (const ti of menu) {
            if (ti.key === key)
                return new StorBacklinkParams().setParamFromItem(ti);
            if (ti.children) {
                let result = this.getItemNavigationByKey(ti.children, key);
                if (result)
                    return result.setParamFromItem(ti);
            }
        }
    }
    /**
     * server | storage | network
     */
    getType(activeMenu) {
        return activeMenu.class ?
            activeMenu.hw_type ? Globals.TYPE_STORAGE : Globals.TYPE_NETWORK
            : Globals.TYPE_SERVER;
    }
    merge(existingNodes, newNodes) {
        for (const nn of newNodes) {
            for (const en of existingNodes) {
                if (nn.title === en.title) {
                    if (nn.children == null) {
                        continue;
                    }
                    if (en.children == null || this.isLoading(en)) {
                        en.addChildren(nn.children);
                    }
                    else {
                        this.merge(en.children, nn.children);
                    }
                }
            }
        }
    }
    isLoading(node) {
        return node.children && node.children.length > 0 &&
            (node.children[0].title === 'Loading...' || node.children[0].title === '>>Load error!<<');
    }
    getActiveMenu(menu) {
        //if(menu[menu.length-1].class)
        for (const m of menu) {
            if (m.children && (m.class || m.next_level))
                return m;
        }
    }
    /**
     * SERVER | STORAGE | SAN | LAN
     */
    get technology() {
        if (this.selectedType === Globals.TYPE_NETWORK ||
            this.selectedType === Globals.TYPE_CUSTOM_GROUPS ||
            this.selectedType === Globals.TYPE_DASHBOARD) {
            return this.selectedParent;
        }
        if (this.selectedType === Globals.TYPE_STORGR)
            return Globals.TYPE_STORAGE;
        return this.selectedType;
    }
    /**
     * SERVER | STORAGE | NETWORK
     */
    get navigationLazyType() {
        // TODO: enum
        switch (this.selectedType) {
            case Globals.TYPE_NETWORK:
            case Globals.TYPE_STORAGE:
            case Globals.TYPE_SERVER:
            case Globals.TYPE_STORGR:
                return this.selectedType;
            case Globals.TYPE_DASHBOARD:
            case Globals.TYPE_CUSTOM_GROUPS:
                if (this.selectedParent === Globals.TYPE_SERVER) {
                    return Globals.TYPE_SERVER;
                }
                if (this.selectedParent === Globals.TYPE_SAN || Globals.TYPE_LAN) {
                    return Globals.TYPE_NETWORK;
                }
                return Globals.TYPE_STORAGE;
            default:
                this.log.warn('Cannot detect navigation type!');
        }
    }
    getParent(menuItem, cgiPath) {
        return cgiPath === Product.LPAR.pathCgi ? menuItem.next_level.hw_type :
            menuItem.class === Globals.TYPE_STORAGE.toUpperCase() ? menuItem.hw_type : menuItem.class.toLowerCase();
    }
    ping() {
        this.pingObs().subscribe(() => { });
    }
    pingObs() {
        return this.http.get('/api/ping');
    }
    getCGs() {
        return this.http.get('/api/cgTrees');
    }
    getServerChildren(platform) {
        return this.http.get(Globals.RUNTIME_PATH + Product.LPAR.pathCgi + Globals.DBWRAPPER_SH, {
            params: this.serverPlatforms[platform].next_level
        });
    }
    getNetworkChildren(networkClass) {
        return this.http.get(Globals.RUNTIME_PATH + Product.STOR.pathCgi + Globals.DBWRAPPER_SH, {
            params: this.networkTypes[networkClass]
        });
    }
    getTreeForItemId(objId) {
        return this.http.get(Globals.RUNTIME_PATH + this.cgiPath + Globals.DBWRAPPER_SH + '?procname=getTreeForItemId&id=' + objId);
    }
    clearObject(obj) {
        for (const prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                delete obj[prop];
            }
        }
    }
    getMenuParents() {
        switch (this.selectedType) {
            case Globals.TYPE_STORAGE:
                return this.storageTypes[this.selectedParent].items.map(x => x.object_id);
            case Globals.TYPE_SERVER: // TODO: re-code LPAR to use hwType
                return this.serverPlatforms[this.selectedParent].next_level.hw_type;
            case Globals.TYPE_NETWORK:
                return this.networkChildren;
            case Globals.TYPE_STORGR:
                return this.sgHolder[this.selectedParent].map(ti => ti.object_id);
            default:
                // configuration, dashboard
                this.log.error('Unsupported type: ' + this.selectedType);
                break;
        }
    }
    getPageTitle() {
        let node = this.selectedNode;
        let title = '';
        let separator = '';
        while (node) {
            if (node.title === 'root')
                break;
            if (node.title === 'Items' && title !== '') {
                node = node.parent;
                continue;
            }
            title = node.title + separator + title;
            separator = ` ${Globals.TITLE_SEPARATOR} `;
            node = node.parent;
        }
        return title;
    }
    isGlobSearch() {
        return this.router.url.includes(`(${Globals.OUTLET_CONTENT}:${ROUTES.GLOB_SEARCH})`);
    }
    static fixDownloadLinks(scope) {
        let div;
        if (scope)
            div = $(scope);
        else
            div = $('.content-outlet');
        div.find('a[href$=".csv"]:not([href^="http"])').each((index, element) => {
            let el = $(element);
            let href = el.attr('href');
            if (href.startsWith(Globals.API_HOST + Globals.RUNTIME_PATH))
                return;
            if (!href.startsWith(Globals.CGI_PATH)) {
                href = (Globals.CGI_PATH === Product.LPAR.pathCgi ? Product.LPAR.path : Product.STOR.path) + href;
            }
            href = Globals.API_HOST + Globals.RUNTIME_PATH + href;
            el.attr('href', href);
        });
    }
}
DataService.intervals = {
    d: 'Last day',
    w: 'Last week',
    m: 'Last month',
    y: 'Last year'
};
DataService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DataService_Factory() { return new DataService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.StorageGroupingService), i0.ɵɵinject(i3.LoggerService), i0.ɵɵinject(i4.Router), i0.ɵɵinject(i5.PortalService), i0.ɵɵinject(i6.UserService), i0.ɵɵinject(i7.MatDialog), i0.ɵɵinject(i8.RxStompService), i0.ɵɵinject(i9.DomSanitizer), i0.ɵɵinject(i0.NgZone)); }, token: DataService, providedIn: "root" });
