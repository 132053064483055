/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./heatmap-global.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../loading-indicator/loading-indicator.component.ngfactory";
import * as i3 from "../../../loading-indicator/loading-indicator.component";
import * as i4 from "../../../../../node_modules/@angular/material/tabs/typings/index.ngfactory";
import * as i5 from "@angular/material/tabs";
import * as i6 from "@angular/common";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./heatmap-global.component";
import * as i9 from "../../../logger.service";
import * as i10 from "@angular/common/http";
import * as i11 from "../../../data.service";
import * as i12 from "@angular/router";
import * as i13 from "../../../custom-messages.service";
var styles_HeatmapGlobalComponent = [i0.styles];
var RenderType_HeatmapGlobalComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_HeatmapGlobalComponent, data: {} });
export { RenderType_HeatmapGlobalComponent as RenderType_HeatmapGlobalComponent };
function View_HeatmapGlobalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xormon-loading-indicator", [], null, null, null, i2.View_LoadingIndicatorComponent_0, i2.RenderType_LoadingIndicatorComponent)), i1.ɵdid(1, 114688, null, 0, i3.LoadingIndicatorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_HeatmapGlobalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 6, "mat-tab", [], null, null, null, i4.View_MatTab_0, i4.RenderType_MatTab)), i1.ɵdid(1, 770048, [[1, 4]], 2, i5.MatTab, [i1.ViewContainerRef], { textLabel: [0, "textLabel"] }, null), i1.ɵqud(603979776, 2, { templateLabel: 0 }), i1.ɵqud(335544320, 3, { _explicitContent: 0 }), (_l()(), i1.ɵeld(4, 0, null, 0, 0, "div", [["class", "stickyheaders-holder"]], [[8, "id", 0], [8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_HeatmapGlobalComponent_2)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.first, ""); _ck(_v, 1, 0, currVal_0); var currVal_3 = !_co.loaded; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = ("tab" + _v.context.index); var currVal_2 = !_co.loaded; _ck(_v, 4, 0, currVal_1, currVal_2); }); }
export function View_HeatmapGlobalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["id", "heatmaps"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "span", [["class", "helpfloat"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [["target", "_blank"], ["title", "Help for heatmap"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "i", [["class", "fas fa-question-circle fa-lg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 4, "mat-tab-group", [["class", "mat-tab-group"]], [[24, "@.disabled", 0], [2, "mat-tab-group-dynamic-height", null], [2, "mat-tab-group-inverted-header", null]], [[null, "selectedIndexChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectedIndexChange" === en)) {
        var pd_0 = (_co.updateTab($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_MatTabGroup_0, i4.RenderType_MatTabGroup)), i1.ɵdid(5, 3325952, null, 1, i5.MatTabGroup, [i1.ElementRef, i1.ChangeDetectorRef, [2, i5.MAT_TABS_CONFIG], [2, i7.ANIMATION_MODULE_TYPE]], { selectedIndex: [0, "selectedIndex"] }, { selectedIndexChange: "selectedIndexChange" }), i1.ɵqud(603979776, 1, { _tabs: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeatmapGlobalComponent_1)), i1.ɵdid(8, 278528, null, 0, i6.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.activeIndex; _ck(_v, 5, 0, currVal_4); var currVal_5 = _co.heatmaps; _ck(_v, 8, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.helpUrl; _ck(_v, 2, 0, currVal_0); var currVal_1 = true; var currVal_2 = i1.ɵnov(_v, 5).dynamicHeight; var currVal_3 = (i1.ɵnov(_v, 5).headerPosition === "below"); _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3); }); }
export function View_HeatmapGlobalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xormon-heatmap-global", [], null, null, null, View_HeatmapGlobalComponent_0, RenderType_HeatmapGlobalComponent)), i1.ɵdid(1, 245760, null, 0, i8.HeatmapGlobalComponent, [i9.LoggerService, i10.HttpClient, i11.DataService, i12.Router, i13.CustomMessagesService, i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeatmapGlobalComponentNgFactory = i1.ɵccf("xormon-heatmap-global", i8.HeatmapGlobalComponent, View_HeatmapGlobalComponent_Host_0, {}, {}, []);
export { HeatmapGlobalComponentNgFactory as HeatmapGlobalComponentNgFactory };
