/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reporter-groups.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./reporter-groups.component";
import * as i3 from "@angular/router";
import * as i4 from "../../../data.service";
var styles_ReporterGroupsComponent = [i0.styles];
var RenderType_ReporterGroupsComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ReporterGroupsComponent, data: {} });
export { RenderType_ReporterGroupsComponent as RenderType_ReporterGroupsComponent };
export function View_ReporterGroupsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "div", [["class", "card-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 15, "div", [["class", "cggrpnames"], ["style", "float: left; margin-right: 10px; outline: none"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 14, "table", [["class", "cfgtree center table table-hover thead-light max-width"], ["id", "repgrptable"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 12, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 11, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "th", [], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "button", [["class", "btn btn-sm btn-secondary btn-add"], ["id", "addgrp"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["New group"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Edit"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Delete"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Description"])), (_l()(), i1.ɵeld(15, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["E-mails"])), (_l()(), i1.ɵeld(17, 0, null, null, 0, "tbody", [], null, null, null, null, null))], null, null); }
export function View_ReporterGroupsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "xormon-reporter-groups", [], null, null, null, View_ReporterGroupsComponent_0, RenderType_ReporterGroupsComponent)), i1.ɵdid(1, 180224, null, 0, i2.ReporterGroupsComponent, [i3.Router, i4.DataService], null, null)], null, null); }
var ReporterGroupsComponentNgFactory = i1.ɵccf("xormon-reporter-groups", i2.ReporterGroupsComponent, View_ReporterGroupsComponent_Host_0, {}, {}, []);
export { ReporterGroupsComponentNgFactory as ReporterGroupsComponentNgFactory };
