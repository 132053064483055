<h1 mat-dialog-title>Change password</h1>
<form #f="ngForm" [matchValue]="['password', 'confirmPassword']">
  <div mat-dialog-content style="width: 300px;">
    <div class="col-md-12 form-group">
      <label>Current password</label>
      <input class="form-control" [(ngModel)]="pass.current" required type="password" name="current">
    </div>
    <div class="col-md-12 form-group">
      <label>New password</label>
      <input class="form-control" [(ngModel)]="pass.newPwd" required name="password" type="password" minlength="6"
        maxlength="50" #password="ngModel">
      <div *ngIf="password?.invalid" class="text-danger">Minimal length is 6 characters, max 50</div>
    </div>
    <div class="col-md-12 form-group">
      <label>Confirm password</label>
      <input class="form-control" [(ngModel)]="pass.confirm" required name="confirmPassword" type="password"
        #confirmPassword="ngModel">
      <div *ngIf="confirmPassword?.invalid" class="text-danger">Passwords do not match</div>
    </div>
    <div class="text-danger">{{error}}</div>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="primary" (click)="save()" cdkFocusInitial
      [disabled]="f.invalid || f.pristine">Save</button>
  </div>
</form>