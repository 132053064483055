<div class="card" id="platformCfg">
  <div class="card-body">
    <h4 class="mb-3">{{title}} configuration</h4>

    <div *ngIf="platform=='solaris'">
      <p>It is the OS agent based solution, there is not any configuration necessary on the LPAR2RRD server</p>
      <p>Just assure that LPAR2RRD daemon is running ( ps -ef | grep lpar2rrd-daemon ) </p>
      <div class="d-flex">
        <a class="mr-2" target="_blank" [href]="urls.solarisSolution">Solution overview</a>
        <a classx="btn btn-primary" target="_blank" [href]="urls.solarisInstallation">Installation
          procedure</a>
      </div>
    </div>

    <div *ngIf="platform=='hyperv'">
      <p>It is the OS agent based solution, there is not any configuration necessary on the LPAR2RRD server</p>
      <p>Just assure that LPAR2RRD daemon is running ( ps -ef | grep lpar2rrd-daemon ) </p>
      <div class="d-flex">
        <a class="mr-2" target="_blank" [href]="urls.hypervSolution">Solution overview</a>
        <a clasxs="btn btn-primary" target="_blank" [href]="urls.hypervInstallation">Installation
          procedure</a>
      </div>
    </div>

    <div *ngIf="platform=='hitachi'">
      <p>It is the OS agent based solution, there is not any configuration necessary on the LPAR2RRD server</p>
      <p>Just assure that LPAR2RRD daemon is running ( ps -ef | grep lpar2rrd-daemon ) </p>
      <div class="d-flex">
        <a classx="btn btn-primary" target="_blank" [href]="urls.hitachiInstallation">Installation
          procedure</a>
      </div>
    </div>

    <div *ngIf="page" [innerHTML]="page" class="inner-html"></div>
  </div>
</div>