import { AfterViewInit, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import Muuri from 'muuri';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { AbstractGraph } from 'src/app/graph/abstract-graph';
import { LoggerService } from 'src/app/logger.service';
import { Dashboard, DashboardGraph, DashboardGroup, DashboardTab } from 'src/app/model/dashboard';
import { Deferred } from 'src/app/model/deferred';
import { PortalService } from './portal.service';

declare function hex_md5(s);
//declare var Muuri;

@Component({
  selector: 'xormon-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PortalComponent implements OnInit, AfterViewInit, OnDestroy {

  static readonly CLASS_LOADING = 'loading';

  readonly dbContent = 'dbcontent';
  private readonly idTop = '#' + this.dbContent;

  private navigationSubscription = Subscription.EMPTY;
  private dashBoard: Dashboard;
  currentTab: DashboardTab;

  private imgEls: JQuery<HTMLElement>;
  private timerIdLong: number;
  private timerIdShort: number;
  private timeoutDashboard: number;
  private viewInited = new Deferred();

  constructor(private dataService: DataService, private portalService: PortalService, private log: LoggerService,
    private router: Router, private confirmService: ConfirmationService) {
    this.dashBoard = this.dataService.dashboard;
    this.navigationSubscription = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.dataService.nodeSelected.promise.then(() => {
          this.viewInited.promise.then(() => {
            if (!this.navigationSubscription.closed) {
              if (Globals.isSameNavigation(e))
                return;
              this.genDashboard();
            }
          });
        });
      });
  }

  ngAfterViewInit(): void {
    this.viewInited.resolve();
  }

  ngOnInit() {

    this.timerIdShort = window.setInterval(() => {
      $.each(this.imgEls, (index, el) => {
        let src = el.getAttribute('src');
        if (src.includes('time=d')) {
          $(el).parent().addClass(PortalComponent.CLASS_LOADING);
          src = this.replaceUrlParam(src, 'none', AbstractGraph.getNone());
          src = this.replaceUrlParam(src, Globals.QUERY_PARAM_NAME_UPDATE_SESSION, false);
          el.setAttribute('src', src);
        }
      });
    }, Globals.GRAPH_RELOAD_INTERVAL_SHORT);
    this.timerIdLong = window.setInterval(() => {
      $.each(this.imgEls, (index, el) => {
        let src = el.getAttribute('src');
        if (!src.includes('time=d')) {
          $(el).parent().addClass(PortalComponent.CLASS_LOADING);
          src = this.replaceUrlParam(src, 'none', AbstractGraph.getNone());
          src = this.replaceUrlParam(src, Globals.QUERY_PARAM_NAME_UPDATE_SESSION, false);
          el.setAttribute('src', src);
        }
      });
    }, Globals.GRAPH_RELOAD_INTERVAL_LONG);
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
    clearInterval(this.timerIdLong);
    clearInterval(this.timerIdShort);
    clearTimeout(this.timeoutDashboard);
  }

  private genDashboard() {
    const self = this;
    this.currentTab = this.dashBoard.tabs.find(value => (value.id + '') == this.dataService.selectedNode.key
      || ((value.name === Globals.TITLE_GLOBAL && this.dataService.selectedNode.key === Globals.TYPE_GLOBAL ||
        value.name === Globals.DEFAULT_DASHBOARD_NAME && this.dataService.selectedNode.key.endsWith('Portal'))
        && value.writable)
      && value.technology === this.dataService.technology);
    if (!this.currentTab || !this.currentTab.groups.length) {
      $(this.idTop).empty().hide();
      return;
    }
    $(this.idTop).empty().show();
    $('.olddbbutton').hide();
    $('.newdbbutton').show();
    var dashboardClickInit = function () {
      $('#dbcontent img.lazydb').lazy({
        bind: 'event',
        effect: 'fadeIn',
        effectTime: 400,
        threshold: 100,
        appendScroll: $("div#dbcontent"),
        beforeLoad: function (element) {
          element.parents(".crop").css("vertical-align", "middle");
          jQuery.ajax({
            url: $(element).attr("data-src"),
            success: function (data, textStatus, jqXHR) {
              var error = jqXHR.getResponseHeader(Globals.HEADER_ERROR);
              if (error) {
                var baseurl = $(element).data("baseurl");
                $(element).replaceWith("<div class='db_error_placeholder' data-baseurl='" + baseurl + "'>" + Base64.decode(error) + "</div><div class='dash' title='Remove this item from DashBoard'></div>");
              }
            }
          });
        },
        afterLoad: function (element) {
          $(element).removeClass('load');
          $(element).parents(".crop").css("vertical-align", "top");
        }
      });
      $('#dbcontent img.lazydb').on("click", function (ev) {
        ev.stopPropagation();
        ev.stopImmediatePropagation();
        ev.preventDefault();
        self.dbColorBox(this);
      });
      $('.grpname_edicon').off().on("click", function (ev) {
        var $span = $(this).siblings('.grpname');
        var name = $span.text();
        if (name == "Unclassified") {

        }
        $("<div></div>").dialog({
          // Remove the closing 'X' from the dialog
          open: function (event, ui) {
            $(".ui-dialog-titlebar-close").hide();
            $(this).append("<input maxLength='250' id='dbgrpedit' autofocus value='" + name + "'/>");
            $("#dbgrpedit").width("18em");
          },
          buttons: {
            "OK": function () {
              var newtext = $("#dbgrpedit").val() as string;
              $(this).dialog("close");
              $span.text(newtext);
              self.saveFlexiDashboard();
            },
            "Cancel": function () {
              $(this).dialog("close");
            }
          },
          close: function (event, ui) {
            $(this).remove();
          },
          resizable: false,
          position: { my: 'left', at: 'right', of: $(this) },
          minWidth: 340,
          title: "Rename dashboard group",
          modal: true
        });
      });
    };

    $("#dbcontent").empty();
    $("#emptydash").hide();

    if (self.dashBoard.tabs.length > 0) {

      $.each(self.currentTab.groups, function (gi, group) {
        $(self.idTop).append(self.genDbGroup(group));
      });
      if (self.currentTab.groups.length) {
        self.gridInit();
      }

      // $('#dbtabs').off("click").on('click', ".rmdbtab", function (ev) {
      //   ev.stopPropagation();
      //   var tabtoremove = $(ev.target).parent().text();
      //   var conf = confirm("Do you really want to remove tab [" + tabtoremove + "] and all it's content from Dashboard?");
      //   if (conf === true) {
      //     var panelId = $(this).closest("li").remove().attr("aria-controls");
      //     $("#" + panelId).remove();
      //     self.saveFlexiDashboard();
      //   }
      // });
      // $('.tab').off().on('dblclick', function () {
      //   $(this).find('input').toggle().val($(this).find('a').html()).focus();
      //   $(this).find('a').toggle();
      // });
      // $('.tab').on('keydown blur dblclick', 'input', function (e) {
      //   if (e.type == "keydown") {
      //     if (e.which == 13) {
      //       $(this).toggle();
      //       $(this).siblings('a').toggle().html($(this).val() as string);
      //       self.saveFlexiDashboard();
      //     }
      //     if (e.which == 38 || e.which == 40 || e.which == 37 || e.which == 39) {
      //       e.stopPropagation();
      //     }
      //   } else if (e.type == "focusout") {
      //     if ($(this).css('display') == "inline-block") {
      //       $(this).toggle();
      //       $(this).siblings('a').toggle().html($(this).val() as string);
      //       self.saveFlexiDashboard();
      //     }
      //   } else {
      //     e.stopPropagation();
      //   }
      // });

    } else {
      $.each(self.dashBoard.tabs[0].groups, function (i, group) {
        $("#dbcontent").append(self.genDbGroup(group));
      });
      self.gridInit();
    }
    dashboardClickInit();

  }

  private dbColorBox(img) {
    const self = this;
    var tUrl = Globals.htmlUrlPrefix + Globals.RUNTIME_PATH + $(img).data("baseurl");
    tUrl = tUrl.replace(/detail=\d/, 'detail=1');
    tUrl += "&none=" + Math.floor(new Date().getTime() / 1000);
    $.colorbox({
      photo: true,
      href: tUrl,
      speed: 100,
      fadeOut: 100,
      scalePhotos: true,
      maxWidth: "95%",
      initialWidth: 1326,
      initialHeight: 700,
      opacity: 0.4,
      onComplete: function () {
        $('.cboxPhoto').off().on("click", $.colorbox.close);
        if ($(this).text() === 'This image failed to load') {
          self.dataService.ping();
        }
      }
    });
  }

  private saveFlexiDashboard() {
    if (!this.currentTab.writable)
      return;
    const self = this;
    var dbTree: Dashboard = { tabs: [] };
    var tabObj: DashboardTab = {
      name: self.currentTab.name,
      groups: [],
      id: this.currentTab.id,
      technology: this.currentTab.technology,
      shared: this.currentTab.shared,
      writable: this.currentTab.writable,
      owner: this.currentTab.owner
    };
    var tabGroups = $(this.idTop).find(".dashgroup");
    tabGroups.each(function (gi, grobj) {
      var grpname = $(grobj).find(".grpname").text();
      if (!grpname) {
        grpname = $(grobj).find(".dash-group-header").text();
      }
      var grptree: DashboardGraph[] = [];
      var grid = $(grobj).find(".grid");
      if ($(grid).find("img.lazydb")) {
        $.each($(grid).find('.grid-item'), function (item, itemobj) {
          var img = $(itemobj).find("img.lazydb");
          if (!img.length) {
            img = $(itemobj).find(".db_error_placeholder");
          }
          var url = img.data("baseurl");
          if (url) {
            url = url.replace(/&none=.*/g, '');
            var dbobj: DashboardGraph = {
              id: { graphUrl: url, groupId: parseInt(grobj.getAttribute('data-key')) },
              title: img.siblings(".dbitemtitle").text(),
              width: $(itemobj).width(),
              height: $(itemobj).height(),
              backlink: $(itemobj).data('backlink'),
              sortOrder: item,
              lpar: $(itemobj).data('lpar')
            };
            grptree.push(dbobj);
          }
        });
        var dbGroup: DashboardGroup = {
          name: grpname,
          graphs: grptree,
          width: $(grobj).width(),
          height: $(grobj).height(),
          id: $(grobj).data('key'),
          sortOrder: gi
        };
        tabObj.groups.push(dbGroup);
      }
    });
    dbTree.tabs.push(tabObj);

    self.portalService.saveTabs(dbTree.tabs).subscribe(data => {
      this.currentTab.groups = data.find(g => g.id === this.currentTab.id).groups;
      this.currentTab.groups.sort((a, b) => a.sortOrder < b.sortOrder ? -1 : 1);
      for (const group of this.currentTab.groups) {
        group.graphs.sort((a, b) => a.sortOrder < b.sortOrder ? -1 : 1);
      }
    }, error => self.log.error('Failed to save dashboard item: ' + tabObj.name, error));

  }

  private genDbGroup(group: DashboardGroup) {
    const self = this;
    var divgrid = $('<div class="grid" />');
    let removeDiv = this.currentTab.writable ? '<div class="rmdbitem hover-danger fa fa-times" title="Remove this item from DashBoard"></div>' : '';
    let maxWidth = 0;
    $.each(group.graphs, function (j, item) {
      var iw = item.width ? item.width : 220;
      var ih = item.height ? item.height : 170;
      if (maxWidth < iw)
        maxWidth = iw;
      iw -= 50;
      ih -= 60;
      var imgurl = Globals.htmlUrlPrefix + Globals.RUNTIME_PATH + item.id.graphUrl + "&width=" + iw.toString() + "&height=" + ih.toString() + "&nonefb=" + Math.floor(new Date().getTime() / 1000);
      imgurl = self.replaceUrlParam(imgurl, "detail", 2);
      let fullTitle = Globals.escapeHtml(item.title);
      let shortTitle = fullTitle.split(Globals.TITLE_SEPARATOR)[0].trim();
      var el = $("<div class='grid-item'><div class='crop loading'><span class='dbitemtitle' title='" + fullTitle + "'>" + shortTitle + "</span><br><img class='lazydb' src='css/images/sloading.gif' data-src='"
        + imgurl + "' data-baseurl='" + item.id.graphUrl + "' title='" + "' alt='" + "'><div class='jumptopage hover-info fa fa-share-square' title='Jump to related page'></div>" + removeDiv + "</div></div>");
      el.width(item.width);
      el.height(item.height);
      el.data('backlink', item.backlink);
      el.data('lpar', item.lpar);
      divgrid.append(el);
    });
    var grpicons = this.currentTab.writable ? "<span class='grpname_edicon hover-info fa fa-pen' title='Edit group name'></span><div class='dbgrpremove hover-danger mt-1 fa fa-times' title='Remove this group from DashBoard'></div>" : '';
    var dgroup = $("<div class='dashgroup' data-key='" + group.id + "'><div class='group-crop'><div class='dashgroup-header'><span class='grpname' title='" + Globals.escapeHtml(group.name) + "'>" + Globals.escapeHtml(group.name) + "</span>" + grpicons + "</div></div></div>");
    if (group.height) {
      //dgroup.height(group.height);
    }
    if (group.width) {
      dgroup.width(group.width);
    }
    dgroup.css('min-width', (maxWidth + 8) + 'px');
    dgroup.find(".dashgroup-header").after(divgrid);
    divgrid.find('img').on('load', (event) => {
      $(event.currentTarget).parent().removeClass(PortalComponent.CLASS_LOADING);
    });
    return dgroup;
  }

  private gridInit(tab?) {
    const self = this;
    var grids = [],
      mainGrid: Muuri,
      mainGridSelector = this.idTop;

    $(mainGridSelector + ' .grid').each(function (i) {
      var $grid = $(this);
      var grid = new Muuri(this, {
        dragEnabled: true,
        dragContainer: document.body,
        dragPlaceholder: {
          enabled: true
        },
        items: '.grid-item',
        layout: {
          //both: true,

        },
        dragSort: function () {
          return grids;
        },
        dragStartPredicate: function (item, event) {
          if (!event.isFinal && event.target.className !== 'lazydb') {
            return false;
          }
          return Muuri.ItemDrag.defaultStartPredicate(item, event, {
            //handle: '.crop',
            distance: 10,
            delay: 50
          });
        }
      });
      $grid.on('click', "div.rmdbitem", function (ev) {
        let item: HTMLElement = $(this).parents(".grid-item")[0];
        item.classList.add('selected-danger');
        self.confirmService.confirm({
          message: 'Are you sure to delete selected graph?',
          accept: () => {
            grid.remove([grid.getItem(item)], { removeElements: true });
            mainGrid.refreshItems().layout();
            self.saveFlexiDashboard();
            item.classList.remove('selected-danger');
          },
          reject: () => {
            item.classList.remove('selected-danger');
          }
        });
      });
      $grid.on('click', "div.jumptopage", function (ev) {
        let backlink = $(this).parents(".grid-item").data('backlink');
        self.dataService.navigateToBacklink(backlink);
      });
      grid.on('dragReleaseStart', function (item) {
        $(item.getElement()).find("img").off("click"); // dirty hack for FF firing click event on drag stop
      });
      grid.on("dragReleaseEnd", function (item) {
        $(item.getElement()).find("img").on("click", function (ev) {
          self.dbColorBox(this);
        });
        grid.synchronize();
        grid.refreshItems();
        grid.layout();
        $(item.getElement()).data('grid', grid);
        mainGrid.refreshItems().layout();
        self.saveFlexiDashboard();
      });

      // get item elements, jQuery-ify them
      var $itemElems = $grid.find(".grid-item");

      $itemElems.data('grid', grid);
      $grid.closest('.dashgroup').data('grid', grid);
      // make item elements resizable
      $itemElems.resizable({
        grid: [51, 31],
        handles: "se",
        minWidth: 170,
        minHeight: 90,
        start: function (event, ui) {
        },
        stop: function (event, ui) {
          var crop = $(event.target).find("div.crop");
          crop.css("width", ui.size.width);
          crop.css("height", ui.size.height);
          crop.addClass(PortalComponent.CLASS_LOADING);
          var img = crop.find("img");
          var src = img.prop("src");
          src = self.replaceUrlParam(src, "width", ui.size.width - 50);
          src = self.replaceUrlParam(src, "height", ui.size.height - 60);
          img.attr("src", src);
          let group = $grid.closest('.dashgroup');
          if (group.width() < ui.size.width) {
            let w = ui.size.width + 4
            group.width(w);
            group.css('min-width', (w + 4) + 'px');
          } else {
            let max = 0;
            $itemElems.each((i, el) => {
              if (max < el.offsetWidth) {
                max = el.offsetWidth;
              }
            });
            group.css('min-width', (max + 8) + 'px');
          }
          ui.element.data('grid').refreshItems().layout();
          mainGrid.refreshItems().layout();
          self.saveFlexiDashboard();
        }
      });
      $itemElems.on('resize', function (event, ui) {
        ui.element.data('grid').refreshItems().layout();
      });
      grids.push(grid);
    }); // each
    self.imgEls = $('.grid-item img');
    clearTimeout(this.timeoutDashboard);
    this.timeoutDashboard = window.setTimeout(function () {
      mainGrid = new Muuri(mainGridSelector, {
        layoutDuration: 400,
        items: '.dashgroup',
        dragPlaceholder: {
          enabled: true
        },
        dragContainer: document.querySelector(mainGridSelector),
        dragEnabled: true,
        dragHandle: '.dashgroup-header',
        dragStartPredicate: {
          //handle: '.dashgroup-header',
          distance: 10,
          delay: 50
        },
        //dragReleaseDuration: 400,
        //dragReleaseEasing: 'ease'
      });
      mainGrid.on("dragReleaseEnd", function (item) {
        mainGrid.synchronize();
        self.saveFlexiDashboard();
      });
      $(mainGridSelector + ' .dbgrpremove').on("click", function (ev) {
        self.confirmService.confirm({
          message: 'Are you sure to delete group <i>' + $(this).parents('.dashgroup').find('.grpname').text() + '</i> and all its items?',
          accept: () => {
            mainGrid.remove(mainGrid.getItems($(this).parents('.dashgroup')[0]), { removeElements: true });
            self.saveFlexiDashboard();
          }
        });
      });
    }, 500);
    let $items = $(mainGridSelector + " .dashgroup");
    $items.resizable({
      //grid: [87, 57],
      handles: "e",
      minWidth: 190,
      minHeight: 120,
      stop: (event, ui) => {
        var crop = $(event.target);//.find("div.group-crop");
        crop.css("width", ui.size.width);
        //crop.css("height", ui.size.height);
        ui.element.data('grid').refreshItems().layout();
        mainGrid.refreshItems().layout();
        this.saveFlexiDashboard();
      }
    });
    // handle resizing
    $items.on('resize', function (event, ui) {
      ui.element.data('grid').refreshItems().layout();
      mainGrid.refreshItems().layout();
    });

  }

  private replaceUrlParam(url: string, paramName: string, paramValue) {
    if (!url) {
      return "";
    }

    if (paramValue == null) {
      paramValue = '';
    }

    var pattern = new RegExp('\\b(' + paramName + '=).*?(&|#|$)');
    if (url.search(pattern) >= 0) {
      return url.replace(pattern, '$1' + paramValue + '$2');
    }

    url = url.replace(/[?#]$/, '');
    return url + (url.indexOf('?') > 0 ? '&' : '?') + paramName + '=' + paramValue;
  }

}
