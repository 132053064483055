import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';
import { Product } from 'src/app/model/product';
import { StorageTotalsService } from '../storage-totals.service';

@Component({
  selector: 'xormon-hosts-top',
  templateUrl: './hosts-top.component.html',
  styleUrls: ['./hosts-top.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HostsTopComponent implements OnInit {

  constructor(private totalsStService: StorageTotalsService, private log: LoggerService) { }

  tabsTps: any[];

  ngOnInit() {

    this.totalsStService.getTabsHost().subscribe(data => {

      this.tabsTps = data.tps;

    }, error => this.log.error('Failed to hosts!', error));
  }

  onTabSelected(indexObj) {
    var index = indexObj.index;
    var tabCont = $('#tab' + index).html();
    //if its not null than there is something loaded (dont load again)
    if (tabCont === null || tabCont === undefined || tabCont.match(/table/g) === null) {
      var tab = $('#tab' + index);

      var urlName = this.tabsTps[index].url;
      this.totalsStService.getUrlHost(urlName).subscribe(page => {

        var csvOld = page.match(/<a class="csvfloat".*<\/a>/g);
        var fixedPage = page.replace(/<a class="csvfloat".*<\/a>/g, "");
        var csvPath = csvOld[0].match(/glob-HOST(.*?)\.csv/g);
        var csv = " <a class='csvfloat btn btn-link' href=\'" + Globals.htmlUrlPrefix + Globals.RUNTIME_PATH + Product.STOR.path + csvPath + "'title='CSV'> <span class='fas fa-file-csv fa-2x'></span></a>";
        fixedPage = fixedPage + csv;
        let re = new RegExp('\\/' + Product.STOR.pathCgi + '\\/detail.sh.*?"', 'g');
        //fixedPage = fixedPage.replace(/\/stor2rrd-cgi\/detail.sh.*?"/g, Globals.RUNTIME_PATH + "$&");
        fixedPage = fixedPage.replace(re, Globals.RUNTIME_PATH + '$&');
        tab.html(fixedPage);
        $('center > .tablesorter')
          .wrap('<div class="scroll-wrap"/>');

        var $t = tab.find('table.tablesorter');
        if ($t.length) {
          Globals.tableSorter($t, true);
        }
        Globals.initFloatingScroll();

      }, error => this.log.error('Failed to get host!', error));
    }
  }
}
