import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';
import { Product } from 'src/app/model/product';

@Component({
  selector: 'xormon-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit, OnDestroy {

  config = {};
  hours = 0;
  minutes = 30;
  env = 'Getting system variables...';
  git = 'Getting GIT properties...';
  cfg = 'Getting webapp properties...';
  oshi = 'Initializing System...';

  private oshiSub = Subscription.EMPTY;

  constructor(private http: HttpClient, private log: LoggerService, private dataService: DataService) {

  }

  ngOnDestroy(): void {
    this.oshiSub.unsubscribe();
  }

  ngOnInit() {
    this.dataService.nodeSelected.promise.then(() => {

      this.http.get('/admin/info/settings').subscribe(data => {
        this.config = data;
        let timeout = JSON.parse(this.config['session.timeout_minutes']);
        this.minutes = timeout % 60;
        this.hours = timeout / 60 | 0;
      }, error => this.log.error('Failed to get server settings!', error));

      this.http.get('/admin/info/cfg', { responseType: 'text' as 'json' }).subscribe((data: string) => this.cfg = data,
        error => {
          this.cfg = 'Failed to get webapp configuration!';
          this.log.error(this.cfg, error);
        });

      this.http.get<string>('/admin/info/env', { responseType: 'text' as 'json' }).subscribe(data => {
        this.env = data;
      },
        error => {
          this.env = 'Failed to get environment variables!';
          this.log.error(this.env, error);
        });

      this.oshiSub = this.http.get('/admin/info/oshi', { responseType: 'text' as 'json' }).subscribe((data: string) => { this.oshi = data; },
        error => {
          this.oshi = 'Failed to initialize system!';
          this.log.error(this.oshi, error);
        });

    });
  }

  saveConfig() {
    let timeout = this.hours * 60 + this.minutes;
    this.config['session.timeout_minutes'] = timeout;
    this.http.put('/admin/info/settings', this.config).subscribe(() => {
      this.log.info('Settings saved successfully.');
    }, error => {
      this.log.error('Failed to save settings!', error);
    });
  }

  testError() {
    this.http.get(Globals.RUNTIME_PATH + Product.LPAR.pathCgi + '/histrep.sh').subscribe(done => this.log.info(JSON.stringify(done)),
      error => this.log.error('This is a test', error));
  }

  test() {
    this.log.error('Regular');

    this.http.get('/logout').subscribe(
      data => {
        console.log(data);
      }
    );
  }

}
