import { Directive, Input, ElementRef, HostListener, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';

@Directive({
  selector: '[xormon-poptip]'
})
export class PoptipDirective implements OnInit, OnDestroy {

  static readonly panels = new Set<OverlayPanel>();

  @Input('xormon-poptip') panel!: OverlayPanel;

  constructor() { }

  ngOnInit(): void {
    PoptipDirective.panels.add(this.panel);
    this.panel.onShow.subscribe(() => {
      $(this.panel.container).off('mouseleave').on('mouseleave', event => {
        this.panel.hide();
      });
    });
  }

  ngOnDestroy(): void {
    PoptipDirective.panels.delete(this.panel);
  }

  @HostListener('focus', ['$event']) onFocus(event: FocusEvent) {
    this.closeOthers();
    this.panel.show(event);
  }

  @HostListener('mouseenter', ['$event']) onMouseEnter(event: MouseEvent) {
    this.closeOthers();
    this.panel.show(event);
  }

  @HostListener('mouseleave', ['$event.target']) onMouseLeave(target: HTMLElement) {
    if (!$(target).is(':focus'))
      this.panel.hide();
  }

  @HostListener('blur') onBlur() {
    this.panel.hide();
  }

  private closeOthers() {
    for (const p of PoptipDirective.panels) {
      if (p !== this.panel) {
        p.hide();
      }
    }
  }

}
