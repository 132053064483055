<mat-tab-group [@.disabled]="true" id="appInfo">
  <mat-tab label="Settings">
    <form (ngSubmit)="saveConfig()" #form="ngForm" class="mb-4">
      <div class="form-row">
        <div class="col-md-6 form-group"
          title="Specifies the time between client requests before the servlet container will invalidate session. A zero or negative time indicates that the session should never timeout. Note that currently active sessions are not affected by this change.">
          <label>Session timeout:</label>
          <div class="d-flex align-items-center">
            <input class="form-control" [(ngModel)]="hours" required="required" name="hours" type="number" min="0"
              max="23" size="3" step="1" pattern="\d+" style="width: 5em;">Hours
            <input class="form-control ml-3" [(ngModel)]="minutes" required="required" name="minutes" type="number"
              min="0" max="59" step="1" pattern="\d+" size="3" style="width: 5em;">Minutes
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-primary" [disabled]="form.invalid || form.pristine">Save</button>
      <!-- <br>
      <button type="button" (click)="testError()">Test Error Detail</button>
      <br>
      <button type="button" (click)="test()">Test Error</button> -->
    </form>
  </mat-tab>
  <mat-tab label="WebApp Configuration">
    <pre>{{cfg}}</pre>
  </mat-tab>
  <mat-tab label="Environment Variables">
    <pre>{{env}}</pre>
  </mat-tab>
  <mat-tab label="HW / SW">
    <pre style="margin-bottom: 0;">{{oshi}}</pre>
  </mat-tab>
</mat-tab-group>
