import { Component, OnDestroy, OnInit } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DataService } from '../../../data.service';
import { LoggerService } from '../../../logger.service';
import { StorageGroupItem as StorageGroupItem, StorageItem } from '../../../model/storage-groups';
import { StorageGroupingService } from './storage-grouping.service';


@Component({
  selector: 'xormon-storage-grouping',
  templateUrl: './storage-grouping.component.html',
  styleUrls: ['./storage-grouping.component.scss']
})
export class StorageGroupingComponent implements OnInit, OnDestroy {

  children: StorageGroupItem[];
  storages: StorageItem[];
  selected: StorageGroupItem;
  responze: object;

  @BlockUI('storages') block: NgBlockUI;

  constructor(private storageGroupingService: StorageGroupingService, private dataService: DataService, private log: LoggerService) { }

  ngOnInit() {
    this.initStorageGroups();
    this.storageGroupingService.getStorages()
      .subscribe(storages => {
        this.storages = storages;
      }, error => this.log.error('Failed to get storage list', error));
    this.block.start();
  }

  ngOnDestroy(): void {
    this.block.unsubscribe();
  }

  private initStorageGroups() {
    this.storageGroupingService.getStorGrList()
      .subscribe(data => this.children = data, error => this.log.error('Failed to get storage groups!', error));
  }

  deleteGroup(index: number): void {
    if (this.children.splice(index, 1).some(c => c._selected))
      this.block.start();
  }
  showStorages(clicked: StorageGroupItem): void {
    this.deselect(this.children);
    clicked._selected = true;
    this.selected = clicked;
    this.storages.forEach((storage) => {
      storage.checked = this.selected.storageNames && this.selected.storageNames.some(sn => sn.object_id === storage.object_id);
    });
    this.block.stop();
  }

  saveSG(): void {
    this.storageGroupingService.saveStorages(this.children)
      .subscribe(
        data => {
          this.log.info('Storage groups saved successfully.');
          this.dataService.loadStorageGroups();
          this.initStorageGroups();
        },
        error => this.log.error('Failed saving storage groups.', error)
      );
  }

  addNewSg(): void {
    if (!this.children) {
      var tmp: StorageGroupItem[] = [];
      this.children = tmp;
    }
    else {
      this.children.push(new StorageGroupItem());
    }
  }

  onClickSelect(storage: StorageItem): void {

    if (!this.selected.storageNames) {

      this.selected.storageNames = [
        storage,
      ];
    }
    else {
      for (var i = 0; i < this.selected.storageNames.length; i++) {
        if (storage.object_id === this.selected.storageNames[i].object_id) {
          this.selected.storageNames.splice(i, 1);
          return;
        }
      }
      this.selected.storageNames.push(storage);
    }
  }

  private deselect(nodes: StorageGroupItem[]): void {

    for (var i = 0; i < nodes.length; i++) {
      nodes[i]._selected = false;
    }
  }

}
