import { HttpParams } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DataService } from 'src/app/data.service';
import { Globals } from 'src/app/globals';
import { LoggerService } from 'src/app/logger.service';
import { GraphData, PortalDialogComponent } from '../content/dashboard/portal/portal-dialog/portal-dialog.component';
import { PortalService } from '../content/dashboard/portal/portal.service';
import { Graph } from '../model/graph';
import { ROUTES } from '../model/routes';
import { AbstractGraph } from './abstract-graph';


@Component({
  selector: 'xormon-graph',
  templateUrl: './graph.component.html',
  styleUrls: ['./graph.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GraphComponent implements OnInit, AfterViewInit {

  @ViewChild('element', { static: true }) element: ElementRef;
  @ViewChild(AbstractGraph, { static: false }) graphComponent: AbstractGraph;

  @Output()
  reset = new EventEmitter<void>();

  @Output()
  groupZoomStart = new EventEmitter<void>();

  @Output()
  groupZoomEnd = new EventEmitter<any>();

  @Output()
  loaded = new EventEmitter<void>();

  @Input()
  graph: Graph;
  graphImg: any;
  loading = true;
  error;
  prefix = Globals.PREFIX_JQUERY;

  constructor(private dataService: DataService, private log: LoggerService, private dialog: MatDialog,
    private router: Router, private portalService: PortalService, private changeDetector: ChangeDetectorRef) {
  }


  ngOnInit() {
    this.setFavorite();
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit(): void {
  }

  private setFavorite() {
    this.graph.fav = false;
    const url = this.normalizeUrl(this.graph.imgSrc);
    for (const tab of this.dataService.dashboard.tabs) {
      if ((tab.technology === Globals.TYPE_GLOBAL || tab.technology === this.dataService.technology) && tab.writable) {
        for (const group of tab.groups) {
          for (const g of group.graphs) {
            if (g.id.graphUrl === url) {
              this.graph.fav = true;
              return;
            }
          }
        }
      }
    }
  }

  // private getUrlNone(url) {
  //   return decodeURIComponent(url.replace(/&none=-?\d+/, ''));
  // }

  private normalizeUrl(url: string) {
    const urlSplit = url.split('?');
    if (urlSplit.length === 1) {
      return urlSplit[0];
    } else {
      let params = new HttpParams({ fromString: urlSplit[1] });
      params = params.set('detail', '9').delete('none');
      return urlSplit[0] + '?' + params.toString();
    }
  }

  // private normalizeDetail(url: string) {
  //   return url.replace(/&detail=\d/, '&detail=9');
  // }

  switchGraphInDashboard(graph, event) {
    const params = Globals.getPrimaryOutlet(this.router).activatedRoute.snapshot.params;
    const queryparams = Globals.getPrimaryOutlet(this.router).activatedRoute.snapshot.queryParamMap;
    let url = new URL(location.href);
    const metric = url.searchParams.get(Globals.METRIC);
    const gd: GraphData = {
      backlink: Globals.NAV_SEPARATOR + params[ROUTES.TYPE] + Globals.NAV_SEPARATOR + params[ROUTES.PARENT] + Globals.NAV_SEPARATOR + Globals.replacement2slash(params[ROUTES.CHILD]) + "&metric=" + metric,
      technology: this.dataService.technology,
      title: this.dataService.selectedType === Globals.TYPE_CUSTOM_GROUPS ?
        this.dataService.selectedNode.title : this.dataService.getPageTitle(),
      url: this.normalizeUrl(this.graph.imgSrc)
    };
    this.dialog.open(PortalDialogComponent, {
      data: gd
    }).afterClosed().subscribe(result => {
      if (result) {
        this.setFavorite();
      }
    });
  }

  detectChanges() {
    this.loaded.emit();
    this.changeDetector.detectChanges();
  }

  @Input()
  set reload(value) {
    if (undefined === value || !this.graphComponent || this.graph.zoomed || this.graph.loading)
      return;
    this.graphComponent.load(this.graph.imgSrc + Globals.QUERY_PARAM_UPDATE_SESSION_FALSE)
      .subscribe({ error: error => this.graph.error = JSON.stringify(error) });
  }

  @Input()
  set outsideZoom(divZoomRef) {
    if (this.graph.zoom && this.graphComponent && divZoomRef)
      this.graphComponent.selectEnd(null, divZoomRef);
  }

  @Input()
  set outsideReset(value) {
    if (undefined === value) {
      return;
    }
    this.doReset($(this.element.nativeElement).find('.reset-zoom'));
  }

  resetZoom(event) {
    if (this.graphComponent.isRegrouped()) {
      this.reset.emit();
    } else {
      this.doReset(event.currentTarget);
    }
  }

  private doReset(el) {
    if (!this.graph.zoomed)
      return;
    let divGraph = $(el).closest('div.graph');
    this.graph.zoomed = false;
    this.graphComponent.load(divGraph.find("img").data("src"))
      .subscribe({ error: error => this.graph.error = JSON.stringify(error) });
  }

}
