<div id="heatmaps" *ngIf="tabs" class="d-flex flex-grow-1 flex-column">
  <mat-tab-group (selectedTabChange)="onTabSelected($event)">
    <mat-tab *ngFor="let tab of tabs; let i = index" label={{tab.name}}>

      <div id="{{'tab'+i}}" class="stickyheaders-holder center">
        <div class="spinner-wrap">
          <xormon-loading-indicator></xormon-loading-indicator>
        </div>
      </div>

    </mat-tab>
  </mat-tab-group>
</div>
<div *ngIf="!tabs" id="heatPage"></div>
